import Axios from "axios"
import "../styles/form_styles.css";
import React, { useState } from "react"
import swal from 'sweetalert';
import config from '../config.json';
import Cookies from "universal-cookie";
import mixpanel from "mixpanel-browser";

const CreateUserForm = (props) => {
    const [form, setForm] = useState({
        username: '',
        fullname: '',
        email: '',
        contact: '',
        password: '',
        password1: ''
    });
    const cookies = new Cookies();
    let actionInitiatingUser = -1;
    let userToken = "";
    try {
        actionInitiatingUser = parseInt(cookies.get('userID'));
        userToken = cookies.get('userToken');
    } catch (error) {}

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setForm({ ...form, [name]: value })
    }

    const Reset = (e) => {
        setForm({ username: "", fullname: "", email: "", contact: "", password: "", password1: "" })
    }
    
    const formSubmit = async (e) => {
        e.preventDefault();
        mixpanel.track("User Page Submit Button Clicked", { userID: cookies.get('userID') });
        const headers = {
            'Authorization': `Bearer ${userToken}`,
            'Content-Type': 'application/json'
        };
        if (form.password === form.password1) {
            swal({
                title: "Are you sure?",
                text: "Do you want to add the user?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then(async (willDelete) => {
                if (willDelete) {
                    setForm({ username: "", fullname: "", email: "", contact: "", password: "", password1: "" })
                    const { username, fullname, email, contact, password } = form;
                    Axios.post(config.BACKEND_URL + "/users", { username, fullname, email, contact, password, actionInitiatingUser}, { headers }).then(res => {
                        swal("User created successfully!", "Click on OK to continue...", "success").then(() => { window.location.reload(); })
                        Reset()
                    }).catch(err => {
                        swal("Invalid Credentials", "Process Failed", "error")
                        console.log(err)
                    });
                } else {
                    swal("User not added!", "", "info");
                }
            });
        }
        else {
            swal("Password mismatch!", "Process failed. Try again.", "error")
        }
        // window.location.reload(false);
    }

    return (
        <form className="row" onSubmit={formSubmit} id='userSubmitForm'>
            <div className="col-xs-4 col-xs-offset-4">
                <div className="floating-label-group">
                    <input type="text" id="username" className="form-control" autoComplete="off"
                        autoFocus required style={{ fontWeight: "bold" }}
                        name="username" value={form.username}
                        onChange={handleChange} />
                    <label className="floating-label">Username</label>
                </div>
                <div className="floating-label-group">
                    <input type="text" id="userfullname" className="form-control" autoComplete="off"
                        autoFocus required style={{ fontWeight: "bold" }}
                        name="fullname" value={form.fullname}
                        onChange={handleChange} />
                    <label className="floating-label">Full Name</label>
                </div>
                <div className="floating-label-group">
                    <input type="text" id="useremail" className="form-control" autoComplete="off"
                        autoFocus required style={{ fontWeight: "bold" }} name="email"
                        value={form.email}
                        onChange={handleChange} />
                    <label className="floating-label">Email ID</label>
                </div>
                <div className="floating-label-group">
                    <input type="text" id="usercontact" className="form-control" autoComplete="off"
                        onKeyPress={(e) => {
                            if (!/[0-9]/.test(e.key)) {
                                e.preventDefault();
                            }
                        }}
                        autoFocus required style={{ fontWeight: "bold" }} name="contact"
                        value={form.contact}
                        onChange={handleChange} />
                    <label className="floating-label">Contact</label>
                </div>

                <div className="floating-label-group">
                    <input type="password" id="userpassword" className="form-control" autoComplete="off"
                        required style={{ fontWeight: "bold" }} name="password"
                        value={form.password}
                        onChange={handleChange} />
                    <label className="floating-label">Password</label>
                </div>
                <div className="floating-label-group">
                    <input type="password" id="usercpassword" className="form-control" autoComplete="off"
                        required style={{ fontWeight: "bold" }} name="password1"
                        value={form.password1}
                        onChange={handleChange} />
                    <label className="floating-label">Confirm Password</label>
                </div>
            </div>
            <section className="d-flex justify-content-around">
                <button className="btn btn-danger" type="reset" onClick={Reset}>
                    Reset
                </button>
                <button className="btn btn-success" type="submit" id='usersubmit'>
                    Submit
                </button>
            </section>
        </form>
    );
}

export default CreateUserForm;