import React from "react";
import { Route } from "react-router-dom";
import swal from "sweetalert";

const middleware = (history, location, next) => {
    // Define an array of required cookie names
    const requiredCookies = ['urreport', 'urdashboard', 'urrole','uruserGroup', 'urview', 'uralarm','uruserDashboard', 'uruser', 'userToken', 'ursource', 'userID'];

    // Check if all required cookies exist
    const missingCookies = requiredCookies.filter(cookieName => !document.cookie.includes(cookieName));
    if (missingCookies.length > 0) {
        // If any required cookies are missing, clear all cookies and redirect to logout route
        document.cookie.split(";").forEach((c) => {
        document.cookie = c
            .replace(/^ +/, "")
            .replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`);
        });
        swal({ title: "Invalid Session", text: "Your session has expired. Please login again.", icon: "warning", timer: 2000, buttons: false }).then(() => {
            window.location.replace("http://localhost:3003");
        });
    } else {
        // If all required cookies exist, continue to next middleware or route handler
        next();
    }
};
const ProtectedRoute = ({ component: Component, ...restOfProps }) => {
    return (
        <Route
            {...restOfProps}
            render={({ history, location, match }) => {
                middleware(history, location, () => {
                    return <Component />;
                });
                return <Component />;
            }}
        />
    );
}

export default ProtectedRoute;