import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from "react-router-dom"
import { CookiesProvider } from 'react-cookie';

ReactDOM.render(
    <React.Fragment>
        <CookiesProvider>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </CookiesProvider>
    </React.Fragment>
    , document.getElementById('root'));

serviceWorker.unregister();
