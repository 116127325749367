import React, { useEffect, useState } from "react"
import '../styles/user_management_styles.css'
import Navbar from "./Navbar.js"
import 'bootstrap/dist/css/bootstrap.min.css';
import Cookie from 'universal-cookie';
import { decrypt } from './useCryptoJS';
import UserTable from "./tables/UserTable";
import CreateUserForm from "./CreateUserForm.js";

const UserManagement_Users = () => {
    const cookies = new Cookie();
    const [uruser, setUruser] = useState("0000");
    
    useEffect(()=>{
        try {
            setUruser(decrypt(cookies.get('uruser')));
        } catch (error) {}
    }, []);
    return (
        <div className="UserManagement_Users">
            <Navbar />
            <main style={{ 'textAlign': 'center' }}>
                <div className="main-content row">
                    <header className="h1">User Management</header>
                    { (uruser.charAt(0)==='1' || uruser.charAt(2)==='1' || uruser.charAt(3)==='1') && 
                        <section className="col-sm-7">
                            <section style={{ marginLeft: "1vw" }} className="center">
                                <div className="h2">
                                    Current Users
                                </div>
                                <div>
                                    <UserTable edit={uruser.charAt(2)==='1'} deletee={uruser.charAt(3)==='1'} />
                                </div>
                            </section>
                        </section>
                    }
                    { uruser.charAt(1)==='1' &&
                        <section className="col-sm-5">
                            <section style={{ marginRight: "1vw" }} className="center">
                                <div className="h2 mb-3">
                                    Create Users
                                </div>
                                <div id="form"><CreateUserForm /></div>
                            </section>
                        </section>
                    }
                </div>
            </main>
        </div>
    )
}

export default UserManagement_Users;
