import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/muuri_styles.css';
import '../styles/add_pane.css';
import '../styles/modal.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import mixpanel from 'mixpanel-browser';
import Cookie from 'universal-cookie';

function AddDashboardModal(props) {
    const cookies = new Cookie();
    return (
        <div className="modalOverlay">
            <div className="overlay"></div>
            <div className="modal-content">
                <div className='close-form'>
                    <button className="close-modal btn btn-danger" onClick={() => props.setToggleAddDashboard(false)}>
                        <FontAwesomeIcon icon={faTimes} />
                    </button>
                </div>
                <div className='form-settings'>
                    <form>
                        <div>
                            <div className='form-row'>
                                <div className='form-label'>
                                    <label >Dashboard Name</label>
                                </div>
                                <input className='dashboard-name-input' name="dashboard_name" ></input>
                            </div>
                            <button className="btn btn-primary btn-sm btn-submit" onClick={(event) => {
                                mixpanel.track("Add Dashboard Submit Button Clicked", { userID: cookies.get('userID') });
                                event.preventDefault();
                                props.addDashboardHandler();
                                props.setToggleAddDashboard(false)
                            }}>
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default AddDashboardModal;