// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import config from "../config.json";
// import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
// import TableCell from '@material-ui/core/TableCell';
// import TableContainer from '@material-ui/core/TableContainer';
// import TableHead from '@material-ui/core/TableHead';
// import TableRow from '@material-ui/core/TableRow';
// import Paper from '@material-ui/core/Paper';
// import '../styles/add_pane.css';

// let columns = [];
// let srcArr = [];

// const RealtimeTable = ({ sources, datasets, paneID }) => {
//     const numberOfColumns = datasets[0].columns;
//     const [data, setData] = useState([]);

//     const getRealtimeTableData = async () => {
//         try {
//             const response = await axios.post(config.BACKEND_URL + '/widgets/get-realtime-data', { paneID });
//             console.log('Response data:', response.data.data);
//             const newRows = response.data.data; // Access the 'data' field directly
//             console.log('New rows:', newRows);

//             // Check if newRows is an array
//             if (Array.isArray(newRows)) {
//                 // Flatten the data arrays from newRows
//                 const flattenedData = newRows.flatMap(row => row.value);
//                 console.log(flattenedData);
//                 setData(newRows);
//             } else {
//                 console.error('Expected an array but got', newRows);
//             }
//         } catch (error) {
//             console.log('Error fetching realtime table data:', error);
//         }
//     };

//     const subscribeToDataSource = async () => {
//         try {
//             await axios.post(config.BACKEND_URL + '/widgets/subscribe-to-data-source', { sources: srcArr, paneID });
//         } catch (error) {
//             console.log(error);
//         }
//     };

//     useEffect(() => {
//         columns = [];
//         srcArr = [];
//         console.log("sources: ", sources);
//         console.log("datasets: ", datasets);
//         console.log("numberOfColumns: ", numberOfColumns);
//         srcArr = sources.split(',').map(item => item.trim());
//         for (let i = 0; i < numberOfColumns; i++) {
//             const labelKey = `rt_label${i}`;
//             // const valueKey = `rt_value${i}`;

//             const label = datasets[0][labelKey];
//             // const value = datasets[0][valueKey];

//             const column = {
//                 title: label,
//                 // field: value
//             };
//             columns.push(column);
//         }
//         console.log("columns: ", columns);
//         subscribeToDataSource();
//         const interval = setInterval(() => {
//             getRealtimeTableData();
//         }, 2000);

//         return () => {
//             clearInterval(interval);
//         };
//     }, []);

//     return (
//         <div style={{ maxHeight: '500px', overflowY: 'auto', padding: '1rem 0.5rem' }}>
//             <h4>Realtime Table Data</h4>
//             <TableContainer component={Paper} className="dark-table-container">
//                 <Table aria-label="simple table" className="dark-table">
//                     <TableHead>
//                         <TableRow>
//                             {columns.map((column, index) => (
//                                 <TableCell key={index} className="dark-table-cell">
//                                     {column.title}
//                                 </TableCell>
//                             ))}
//                         </TableRow>
//                     </TableHead>
//                     <TableBody>
//                         <TableRow className="dark-table-row">
//                             <TableCell className="dark-table-cell">
//                                 {/* {54} */}
//                             </TableCell>
//                         </TableRow>
//                         {/* {data && data.length > 0 ? (
//                             data.map((row, rowIndex) => (
//                             <TableRow key={rowIndex} className="dark-table-row">
//                                 {columns && columns.length > 0 ? (
//                                     columns.map((column, columnIndex) => {
//                                     const value = row[column.field]; // Access the correct field in each row
//                                     console.log(`Row ${rowIndex}, Column ${columnIndex}: ${columns}, value: ${value}`);
//                                     return (
//                                         <TableCell key={columnIndex} className="dark-table-cell">
//                                             {value}
//                                         </TableCell>
//                                     );
//                                 })):null}
//                             </TableRow>
//                         ))):null} */}
//                     </TableBody>
//                 </Table>
//             </TableContainer>
//         </div>
//     );
// };

// export default RealtimeTable;



import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from "../config.json";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import '../styles/add_pane.css';

const RealtimeTable = ({ sources, datasets, paneID, widgetProperty }) => {
    const [columns, setColumns] = useState([]);
    const [data, setData] = useState({});

    const getRealtimeTableData = async () => {
        try {
            const response = await axios.post(config.BACKEND_URL + '/widgets/get-realtime-data', { paneID, sources });
            console.log('Response data:', response.data.data);
            const newRows = response.data; // Access the 'data' field directly
            // Check if newRows is an array
            setData(newRows);
            
        } catch (error) {
            console.log('Error fetching realtime table data:', error);
        }
    };

    const subscribeToDataSource = async () => {
        try {
            await axios.post(config.BACKEND_URL + '/widgets/subscribe-to-data-source', { sources: sources.split(',').map(item => item.trim()), paneID });
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        const numberOfColumns = widgetProperty.columns;
        const cols = [];
        // const srcArr = sources.split(',').map(item => item.trim());

        // console.log("sources: ", sources);
        // console.log("datasets: ", datasets);
        // console.log("numberOfColumns: ", numberOfColumns);

        for (let i = 0; i < numberOfColumns; i++) {
            const labelKey = `label${i}`;
            const label = datasets[i][labelKey];
            const column = { title: label };
            cols.push(column);
        }

        setColumns(cols);
        // subscribeToDataSource();
        const interval = setInterval(() => {
            getRealtimeTableData();
        }, 2000);

        return () => {
            clearInterval(interval);
        };
    }, [sources, datasets, paneID]);

    return (
        <div >
            <h4 style={{ color: 'black' }}>{widgetProperty.tableName}</h4>
            <TableContainer component={Paper} className="dark-table-container">
                <Table aria-label="simple table" className="dark-table">
                    <TableHead>
                        <TableRow key={"0"}>
                            {columns.map((column, index) => (
                                <TableCell key={index} className="dark-table-cell">
                                    {column.title}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    <TableRow key={"1"} className="dark-table-row">
                        {Object.keys(data).map((value, index) => (
                            Object.keys(data[value]).map((ind) => (
                                <TableCell className="dark-table-cell">
                                {data[value][ind]}
                            </TableCell>
                            ))
                           
                        )
                        )}
                         </TableRow>

                   
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default RealtimeTable;
{/* {data && data.length > 0 ? (
                            data.map((row, rowIndex) => (
                                <TableRow key={rowIndex} className="dark-table-row">
                                    {columns && columns.length > 0 ? (
                                        columns.map((column, columnIndex) => {
                                            const value = row[column.field]; // Access the correct field in each row
                                            console.log(`Row ${rowIndex}, Column ${columnIndex}: ${columns}, value: ${value}`);
                                            return (
                                                <TableCell key={columnIndex} className="dark-table-cell">
                                                    {value}
                                                </TableCell>
                                            );
                                        })):null}
                                </TableRow>
                            ))):null} */}