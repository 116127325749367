import React from "react"
import '../styles/user_management_styles.css'
import '../styles/alarms_create.css'
import Navbar from "./Navbar.js"
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import Table from "./Table.js";
import useFetch from './useFetch.js';
import swal from "sweetalert";
import axios from "axios";
import MultiSelect from "./MultiSelect";
import config from '../config.json';
import Cookie from 'universal-cookie';
import mixpanel from "mixpanel-browser";

const Alarms_Create = () => {
    const cookies = new Cookie();
    let actionInitiatingUser = -1;
    let userToken = "";
    try {
        actionInitiatingUser = parseInt(cookies.get('userID'));
        userToken = cookies.get('userToken');
    } catch (error) {}
    const headers = {
        'Authorization': `Bearer ${userToken}`,
        'Content-Type': 'application/json'
    };
    const { isPending: isPendingAlarmSettings, data: alarmSettings, error: errorAlarmSettings } = useFetch({ actionInitiatingUser: actionInitiatingUser.toString() , URL: config.BACKEND_URL + '/alarms' });
    const { isPending: isPendingSources, data: sources, error: errorSources } = useFetch({ actionInitiatingUser: actionInitiatingUser.toString() , URL: config.BACKEND_URL + '/logger_sources/sources' });
    const { isPending: isPendingUserGroups, data: userGroups, error: errorUserGroups } = useFetch({ actionInitiatingUser: actionInitiatingUser.toString() , URL: config.BACKEND_URL + '/usergroups' });
    let selectedUserGroups = [];
    const handleUserGroupSelection = (selected) => {
        selectedUserGroups = selected.map(ele => ele.value);
    }

    const handleAlarmsSubmit = async (e) => {
        e.preventDefault();
        mixpanel.track("Create Alarm Submit Button Clicked", { userID: cookies.get('userID') });
        let title = $("#title")[0].value,
            value = $("#value")[0].value,
            direction = $("#direction")[0].value,
            userGroupIDs = selectedUserGroups,
            color = $("#color")[0].value,
            sourceId = $("#source")[0].value;
        try {
            swal({
                title: "Are you sure?",
                text: "Do you want to add the alarm?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then(async (willDelete) => {
                if (willDelete) {
                    let requestBody = { title, value, direction, color, userGroupIDs, actionInitiatingUser };
                    await axios.post(config.BACKEND_URL + `/alarms/for-source/${sourceId}`, requestBody, { headers });
                    swal("Alarm added!", "Click on OK to continue...", "success")
                        .then(() => {
                            window.location.reload();
                        });
                } else {
                    swal("Alarm not added!", "", "info");
                }
            });
        }
        catch (err) {
            console.log(err);
        }
    }

    return (
        <div className="Alarms d-flex flex-column">
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons"
                rel="stylesheet" />
            <Navbar />
            <main className="container-fluid pb-3">
                <header className="h1 text-center">Create Alarm</header>
                <section className="outer-section d-flex row justify-content-center">
                    <section className="d-inline-block row col-sm-7">
                        {/* <p style={{ height: "100px" }}></p> */}
                        <p className="section-header h2 text-center mb-5">Current Alarms</p>
                        {isPendingAlarmSettings && <div className="text-center h3">Loading...</div>}
                        {errorAlarmSettings && <div className="text-center h3">Cannot fetch the alarms right now!</div>}
                        {
                            alarmSettings && alarmSettings.length === 0 && <div className="text-center h4 mt-5 mb-3">Currently, there are no alarms!</div> ||
                            alarmSettings &&
                            <Table
                                tableID={"alarmsSettingsTable"}
                                tableData={alarmSettings}
                                tableColumns={[
                                    { title: "Title", field: "title", headerFilter:"input" },
                                    { title: "Value", field: "value", headerFilter:"input" },
                                    { 
                                        title: "Type", 
                                        field: "direction",
                                        formatter: function(cell) {
                                            const direction = cell.getValue();
                                            return direction==='>'?"Higher":"Lower";
                                        }
                                    },
                                    { 
                                        title: "Color", 
                                        field: "color",
                                        formatter: function(cell) {
                                            const color = cell.getValue();
                                            return '<div style="background-color:' + color + '; color: #ffffff; width: 100%; height: 100%;">' + color + '</div>';
                                        }
                                    },
                                ]}
                                isSelectable={false}
                            />
                        }
                    </section>
                    <form className="row col-sm-5" onSubmit={handleAlarmsSubmit}>
                        <p className="section-header h2 text-center" >Create Alarm</p>
                        <div className="col-xs-4 col-xs-offset-4">
                            <div id="direction-label" className="floating-label-group mb-0">
                                {isPendingSources && <div className="text-center h4" style={{ marginBottom: "0.25rem" }}>Loading...</div>}
                                {errorSources && <div className="text-center h4" style={{ marginBottom: "0.25rem" }}>Cannot fetch the sources right now!</div>}
                                {
                                    sources && sources.data && sources.data.length >= 0 &&
                                    <select id="source" className="form-control form-control" name="source" style={{ marginBottom: "0.25rem" }} >
                                        <option key="" value="selecteSourceText">Choose data source</option>
                                        {
                                            sources.data.map((source, index) => {
                                                return (
                                                    <option key={index} value={source.id}>{source.name}</option>
                                                )
                                            })
                                        }
                                    </select>

                                }
                            </div>
                            {isPendingUserGroups && <div className="text-center h4 py-2">Loading...</div>}
                            {errorUserGroups && <div className="text-center h4 py-2">Cannot fetch the user groups right now!</div>}
                            {userGroups && ((userGroups.length === 0 && <div className="text-center h5">Currently, there are no user groups!</div>) ||
                                <div className="floating-label-group" style={{ marginBottom: "0.25rem", marginTop: "0" }} >
                                    <label htmlFor="userGroups" >User groups associated with this alarm</label>
                                    <MultiSelect
                                        id="userGroups"
                                        data={userGroups.data.map(group => {
                                            return {
                                                label: group.name,
                                                value: group.id
                                            }
                                        })}
                                        handleChange={handleUserGroupSelection}
                                        placeholder={"Search for, or select user group names..."}
                                        defaultSelected={[]}
                                    />
                                </div>)}
                            <div className="floating-label-group">
                                <input type="text" id="title" className="form-control" required
                                    name="username" style={{ fontWeight: "bold" }} />
                                <label className="floating-label">Title</label>
                            </div>
                            <div className="floating-label-group" style={{ marginBottom: "0.25rem" }}>
                                <input type="number" id="value" className="form-control" required name="value" />
                                <label className="floating-label">Value</label>
                            </div>
                            <div className="floating-label-group" style={{ marginTop: "0", marginBottom: "0.25rem" }}>
                                <label htmlFor="direction" >Direction</label>
                                <select id="direction" className="form-select">
                                    <option value=">" selected>Upper Limit</option>
                                    <option value="<">Lower Limit</option>
                                </select>
                            </div>
                            <div className="floating-label-group">
                                <label htmlFor="color" style={{ position: "relative", bottom: "0.4rem", marginRight: "1rem" }}>Color</label>
                                <input className="ml-3" type="color" name="color" id="color"></input>
                            </div>
                        </div>
                        <section className="d-flex justify-content-center">
                            <button className="btn btn-danger" type="reset">
                                Reset
                            </button> &nbsp; &nbsp; &nbsp; &nbsp;
                            <button className="btn btn-success" type="submit">
                                Submit
                            </button>
                        </section>
                    </form>
                </section>
            </main >
        </div >
    );
}

export default Alarms_Create;