import React from "react"
import '../styles/user_management_styles.css'
import Navbar from "./Navbar.js"
import 'bootstrap/dist/css/bootstrap.min.css';
import MultiSelect from "./MultiSelect.js";
import useFetch from './useFetch.js';
import swal from "sweetalert";
import axios from "axios";
import config from '../config.json';
import Cookie from 'universal-cookie';
import mixpanel from "mixpanel-browser";

const Alarms_Delete = () => {
    const cookies = new Cookie();
    let actionInitiatingUser = -1;
    let userToken = "";
    try {
        actionInitiatingUser = parseInt(cookies.get('userID'));
        userToken = cookies.get('userToken');
    } catch (error) {}
    const headers = {
        'Authorization': `Bearer ${userToken}`,
        'Content-Type': 'application/json'
    };
    const { data: alarms, isPending: isPendingAlarms, error: errorAlarms } = useFetch({ actionInitiatingUser: actionInitiatingUser.toString(), URL: config.BACKEND_URL + '/alarms' });
    let alarmsToBeDeleted = [];
    
    const handleDelete = async (e) => {
        e.preventDefault();
        mixpanel.track("Create Alarm Delete Button Clicked", { userID: cookies.get('userID') });
        if (alarmsToBeDeleted.length === 0) {
            swal("No alarms selected!", "Please select at least one alarm first...", "error")
        }
        try {
            //Are you sure you want to delete these alarms?
            swal({
                title: "Are you sure?",
                text: "Do you want to delete " + alarmsToBeDeleted.length + " alarms?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then(async (willDelete) => {
                    if (willDelete) {
                        for (let alarmID of alarmsToBeDeleted) {
                            await axios.delete(config.BACKEND_URL + `/alarms/${alarmID}/${actionInitiatingUser}`, { headers });
                        }
                        swal("Alarm deleted successfully!", "Click on OK to continue...", "success")
                            .then(() => {
                                window.location.reload();
                            });
                    } else {
                        swal("Alarms not deleted!", "", "info");
                    }
                });
        } catch (err) {
            console.log(err);
            swal("Error deleting alarm!", "", "error")
        }
    }

    const handleChange = (selected) => {
        alarmsToBeDeleted = selected.map(selectedAlarm => selectedAlarm.value);
    }
    return (
        <div className="Alarms d-flex flex-column">
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons"
                rel="stylesheet" />
            <Navbar />
            {/* <TempNavbar context={context} /> */}
            <main className="container-fluid">
                <section className="d-flex row justify-content-center" style={{ padding: "1vw" }}>
                    <header className="h1 text-center mb-2">Delete Alarms</header>
                    <div className="h3 text-center mb-2">Select the alarms you wish to delete.</div>
                    <section className="row d-flex justify-content-center mb-3" style={{ width: "100%" }}>
                        <form id="deleteForm" className="row col-sm-9 d-flex justify-content-center" onSubmit={handleDelete}>
                            <section /*className="d-flex justify-content-center col-sm-12"*/>
                                <div className="d-flex py-2 px-5 justify-content-center mb-3">
                                    {isPendingAlarms && <div className="text-center h4 mb-3">Loading...</div>}
                                    {errorAlarms && <div className="text-center h4 mb-3">Cannot fetch all the alarms right now!</div>}
                                    {alarms &&
                                        ((alarms.length === 0 && <div className="text-center h4 mb-3">Currently, there are no alarms!</div>) ||
                                            <MultiSelect
                                                handleChange={handleChange}
                                                placeholder={"Search for, or select alarms..."}
                                                data={alarms.map(alarm => {
                                                    return {
                                                        value: alarm.id,
                                                        label: alarm.title
                                                    }
                                                })}
                                                defaultSelected={[]}
                                            />)
                                    }
                                </div>
                                <section className="d-flex justify-content-center">
                                    <button className="btn btn-danger" type="submit">Delete</button>
                                </section>
                            </section>
                        </form>
                    </section>
                </section>
            </main >
        </div >
    );
}

export default Alarms_Delete;