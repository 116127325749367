import React from "react";
import Navbar from "./Navbar.js";

const NotFoundPage = () => {
    return (
        <div className="Reports">
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons"
                rel="stylesheet" />
            <Navbar />
            <main className="container-fluid">
                <div className="d-flex justify-content-center align-items-center mt-3" style={{ top: "50%" }}>
                    <header className="display-6 mt-5">404 Page Not Found</header>
                </div>
            </main >
        </div >
    );
}

export default NotFoundPage;