import config from './config.json';
import React, { useEffect } from 'react';
import './App.css';
import { Switch, Route, Redirect } from "react-router-dom"
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import Logout from './components/Logout';
import UserManagement_Users from "./components/UserManagement_Users"
import UserManagement_CreateUserGroup from './components/UserManagement_CreateUserGroup';
import UserManagement_CreateDashboardGroup from './components/UserManagement_CreateDashboardGroup';
import HTTPSources from './components/HTTPSources';
import MQTTSources from './components/MQTTSources';
import Reports from './components/Reports';
import Alarms from './components/Alarms';
import Alarms_Create from './components/Alarms_Create';
import Alarms_Edit from './components/Alarms_Edit';
import Alarms_Delete from './components/Alarms_Delete';
import Settings from './components/Settings';
import Views from './components/Views';
import Views_Create from './components/Views_Create';
import Views_Edit from './components/Views_Edit';
import Views_Delete from './components/Views_Delete';
import NotFoundPage from './components/NotFoundPage';
import EventLog from './components/EventLog';
import SetLoginCookies from './components/SetLoginCookies';
import ProtectedRoute from './components/ProtectedRoute';
import Axios from 'axios';
import PermissionsCreate from './components/PermissionsCreate';
import PermissionsAssign from './components/PermissionsAssign';
import PermissionDelete from './components/PermissionDelete';
import PermissionsEdit from './components/PermissionsEdit';
import SuperUserPage from './components/SuperuserPage';
import UserProfile from './components/UserProfile';
import Inviteform from './components/Inviteform';
import RegisterUser from './components/RegisterUser';
import ResetPassword from './components/ResetPassword';
import mixpanel from 'mixpanel-browser';

function App() {
  useEffect(()=>{
    Axios.post(config.BACKEND_URL + "/superuser/create", { username : "superuser", password : "superuser@123", fullname: "Sachin Vishwakarma", email: "sachinvishwakarma7977@gmail.com", contact: "7348387913" }).then(res => {
    }).catch(err => {
    });
  }, []);
  useEffect(() =>  {
    mixpanel.init('25a6b37960a514f6e707c9c25ca300af', { debug: true });
  }, []);
  return (
    <>
      <link href="https://fonts.googleapis.com/icon?family=Material+Icons"
        rel="stylesheet" />
      {/* <ParticlesBackground /> */}
      <Switch>
        {/* Home */}
        <Route exact path="/" component={Login} />
        <ProtectedRoute path="/dashboard" component={Dashboard}/>

        {/* Set login cookies */}
        <Route path="/set-login-cookies" component={SetLoginCookies} />

        {/* User Management */}
        <ProtectedRoute exact path="/user_management" component={UserManagement_Users} />
        <ProtectedRoute path="/user_management/users" component={UserManagement_Users} />
        <ProtectedRoute path="/user_management/user_groups" component={UserManagement_CreateUserGroup} />
        <ProtectedRoute path="/user_management/dashboard_groups" component={UserManagement_CreateDashboardGroup} />

        {/* Data Sources */}
        <ProtectedRoute path="/sensor_settings" component={Settings} />
        <ProtectedRoute path="/http_sources" component={HTTPSources} />
        <ProtectedRoute path="/mqtt_sources" component={MQTTSources} />

        {/* Reports */}
        <ProtectedRoute path="/reports" component={Reports}/>

        {/* Logs */}
        <ProtectedRoute path="/EventLog" component={EventLog} />

        {/* Views */}
        <ProtectedRoute exact path="/views" component={Views} />
        <ProtectedRoute path="/views/create" component={Views_Create} />
        <ProtectedRoute path="/views/edit" component={Views_Edit} />
        <ProtectedRoute path="/views/delete" component={Views_Delete} />

        {/* Alarms */}
        <ProtectedRoute exact path="/alarms" component={Alarms} />
        <ProtectedRoute path="/alarms/create" component={Alarms_Create} />
        <ProtectedRoute path="/alarms/edit" component={Alarms_Edit} />
        <ProtectedRoute path="/alarms/delete" component={Alarms_Delete} />

        {/* Permissions */}
        <ProtectedRoute path="/permissions/create" component={PermissionsCreate} />
        <ProtectedRoute path="/permissions/edit" component={PermissionsEdit} />
        <ProtectedRoute path="/permissions/delete" component={PermissionDelete} />
        <ProtectedRoute path="/permissions/assign" component={PermissionsAssign} />

        <ProtectedRoute exact path="/user-profile" component={UserProfile}/>

        <ProtectedRoute exact path="/superuser" component={SuperUserPage} />

        {/* Invite Links */}
        <ProtectedRoute path="/invite-user" component={Inviteform} />

        <Route exact path="/register-user/:token" component={RegisterUser} />

        <Route exact path="/reset-password/:token" component={ResetPassword} />

        {/* Logout */}
        <Route path="/logout" component={Logout} />

        {/* Not Found Page */}
        <Route component={NotFoundPage}></Route>
      </Switch>
    </>
  );
}

export default App;
