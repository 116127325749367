import React, { useState, useEffect, useRef } from "react"
import '../styles/user_management_styles.css'
import Navbar from "./Navbar.js";
import 'bootstrap/dist/css/bootstrap.min.css';
import Table from "./Table";
import useFetch from './useFetch.js';
import swal from 'sweetalert';
import axios from 'axios';
import $ from 'jquery';
import config from '../config.json';
import Cookie from 'universal-cookie';
import mixpanel from "mixpanel-browser";
import { decrypt } from './useCryptoJS';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faUpload } from '@fortawesome/free-solid-svg-icons';
import mqtt from 'mqtt';
//import DeleteIcon from '@mui/icons-material/Delete';
//import FileDownloadIcon from '@mui/icons-material/FileDownload';



const MQTTSources = (props) => {


    const [mqttMessage, setMqttMessage] = useState('');
    const [mqttData, setMqttData] = useState({
        host: '',
        port: '',
        topic: '',
        dataSource: '',
        username: '',
        password: '',
        clientId: '',
        wsPort: 8080
    });
    const cookies = new Cookie();
    const [ursource, setUrsource] = useState("0000");
    let actionInitiatingUser = "";
    let userToken = "";

    try {
        actionInitiatingUser = parseInt(cookies.get('userID'));
        userToken = cookies.get('userToken');
    } catch (error) { }

    const headers = {
        'Authorization': `Bearer ${userToken}`,
        'Content-Type': 'application/json'
    };
    useEffect(() => {
        setUrsource(decrypt(cookies.get('ursource')));
    }, []);
    const { isPending, data: sources, error } = useFetch({ actionInitiatingUser: { userID: actionInitiatingUser }, URL: config.BACKEND_URL + '/logger_sources/sources/mqtt' });
    let selectedRows = [];
    let isInEditableMode = false;
    let editableData = {};

    const setEditableData = function (value) {
        editableData = value;
        $('#mqttName')[0].value = value.name;
        $('#mqttHost')[0].value = value.host;
        $('#mqttPort')[0].value = value.port;
        $('#mqttTopic')[0].value = value.topic;
        $('#mqttKey')[0].value = value.key;
        $('#mqttEnable')[0].checked = value.enable;
        document.querySelector('#mqttFormSubmit').innerText = 'Update';
        isInEditableMode = true;
    }

    const setSelectedRows = (rows) => {
        selectedRows = rows;
    }

    const handleDeleteButton = (e) => {
        e.preventDefault();
        mixpanel.track("MQTT Delete Button Clicked", { userID: cookies.get('userID') });
        if (ursource.charAt(3) != '1') {
            swal("No Permission", "You cannot perform delete operation", "error");
            return;
        }
        if (selectedRows.length === 0) {
            swal("Invalid selection!", "You have no selected any rows for deleting!", "error");
        }
        else {
            swal({
                title: "Are you sure?",
                text: "Do you want to delete the selected sources?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then(async (willDelete) => {
                    if (willDelete) {
                        try {
                            await axios.post(config.BACKEND_URL + '/logger_sources/sources/delete-by-ids', {
                                sourceIDs: selectedRows.map(row => row.id),
                                actionInitiatingUser
                            }, { headers }).then(res => {
                                swal("Sources deleted successfully!", "Click on OK to continue...", "success").then(() => window.location.reload());
                            }).catch(err => {
                                console.log(err);
                            })
                        } catch (err) {
                            swal("Error!", "Your selected sources have not been deleted because of a server error!", "error");
                        }
                    }
                    else {
                        swal("Cancelled!", "Your selected rows have not been deleted!", "error");
                    }
                });
        }
    };

    const handleEditButton = (e) => {
        e.preventDefault();
        mixpanel.track("MQTT Edit Button Clicked", { userID: cookies.get('userID') });
        if (ursource.charAt(2) != '1') {
            swal("No Permission", "You cannot perform edit operation", "error");
            return;
        }
        if (selectedRows.length === 0) {
            swal("Please select a row to edit!", "", "error");
        }
        else if (selectedRows.length > 1) {
            swal("Please select only one row to edit!", "", "error");
        }
        else {
            setEditableData(selectedRows[0]);
        }
    }

    const handleMQTTForm = async (e) => {
        console.log("MAIN FORM CALLED")
        e.preventDefault();
        mixpanel.track("MQTT Submit Button Clicked", { userID: cookies.get('userID') });
        if (ursource.charAt(2) == '0' && ursource.charAt(1) == '0') {
            swal("No Permission", "You cannot perform create/edit operation", "error");
            return;
        }
        if (!isInEditableMode) {
            const name = $('#mqttName')[0].value;
            const host = $('#mqttHost')[0].value;
            const port = $('#mqttPort')[0].value;
            const topic = $('#mqttTopic')[0].value;
            const key = $('#mqttKey')[0].value;
            const enable = $('#mqttEnable')[0].checked;
            const username = $('#mqttUsername')[0].value.trim();
            const password = $('#mqttPassword')[0].value.trim();
            const clientId = $('#mqttClientid')[0].value.trim();
            let data = { host, port, topic, key };
            if (username === '' && password != '') {
                swal("Error!", "Please enter username also if password is given", "error");
                return;
            } else if (username != '' && password === '') {
                data = { ...data, username };
            } else if (username != '' && password != '') {
                data = { ...data, username, password };
            }
            if (clientId != '') {
                data = { ...data, clientId };
            }
            swal({
                title: "Are you sure?",
                text: "Do you want to add the data source?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then(async (willDelete) => {
                    if (willDelete) {
                        try {
                            let createBody = { name, type: "MQTT", data, enable, actionInitiatingUser };
                            console.log("CONSOLE BODY", createBody)
                            await axios.post(config.BACKEND_URL + '/logger_sources/sources/mqtt', createBody, { headers }).then(res => {
                                swal("Source added successfully!", "Click on OK to continue...", "success").then(() => window.location.reload());
                            }).catch(err => {
                                console.log(err);
                            });
                        }
                        catch (err) {
                            console.log("Error: ", err);
                            swal("Error!", "Your data source has not been added because of an error!", "error");
                        }
                    } else {
                        swal("Data source not added!", "", "info");
                    }
                });
        }
        else {
            console.dir(e.target);
            const name = $('#mqttName')[0].value;
            const host = $('#mqttHost')[0].value;
            const port = $('#mqttPort')[0].value;
            const topic = $('#mqttTopic')[0].value;
            const key = $('#mqttKey')[0].value;
            const enable = $('#mqttEnable')[0].checked;
            const username = $('#mqttUsername')[0].value.trim();
            const password = $('#mqttPassword')[0].value.trim();
            const clientId = $('#mqttClientid')[0].value.trim();
            let data = { name, host, port, topic, key, enable, actionInitiatingUser };
            if (username === '' && password != '') {
                swal("Error!", "Please enter username also if password is given", "error");
                return;
            } else if (username != '' && password === '') {
                data = { ...data, username };
            } else if (username != '' && password != '') {
                data = { ...data, username, password };
            }
            if (clientId != '') {
                data = { ...data, clientId };
            }
            //Send a patch request from here
            swal({
                title: "Are you sure?",
                text: "Do you want to edit the selected data source?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then(async (willDelete) => {
                    if (willDelete) {
                        try {
                            await axios.patch(config.BACKEND_URL + `/logger_sources/sources/mqtt/${editableData.id}`, data, { headers }).then(res => {
                                swal("Source updated successfully!", "Click on OK to refresh the page...", "success").then(() => window.location.reload());
                            }).catch(err => {
                                console.log(err);
                            })
                        }
                        catch (err) {
                            console.log(err);
                            swal("Could not update source!", err.response.data.message, "error");
                        }
                    }
                    else {
                        swal("The selected source has not been edited!", "", "info");
                    }
                });
        }
    }

    const handleMqttMessage = (message) => {
        console.log(message)
        setMqttMessage(message);
    };

    const handleNestedFormChange = (key, value) => {
        setMqttData((prevData) => ({
            ...prevData,
            [key]: value
        }));
    };

    return (
        <div className="MQTTSources d-flex flex-column">
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons"
                rel="stylesheet" />
            <script src='bootstrap/dist/js/bootstrap.min.js'></script>
            <Navbar />
            <header className="h1 text-center mt-3">MQTT Data Sources</header>
            <main>
                {ursource.charAt(0) === '1' &&
                    <section className="d-flex row justify-content-center p-3" style={{ width: "50%", float: "left" }}>
                        <header className="h2 text-center mb-3s">Current MQTT Data Sources</header>
                        {isPending && <div className="text-center mt-5 h4">Loading...</div>}
                        {error && <div className="text-center mt-5 h4">Cannot fetch the data right now!</div>}
                        {
                            sources && sources.data && sources.data.length === 0 && <div className="text-center mt-5 h4">Currently, there are no MQTT data sources!</div> ||
                            sources && sources.columns.length >= 0 &&
                            <>
                                <Table
                                    tableColumns={sources.columns}
                                    tableData={sources.data}
                                    handleDeleteButton={handleDeleteButton}
                                    handleEditButton={handleEditButton}
                                    setParentRows={setSelectedRows}
                                    shouldModifySources={true}
                                    tableID="MQTTTable"
                                    edit={ursource.charAt(2) === '1'}
                                    deletee={ursource.charAt(3) === '1'}
                                />

                            </>
                        }
                    </section>
                }
                <section className="d-flex row justify-content-center p-3" style={{ padding: "1vw", width: "50%", float: "right" }}>
                    <header className="h2 text-center">Add an MQTT Data Sources </header>
                    <div>
                        <section className="d-flex justify-content-center">
                            <button className="btn btn-primary" type="reset">
                                <FontAwesomeIcon icon={faUpload} /> Upload Excel Sheet
                            </button>
                            &nbsp;
                            <button className="btn btn-info" type="reset">
                                <FontAwesomeIcon icon={faDownload} /> Download Sample Format
                            </button>
                        </section>
                    </div>
                </section>
                <section className="d-flex row justify-content-center p-3" style={{ padding: "1vw", width: "50%", float: "right" }}>
                    <form id="mqttForm" onSubmit={handleMQTTForm} className="row d-flex justify-content-center mb-3">
                        <header className="h2 text-center">Add an MQTT Data Source</header>
                        <div className="col-xs-4 col-xs-offset-4">
                            <p className="h4 text-center mb-2">Logging</p>
                            <div className="form-check form-switch form-switch-lg d-flex justify-content-center">
                                <label className="form-check-label mr-3" htmlFor="mqttEnable">Disable</label>
                                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                <input className="form-check-input" type="checkbox" id="mqttEnable" />
                                &nbsp; &nbsp;
                                <label className="form-check-label" htmlFor="mqttEnable">Enable</label>
                            </div>

                            <div className="floating-label-group">
                                <input type="text" id="mqttHost" className="form-control" autoComplete="off" required style={{ fontWeight: "bold" }} />
                                <label className="sources floating-label">Enter HOST for data retrieval</label>
                            </div>
                            <div className="floating-label-group">
                                <input type="text" id="mqttPort" className="form-control" autoComplete="off" required style={{ fontWeight: "bold" }} />
                                <label className="floating-label">Enter PORT for data retrieval</label>
                            </div>
                            <div className="floating-label-group">
                                <input type="text" id="mqttTopic" className="form-control" autoComplete="off" required style={{ fontWeight: "bold" }} />
                                <label className="floating-label">Enter TOPIC for data retrieval</label>
                            </div>
                            {/* <button type="submit" className="btn btn-primary" style={{margin:'1rem 0 0 0'}} onClick={handleMQTTSubscribe}>Subscribe</button> */}


                            {/* Nested form for MQTT connection details */}
                            {/* <NestedMqttForm onMqttMessage={handleMqttMessage} onNestedFormChange={handleNestedFormChange} /> */}


                            <div className="floating-label-group">
                                <input type="text" id="mqttKey" className="form-control" autoComplete="off" required style={{ fontWeight: "bold" }} />
                                {/* <input type="text" id={"mqttKey"} className={"form-control"} autoComplete={"off"} required style={{ fontWeight: "bold" }} readOnly /> */}
                                <label className="floating-label">Enter KEY for data retrieval </label>
                                {/* <DynamicDropdown id={"mqttKey"} className={"form-control"} autoComplete={"off"} data={mqttMessage} /> */}
                            </div>
                            <div className="floating-label-group">
                                <input type="text" id="mqttName" className="form-control" autoComplete="off" required style={{ fontWeight: "bold" }} />
                                <label className="floating-label">Enter name of the data source</label>
                            </div>

                            <div className="floating-label-group">
                                <input type="text" id="mqttUsername" className="form-control" autoComplete="off" style={{ fontWeight: "bold" }} />
                                <label className="floating-label">Enter Username</label>
                            </div>
                            <div className="floating-label-group">
                                <input type="password" id="mqttPassword" className="form-control" autoComplete="off" style={{ fontWeight: "bold" }} />
                                <label className="floating-label">Enter Password</label>
                            </div>
                            <div className="floating-label-group">
                                <input type="text" id="mqttClientid" className="form-control" autoComplete="off" style={{ fontWeight: "bold" }} />
                                <label className="floating-label">Enter ClientID</label>
                            </div>

                            {/* Sachin start */}

                            {/* Sachin end */}
                        </div>
                        <section className="d-flex justify-content-center">
                            <button id="mqttFormSubmit" className="btn btn-success" type="submit">
                                Submit
                            </button>
                            &nbsp;
                            <button className="btn btn-warning" type="reset">
                                Reset
                            </button>
                        </section>
                    </form>
                </section>
            </main>
        </div >
    )
};
export default MQTTSources;


const Dropdown = ({ options, onChange, value }) => (
    <select onChange={e => onChange(e.target.value)} value={value} style={{ padding: '.6rem', borderRadius: '10px', fontWeight: 'bold', margin: '1rem .4rem 1rem 0rem' }}>
        <option value="">Select</option>
        {options.map(option => (
            <option key={option} value={option}>{option}</option>
        ))}
    </select>
);


const DynamicDropdown = ({ data, id, className, autoComplete }) => {
    const [selectedPath, setSelectedPath] = useState([]);
    console.log("Current selectedPath state:", selectedPath); // Debugging

    const handleChange = (index, value) => {
        const newPath = selectedPath.slice(0, index);
        if (value) {
            newPath.push(value);
        }
        console.log("New Path after change:", newPath); // Debugging
        setSelectedPath(newPath);
    };

    const getOptions = (path) => {
        if (data) {
            let options = JSON.parse(data);
            console.log("Current data type:", typeof (data)); // Debugging

            for (const key of path) {
                console.log("Traversing key:", key); // Debugging
                options = options[key];
                if (!options || typeof options !== 'object') {
                    return []; // Return empty if path is invalid
                }
            }

            if (typeof options === 'object' && options !== null) {
                const keys = Object.keys(options);
                console.log("Available options at this level:", keys); // Debugging
                return keys;
            } else {
                return []; // If it's not an object, return empty array
            }
        }
        else {
            return []
        }
    };

    const renderDropdowns = () => {
        const dropdowns = [];
        for (let i = 0; i <= selectedPath.length; i++) {
            const options = getOptions(selectedPath.slice(0, i));
            if (options.length === 0) break;

            dropdowns.push(
                <Dropdown
                    key={i}
                    options={options}
                    onChange={(value) => handleChange(i, value)}
                    value={selectedPath[i] || ''}
                />
            );
        }
        return dropdowns;
    };

    const finalPath = selectedPath.join('.');

    return (
        <div>
            {renderDropdowns()}
            {finalPath ? (
                <div>
                    <input type="text" value={finalPath} id={id} className={className} autoComplete={autoComplete} required style={{ fontWeight: "bold" }} readOnly />
                </div>
            ) : (
                <div>
                    <input type="text" id={id} className={className} autoComplete={autoComplete} required style={{ fontWeight: "bold" }} placeholder="Enter Key for data retrieval" />
                </div>
            )}
        </div>
    );
};


const NestedMqttForm = ({ onMqttMessage, onNestedFormChange }) => {
    const [host, setHost] = useState('');
    const [port, setPort] = useState('');
    const [topic, setTopic] = useState('');
    const [dataSource, setDataSource] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [client, setClient] = useState('');
    const [wsPort, setWsPort] = useState(8080);
    const [subscriptionStatus, setSubscriptionStatus] = useState('idle'); // idle, subscribing, subscribed, failed
    const [mqttMessage, setMqttMessage] = useState('')
    const [countdown, setCountdown] = useState(0); // State for countdown
    const timeoutRef = useRef(null);
    const countdownIntervalRef = useRef(null);
    const failureHandledRef = useRef(false); // Ref to track if failure has been handled

    const handleMQTTSubscribe = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setSubscriptionStatus('subscribing'); // Set status to subscribing
        failureHandledRef.current = false;

        const url = `ws://${host}:${wsPort}`;
        let connectOptions = {
            'host': host,
            'port': port,
        };
        if (!username && !password && !client) {
            connectOptions = {
                'host': host,
                'port': port,
                'client': client
            };
        } else if (username != '' && !password && !client) {
            connectOptions = {
                'host': host,
                'port': port,
                'username': username
            };
        } else if (username != '' && !password && client != '') {
            connectOptions = {
                'host': host,
                'port': port,
                'username': username,
                'client': client
            };
        } else if (username != '' && password != '' && !client) {
            connectOptions = {
                'host': host,
                'port': port,
                'username': username,
                'password': password
            };
        } else if (username != '' && password != '' && client != '') {
            connectOptions = {
                'host': host,
                'port': port,
                'username': username,
                'password': password,
                'client': client
            };
        }

        // Connect to the MQTT broker
        const mqttClient = mqtt.connect(url);

        mqttClient.on('connect', () => {
            console.log('Connected');

            mqttClient.subscribe(topic, (err) => {
                if (!err) {
                    console.log(`Subscribed to topic: ${topic}`);
                    setSubscriptionStatus('subscribed'); // Set status to subscribed
                    resetMessageTimeout();
                } else {
                    console.error('Subscription error:', err);
                    setSubscriptionStatus('failed'); // Set status to failed
                }
            });
        });

        mqttClient.on('error', (err) => {
            console.error('Connection error:', err);
            if (!failureHandledRef.current) {
                setSubscriptionStatus('failed');
                failureHandledRef.current = true;
            }
        });

        mqttClient.on('offline', () => {
            console.log('MQTT client is offline');
            if (!failureHandledRef.current) {
                setSubscriptionStatus('failed');
                failureHandledRef.current = true;
            }
        });

        mqttClient.on('close', () => {
            console.log('MQTT client closed connection');
            if (!failureHandledRef.current) {
                setSubscriptionStatus('failed');
                failureHandledRef.current = true;
            }
        });

        mqttClient.on('disconnect', () => {
            console.log('MQTT client disconnected');
            if (!failureHandledRef.current) {
                setSubscriptionStatus('failed');
                failureHandledRef.current = true;
            }
        });

        // Handle incoming messages
        mqttClient.on('message', (topic, message) => {
            onMqttMessage(message.toString());
            setSubscriptionStatus('idle')
            resetMessageTimeout();
        });

        setClient(mqttClient);
    };

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        onNestedFormChange(id, value);
        setSubscriptionStatus('idle'); // Reset status to idle on input change
        if (id === 'mqttHost') setHost(value);
        if (id === 'mqttPort') setPort(value);
        if (id === 'mqttTopic') setTopic(value);
        if (id === 'mqttName') setDataSource(value);
        if (id === 'mqttUsername') setUsername(value);
        if (id === 'mqttPassword') setPassword(value);
        if (id === 'mqttClientid') setClient(value);
        if (id === 'mqttWsPort') setWsPort(value);
    };

    const resetMessageTimeout = () => {
        clearTimeout(timeoutRef.current);
        clearInterval(countdownIntervalRef.current);
        setCountdown(30);

        countdownIntervalRef.current = setInterval(() => {
            setCountdown(prevCountdown => {
                if (prevCountdown <= 1) {
                    clearInterval(countdownIntervalRef.current);
                    setSubscriptionStatus('idle');
                    return 0;
                }
                return prevCountdown - 1;
            });
        }, 1000);

        timeoutRef.current = setTimeout(() => {
            setSubscriptionStatus('idle');
            clearInterval(countdownIntervalRef.current);
        }, 30000); // 10 seconds
    };

    useEffect(() => {
        return () => {
            clearTimeout(timeoutRef.current);
            clearInterval(countdownIntervalRef.current);
        };
    }, []);


    return (
        <div>
            <form onSubmit={handleMQTTSubscribe}>
                <div className="floating-label-group">
                    <input
                        type="text"
                        id="mqttHost"
                        className="form-control"
                        autoComplete="off"
                        required
                        style={{ fontWeight: 'bold' }}
                        value={host}
                        onChange={handleInputChange}
                    />
                    <label className="sources floating-label">Enter HOST for data retrieval</label>
                </div>
                <div className="floating-label-group">
                    <input
                        type="text"
                        id="mqttPort"
                        className="form-control"
                        autoComplete="off"
                        required
                        style={{ fontWeight: 'bold' }}
                        value={port}
                        onChange={handleInputChange}
                    />
                    <label className="floating-label">Enter PORT for data retrieval</label>
                </div>
                <div className="floating-label-group">
                    <input
                        type="text"
                        id="mqttTopic"
                        className="form-control"
                        autoComplete="off"
                        required
                        style={{ fontWeight: 'bold' }}
                        value={topic}
                        onChange={handleInputChange}
                    />
                    <label className="floating-label">Enter TOPIC for data retrieval</label>
                </div>
                <div className="floating-label-group">
                    <input type="text" id="mqttName" className="form-control" autoComplete="off" required style={{ fontWeight: "bold" }} onChange={handleInputChange} />
                    <label className="floating-label">Enter name of the data source</label>
                </div>

                <div className="floating-label-group">
                    <input type="text" id="mqttUsername" className="form-control" autoComplete="off" style={{ fontWeight: "bold" }} onChange={handleInputChange} />
                    <label className="floating-label">Enter Username</label>
                </div>
                <div className="floating-label-group">
                    <input type="password" id="mqttPassword" className="form-control" autoComplete="off" style={{ fontWeight: "bold" }} onChange={handleInputChange} />
                    <label className="floating-label">Enter Password</label>
                </div>
                <div className="floating-label-group">
                    <input type="text" id="mqttClientid" className="form-control" autoComplete="off" style={{ fontWeight: "bold" }} onChange={handleInputChange} />
                    <label className="floating-label">Enter ClientID</label>
                </div>
                <div className="floating-label-group" style={{ display: 'flex', gap: '10px' }}>
                    <input type="number" id="mqttWsPort" className="form-control" required autoComplete="off" style={{ fontWeight: "bold" }} onChange={handleInputChange} />
                    <label className="floating-label">Enter web socket port</label>
                    <button type="submit" className="btn btn-primary" >

                        {subscriptionStatus === 'idle' && 'Subscribe'}
                        {subscriptionStatus === 'subscribing' && 'Subscribing...'}
                        {subscriptionStatus === 'subscribed' && 'Subscribed ✔'}
                        {subscriptionStatus === 'failed' && 'Subscribe'}
                    </button>

                </div>
                {subscriptionStatus === 'subscribed' ? (
                    <div style={{ marginTop: '10px', fontWeight: 'bold' }}>
                        No message received, resetting in: <span style={{ color: '#77FB43' }}>{countdown}s</span>
                    </div>
                )
                    : subscriptionStatus === 'failed' ? (<div style={{ marginTop: '10px', fontWeight: 'bold', color: 'red' }}>
                        Failed to subscribe:  <span style={{ color: 'white' }}>{topic}</span> topic
                    </div>)
                        : null
                }

            </form>
        </div>
    );
};


