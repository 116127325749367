import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect } from 'react';
import { TabulatorFull } from "tabulator-tables";
import "tabulator-tables/dist/css/tabulator.min.css";
import $ from 'jquery';
import { jsPDF } from "jspdf";
import "../styles/table_styles.css";
import Cookie from 'universal-cookie';

const Table = ({ shouldDisplay = true, isSelectable = true, tableData = [], tableColumns = [], handleDeleteButton, handleEditButton, shouldModifySources, shouldDownload, displaySerial = false, setParentRows = function () { }, highlightedRows = [], tableID = "data", edit,  deletee }) => {
    useEffect(() => {
        let setColumns = (tableColumns) => {
            let columns = [];
            if (displaySerial) {
                columns.push({ formatter: "rownum", align: "center", width: 70 });
            }
            if (isSelectable) {
                columns.unshift({
                    formatter: "rowSelection", titleFormatter: "rowSelection", hozAlign: "center", headerSort: false, width: 70
                });
            }
            columns.push(...tableColumns);
            return columns;
        }
        if (shouldDisplay) {
            let table = new TabulatorFull(`#${tableID}`, {
                layout: "fitColumns",
                columns: setColumns(tableColumns),
                data: tableData,
                pagination: "local",
                paginationSize: 10,
                paginationSizeSelector: [10, 25, 50, 100],
                tooltips: true,
                responsiveLayout: true,
                selectable: isSelectable
            });

            table.on("rowSelectionChanged", function (e, row) {
                setParentRows(table.getSelectedData());
            });

            table.on("tableBuilt", function () {
                if (highlightedRows.length > 0) {
                    table.selectRow(table.getRows().filter(row => highlightedRows.includes(row.getData().id)));
                    setParentRows(table.getSelectedData());
                }
            })

            if (shouldDownload) {
                const downloadFileName = [undefined, null, ''].includes(tableID) ? "data" : tableID.toString();
                const doc = new jsPDF("p", "mm", "a4");
                $("#CSVDownloadButton")[0].addEventListener("click", function () {
                    table.download("csv", `${downloadFileName}.csv`, {
                        columnGroups: true,
                        rowGroups: true,
                        columnCalcs: true,
                        columnCalcFormatter: "money",
                        rowCalcs: true,
                        rowCalcFormatter: "money"
                    });
                })
            }
        }
    }, [highlightedRows, tableData, shouldDisplay])

    return (
        <>
            {shouldDisplay &&
                <div>
                    <div id={tableID}></div>
                </div>
            }
            {(shouldModifySources || shouldDownload) &&
                <section className="d-flex flex-row-reverse mb-3 mt-2">
                    {
                        shouldDownload &&
                        <div className="dropdown">
                            <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                Download
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="downloadDropdown">
                                <li><button id="CSVDownloadButton" className="btn btn-light dropdown-item">CSV</button></li>
                            </ul>
                        </div>
                    }
                    &nbsp;
                    {
                        shouldModifySources &&
                        <section>
                            {deletee && <button className="btn btn-danger" onClick={(e) => { handleDeleteButton(e) }}>Delete</button>}
                            &nbsp;
                            {edit && <button className="btn btn-primary" onClick={(e) => { handleEditButton(e) }}>Edit</button>}
                        </section>
                    }
                </section>
            }
        </>
    );
}
export default Table;
