import React, { useState } from "react"
import { Redirect } from "react-router-dom"
import Axios from "axios"
import '../styles/compo.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '../images/logo.png'
import swal from 'sweetalert';
import Cookie from 'universal-cookie';
import ParticlesBackground from "./ParticlesBackground";
import config from '../config.json';
const { encrypt } = require('./useCryptoJS');

const Login = () => {
    const cookies = new Cookie();
    const [loggedIn, setLoggedIn] = useState(false);
    let userID = "";
    try {
        userID = cookies.get('userID');
    } catch (error) {}
    
    if (userID) {
        return <Redirect to="/dashboard" />
    }
    let username = null, password = null;
    const formSubmit = async (ev) => {
        ev.preventDefault();
        if(username === 'superuser'){
            Axios.post(config.BACKEND_URL + "/superuser/login", { username, password }).then(res =>{
               if(res.status === 200){
                    cookies.set('userID', res.data.id.toString(), { path: '/', secure: true, maxAge: 86400 });
                    cookies.set('userToken', res.data.userToken, { path: '/', secure: true, maxAge: 86400 });
                    cookies.set('userStatus', encrypt("superuser"), { path: '/', secure: true, maxAge: 86400 });
                    cookies.set('urname',encrypt("1111"), { path: '/', secure: true, maxAge: 86400 });
                    cookies.set('uruser',encrypt("1111"), { path: '/', secure: true, maxAge: 86400 });
                    cookies.set('uruserGroup',encrypt("1111"), { path: '/', secure: true, maxAge: 86400 });
                    cookies.set('uruserDashboard',encrypt("1111"), { path: '/', secure: true, maxAge: 86400 });
                    cookies.set('uralarm',encrypt("1111"), { path: '/', secure: true, maxAge: 86400 });
                    cookies.set('urview',encrypt("1111"), { path: '/', secure: true, maxAge: 86400 });
                    cookies.set('ursource',encrypt("1111"), { path: '/', secure: true, maxAge: 86400 });
                    cookies.set('urrole',encrypt("11111"), { path: '/', secure: true, maxAge: 86400 });
                    cookies.set('urdashboard',encrypt("1111"), { path: '/', secure: true, maxAge: 86400 });
                    cookies.set('urreport',encrypt("1"), { path: '/', secure: true, maxAge: 86400 });
                    setLoggedIn(true);
                    swal("Super User Login Successfully", "Click on OK to continue...", "success");
                    return <Redirect to="/dashboard" />;
               } else {
                    swal("Login failed!", "Invalid Username or Password", "error");
               }
            }).catch(err => {
                swal("Login failed!", "Invalid Username or Password", "error");
                console.log(err);
            });
            
        } else {
            Axios.post(config.BACKEND_URL + "/users/login", { username, password })
                .then(async res => {
                    if (res.data.isEmailAuthenticated === false) {
                        swal("Login failed!", "Please check mail and verify your email address", "error");
                        await Axios.post(config.BACKEND_URL + '/users/login-authenticate', { loginUser: true, username: username }).then(() => {}).catch(err => console.log(err));
                        setLoggedIn(false);
                    }
                    else {
                        if(res.status === 200){
                            cookies.set('userID', res.data.id.toString(), { path: '/', secure: true, maxAge: 86400 });
                            cookies.set('urname',encrypt(res.data.roleOfUser.name), { path: '/', secure: true, maxAge: 86400 });
                            cookies.set('userToken', res.data.userToken, { path: '/', secure: true, maxAge: 86400 });
                            cookies.set('uruser',encrypt(res.data.roleOfUser.user), { path: '/', secure: true, maxAge: 86400 });
                            cookies.set('uruserGroup',encrypt(res.data.roleOfUser.userGroup), { path: '/', secure: true, maxAge: 86400 });
                            cookies.set('uruserDashboard',encrypt(res.data.roleOfUser.userDashboard), { path: '/', secure: true, maxAge: 86400 });
                            cookies.set('uralarm',encrypt(res.data.roleOfUser.alarm), { path: '/', secure: true, maxAge: 86400 });
                            cookies.set('urview',encrypt(res.data.roleOfUser.view), { path: '/', secure: true, maxAge: 86400 });
                            cookies.set('ursource',encrypt(res.data.roleOfUser.source), { path: '/', secure: true, maxAge: 86400 });
                            cookies.set('urrole',encrypt(res.data.roleOfUser.role), { path: '/', secure: true, maxAge: 86400 });
                            cookies.set('urdashboard',encrypt(res.data.roleOfUser.dashboard), { path: '/', secure: true, maxAge: 86400 });
                            cookies.set('urreport',encrypt(res.data.roleOfUser.report), { path: '/', secure: true, maxAge: 86400 });
                            swal("Login successful!", "Click on OK to continue...", "success");
                            setLoggedIn(true);
                            return <Redirect to="/dashboard" />;
                        } else {
                            swal("Login failed!", "Invalid credentials. or Role is not assigned to the user", "error");
                        }
                    }
                }).catch(err => {
                    console.log('ERROR: ', err.message);
                    swal("Login failed!", "Invalid credentials. or Role is not assigned to the user", "error");
                });
            }
    }
    
    return (
        (
            <div className="container">
                <ParticlesBackground />
                <div className="formDiv">
                    <form className="form" onSubmit={formSubmit}>
                        <h4><img src={logo} alt={"Hello"} className="mx-2" style={{ width: "32px", height: "32px" }} /><strong>Embedos Dashboard 3.0</strong></h4><br />
                        <h4><strong>Login</strong></h4><br />
                        <div className="mb-3">
                            <input type="text" className="form-control" placeholder="username" name="username" onChange={(e) => { username = e.target.value }} required />
                        </div>
                        <div className="mb-3">
                            <input type="password" className="form-control" placeholder="password" name="password" onChange={(e) => { password = e.target.value }} required />
                        </div>
                        <div className="mb-3">
                            <button type="submit" className="btn btn-primary" style={{ width: "40%" }}>Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        )
    );
}

export default Login;