import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle, useCallback } from "react";
import Widget from "./Widget.js";
import Muuri from "muuri";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/muuri_styles.css";
import "../styles/add_pane.css";
import "../styles/modal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faCog, faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import EditSettingModal from "./EditSettingModal.js";
import WidgetSettingModal from "./WidgetSettingModal.js";
import DropDownModal from "./DropDownModal.js";
import GridLayout from "react-grid-layout";
import { Responsive, WidthProvider } from 'react-grid-layout';
import '../../node_modules/react-grid-layout/css/styles.css'
import '../../node_modules/react-resizable/css/styles.css'

import PropTypes from "prop-types";
import _ from "lodash";


const getLayoutsFromPanes = (paneArray) => {
  // Generate layouts for each breakpoint
  const layouts = { lg: [], md: [], sm: [], xs: [] };

  paneArray.forEach((pane, ind) => {
    ['lg', 'md', 'sm', 'xs'].forEach((breakpoint) => {
      layouts[breakpoint].push({
        i: ind.toString(),
        x: pane.x,
        y: pane.y,
        w: pane.w,
        h: pane.h,

      });
    });
  });

  console.log("LAYOUTS FROM FUNCTION", layouts)
  return layouts;
};

const ResponsiveGridLayout = WidthProvider(Responsive);
const generateRandomId = () => {
  const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  const idLength = 10;
  let randomId = '';

  for (let i = 0; i < idLength; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomId += characters[randomIndex];
  }

  return randomId;
};

const MuuriWidget = forwardRef((props, ref) => {

  const [dataIndex, setDataIndex] = useState(0);
  const [currentPaneIndex, setCurrentPaneIndex] = useState(0);
  const [currentWidgetIndex, setCurrentWidgetIndex] = useState(0);
  const [modal, setModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editWidgetModal, setEditWidgetModal] = useState(false);
  const [changeSourceModal, setChangeSourceModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [isChildDrag, setChildDrag] = useState(false)
  const [isParentDraggable, setIsParentDraggable] = useState(true);
  const parentGridRef = useRef(null);
  const startPosRef = useRef({ x: 0, y: 0 });

  const [currentBreakpoint, setCurrentBreakpoint] = useState("lg");
  const [compactType, setCompactType] = useState("horizontal");
  const [mounted, setMounted] = useState(false);
  const [layouts, setLayouts] = useState({});
  const [initialSetup, setInitialSetup] = useState(true);


  useImperativeHandle(ref, () => ({
    updateLayouts: (newPaneArray) => {
      const newLayouts = getLayoutsFromPanes(newPaneArray);
      setLayouts(newLayouts);
    }
  }));

  // useEffect(() => {
  //   if (props.paneArray && props.paneArray.length > 0) {
  //     const newLayouts = getLayoutsFromPanes(props.paneArray);
  //     if (JSON.stringify(newLayouts) !== JSON.stringify(layouts)) {
   
  //       setLayouts(newLayouts);
  //       setInitialSetup(false);
  //       console.log("LAYOUTS", layouts)
  //     }
  //     setMounted(true);
  //   }
  // }, [props.paneArray, layouts]);  // Add props.paneArray as a dependency

 // Load layout when component is initialized
//  const initializeLayout = () => {
//   console.log("initialize layout called")
//   if (props.paneArray && props.paneArray.length > 0) {
//     const newLayouts = getLayoutsFromPanes(props.paneArray);
//     setInitialSetup(false)
//     setMounted(true);
//     // setLayouts(newLayouts);
//   }
// };

// useEffect(() => {

//   initializeLayout(); // Initialize layout on first render
// }, [props.paneArray]); // Empty dependency array ensures this runs only once on mount

const prevDashboard = useRef(null);
const layoutInitialized = useRef(false);
const prevPaneArray = useRef(null); // Track previous paneArray for comparison
const debounce = (func, wait) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
};
const initializeLayout = useCallback(() => {
  if (props.paneArray && props.paneArray.length > 0 && !_.isEqual(prevDashboard.current, props.newDashboard)) {
    const newLayouts = getLayoutsFromPanes(props.paneArray);
    setLayouts(newLayouts);
    setMounted(true);
    setInitialSetup(false);
    prevDashboard.current =  props.newDashboard
  }
}, [props.paneArray]);

useEffect(() => {
  // Check if paneArray has actually changed before re-initializing layout
  if (!_.isEqual(prevPaneArray.current, props.paneArray)) {
    console.log("CALLING REINIT")
    initializeLayout();
    prevPaneArray.current = props.paneArray;
  }
}, [props.paneArray, initializeLayout]);

  const onLayoutChange = useCallback(
    debounce((layout, allLayouts) => {
      if (initialSetup) return;
      // if (!props.paneArray) return;

      const updatedPaneArray = props.paneArray.map((pane, index) => {
        const updatedPane = allLayouts[currentBreakpoint].find((item) => item.i === index.toString());
        if (!updatedPane) {
          console.warn(`Pane with index ${index} not found in allLayouts[${currentBreakpoint}]`);
          return pane;
        }

        return {
          ...pane,
          x: updatedPane.x,
          y: updatedPane.y,
          w: updatedPane.w,
          h: updatedPane.h,
        };
      });

      if (!_.isEqual(updatedPaneArray, props.paneArray)) {
        console.log(props.paneArray, updatedPaneArray)
        props.setPaneArray(updatedPaneArray);
      }
      setLayouts(allLayouts);
      if (props.onLayoutChange) {
        props.onLayoutChange(layout, allLayouts);
      }
    }, 300),
    [currentBreakpoint, props.paneArray, props.setPaneArray, props.onLayoutChange, initialSetup]
  );


  var dummyPaneArray = [];
  var dummyWidgetArray = []
  var options = {
    colorStart: "#6fadcf",
    colorStop: void 0,
    gradientType: 0,
    strokeColor: "#e0e0e0",
    generateGradient: true,
    pointer: {
      length: 0.3,
      strokeWidth: 0.035,
      iconScale: 1.0,
    },
    staticLabels: {
      font: "10px sans-serif",
      labels: [0, 10, 30, 50, 70, 100],
      fractionDigits: 0,
    },
    staticZones: [
      { strokeStyle: "#F03E3E", min: 0, max: 10 },
      { strokeStyle: "#FFDD00", min: 10, max: 30 },
      { strokeStyle: "#30B32D", min: 30, max: 50 },
      { strokeStyle: "#FFDD00", min: 50, max: 70 },
      { strokeStyle: "#F03E3E", min: 70, max: 100 },
    ],
    angle: 0,
    lineWidth: 0.25,
    radiusScale: 1.0,
    fontSize: 40,
    limitMax: false,
    limitMin: false,
    highDpiSupport: true,
  };


  const toggleModal = () => {
    setModal(false);
  };

  const editWidgeToggleModal = () => {
    setEditWidgetModal(false);
    setEditMode(false)
  };

  const toggleEditModal = () => {
    setEditModal(false);
  };

  if (modal) {
    document.body.classList.add("active-modal");
  } else {
    document.body.classList.remove("active-modal");
  }

  const deletePane = (ind) => {
    console.log("PANE ID", ind)
    dummyPaneArray = [...props.paneArray];
    dummyPaneArray = dummyPaneArray.filter((prevPane, id) => {
      return id != ind;
    });
    props.setPaneArray(dummyPaneArray);

    // Update layouts based on the new paneArray
    const newLayouts = getLayoutsFromPanes(dummyPaneArray);
    setLayouts(newLayouts);
  };

  // const calculatePaneHeight = (numWidgets) => {
  //   const widgetHeight = 150; // Example fixed height for each widget

  //   const padding = 30; // Additional padding or margin
  //   return (numWidgets * widgetHeight) / 19
  // }

  // const calculatePaneWidth = (numWidgets) => {
  //   const widgetWidth = 160; // Example fixed height for each widget

  //   const padding = 30; // Additional padding or margin
  //   return (numWidgets * widgetWidth) / 40
  // }


  const deleteWidget = (ind, idx) => {
    let mainPainArray = [...props.paneArray]
    dummyWidgetArray = [...props.paneArray[ind].widgetsArray];
    console.log("BEFORE", dummyWidgetArray)
    dummyWidgetArray = dummyWidgetArray.filter((prevPane, id) => {
      return id != idx;
    });
    // Update the widgetsArray of the specified pane
    mainPainArray[ind] = {
      ...mainPainArray[ind],
      widgetsArray: dummyWidgetArray
    };

    props.setPaneArray(mainPainArray);
  };

  const addWidget = (id) => {
    setCurrentPaneIndex(id);
    setModal(true);
    setEditMode(false)
  };

  // const onLayoutChange = (layout, allLayouts) => {
  //   console.log("ONLAYOUT CHANGE",allLayouts );
  //   console.log("PANEARRAY", props.paneArray);
  //   if (initialSetup) return;
  //   if(!props.paneArray) return;
  //   // Only update the paneArray if the layout for the current breakpoint is changing
  //   const breakpoint = 'lg'; // Replace with the current breakpoint
  //   const updatedPaneArray = props.paneArray.map((pane, index) => {
  //     const updatedPane = allLayouts[currentBreakpoint].find((item) => item.i === index.toString());

  //     return {
  //       ...pane,
  //       x: updatedPane.x,
  //       y: updatedPane.y,
  //       w: updatedPane.w,
  //       h: updatedPane.h,
  //     };
  //   });
  
  //   props.setPaneArray(updatedPaneArray);
  //   setLayouts(allLayouts);
  //   props.onLayoutChange(layout, allLayouts);
  // };


 

  // const onWidgetLayoutChange = (newLayout) => {
  //   const updatedPaneArray = props.paneArray.map((pane, paneIndex) => {
  //     // Update each widget's dataGrid within the pane
  //     const updatedWidgetsArray = pane.widgetsArray.map((widget, widgetIndex) => {
  //       const updatedWidgetLayout = newLayout.find((item) => item.i === `${paneIndex}${widgetIndex}`);

  //       if (updatedWidgetLayout) {
  //         return {
  //           ...widget,
  //           dataGrid: {
  //             ...widget.dataGrid,
  //             wx: updatedWidgetLayout.x,
  //             wy: updatedWidgetLayout.y,
  //             ww: updatedWidgetLayout.w,
  //             wh: updatedWidgetLayout.h,
  //           }
  //         };
  //       }
  //       return widget;
  //     });

  //     return {
  //       ...pane,
  //       widgetsArray: updatedWidgetsArray
  //     };
  //   });

  //   props.setPaneArray(updatedPaneArray);
  // };

  const onWidgetLayoutChange = (layout, allLayouts) => {
    const updatedPaneArray = props.paneArray.map((pane, paneIndex) => {
      // Update each widget's dataGrid within the pane
      const updatedWidgetsArray = pane.widgetsArray.map((widget, widgetIndex) => {
        const updatedWidgetLayout = layout.find((item) => item.i === `${paneIndex}${widgetIndex}`);
  
        if (updatedWidgetLayout) {
          return {
            ...widget,
            dataGrid: {
              ...widget.dataGrid,
              wx: updatedWidgetLayout.x,
              wy: updatedWidgetLayout.y,
              ww: updatedWidgetLayout.w,
              wh: updatedWidgetLayout.h,
            }
          };
        }
        return widget;
      });
  
      return {
        ...pane,
        widgetsArray: updatedWidgetsArray
      };
    });
  
    props.setPaneArray(updatedPaneArray);
  };
  

  const widgetSettupHandler = () => {
    let form = document.querySelector("#create-widget");
    let formData = Object.fromEntries(new FormData(form).entries());
    dummyPaneArray = [...props.paneArray];
    if (formData.widget_type === "gauge") {
      let datasets = [{
        name: formData.widget_name,
      }];
      if (!editMode) {
        dummyPaneArray[currentPaneIndex].widgetsArray.push({
          widget_type: formData.widget_type,
          widget_name: formData.widget_name,
          widget_unit: formData.widget_unit,
          dashboard_name: props.newDashboard,
          value: 0, //allSystems[dataIndex].value
          datasource: [],
          datasets: datasets,
          dataGrid: { wx: 0, wy: 0, ww: 3, wh: 7, wminWidth: 2, wmaxWidth: 12, wminHeight: 5 }
        });
      }
      else {
        const newWidgetsArray = [...dummyPaneArray[currentPaneIndex].widgetsArray];
        newWidgetsArray[currentWidgetIndex] = {
          widget_type: formData.widget_type,
          widget_name: formData.widget_name,
          widget_unit: formData.widget_unit,
          dashboard_name: props.newDashboard,
          value: 0, //allSystems[dataIndex].value
          datasource: [],
          datasets: datasets,
          dataGrid: {
            wx: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.wx,
            wy: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.wy,
            ww: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.ww,
            wh: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.wh,
            wminW: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.wminWidth,
            wmaxW: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.wmaxWidth,
            wminH: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.wminHeight
          }
        };
        dummyPaneArray[currentPaneIndex].widgetsArray = newWidgetsArray;

      }

      setDataIndex((dataIndex + 1) % 8);
    } else if (formData.widget_type === "bar_chart") {
      let form = document.querySelector("#create-widget");
      let formData = Object.fromEntries(new FormData(form).entries());
      dummyPaneArray = [...props.paneArray];
      if (formData.widget_type === "bar_chart") {
        let datasets = [{
          name: formData.widget_name,
          bar_bg_color: formData.bar_bg_color,
          bar_br_color: formData.bar_br_color
        }];
        if (!editMode) {
          dummyPaneArray[currentPaneIndex].widgetsArray.push({
            widget_type: formData.widget_type,
            widget_name: formData.widget_name,
            dashboard_name: props.newDashboard,
            data: [],
            labels: [],
            datasets: datasets,
            dataGrid: { wx: 0, wy: 0, ww: 4, wh: 7, wminWidth: 3, wmaxWidth: 12, wminHeight: 5 }
          });
        }
        else {
          const newWidgetsArray = [...dummyPaneArray[currentPaneIndex].widgetsArray];
          newWidgetsArray[currentWidgetIndex] = {
            widget_type: formData.widget_type,
            widget_name: formData.widget_name,
            dashboard_name: props.newDashboard,
            data: [],
            labels: [],
            datasets: datasets,
            dataGrid: {
              wx: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.wx,
              wy: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.wy,
              ww: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.ww,
              wh: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.wh,
              wminW: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.wminWidth,
              wmaxW: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.wmaxWidth,
              wminH: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.wminHeight
            }
          };
          dummyPaneArray[currentPaneIndex].widgetsArray = newWidgetsArray;

        }

      }
    } else if (formData.widget_type === "realtime_chart") {
      let datasets = [];
      let i = 0;
      while (formData["chart_label" + i]) {
        datasets.push({
          label: formData["chart_label" + i],
          data: [],
          // you can set indiviual colors for each bar
          backgroundColor: formData["chart_color" + i],
          borderWidth: 1,
        })
        i++;
      }
      if (!editMode) {
        dummyPaneArray[currentPaneIndex].widgetsArray.push({
          widget_type: formData.widget_type,
          datasets: datasets,
          dashboard_name: props.newDashboard,
          value: 0, //allSystems[dataIndex].value
          datasource: [],
          dataGrid: { wx: 0, wy: 0, ww: 4, wh: 7, wminWidth: 3, wmaxWidth: 12, wminHeight: 5 }
        });
      }
      else {
        const newWidgetsArray = [...dummyPaneArray[currentPaneIndex].widgetsArray];
        newWidgetsArray[currentWidgetIndex] = {
          widget_type: formData.widget_type,
          datasets: datasets,
          dashboard_name: props.newDashboard,
          value: 0, //allSystems[dataIndex].value
          datasource: [],
          dataGrid: {
            wx: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.wx,
            wy: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.wy,
            ww: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.ww,
            wh: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.wh,
            wminW: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.wminWidth,
            wmaxW: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.wmaxWidth,
            wminH: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.wminHeight
          }
        };
        dummyPaneArray[currentPaneIndex].widgetsArray = newWidgetsArray;

      }


    } else if (formData.widget_type === "fluid_tank") {
      let datasets = [{
        name: formData.widget_name,
      }];

      if (!editMode) {
        dummyPaneArray[currentPaneIndex].widgetsArray.push({
          widget_type: formData.widget_type,
          widget_name: formData.widget_name,
          lower_limit: formData.lower_limit,
          upper_limit: formData.upper_limit,
          dashboard_name: props.newDashboard,
          value: 0, //allSystems[dataIndex].value
          datasource: [],
          datasets: datasets,
          dataGrid: { wx: 0, wy: 0, ww: 4, wh: 7, wminWidth: 3, wmaxWidth: 12, wminHeight: 5 }
        });
      }
      else {
        const newWidgetsArray = [...dummyPaneArray[currentPaneIndex].widgetsArray];
        newWidgetsArray[currentWidgetIndex] = {
          widget_type: formData.widget_type,
          widget_name: formData.widget_name,
          lower_limit: formData.lower_limit,
          upper_limit: formData.upper_limit,
          dashboard_name: props.newDashboard,
          value: 0, //allSystems[dataIndex].value
          datasource: [],
          datasets: datasets,
          dataGrid: {
            wx: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.wx,
            wy: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.wy,
            ww: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.ww,
            wh: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.wh,
            wminW: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.wminWidth,
            wmaxW: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.wmaxWidth,
            wminH: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.wminHeight
          }
        };
        dummyPaneArray[currentPaneIndex].widgetsArray = newWidgetsArray;

      }

    } else if (formData.widget_type === "custom_gauge") {
      let datasets = [{
        unit: formData.cg_unit,
        minValue: formData.cg_min_value,
        maxValue: formData.cg_max_value,
        animationSpeed: formData.cg_ani_speed,
        angle: formData.cg_angle,
        lineWidth: formData.cg_gauge_width,
        radiusScale: formData.cg_radius,
        pointerLength: formData.cg_pointer_length,
        pointerStrokeWidth: formData.cg_pointer_sw,
        pointerColor: formData.cg_pointer_color,
        divisions: formData.cg_divisions,
        divWidth: formData.cg_dw,
        divLength: formData.cg_dl,
        divColor: formData.cg_div_color,
        subDivisions: formData.cg_subdivisions,
        subLength: formData.cg_sdw,
        subWidth: formData.cg_sdl,
        subColor: formData.cg_subdiv_color,
        limitMax: formData.cg_limit_max === "on",
        limitMin: formData.cg_limit_min === "on",
        colorStart: formData.cg_start_color,
        colorStop: formData.cg_stop_color,
        strokeColor: formData.cg_stroke_color,
        generateGradient: formData.cg_gradient === "on",
        donut: formData.cg_donut === "on",
      }];
      if (!editMode) {
        dummyPaneArray[currentPaneIndex].widgetsArray.push({
          widget_type: formData.widget_type,
          dashboard_name: props.newDashboard,
          datasets: datasets,
          dataGrid: { wx: 0, wy: 0, ww: 4, wh: 7, wminWidth: 3, wmaxWidth: 12, wminHeight: 5 }
        });
      }
      else {
        const newWidgetsArray = [...dummyPaneArray[currentPaneIndex].widgetsArray];
        newWidgetsArray[currentWidgetIndex] = {
          widget_type: formData.widget_type,
          dashboard_name: props.newDashboard,
          datasets: datasets,
          dataGrid: {
            wx: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.wx,
            wy: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.wy,
            ww: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.ww,
            wh: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.wh,
            wminW: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.wminWidth,
            wmaxW: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.wmaxWidth,
            wminH: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.wminHeight
          }
        };
        dummyPaneArray[currentPaneIndex].widgetsArray = newWidgetsArray;

      }

    } else if (formData.widget_type === "equilizer_bar") {
      console.log(formData)
      let datasets = [{
        name: formData.eb_name,
        sourceCnt: parseInt(formData.eb_src_cnt),
        idx: 0,
      }];
      let cnt = 0;
      while (true) {
        let k1 = `eb_bg_color_${cnt}`;
        let k2 = `eb_br_color_${cnt}`;
        if (formData[k1] && formData[k2]) {
          if (cnt === 0) {
            datasets[0]['k1'] = formData[k1];
            datasets[0]['k2'] = formData[k2];
          } else {
            datasets.push({
              idx: cnt,
              k1: formData[k1],
              k2: formData[k2],
            });
          }
        } else {
          break;
        }
        cnt++;
      }
      if (!editMode) {

        dummyPaneArray[currentPaneIndex].widgetsArray.push({
          widget_type: formData.widget_type,
          dashboard_name: props.newDashboard,
          datasets: datasets,
          dataGrid: { wx: 0, wy: 0, ww: 4, wh: 7, wminWidth: 3, wmaxWidth: 12, wminHeight: 5 }
        });
      }
      else {
        const newWidgetsArray = [...dummyPaneArray[currentPaneIndex].widgetsArray];
        newWidgetsArray[currentWidgetIndex] = {
          widget_type: formData.widget_type,
          dashboard_name: props.newDashboard,
          datasets: datasets,
          dataGrid: {
            wx: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.wx,
            wy: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.wy,
            ww: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.ww,
            wh: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.wh,
            wminW: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.wminWidth,
            wmaxW: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.wmaxWidth,
            wminH: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.wminHeight
          }
        };
        dummyPaneArray[currentPaneIndex].widgetsArray = newWidgetsArray;

      }


    } else if (formData.widget_type === 'mqtt_writer') {
      let datasets = [{
        host: formData.mw_server,
        port: formData.mw_port,
        topic: formData.mw_topic,
      }];
      if (formData.mw_username && formData.mw_password) {
        datasets[0]['username'] = formData.mw_username;
        datasets[0]['password'] = formData.mw_password;
      }
      if (formData.mw_clientId) {
        datasets[0]['clientId'] = formData.mw_clientId;
      }
      if (!editMode) {
        dummyPaneArray[currentPaneIndex].widgetsArray.push({
          widget_type: formData.widget_type,
          dashboard_name: props.newDashboard,
          datasets: datasets,
          dataGrid: { wx: 0, wy: 0, ww: 4, wh: 7, wminWidth: 3, wmaxWidth: 12, wminHeight: 5 }

        });
      }
      else {
        const newWidgetsArray = [...dummyPaneArray[currentPaneIndex].widgetsArray];
        newWidgetsArray[currentWidgetIndex] = {
          widget_type: formData.widget_type,
          dashboard_name: props.newDashboard,
          datasets: datasets,
          dataGrid: {
            wx: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.wx,
            wy: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.wy,
            ww: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.ww,
            wh: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.wh,
            wminW: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.wminWidth,
            wmaxW: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.wmaxWidth,
            wminH: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.wminHeight
          }
        };
        dummyPaneArray[currentPaneIndex].widgetsArray = newWidgetsArray;
      }

    } else if (formData.widget_type === 'mqtt_indicator') {
      let datasets = [{
        offText: formData.mi_off_text,
        onText: formData.mi_on_text,
        refreshInterval: formData.mi_refresh,
        title: formData.mi_title,
        // value: formData.mi_value,
      }];
      if (!editMode) {
        dummyPaneArray[currentPaneIndex].widgetsArray.push({
          widget_type: formData.widget_type,
          dashboard_name: props.newDashboard,
          datasets: datasets,
          dataGrid: { wx: 0, wy: 0, ww: 4, wh: 7, wminWidth: 3, wmaxWidth: 12, wminHeight: 5 }
        });
      }
      else {
        const newWidgetsArray = [...dummyPaneArray[currentPaneIndex].widgetsArray];
        newWidgetsArray[currentWidgetIndex] = {
          widget_type: formData.widget_type,
          dashboard_name: props.newDashboard,
          datasets: datasets,
          dataGrid: {
            wx: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.wx,
            wy: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.wy,
            ww: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.ww,
            wh: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.wh,
            wminW: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.wminWidth,
            wmaxW: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.wmaxWidth,
            wminH: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.wminHeight
          }
        };
        dummyPaneArray[currentPaneIndex].widgetsArray = newWidgetsArray;
      }

    } else if (formData.widget_type === 'realtime_table') {
      let datasets = [];
      let cnt = 0;
      while (true) {
        let label = `rt_label${cnt}`;
        // let value = `rt_value${cnt}`        it was inside if condition && formData[value];
        if (formData[label]) {
          datasets.push({
            [`label${cnt}`]: formData[label]
          })
          // datasets[0][label] = formData[label];
          // datasets[0][value] = formData[value];
        } else {
          break;
        }
        cnt++;
      }
      if (!editMode) {
        dummyPaneArray[currentPaneIndex].widgetsArray.push({
          widget_type: formData.widget_type,
          dashboard_name: props.newDashboard,
          columns: formData.rt_noc,
          tableName: formData.table_name,
          datasets: datasets,
          datasource: [],
          value: 0,
          paneID: generateRandomId(),
          dataGrid: { wx: 0, wy: 0, ww: 4, wh: 7, wminWidth: 3, wmaxWidth: 12, wminHeight: 5 }
        });
      }
      else {
        const newWidgetsArray = [...dummyPaneArray[currentPaneIndex].widgetsArray];
        newWidgetsArray[currentWidgetIndex] = {
          widget_type: formData.widget_type,
          dashboard_name: props.newDashboard,
          datasets: datasets,
          columns: formData.rt_noc,
          tableName: formData.table_name,
          datasource: [],
          value: 0,
          paneID: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].paneID,
          dataGrid: {
            wx: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.wx,
            wy: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.wy,
            ww: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.ww,
            wh: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.wh,
            wminW: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.wminWidth,
            wmaxW: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.wmaxWidth,
            wminH: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.wminHeight
          }
        };
        dummyPaneArray[currentPaneIndex].widgetsArray = newWidgetsArray;
      }

    } else if (formData.widget_type === 'text_widget') {
      let datasets = [{
        text: formData.text_widget,
        fontSize: formData.text_font_size,
        fontWeight: formData.text_font_weight
      }];
      if (!editMode) {
        dummyPaneArray[currentPaneIndex].widgetsArray.push({
          widget_type: formData.widget_type,
          dashboard_name: props.newDashboard,
          datasets: datasets,
          datasource: [],
          dataGrid: { wx: 0, wy: 0, ww: 4, wh: 7, wminWidth: 3, wmaxWidth: 12, wminHeight: 5 }
        });
      }
      else {
        const newWidgetsArray = [...dummyPaneArray[currentPaneIndex].widgetsArray];
        newWidgetsArray[currentWidgetIndex] = {
          widget_type: formData.widget_type,
          dashboard_name: props.newDashboard,
          datasets: datasets,
          datasource: [],
          dataGrid: {
            wx: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.wx,
            wy: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.wy,
            ww: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.ww,
            wh: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.wh,
            wminW: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.wminWidth,
            wmaxW: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.wmaxWidth,
            wminH: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.wminHeight
          }
        };
        dummyPaneArray[currentPaneIndex].widgetsArray = newWidgetsArray;
      }

    } else if (formData.widget_type === 'dynamic_text_widget') {
      console.log("FORM DATA: ", formData);
      // let numberOfStatus = parseInt(formData.NUMBER_of_STATUS);
      // let textStatus = {};
      // for (let i = 0; i < numberOfStatus; i++) {
      //   let keyName = `key_${i}`;
      //   let valueName = `value_${i}`;
      //   let KeyVal = formData[keyName];
      //   let Value = formData[valueName];
      //   textStatus[KeyVal] = Value;
      // }
      // console.log(textStatus);
      let datasets = [{
        name: formData.dynamic_text_widget,
        // text: textStatus,
        logicInput: formData.logic_input,
        fontSize: formData.dynamic_text_widget_font_size,
        fontWeight: formData.dynamic_text_widget_font_weight
      }];

      // dummyPaneArray[currentPaneIndex].widgetsArray.push({
      //   widget_type: formData.widget_type,
      //   dashboard_name: props.newDashboard,
      //   value: 0, //allSystems[dataIndex].value
      //   datasets: datasets,
      //   datasource: [],
      // });
      if (!editMode) {
        console.log("ADDING")
        dummyPaneArray[currentPaneIndex].widgetsArray.push({
          widget_type: formData.widget_type,
          dashboard_name: props.newDashboard,
          value: 0, //allSystems[dataIndex].value
          datasets: datasets,
          datasource: [],
          dataGrid: { wx: 0, wy: 0, ww: 4, wh: 7, wminWidth: 2, wmaxWidth: 12, wminHeight: 2 }
        });
      }
      else {
        console.log("EDITING")
        const newWidgetsArray = [...dummyPaneArray[currentPaneIndex].widgetsArray];
        newWidgetsArray[currentWidgetIndex] = {
          widget_type: formData.widget_type,
          dashboard_name: props.newDashboard,
          value: 0, // Replace with actual value if needed
          datasets: datasets,
          datasource: [],
          dataGrid: {
            wx: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.wx,
            wy: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.wy,
            ww: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.ww,
            wh: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.wh,
            wminW: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.wminWidth,
            wmaxW: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.wmaxWidth,
            wminH: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.wminHeight
          }
        };
        dummyPaneArray[currentPaneIndex].widgetsArray = newWidgetsArray;

      }

    } else if (formData.widget_type === 'custom_form_widget') {
      console.log("FORM DATA: ", formData);
      let number_of_form_input_fields = parseInt(formData.number_of_input_fields);
      console.log("number_of_form_input_fields = ", number_of_form_input_fields);
      let formInputs = {};
      for (let i = 0; i < number_of_form_input_fields; i++) {
        let fieldName = `form_input_field_${i}`;
        let fieldVal = formData[fieldName];
        console.log("fieldName: ", fieldName, "fieldVal: ", fieldVal);
        formInputs[fieldName] = fieldVal;
      }
      console.log(formInputs);
      let datasets = [{
        name: formData.form_header,
        host: formData.mw_server,
        port: formData.mw_port,
        topic: formData.mw_topic,
        customFormInputs: formInputs,
        numberOfInputFields: number_of_form_input_fields
      }];
      if (formData.mw_username && formData.mw_password) {
        datasets[0]['username'] = formData.mw_username;
        datasets[0]['password'] = formData.mw_password;
      }
      if (formData.mw_clientId) {
        datasets[0]['clientId'] = formData.mw_clientId;
      }

      if (!editMode) {
        dummyPaneArray[currentPaneIndex].widgetsArray.push({
          widget_type: formData.widget_type,
          dashboard_name: props.newDashboard,
          value: 0, //allSystems[dataIndex].value
          datasets: datasets,
          datasource: [],
          dataGrid: { wx: 0, wy: 0, ww: 4, wh: 7, wminWidth: 3, wmaxWidth: 12, wminHeight: 5 }
        });
      }
      else {
        const newWidgetsArray = [...dummyPaneArray[currentPaneIndex].widgetsArray];
        newWidgetsArray[currentWidgetIndex] = {
          widget_type: formData.widget_type,
          dashboard_name: props.newDashboard,
          value: 0, //allSystems[dataIndex].value
          datasets: datasets,
          datasource: [],
          dataGrid: {
            wx: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.wx,
            wy: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.wy,
            ww: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.ww,
            wh: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.wh,
            wminW: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.wminWidth,
            wmaxW: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.wmaxWidth,
            wminH: dummyPaneArray[currentPaneIndex].widgetsArray[currentWidgetIndex].dataGrid.wminHeight
          }

        };
        dummyPaneArray[currentPaneIndex].widgetsArray = newWidgetsArray;
      }


    }
    props.setPaneArray(dummyPaneArray);
    setModal(false);
  };

  const changeSource = (ind, idx) => {
    setCurrentPaneIndex(ind);
    setCurrentWidgetIndex(idx);
    setChangeSourceModal(true);
  };

  const editWidget = (ind, idx) => {
    setCurrentPaneIndex(ind);
    setCurrentWidgetIndex(idx);
    setEditWidgetModal(true);
    setEditMode(true)
  };

  const stopPropagation = (e) => {
    e.stopPropagation(); // Prevent grid item dragging
  };

  const handleMouseDown = (event) => {
    console.log('Mouse down:', event.target.id);
    setChildDrag(true);
    setIsParentDraggable(false);
    console.log("ISPARENT DRAGABLE:", isParentDraggable)
    console.log("IS CHILD DRAGABLE:", isChildDrag)
  };

  const handleMouseUp = (event) => {
    console.log('Mouse up:', event.target.id);
    setChildDrag(false);
    setIsParentDraggable(true);
    console.log("ISPARENT DRAGABLE:", isParentDraggable)
    console.log("IS CHILD DRAGABLE:", isChildDrag)
  };

  const handleParentDragDown = (event) => {
    console.log('Mouse up:', event.target.id);
    setIsParentDraggable(true);
    console.log("ISPARENT DRAGABLE:", isParentDraggable)
    console.log("IS CHILD DRAGABLE:", isChildDrag)
  };

  const handleParentDragStart = (e) => {
    if (parentGridRef.current) {
      startPosRef.current = { x: e.clientX, y: e.clientY };
      document.addEventListener('mousemove', handleParentDrag);
      document.addEventListener('mouseup', handleParentDragEnd);
      parentGridRef.current.classList.add('dragging');
    }
  };

  const handleParentDrag = (e) => {
    if (parentGridRef.current) {
      const deltaX = e.clientX - startPosRef.current.x;
      const deltaY = e.clientY - startPosRef.current.y;
      parentGridRef.current.style.transform = `translate(${deltaX}px, ${deltaY}px)`;
    }
  };

  const handleParentDragEnd = (e) => {
    document.removeEventListener('mousemove', handleParentDrag);
    document.removeEventListener('mouseup', handleParentDragEnd);
    if (parentGridRef.current) {
      parentGridRef.current.classList.remove('dragging');
      parentGridRef.current.style.transform = 'none';
    }
  };

  useEffect(() => {
    return () => {
      document.removeEventListener('mousemove', handleParentDrag);
      document.removeEventListener('mouseup', handleParentDragEnd);
    };
  }, []);

  const onBreakpointChange = (breakpoint) => {
    setCurrentBreakpoint(breakpoint);
  };

  const onCompactTypeChange = () => {
    setCompactType((prevCompactType) => {
      return prevCompactType === "horizontal"
        ? "vertical"
        : prevCompactType === "vertical"
          ? null
          : "horizontal";
    });
  };

  const onNewLayout = () => {
    setLayouts({ lg: generateLayout() });
  };

  function generateLayout() {
    return _.map(_.range(0, props.paneArray.length), (item, i) => {
      var y = Math.ceil(Math.random() * 4) + 1;
      return {
        x: 0,
        y: 0,
        w: 3,
        h: 5,
        i: i.toString(),

      };
    });
  }
  // console.log("PANE array", props.paneArray)
  // console.log("generateLayout", layouts)


  return (
    <>
      <div className="pane-display" style={{ overflow: 'hidden' }}>
       

        {/* NEW LAYOUT 2.0 */}
{/* 
         <div>
          Current Breakpoint: {currentBreakpoint} ({props.cols[currentBreakpoint]} columns)
        </div> */}
        
        <button onClick={onCompactTypeChange}>Change Compaction Type</button> Compaction type: {_.capitalize(compactType) || "No Compaction"}
        <ResponsiveGridLayout
          {...props}
          layouts={layouts}
          onBreakpointChange={onBreakpointChange}
          onLayoutChange={onLayoutChange}
          measureBeforeMount={false}
          useCSSTransforms={mounted}
          compactType={compactType}
          preventCollision={!compactType}
          isDraggable={isParentDraggable}
        >
          {props.paneArray.map((pane, ind) => (
            <div
              key={ind.toString()}

              style={{
                backgroundColor: 'white',
                borderRadius: '20px',
                fontSize: 20,
                color: "black",
                height: 0,
                
                overflowX:'hidden'
              }}
              ref={props.parentGridRef}
              className="parent-grid"
              onDoubleClick={handleParentDragDown}
            >
              <div className="item-content">
                <div style={{ paddingBottom: "5px", textAlign: "center", cursor: 'move' }} className="grid-heading">
                  <span style={{ paddingLeft: "10px", color: 'white' }}>Pane {ind}</span>
                </div>
                <div className="modify-pane">
                  <button
                    type="submit"
                    className="btn btn-circle btn-sm"
                    onClick={() => addWidget(ind)}
                    onMouseDown={stopPropagation}
                    onTouchStart={stopPropagation}
                  >
                    <FontAwesomeIcon icon={faPlus} size="xs" />
                  </button>
                  <button
                    type="submit"
                    className="btn btn-danger btn-sm btn-set"
                    onClick={() => deletePane(ind)}
                    onMouseDown={stopPropagation}
                    onTouchStart={stopPropagation}
                  >
                    <FontAwesomeIcon icon={faTrashAlt} size="xs" />
                  </button>
                </div>
                <div>
                  <ResponsiveGridLayout
                    className="layout nested-grid"
                    breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
                    cols={{ lg: 12, md: 12, sm: 6, xs: 4, xxs: 2 }}
                    rowHeight={30}
                    onLayoutChange={(layout, allLayouts) => onWidgetLayoutChange(layout, allLayouts)}
                    // width={1200}
                    // onLayoutChange={onWidgetLayoutChange}
                    layouts={layouts[ind]}
                    
                  >

                    {pane.widgetsArray.map((widget, idx) => (
                      <div
                        key={`${ind}${idx}`}
                        id={idx}
                        style={{
                          width: '100%',
                          padding: '10px',
                          borderRadius: '20px',
                          backgroundColor: '#F8F6F6',
                          
                        }}
                        className="nested-grid-item"
                        onMouseDownCapture={handleMouseDown}
                        data-grid={{ x: widget.dataGrid.wx, y: widget.dataGrid.wy, w: widget.dataGrid.ww, h: widget.dataGrid.wh, minW: widget.dataGrid.wminWidth, maxW: widget.dataGrid.wmaxWidth, minH: widget.dataGrid.wminHeight }}
                      >
                        <div className="widget-settings" style={{ zIndex: '9999' }}>
                          <DropdownButton
                            id="dropdown-basic-button"
                            title={<FontAwesomeIcon icon={faEllipsisH} />}
                            variant="secondary"
                            size="sm"
                            onMouseDown={stopPropagation}
                            onTouchStart={stopPropagation}
                          >
                            <Dropdown.Item
                              as="button"
                              onClick={() => changeSource(ind, idx)}
                              onMouseDown={stopPropagation}
                              onTouchStart={stopPropagation}
                            >
                              <FontAwesomeIcon icon={faCog} style={{ color: 'grey' }} /> Choose Source
                            </Dropdown.Item>
                            <Dropdown.Item
                              as="button"
                              onClick={() => editWidget(ind, idx)}
                              onMouseDown={stopPropagation}
                              onTouchStart={stopPropagation}
                            >
                              <FontAwesomeIcon icon={faEdit} style={{ color: 'blue' }} /> Edit
                            </Dropdown.Item>
                            <Dropdown.Item
                              as="button"
                              onClick={() => deleteWidget(ind, idx)}
                              onMouseDown={stopPropagation}
                              onTouchStart={stopPropagation}
                            >
                              <FontAwesomeIcon icon={faTrashAlt} style={{ color: 'red' }} /> Delete
                            </Dropdown.Item>
                          </DropdownButton>
                        </div>
                        <span style={{ marginBottom: "10px" }}>
                          {widget.widget_name}
                        </span>
                        <Widget
                          key={Math.random()}
                          newDashboard={props.newDashboard}
                          value={widget.value}
                          minValue={0}
                          maxValue={100}
                          animationSpeed={32}
                          options={options}
                          className="gauge-canvas"
                          widget={widget}
                          paneArray={props.paneArray}
                          setPaneArray={props.setPaneArray}
                          currPaneIndex={ind}
                          currWidgetIndex={idx}
                          onMouseDown={stopPropagation}
                          onTouchStart={stopPropagation}
                        />
                      </div>
                    ))}
                  </ResponsiveGridLayout>
                </div>
              </div>
            </div>
          ))}
        </ResponsiveGridLayout>


       
        {modal && (
          <DropDownModal
            toggleModal={toggleModal}
            widgetSettupHandler={widgetSettupHandler}

          />
        )}

        {/* {editModal && (
          <EditSettingModal
            paneSettingsHandler={paneSettingsHandler}
            toggleEditModal={toggleEditModal}
          />
        )} */}
        {editWidgetModal && (
          <DropDownModal
            editWidgeToggleModal={editWidgeToggleModal}
            widgetSettupHandler={widgetSettupHandler}
            editWidgetModal={editWidgetModal}
            paneArray={props.paneArray}
            setPaneArray={props.setPaneArray}
            currentPaneIndex={currentPaneIndex}
            currentWidgetIndex={currentWidgetIndex}
            widget={props.paneArray[currentPaneIndex].widgetsArray[currentWidgetIndex]}

          />
        )}
        {changeSourceModal && (
          <WidgetSettingModal
            setChangeSourceModal={setChangeSourceModal}
            paneArray={props.paneArray}
            setPaneArray={props.setPaneArray}
            currentPaneIndex={currentPaneIndex}
            currentWidgetIndex={currentWidgetIndex}
            widget={props.paneArray[currentPaneIndex].widgetsArray[currentWidgetIndex]}
          />
        )}
      </div>
    </>
  );
})



MuuriWidget.propTypes = {
  onLayoutChange: PropTypes.func.isRequired

};

MuuriWidget.defaultProps = {
  className: "layout",
  rowHeight: 30,
  onLayoutChange: function () { },
  cols: { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }
};


// CSS styles
const styles = `
.main-grid {
  position: relative;
}

.parent-grid .grid-heading {
  cursor: move;
  background-color:#393939;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  color:white;
}

.parent-grid.dragging {
  cursor: move;
}

.nested-grid-item:hover {
  cursor: move;
}
`;

// Add styles to the document
const styleSheet = document.createElement('style');
styleSheet.type = 'text/css';
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);
export default MuuriWidget;