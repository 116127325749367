import React, { useState } from "react"
import '../styles/user_management_styles.css'
import Navbar from "./Navbar.js";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import useFetch from './useFetch.js';
import $ from 'jquery';
import swal from "sweetalert";
import axios from "axios";
import { useEffect } from "react";
import MultiSelect from "./MultiSelect";
import config from '../config.json';
import "../fSelect.js";
import Cookie from 'universal-cookie';
import mixpanel from "mixpanel-browser";

const Alarms_Edit = () => {
    const cookies = new Cookie();;
    let actionInitiatingUser = -1;
    let userToken = "";
    try {
        actionInitiatingUser = parseInt(cookies.get('userID'));
        userToken = cookies.get('userToken');
    } catch (error) {}
    const headers = {
        'Authorization': `Bearer ${userToken}`,
        'Content-Type': 'application/json'
    };
    let selectedUserGroups = [];
    const { data: sources, isPending: isPendingSources, error: errorSources } = useFetch({ actionInitiatingUser: actionInitiatingUser.toString(), URL: config.BACKEND_URL + '/logger_sources/sources' });
    const { data: alarmSettings, isPending: isPendingAlarmSettings, error: errorAlarmSettings } = useFetch({ actionInitiatingUser: actionInitiatingUser.toString(), URL: config.BACKEND_URL + '/alarms' });
    const { data: userGroups, isPending: isPendingUserGroups, error: errorUserGroups } = useFetch({ actionInitiatingUser: actionInitiatingUser.toString(), URL: config.BACKEND_URL + '/usergroups' });
    const [selectedAlarmUserGroups, setSelectedAlarmUserGroups] = useState([]);
    const [selectedAlarm, setSelectedAlarm] = useState(undefined);
    useEffect(() => {
        if (userGroups && userGroups.data) {
            if (!selectedAlarm) {
                setSelectedAlarmUserGroups([]);
                return;
            }
            const filterGrps = userGroups.data.filter(group => selectedAlarm.userGroupIDs.includes(group.id));
            const ugrps = filterGrps.map((group) => ({
                label: group.name,
                value: group.id
            }));
            setSelectedAlarmUserGroups(prevState => {
                if (prevState.length !== ugrps.length) {
                    return ugrps;
                }
                for (let i = 0; i < ugrps.length; i++) {
                    if (ugrps[i].value !== prevState[i].value) {
                        return ugrps;
                    }
                }
                    return prevState;
              });
            }
    }, [selectedAlarm, userGroups]);
    
    const handleSelectedUserGroupIDs = (selected) => {
        selectedUserGroups = selected;
    }

    const handleEditForm = async (e) => {
        e.preventDefault();
        mixpanel.track("Create Alarm Edit Button Clicked", { userID: cookies.get('userID') });
        if (alarmSettings.length === 0) {
            swal("Error.", "No alarms found!", "error")
                .then(() => {
                    setSelectedAlarm(undefined);
                });
            return;
        }
        if (!selectedAlarm) {
            swal("Alarm not selected!", "Select an alarm before it can be edited.", "error");
            return;
        }
        if (!selectedUserGroups || selectedUserGroups.length === 0) {
            swal("No user groups selected!", "Select at least one user group before it can be edited.", "error");
            return;
        }
        const title = $("#title")[0].value,
            value = $("#value")[0].value,
            direction = $("#direction")[0].value,
            color = $("#color")[0].value,
            sourceId = parseInt($("#source")[0].value),
            userGroupIDs = selectedUserGroups.map(group => group.value);
        const editBody = { title, color, value, sourceId, direction, userGroupIDs, actionInitiatingUser };
        try {
            swal({
                title: "Are you sure?",
                text: "Do you want to edit the alarm?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then(async (willDelete) => {
                if (willDelete) {
                    await axios.patch(config.BACKEND_URL + `/alarms/${selectedAlarm.id}`, editBody, { headers });
                    swal("Alarm updated!", "Alarm has been updated successfully.", "success")
                        .then(() => {
                            window.location.reload();
                        });
                } else {
                    swal("Alarm not updated!", "", "info");
                }
            });
        }
        catch (err) {
            swal("Error!", err.message, "error");
        }
    }

    const handleAlarmSelect = (e) => {
        e.persist();
        if (e.target.value === 'selectAlarmText') {
            setSelectedAlarm(undefined);
            return;
        }
        else {
            setSelectedAlarm(alarmSettings.find(alarm => alarm.id === parseInt(e.target.value)));
        }
    }
    return (
        <div className="Alarms">
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons"
                rel="stylesheet" />
            <Navbar />
            <main className="container-fluid pb-3">
                <section className="d-flex row justify-content-center" style={{ padding: "1vw" }}>
                    <header className="h1 text-center">Edit Alarm</header>
                    <p className="h3 text-center">Select the alarm you wish to edit.</p>
                </section>
                <section className="d-flex px-5 justify-content-center">
                    {isPendingAlarmSettings && <div className="text-center h3">Loading...</div>}
                    {errorAlarmSettings && <div className="text-center h3">Could not fetch the alarms!</div>}
                    {alarmSettings &&
                        ((alarmSettings.length === 0 && <div className="text-center h3">Currently, there are no alarms!</div>) ||
                            <select name="alarm" id="alarm" className="form-select" style={{ width: "auto" }} onChange={handleAlarmSelect}>
                                <option value="selectAlarmText" selected>Choose alarm</option>
                                {alarmSettings.map(alarm => { return ( <option key={alarm.id} value={alarm.id} >{alarm.title}</option> ) })}
                            </select>
                        )}
                </section>
                <section className="d-flex justify-content-center">
                    {selectedAlarm && <form onSubmit={handleEditForm} className="col col-sm-6">
                        <div className="col-xs-4 col-xs-offset-4">
                            <div id="direction-label" className="floating-label-group" style={{ marginBottom: "0.25rem" }}>
                                {isPendingSources && <div className="text-center h5">Loading...</div>}
                                {errorSources && <div className="text-center h5">Cannot fetch the sources right now!</div>}
                                {sources && sources.data && sources.data.length === 0 && <div className="text-center h5">Currently, there are no data sources!</div>}
                                {sources && sources.data && sources.data.length > 0 &&
                                    <select id="source" className="form-control form-control" name="source">
                                        <option value="selectSourceText">Choose a source</option>
                                        {
                                            sources.data.map((source, index) => {
                                                return (
                                                    index === selectedAlarm.sourceId ?
                                                        <option key={index} value={source.id}>{source.name}</option> :
                                                        <option key={index} value={source.id} selected>{source.name}</option>
                                                )
                                            })
                                        }
                                    </select>

                                }
                            </div>
                            {isPendingUserGroups && <div className="text-center h4 py-2">Loading...</div>}
                            {errorUserGroups && <div className="text-center h4 py-2">Cannot fetch the user groups right now!</div>}
                            {userGroups && ((userGroups.length === 0 && <div className="text-center h5">Currently, there are no user groups!</div>) ||
                                <div className="floating-label-group" style={{ marginBottom: "0.25rem", marginTop: "0" }} >
                                    <label htmlFor="userGroups" >User groups associated with this alarm</label>
                                    <MultiSelect
                                        id="userGroups"
                                        data={userGroups.data.map(group => ({
                                            label: group.name,
                                            value: group.id
                                        }))}
                                        handleChange={handleSelectedUserGroupIDs}
                                        placeholder="Search for, or select user group names..."
                                        defaultSelected={selectedAlarmUserGroups}
                                    />
                                </div>)}
                            <div className="floating-label-group">
                                <input type="text" id="title" className="form-control" required name="username" style={{ fontWeight: "bold" }} value={selectedAlarm.title} onChange={e => setSelectedAlarm({...selectedAlarm, title: e.target.value})}  />
                                <label className="floating-label">Title</label>
                            </div>
                            <div className="floating-label-group" style={{ marginBottom: "0.25rem" }}>
                                <input type="number" id="value" className="form-control" required name="value" value={selectedAlarm.value} onChange={e => setSelectedAlarm({...selectedAlarm, value: e.target.value})} />
                                <label className="floating-label">Value</label>
                            </div>
                            <div className="floating-label-group" style={{ marginBottom: "0.25rem", marginTop: "0" }}>
                                <label htmlFor="direction" >Direction</label>
                                <select id="direction" className="form-select" value={selectedAlarm.direction} onChange={e => setSelectedAlarm({...selectedAlarm, direction: e.target.value})}>
                                    <option value=">" selected>Upper Limit</option>
                                    <option value="<">Lower Limit</option>
                                </select>
                            </div>
                            <div className="floating-label-group">
                                <label htmlFor="color" style={{ position: "relative", bottom: "0.4rem", marginRight: "1rem" }}>Color</label>
                                <input className="ml-3" type="color" name="color" id="color" value={selectedAlarm.color}  onChange={e => setSelectedAlarm({...selectedAlarm, color: e.target.value})}></input>
                            </div>
                        </div>
                        <section className="d-flex justify-content-center">
                            <button className="btn btn-danger" type="reset">
                                Reset
                            </button>
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            <button className="btn btn-success" type="submit">
                                Submit
                            </button>
                        </section>
                    </form>}
                </section>
            </main >
        </div >
    );
}

export default Alarms_Edit;