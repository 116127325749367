import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { TabulatorFull as Tabulator } from "tabulator-tables";
import "tabulator-tables/dist/css/tabulator.min.css";
import config from '../../config.json';
import axios from 'axios'
import swal from 'sweetalert';
import Axios from 'axios';
import { Dialog, DialogContent } from '@material-ui/core';
import Cookies from 'universal-cookie';
import mixpanel from 'mixpanel-browser';

var table;
var selectedData = [];


async function fetchData(flag) {
  const cookies = new Cookies();
  const userToken = cookies.get('userToken');
  const headers = {
    'Authorization': `Bearer ${userToken}`,
    'Content-Type': 'application/json'
  };
  const userId = cookies.get('userID');
  const urlStr = flag === true ? "/permissions/without-default/findall" : "/permissions/findall";
  await axios.post(config.BACKEND_URL + urlStr , {userId}, { headers }).then(res => {
    table.setData(res.data);
  }).catch(err => {
    console.log(err);
  });
}

function RoleTable({setRoleEditData, setSelectedRole, deleteButton, editButton, defaultOnly, actionInitiatingUser, setCanUserEdit}) {
  const cookies = new Cookies();
  const userToken = cookies.get('userToken');
  const headers = {
    'Authorization': `Bearer ${userToken}`,
    'Content-Type': 'application/json'
  };
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState("");

  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleClick = async (e, cell, data) => {
    setDialogOpen(true);
    const alarm = "" + (data.alarm[0]==='1'?"V":"") + (data.alarm[1]==='1'?"C":"") + (data.alarm[2]==='1'?"E":"") + (data.alarm[3]==='1'?"D":"");
    const dashboard = "" + (data.dashboard[0]==='1'?"V":"") + (data.dashboard[1]==='1'?"C":"") + (data.dashboard[2]==='1'?"E":"") + (data.dashboard[3]==='1'?"D":"");
    const report = "" + (data.report[0]==='1'?"V":"");
    const role = "" + (data.role[0]==='1'?"V":"") + (data.role[1]==='1'?"C":"") + (data.role[2]==='1'?"E":"") + (data.role[3]==='1'?"D":"") + (data.role[4]==='1'?"A":"");
    const source = "" + (data.source[0]==='1'?"V":"") + (data.source[1]==='1'?"C":"") + (data.source[2]==='1'?"E":"") + (data.source[3]==='1'?"D":"");
    const user = "" + (data.user[0]==='1'?"V":"") + (data.user[1]==='1'?"C":"") + (data.user[2]==='1'?"E":"") + (data.user[3]==='1'?"D":"");
    const userDashboard = "" + (data.userDashboard[0]==='1'?"V":"") + (data.userDashboard[1]==='1'?"C":"") + (data.userDashboard[2]==='1'?"E":"") + (data.userDashboard[3]==='1'?"D":"");
    const userGroup = "" + (data.userGroup[0]==='1'?"V":"") + (data.userGroup[1]==='1'?"C":"") + (data.userGroup[2]==='1'?"E":"") + (data.userGroup[3]==='1'?"D":"");
    const view = "" + (data.view[0]==='1'?"V":"") + (data.view[1]==='1'?"C":"") + (data.view[2]==='1'?"E":"") + (data.view[3]==='1'?"D":"");
    const message = `Name: ${data.name}\nAlarm: ${alarm===""?"No Role":alarm}\nDashboard: ${dashboard===""?"No Role":dashboard}\nReport: ${report===""?"No Role":report}\nRole: ${role===""?"No Role":role}\nSource: ${source===""?"No Role":source}\nUser: ${user===""?"No Role":user}\nUser-Dashboard: ${userDashboard===""?"No Role":userDashboard}\nUser-Group: ${userGroup===""?"No Role":userGroup}\nView: ${view===""?"No Role":view}`;
    setDialogContent(message);
  };
  const buttonFormatter = () => {
    return "<Button variant='outlined' color='primary'>Details</Button>";
  };
  const columns = [
      { formatter: "rownum", align: "center" },
      { title: "Rolename", field: "name", headerFilter:"input", width: "40%" },
      {
        title: "Information",
        width: "40%",
        formatter: buttonFormatter,
        cellClick: function(e, cell) {
          e.stopPropagation();
          if(cell.getColumn()._column.definition.title === 'Information'){
            cell.getRow().getElement().classList.remove("tabulator-selected");
          }
          handleClick(e, cell, cell.getRow().getData());
          table.deselectRow();
        },
      }
  ];

  useEffect(() => {
      table = new Tabulator("#role-table", {
          rowClick: function (e, row) { alert("Row " + row.getData().name + " Clicked!!!!"); },
      layout: "fitColumns",
      columns: columns,
      pagination: "local",
      paginationSize: 10,
      paginationSizeSelector: [25, 50, 75, 100],
      tooltips: true,
      responsiveLayout: true,
      selectableRows: true
    });
    
    if(defaultOnly === true){
      table.on('tableBuilt', () => fetchData(true));
    } else {
      table.on('tableBuilt',() => fetchData(false));
    }
    table.on('rowSelectionChanged', () => {
        selectedData = table.getSelectedData().map(role => role.id);
        setSelectedRole(selectedData);
    });
  }, []);

const Edit = async (e) =>{
    mixpanel.track("Edit Role Page Edit Button Clicked", { userID: cookies.get('userID') });
    if(selectedData.length === 1){
        Axios.get(config.BACKEND_URL + `/permissions/getById/${selectedData[0]}`, { headers }).then(res=>{
            if(res.status==200){
                setRoleEditData(res.data);
                setCanUserEdit(true);
            }
        }).catch(err => {
            console.log(err);
        });
    } else {
        swal("Only one role can be edit at a time", "Please try again", "error");
    }
}

const Delete = async (e) => {
  mixpanel.track("Delete Role Page Delete Button Clicked", { userID: cookies.get('userID') });
  swal("Are you sure you want to delete selected roles", "", "warning", {
      buttons: {
        cancel: "Cancel",
          catch: {
            text: "Delete",
            value: "delete",
          },
        },
      }).then(async (value) => {
      if(value==="delete"){
        try {
          await axios.post(config.BACKEND_URL + "/permissions/delete", {selectedData, actionInitiatingUser}, { headers }).then(res => {
            if(res.status===204){
              swal("Roles Deleted", "Click on OK to continue...", "success").then(() => {
                  window.location.reload();
              });
            }
          }).catch(err => {
            console.log(err);
          });
        } catch (err) {
          console.log(err);
        }
    }
  }).catch(err => {
    console.log(err);
  });
}
  return (
    <>
      <div className="RoleTable">
        <div style={{ 'padding': '25px 0' }}>
          <div id="role-table"></div>
        </div>
      </div>
      <div>
        {deleteButton && <button className="btn btn-danger mx-4" type="delete" onClick={Delete}>  Delete </button>}
        {editButton && <button className="btn btn-danger" style={{ display: 'block', float: 'right', padding: '0.5rem 1.5rem'}} type="delete" onClick={Edit}>  Edit </button>}
      </div>
      <Dialog open={dialogOpen} onClose={handleClose} aria-labelledby="information-dialog-title">
        <DialogContent> 
          <div style={{ whiteSpace: "pre-line" }}>
            {dialogContent.split("\n").map((line, i) => {
              const [key, value] = line.split(":");
              return (
                <div key={i} style={{fontSize: '1.2rem'}}>
                  <strong>{key}:</strong> {value}
                </div>
              );
            })}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default RoleTable;