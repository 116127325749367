import React, { useEffect, useRef, useState } from "react"
import '../styles/user_management_styles.css'
import Navbar from "./Navbar.js"
import 'bootstrap/dist/css/bootstrap.min.css';
import Table from "./Table.js";
import useFetch from './useFetch.js';
import swal from "sweetalert";
import axios from "axios";
import config from '../config.json';
import Cookie from 'universal-cookie';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel } from "@material-ui/core";
import mixpanel from "mixpanel-browser";

const useStyles = makeStyles((theme) => ({
    dialogContent: {
        display: 'flex', 
        flexDirection: 'column', 
        maxHeight: '200px', 
        overflow: 'auto',
    },
}));

const Views_Create = () => {
    const cookies = new Cookie();
    let userToken = "";
    let actionInitiatingUser = -1;
    try {
        userToken = cookies.get('userToken');
        actionInitiatingUser = parseInt(cookies.get('userID'));
    } catch (error) {}
    const headers = {
        'Authorization': `Bearer ${userToken}`,
        'Content-Type': 'application/json'
    };
    const [data, setData] = useState(null);
    const classes = useStyles();
    let selectedRows = useRef([]);
    let tempStartDateTime = useRef("");
    let tempEndDateTime = useRef("");
    const { data: sources, isPending, error } = useFetch({ actionInitiatingUser: { userID: actionInitiatingUser.toString() }, URL: config.BACKEND_URL + '/logger_sources/sources' });
    const [userGroup, setUserGroup] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [stateOption, setStateOption] = useState([]);

    const handleStartTime = (e) => {
        tempStartDateTime.current = e.target.value;
        if (e.target.value === '') {
            swal("Start time cannot be unspecified!", "Please specify a start date-time.", "error");
        }
        else if (!areDatesValid(tempStartDateTime.current, tempEndDateTime.current)) {
            swal("Start time must be before end time!", "Please specify valid start time.", "error");
        }
    }

    const handleEndTime = (e) => {
        tempEndDateTime.current = e.target.value;
        if (e.target.value === '') {
            swal("End time cannot be unspecified!", "Please specify an end date-time.", "error");
        }
        else if (!areDatesValid(tempStartDateTime.current, tempEndDateTime.current)) {
            swal("End time must be after start time!", "Please specify a valid end time.", "error");
        }
    }

    const isUGSelected = () => {
        for (let i = 0; i < stateOption.length; i++) {
            if(stateOption[i]) return true;
        }
        return false;
    }
    const handleViewsSubmit = async (e) => {
        e.preventDefault();
        mixpanel.track("View Create Button Clicked", { userID: cookies.get('userID') });
        if (tempStartDateTime.current === '' || tempEndDateTime.current === '') {
            swal("Start time and end time cannot be unspecified!", "Please specify valid date-times.", "error");
            return;
        }
        if (areDatesValid(tempStartDateTime.current, tempEndDateTime.current)) {
            if(userGroup.length > 0 && !isUGSelected()){
                swal("Please Select at least one User Group", "", "error");
                return;
            }
            const filteredUserGroup = userGroup.filter((user, index) => {
                return stateOption[index];
            });
            let start = tempStartDateTime.current;
            let end = tempEndDateTime.current;
            let name = "", tableStatus = false, chartStatus = false;
            for (let ele of e.target) {
                if (ele.nodeName.toLowerCase() === 'input') {
                    switch (ele.id.toLowerCase()) {
                        case 'name': 
                            name = ele.value; 
                            break;
                        case 'table': 
                            tableStatus = ele.checked;
                            break;
                        case 'chart': 
                            chartStatus = ele.checked;
                            break;
                    }
                }
            }
            let sourceIDs = selectedRows.current.map(row => row.id);
            if (sourceIDs.length === 0) {
                swal("No sources selected!", "Please select at least one source.", "error");
                return;
            }
            const body = {
                "timeStamps": {
                    "start": tempStartDateTime.current,
                    "end": tempEndDateTime.current
                },
                "sourceIDs": sourceIDs,
                "name": name,
                "table": tableStatus,
                "chart": chartStatus,
                "filteredUserGroup": filteredUserGroup,
                "actionInitiatingUser": actionInitiatingUser
            };
            await axios.post(config.BACKEND_URL + `/views`, body, { headers }).then(res => {
                setData(res.data);
                swal("Stored the view!", "Click on OK to continue.", "success");
                setTimeout(() => {
                    window.location.reload();
                }, 500);
            }).catch(err => {
                console.log(err);
                if (err.name === 'ViewAlreadyExists') {
                    swal("Could not store the view!", "A view with the given name already exists. Please enter a new name.", "error");
                }
                else {
                    swal("Could not store the view!", "Some error has occurred.");
                }
            });
        }
        else {
            swal("Please enter valid dates!", "Ending date has to be greater than or equal to the start date", "error");
        }
    }

    const areDatesValid = (firstDate = '', secondDate = '') => {
        return (firstDate.length === 0) || (secondDate.length === 0) || (firstDate <= secondDate);
    }

    const setSelectedRows = (rows) => {
        selectedRows.current = rows;
    }

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleClear = () => {
        setStateOption(Array(userGroup.length).fill(false));
    }

    const handleAll = () => {
        setStateOption(Array(userGroup.length).fill(true));
    }

    const handleChangeDialog = (e, index) => {
        let newArr = [...stateOption]; 
        newArr[index] = e.target.checked;
        setStateOption(newArr);
    };

    useEffect(() => {
        const fetchUG = async () => {
            await axios.post(config.BACKEND_URL + '/views/user-group', {userId : actionInitiatingUser }, { headers }).then(res => {
                const tugs = res.data.map(ug => { return {id: ug.id, name: ug.name}; });
                setUserGroup(tugs);
            }).catch(err => {
                console.log(err);
            });
        };
        fetchUG();
        setStateOption(Array(userGroup.length).fill(true));
    }, []);
    return (
        <div className="Views">
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <Navbar />
            <main className="container-fluid">
                <section className="d-flex row justify-content-center" style={{ padding: "1vw" }}>
                    <header className="h1 text-center mb-3">Create View</header>
                    <section className="selectDateRange row d-flex justify-content-center mb-3" style={{ width: "100%" }}>
                        <form id="createForm" className="row col-sm-9 d-flex justify-content-center" onSubmit={handleViewsSubmit}>
                            <section className="row d-flex justify-content-center">
                                <section className="d-flex col-sm-4 justify-content-center flex-row">
                                    <label className="text-center col-sm-3" htmlFor="startDate">From</label>
                                    <input className="col-sm-10" type="datetime-local" name="startDate" id="startDate" onChange={handleStartTime} />
                                </section>
                                <section className="d-flex col-sm-4 justify-content-center flex-row">
                                    <label className="text-center col-sm-2" htmlFor="endDate">To</label>
                                    <input className="col-sm-10" type="datetime-local" name="endDate" id="endDate" onChange={handleEndTime} />
                                </section>
                            </section>
                            <section className="row d-flex justify-content-center mt-2">
                                <section className="col-sm-6 floating-label-group row d-flex justify-content-center">
                                    <input type="text" id="name" className="form-control" autoComplete="off" required style={{ fontWeight: "bold" }} />
                                    <label className="floating-label">Enter name of the view</label>
                                </section>
                            </section>
                            <section className="row d-flex justify-content-center form-check flex-column">
                                <header className="col-sm-12 d-flex justify-content-center h5 mb-0">What do you wish to include in the view?</header>
                                <section className="col-sm-12 floating-label-group d-flex justify-content-center form-check">
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" name="storeDetails[]" type="checkbox" value="table" id="table" />
                                        <label className="form-check-label" htmlFor="table">
                                            Table
                                        </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" name="storeDetails[]" type="checkbox" value="chart" id="chart" />
                                        <label className="form-check-label" htmlFor="chart">
                                            Chart
                                        </label>
                                    </div>
                                </section>
                            </section>
                            <div className="row">
                                <header className="col-sm-9 d-flex justify-content-center h5 mb-3 mt-3">Please Select User Group to which you want to assign this view*</header>
                                <Button className="col-sm-3" variant="contained" color="primary" onClick={handleOpenDialog} style={{width: 200}}> Open User Group </Button>
                            </div>
                            <section className="d-flex row justify-content-center p-3" style={{ width: "auto", float: "center", height: "auto" }}>
                                {isPending && <div className="text-center h4 mb-3">Loading...</div>}
                                {error && <div className="text-center h4 mb-3">Cannot fetch data right now!</div>}
                                {sources && sources.columns.length > 0 &&
                                    <div className="100rem">
                                        <Table
                                            tableColumns={sources.columns}
                                            tableData={sources.data}
                                            setParentRows={setSelectedRows}
                                            shouldModifySources={false}
                                            tableID="sourcesTable"
                                            shouldDownload={false}
                                        />
                                    </div>
                                }
                            </section>
                            <section className="d-flex justify-content-center">
                                <button className="btn btn-success" type="submit">Submit</button>
                                &nbsp;
                                &nbsp;
                                <button className="btn btn-danger" type="reset">Reset</button>
                            </section>
                        </form>
                        <div>
                            <Dialog open={openDialog} onClose={handleCloseDialog} ariaLabel="form-dialog-title" fullWidth maxWidth="sm">
                                <DialogTitle id="form-dialog-title">Below are the list of User Group in which you are in</DialogTitle>
                                <DialogContent className={classes.dialogContent}>
                                    {userGroup && userGroup.map((ug, index) => <FormControlLabel key={index} control={<Checkbox key={index} checked={stateOption[index]} onChange={(e) => handleChangeDialog(e, index)} name={ug.name} color="primary" />} label={ug.name} /> )}
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleCloseDialog} color="primary"> Cancel </Button>
                                    <Button onClick={handleClear} color="primary"> Clear </Button>
                                    <Button onClick={handleAll} color="primary"> All </Button>
                                    <Button onClick={handleCloseDialog} color="primary"> Ok </Button>
                                </DialogActions>
                            </Dialog>
                        </div>
                    </section>
                </section>
            </main >
        </div >
    );
}

export default Views_Create;