import React, { useState } from 'react';
import axios from 'axios';
import config from '../config.json';
import { Grid, Paper, TextField, Button } from '@material-ui/core';
import swal from 'sweetalert';
import Cookies from 'universal-cookie';
import mixpanel from 'mixpanel-browser';
import Navbar from './Navbar';

const Inviteform = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const cookies = new Cookies();

  let userToken = '';
  let userID = '';
  try {
    userToken = cookies.get('userToken');
    userID = cookies.get('userID');
  } catch (error) {}

  const headers = {
    'Authorization': `Bearer ${userToken}`,
    'Content-Type': 'application/json',
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    mixpanel.track("Send Invite Button Clicked", { userID: cookies.get('userID') });
    // Send email to server
    axios.post(config.BACKEND_URL + '/invite-user', { email }, { headers }).then(res => {
      // Handle success response
      if(res.status === 203){
        return swal("Duplicate Email", res.data.message, "error");
      }
      swal("Invitation Sent successfully", "Please ask user to register before 24hr", "success");
    }).catch(err => {
      // Handle error response
      console.log(err);
      setError('Error sending invite. Please try again.');
    });
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  return (
    <div class="Invites">
        <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
        <script src='bootstrap/dist/js/bootstrap.min.js'></script>
        <Navbar />
        <br /><br />
        <main className="container-fluid">
          <Grid container justify="center">
            <Grid item xs={12} sm={8} md={6} lg={4}>
              <Paper style={{ padding: '16px' }}>
                <form onSubmit={handleSubmit}>
                  <TextField label="Email" variant="outlined" margin="normal" fullWidth required type="email" value={email} onChange={handleEmailChange} error={error.length !== 0} helperText={error} />
                  <Button type="submit" variant="contained" color="primary" fullWidth style={{ marginTop: '16px' }}> Send Invite </Button>
                </form>
              </Paper>
            </Grid>
          </Grid>
        </main>
    </div>
    
  );
};

export default Inviteform;