import Axios from "axios";
import swal from "sweetalert";
import Cookies from "universal-cookie";
import config from '../config.json';
import mixpanel from 'mixpanel-browser';

// This function will check whether all the
export const isAllTrue = (obj, objname, check) => {
    if (Object.keys(obj).length === 2) {
        return check;
    }
    let cnt = 0;
    for (const [key, value] of Object.entries(obj)) {
        if (key.toString().includes("all") || key.toString() === objname.toString()) {
            continue;
        }
        if (value) cnt += 1
    }
    if (check && !objname.includes("all")) cnt += 1;
    return cnt === 4;
}

export const submitEdit = (e, user, userGroup, userDash, alarm, view, source, report, role, dashboard, roleName, roleEditData, actionInitiatingUser) => {
    e.preventDefault();
    const cookies = new Cookies();
    mixpanel.track("Edit Role Page Submit Button Clicked", { userID: cookies.get('userID') });
    const userToken = cookies.get('userToken');
    const headers = {
        'Authorization': `Bearer ${userToken}`,
        'Content-Type': 'application/json'
    };
    delete user.allu;
    delete userGroup.allug;
    delete userDash.allud;
    delete alarm.alla;
    delete view.allv;
    delete source.alls;
    delete report.allr;
    delete role.allrl;
    delete dashboard.alld;
    role.viewrl = role.createrl || role.assignrl || role.deleterl || role.editrl;
    const userId = cookies.get('userID');
    const data = { id: roleEditData.id, user, userGroup, userDash, alarm, view, source, role, dashboard, report, roleName, createdBy: userId, actionInitiatingUser }
    swal("Are you sure you want to update this role?", "", "warning", {
        buttons: {
            cancel: "Cancel",
            catch: {
                text: "Update",
                value: "update",
            },
        },
    }).then(value => {
        if (value === "update") {
            Axios.patch(config.BACKEND_URL + "/permissions/edit", data, { headers }).then(res => {
                if (res.status === 201) {
                    swal("Role Updated!", "", "success").then(() => window.location.reload());
                }
            }).catch(err => {
                swal("Not Updated", "Some Error occured", "error");
                console.log(err);
            });
        }
    });
}

export const handleChangeud = (event, viewud, createud, editud, deleteud, setUserDash, userDash, setUserGroup, userGroup, setUser, user, role, alarm, setOpenSnack, setAlertMsg, setOpenSnackUG, setAlertMsgUG) => {
    if (event.target.name === 'createud' || event.target.name === 'editud') {
        setUserDash({
            ...userDash,
            [event.target.name]: event.target.checked,
            viewud: (event.target.name === 'createud' ? editud : createud) || deleteud || event.target.checked,
            allud: isAllTrue(userDash, event.target.name, event.target.checked)
        });
        
        setUserGroup({
            ...userGroup,
            viewug: event.target.checked || userGroup.deleteug || userGroup.editug || userGroup.createug,
            allug: isAllTrue(userGroup, "allug", true)
        });
        const viewFCE = event.target.checked || user.deleteu || user.editu || user.createu || role.assignrl || userGroup.createug || userGroup.editug || (event.target.name === 'editud' ? userDash.createud : userDash.editud) || alarm.createa || alarm.edita;
        setUser({
            ...user,
            viewu: viewFCE,
            allu: isAllTrue(user, "allu", true)
        });
        return;
    }
    if (event.target.name === 'viewud' && viewud && !event.target.checked && (editud || deleteud || createud)) {
        const alertStr = "View User Dashboard is checked due to" + (editud ? ", edit user dashboard" : "") + (deleteud ? ", delete user dashboard" : "") + (createud ? ", create user dashboard" : "");
        setAlertMsg(alertStr);
        setOpenSnack(alertStr!=="View User Dashboard is checked due to");
        return;
    }
    if (event.target.name === 'allud') {
        setUserDash({
            allud: event.target.checked,
            viewud: event.target.checked,
            createud: event.target.checked,
            editud: event.target.checked,
            deleteud: event.target.checked
        });
        setUserGroup({
            ...userGroup,
            viewug: event.target.checked || userGroup.deleteug || userGroup.editug || userGroup.createug,
            allug: isAllTrue(userGroup, "allug", true)
        });
        const viewFCE = event.target.checked || user.deleteu || user.editu || user.createu || role.assignrl || userGroup.createud || userGroup.editud || alarm.createa || alarm.edita;
        setUser({
            ...user,
            viewu: viewFCE,
            allu: isAllTrue(user, "allu", event.target.checked)
        });
        return;
    }
    if (event.target.name === 'viewud') {
        setUserDash({
            ...userDash,
            viewud: createud || deleteud || editud || event.target.checked,
            allud: isAllTrue(userDash, event.target.name, event.target.checked)
        });
    }
    if (event.target.name === 'editud') {
        setUserDash({
            ...userDash,
            [event.target.name]: event.target.checked,
            viewud: createud || deleteud || event.target.checked,
            allud: isAllTrue(userDash, event.target.name, event.target.checked)
        });
    }
    if (event.target.name === 'deleteud') {
        setUserDash({
            ...userDash,
            [event.target.name]: event.target.checked,
            viewud: editud || createud || event.target.checked,
            allud: isAllTrue(userDash, event.target.name, event.target.checked)
        });
    }
    if (event.target.name === 'createud') {
        setUserDash({
            ...userDash,
            [event.target.name]: event.target.checked,
            viewud: editud || deleteud || event.target.checked,
            allud: isAllTrue(userDash, event.target.name, event.target.checked)
        });
    }
};

export const handleChangeu = (event, viewu, createu, editu, deleteu, setUser, user, role, userGroup, userDash, alarm, setOpenSnack, setAlertMsg) => {
    const viewFCE = role.assignrl || userGroup.createug || userGroup.editug || userDash.createud || userDash.editud || alarm.createa || alarm.edita;
    if (event.target.name === 'viewu' && viewu && !event.target.checked && (editu || deleteu || createu)) {
        const alertStr = "View User is checked due to" + (user.deleteu ? ", user delete" : "") + (user.createu ? ", user create" : "") + (user.editu ? ", user edit" : "") + (user.deleteu ? ", user delete" : "") + (role.assignrl ? ", assign role" : "") + (userGroup.createug ? ", create usergroup" : "") + (userGroup.editug ? ", edit usergroup" : "") + (userDash.editud ? ", edit user dashboard" : "") + (userDash.createud ? ", create user dashboard" : "") + (alarm.createa ? ", create alarm" : "") + (alarm.edita ? ", edit alarm" : "");
        setAlertMsg(alertStr);
        setOpenSnack(alertStr!=="View User is checked due to");
        return;
    }
    if (event.target.name === 'allu') {
        setUser({
            allu: event.target.checked,
            viewu: event.target.checked || viewFCE,
            createu: event.target.checked,
            editu: event.target.checked,
            deleteu: event.target.checked
        });
    }
    if (event.target.name === 'viewu') {
        const alertStr = "View User is checked due to" + (user.deleteu ? ", user delete" : "") + (user.createa ? ", user create" : "") + (user.editu ? ", user edit" : "") + (role.assignrl ? ", assign role" : "") + (userGroup.createug ? ", create usergroup" : "") + (userGroup.editug ? ", edit usergroup" : "") + (userDash.editud ? ", edit user dashboard" : "") + (userDash.createud ? ", create user dashboard" : "") + (alarm.createa ? ", create alarm" : "") + (alarm.edita ? ", edit alarm" : "");
        setAlertMsg(alertStr);
        setOpenSnack(alertStr!=="View User is checked due to");
        setUser({
            ...user,
            viewu: createu || editu || deleteu || event.target.checked || viewFCE,
            allu: isAllTrue(user, event.target.name, event.target.checked)
        });
    }
    if (event.target.name === 'editu') {
        setUser({
            ...user,
            [event.target.name]: event.target.checked,
            viewu: createu || deleteu || event.target.checked || viewFCE,
            allu: isAllTrue(user, event.target.name, event.target.checked)
        });
    }
    if (event.target.name === 'deleteu') {
        setUser({
            ...user,
            [event.target.name]: event.target.checked,
            viewu: editu || createu || event.target.checked || viewFCE,
            allu: isAllTrue(user, event.target.name, event.target.checked)
        });
    }
    if (event.target.name === 'createu') {
        setUser({
            ...user,
            [event.target.name]: event.target.checked,
            viewu: editu || deleteu || event.target.checked || viewFCE,
            allu: isAllTrue(user, event.target.name, event.target.checked)
        });
    }
};

export const handleChangeug = (event, viewug, createug, editug, deleteug, setUserGroup, userGroup, setUser, user, role, alarm, userDash, setOpenSnack, setAlertMsg) => {
    if (event.target.name === 'allug') {
        setUserGroup({
            allug: event.target.checked,
            viewug: event.target.checked || userDash.createud || userDash.editud || alarm.createa || alarm.edita,
            createug: event.target.checked,
            editug: event.target.checked,
            deleteug: event.target.checked
        });
        const viewFCE = event.target.checked || user.deleteu || user.editu || user.createu || role.assignrl || userDash.createud || userDash.editud || alarm.createa || alarm.edita;
        setUser({
            ...user,
            viewu: viewFCE,
            allu: isAllTrue(user, "allu", true)
        });
        return;
    }
    if (event.target.name === 'createug' || event.target.name === 'editug') {
        setUserGroup({
            ...userGroup,
            [event.target.name]: event.target.checked,
            viewug: (event.target.name === 'createug' ? editug : createug) || deleteug || event.target.checked || userDash.createud || userDash.editud || alarm.createa || alarm.edita,
            allug: isAllTrue(userGroup, event.target.name, event.target.checked)
        });
        
        const viewFCE = event.target.checked || user.deleteu || user.editu || user.createu || role.assignrl || (event.target.name === 'editug' ? userGroup.createug : userGroup.editug) || userDash.createud || userDash.editud || alarm.createa || alarm.edita;
        setUser({
            ...user,
            viewu: viewFCE,
            allu: isAllTrue(user, "allu", true)
        });
        return;
    }
    if (event.target.name === 'viewug' && viewug && !event.target.checked && (editug || deleteug || createug || userDash.createud || userDash.editud || alarm.createa || alarm.edita)) {
        const alertStr = "View User Group is checked due to" + (userDash.createud ? " create user dashboard" : "") + (userDash.editud ? ", edit user dashboard" : "") + (alarm.createa ? ", create alarm" : "") + (alarm.edita ? ", edit alarm" : "") + (createug ? ", create user group": "") + (editug ? ", edit user group": "") + (deleteug ? ", delete user group": "");
        setAlertMsg(alertStr);
        setOpenSnack(alertStr!=="View User Group is checked due to");
        return;
    }
    if (event.target.name === 'viewug') {
        setUserGroup({
            ...userGroup,
            viewug: createug || deleteug || editug || event.target.checked,
            allug: isAllTrue(userGroup, event.target.name, event.target.checked)
        });
    }
    if (event.target.name === 'deleteug') {
        setUserGroup({
            ...userGroup,
            deleteug: event.target.checked,
            allug: isAllTrue(userGroup, event.target.name, event.target.checked)
        });
    }
};

export const handleChangea = (event, viewa, createa, edita, deletea, setAlarm, alarm, setSource, source, setUserGroup, userGroup, setUser, user, role, userDash, view, setOpenSnack, setAlertMsg) => {
    if (event.target.name === 'createa' || event.target.name === 'edita') {
        setAlarm({
            ...alarm,
            [event.target.name]: event.target.checked,
            viewa: (event.target.name === 'createa' ? edita : createa) || deletea || event.target.checked,
            alla: isAllTrue(alarm, event.target.name, event.target.checked)
        });
        const viewFCE = event.target.checked || user.deleteu || user.editu || user.createu || role.assignrl || userGroup.createug || userGroup.editug || userDash.createud || userDash.editud || (event.target.name === 'edita' ? alarm.createa : alarm.edita);
        setSource({
            ...source,
            views: event.target.checked || source.deletes || source.edits || source.creates || view.createv || view.editv || (event.target.name === 'edita' ? alarm.createa : alarm.edita),
            alls: isAllTrue(source, "alls", true)
        });
        setUserGroup({
            ...userGroup,
            viewug: event.target.checked || userGroup.deleteug || userGroup.editug || userGroup.createug,
            allug: isAllTrue(userGroup, "allug", true)
        });
        setUser({
            ...user,
            viewu: viewFCE,
            allu: isAllTrue(user, "allu", true)
        });
        return;
    }
    if (event.target.name === 'viewa' && viewa && !event.target.checked && (edita || deletea || createa)) {
        const alertStr = "View Alarm is checked due to" + (edita ? ", edit alarm" : "") + (deletea ? ", delete alarm" : "") + (createa ? ", create alarm" : "");
        setAlertMsg(alertStr);
        setOpenSnack(alertStr!=="View Source is checked due to");
        return;
    }
    if (event.target.name === 'alla') {
        setAlarm({
            alla: event.target.checked,
            viewa: event.target.checked,
            createa: event.target.checked,
            edita: event.target.checked,
            deletea: event.target.checked
        });
        const viewFCE = event.target.checked || user.deleteu || user.editu || user.createu || role.assignrl || userGroup.createug || userGroup.editug || userDash.createud || userDash.editud;
        setSource({
            ...source,
            views: event.target.checked || source.deletes || source.edits || source.creates,
            alls: isAllTrue(source, "alls", true)
        });
        setUserGroup({
            ...userGroup,
            viewug: event.target.checked || userGroup.deleteug || userGroup.editug || userGroup.createug,
            allug: isAllTrue(userGroup, "allug", true)
        });
        setUser({
            ...user,
            viewu: viewFCE,
            allu: isAllTrue(user, "allu", true)
        });
    }
    if (event.target.name === 'viewa') {
        setAlarm({
            ...alarm,
            viewa: createa || deletea || edita || event.target.checked,
            alla: isAllTrue(alarm, event.target.name, event.target.checked)
        });
    }
    if (event.target.name === 'edita') {
        setAlarm({
            ...alarm,
            [event.target.name]: event.target.checked,
            viewa: createa || deletea || event.target.checked,
            alla: isAllTrue(alarm, event.target.name, event.target.checked)
        });
    }
    if (event.target.name === 'deletea') {
        setAlarm({
            ...alarm,
            [event.target.name]: event.target.checked,
            viewa: edita || createa || event.target.checked,
            alla: isAllTrue(alarm, event.target.name, event.target.checked)
        });
    }
    if (event.target.name === 'createa') {
        setAlarm({
            ...alarm,
            [event.target.name]: event.target.checked,
            viewa: edita || deletea || event.target.checked,
            alla: isAllTrue(alarm, event.target.name, event.target.checked)
        });
    }
};

export const handleChangev = (event, viewv, createv, editv, deletev, setView, view, setSource, source, alarm, setOpenSnack, setAlertMsg) => {
    if (event.target.name === 'createv' || event.target.name === 'editv') {
        setView({
            ...view,
            [event.target.name]: event.target.checked,
            viewv: (event.target.name === 'createv' ? editv : createv) || deletev || event.target.checked,
            allv: isAllTrue(view, event.target.name, event.target.checked)
        });
        setSource({
            ...source,
            views: event.target.checked || source.deletes || source.edits || source.creates || (event.target.name === 'editv' ? view.createv : view.editv) || alarm.createa || alarm.edita,
            alls: isAllTrue(source, "alls", true)
        });
        return;
    }
    if (event.target.name === 'viewv' && viewv && !event.target.checked && (editv || deletev || createv)) {
        const alertStr = "View User Dashboard is checked due to" + (editv ? ", edit view" : "") + (deletev ? ", delete view" : "") + (createv ? ", create view" : "");
        setAlertMsg(alertStr);
        setOpenSnack(alertStr!=="View User Dashboard is checked due to");
        return;
    }
    if (event.target.name === 'allv') {
        setView({
            allv: event.target.checked,
            viewv: event.target.checked,
            createv: event.target.checked,
            editv: event.target.checked,
            deletev: event.target.checked
        });
        setSource({
            ...source,
            views: event.target.checked || source.deletes || source.edits || source.creates || view.createv || view.editv || alarm.createa || alarm.edita,
            alls: isAllTrue(source, "alls", true)
        });
    }
    if (event.target.name === 'viewv') {
        setView({
            ...view,
            viewv: createv || deletev || editv || event.target.checked,
            allv: isAllTrue(view, event.target.name, event.target.checked)
        });
    }
    if (event.target.name === 'editv') {
        setView({
            ...view,
            [event.target.name]: event.target.checked,
            viewv: createv || deletev || event.target.checked,
            allv: isAllTrue(view, event.target.name, event.target.checked)
        });
    }
    if (event.target.name === 'deletev') {
        setView({
            ...view,
            [event.target.name]: event.target.checked,
            viewv: editv || createv || event.target.checked,
            allv: isAllTrue(view, event.target.name, event.target.checked)
        });
    }
    if (event.target.name === 'createv') {
        setView({
            ...view,
            [event.target.name]: event.target.checked,
            viewv: editv || deletev || event.target.checked,
            allv: isAllTrue(view, event.target.name, event.target.checked)
        });
    }
};

export const handleChanges = (event, views, creates, edits, deletes, setSource, source, view, alarm, setOpenSnack, setAlertMsg) => {
    if (event.target.name === 'views' && views && !event.target.checked && (edits || deletes || creates)) {
        const alertStr = "View Source is checked due to" + (edits ? ", edit source" : "") + (deletes ? ", delete source" : "") + (creates ? ", create source" : "") + (view.createv ? ", create view" : "") + (view.editv ? ", edit view" : "") + (alarm.createa ? ", create alarm" : "") + (alarm.edita ? ", edit alarm" : "");
        setAlertMsg(alertStr);
        setOpenSnack(alertStr!=="View Source is checked due to");
        return;
    }
    const viewFCE = view.createv || view.editv || alarm.createa || alarm.edita;
    if (event.target.name === 'alls') {
        setSource({
            alls: event.target.checked,
            views: event.target.checked || viewFCE,
            creates: event.target.checked,
            edits: event.target.checked,
            deletes: event.target.checked
        });
    }
    if (event.target.name === 'views') {
        const alertStr = "View Source is checked due to" + (edits ? ", edit source" : "") + (deletes ? ", delete source" : "") + (creates ? ", create source" : "") + (view.createv ? ", create view" : "") + (view.editv ? ", edit view" : "") + (alarm.edita ? ", create alarm" : "") + (alarm.createa ? ", edit alarm" : "");
        setAlertMsg(alertStr);
        setOpenSnack(alertStr!=="View Source is checked due to");
        setSource({
            ...source,
            views: creates || deletes || edits || event.target.checked || viewFCE,
            alls: isAllTrue(source, event.target.name, event.target.checked)
        });
    }
    if (event.target.name === 'edits') {
        setSource({
            ...source,
            [event.target.name]: event.target.checked,
            views: creates || deletes || event.target.checked || viewFCE,
            alls: isAllTrue(source, event.target.name, event.target.checked)
        });
    }
    if (event.target.name === 'deletes') {
        setSource({
            ...source,
            [event.target.name]: event.target.checked,
            views: edits || creates || event.target.checked || viewFCE,
            alls: isAllTrue(source, event.target.name, event.target.checked)
        });
    }
    if (event.target.name === 'creates') {
        setSource({
            ...source,
            [event.target.name]: event.target.checked,
            views: edits || deletes || event.target.checked || viewFCE,
            alls: isAllTrue(source, event.target.name, event.target.checked)
        });
    }
};

export const handleChangerl = (event, setRole, role, setUser, user, setOpenSnack, setAlertMsg) => {
    if (event.target.name === 'assignrl') {
        setRole({
            ...role,
            [event.target.name]: event.target.checked,
            allrl: isAllTrue(role, event.target.name, event.target.checked)
        });
        setUser({
            ...user,
            viewu: event.target.checked || user.deleteu || user.editu || user.createu,
            allu: isAllTrue(user, "viewu", event.target.checked)
        });
        return;
    }
    if (event.target.name === 'allrl') {
        setRole({
            allrl: event.target.checked,
            createrl: event.target.checked,
            editrl: event.target.checked,
            deleterl: event.target.checked,
            assignrl: event.target.checked,
        });
    } else {
        setRole({
            ...role,
            [event.target.name]: event.target.checked,
            allrl: isAllTrue(role, event.target.name, event.target.checked)
        });
    }
};

export const handleChangeda = (event,  viewd, created, deleted, dashboard, setDashboard ,setOpenSnack, setAlertMsg) => {
    if(event.target.name === 'alld'){
        setDashboard({...dashboard, alld: event.target.checked, viewd: event.target.checked, created: event.target.checked, deleted: event.target.checked});
    } else if(event.target.name === 'created'){
        setDashboard({...dashboard, viewd: viewd || event.target.checked, created: event.target.checked});
    } else if(event.target.name === 'deleted'){
        setDashboard({...dashboard, viewd: viewd || event.target.checked, deleted: event.target.checked});
    } else if(event.target.name === 'viewd') {
        setDashboard({...dashboard, viewd: event.target.checked || created || deleted});
        if(event.target.checked === false && (created || deleted)){
            setOpenSnack(true);
            setAlertMsg("View dashboard is checked due to Create or Delete dashboard");
        }
    }
}

export const handleChanger = (event, setReport, report, setOpenSnack, setAlertMsg) => {
    if (event.target.name === 'allr') {
        setReport({
            allr: event.target.checked,
            viewr: event.target.checked
        });
    } else {
        setReport({
            ...report,
            [event.target.name]: event.target.checked,
            allr: isAllTrue(report, event.target.name, event.target.checked)
        });
    }
};

export const submitCreate = (e, user, userGroup, userDash, alarm, view, source, report, role, dashboard, roleName, actionInitiatingUser) => {
    e.preventDefault();
    const cookies = new Cookies();
    mixpanel.track("Create Role Page Submit Button Clicked", { userID: cookies.get('userID') });
    const userToken = cookies.get('userToken');
    const headers = {
        'Authorization': `Bearer ${userToken}`,
        'Content-Type': 'application/json'
    };
    delete user.allu;
    delete userGroup.allug;
    delete userDash.allud;
    delete alarm.alla;
    delete view.allv;
    delete source.alls;
    delete report.allr;
    delete role.allrl;
    delete dashboard.alld;
    role.viewrl = role.createrl || role.assignrl || role.deleterl || role.editrl;
    const userId = cookies.get('userID');
    const data = { user, userGroup, userDash, alarm, view, source, role, dashboard, report, roleName, createdBy: userId, actionInitiatingUser }
    Axios.post(config.BACKEND_URL + "/permissions/create", data, { headers }).then(res => {
        swal("Role Added!", "Role Created Successfully...", "success").then(() => window.location.reload());
    }).catch(err => {
        swal("Not Added", "Role with this name already exists or Some Error occured", "error");
        console.log(err);
    })
}