import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from 'react';
import Chart from 'chart.js/auto';
import { registerables } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import Cookie from 'universal-cookie';

import "../styles/chart_styles.css";
Chart.register(...registerables, zoomPlugin);
const TestChart = ({
    chartID = "newChart",
    shouldDisplay = true,
    datasets
}) => {
    const cookies = new Cookie();
    let currentChart = undefined;
    const resetZoom = (e) => {
        e.preventDefault();
        currentChart.resetZoom();
    }
    useEffect(() => {
        if (!shouldDisplay && currentChart) {
            currentChart.destroy();
            return;
        }
        const ctx = document.getElementById(chartID).getContext('2d');
        const newChart = new Chart(ctx, {
            type: 'line',
            data: {
                datasets: datasets,
                // labels: labels
            },
            options: {
                plugins: {
                    zoom: {
                        pan: {
                            enabled: true,
                            mode: 'x',
                        },
                        zoom: {
                            drag: {
                                enabled: true
                            },
                            wheel: {
                                enabled: true,
                            },
                            pinch: {
                                enabled: true
                            },
                            mode: 'xy',
                        }
                    }
                }
            }
        });
        currentChart = newChart;
    }, [shouldDisplay, datasets])
    return (
        <>
            <script src="https://cdnjs.cloudflare.com/ajax/libs/Chart.js/3.5.1/chart.min.js"></script>
            <script src="https://cdnjs.cloudflare.com/ajax/libs/hammer.js/2.0.8/hammer.min.js"></script>
            <script src="https://cdnjs.cloudflare.com/ajax/libs/chartjs-plugin-zoom/1.0.0/chartjs-plugin-zoom.min.js"></script>
            <script src="https://cdnjs.cloudflare.com/ajax/libs/moment.js/2.29.1/moment.min.js"></script>
            <script src="https://cdnjs.cloudflare.com/ajax/libs/chartjs-adapter-moment/1.0.0/chartjs-adapter-moment.min.js"></script>
            {shouldDisplay &&
                <div className="text-center">
                    <button className="btn btn-info" onClick={resetZoom}>Reset Zoom</button>
                    <div className="mt-3 mb-3 chart-container">
                        <canvas id={chartID}></canvas>
                    </div>
                </div>
            }
        </>
    );
}

export default TestChart;