import React, { useRef, useState } from "react"
import '../styles/user_management_styles.css'
import Navbar from "./Navbar.js";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import Table from "./Table.js";
import useFetch from './useFetch.js';
import $ from 'jquery';
import swal from "sweetalert";
import axios from "axios";
import config from '../config.json';
import Cookie from 'universal-cookie';
import mixpanel from "mixpanel-browser";
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    dialogContent: {
        display: 'flex', 
        flexDirection: 'column', 
        maxHeight: '200px', 
        overflow: 'auto',
    },
}));

const Views_Edit = () => {
    const cookies = new Cookie();
    let userToken = "";
    let actionInitiatingUser = -1;
    try {
        userToken = cookies.get('userToken');
        actionInitiatingUser = parseInt(cookies.get('userID'));
    } catch (error) {}
    const headers = {
        'Authorization': `Bearer ${userToken}`,
        'Content-Type': 'application/json'
    };
    const classes = useStyles();
    const { data: views, isPending: isPendingViews, error: errorViews } = useFetch({ actionInitiatingUser: { userID: actionInitiatingUser.toString() }, URL: config.BACKEND_URL + '/views' });
    const { data: sources, isPending: isPendingSources, error: errorSources } = useFetch({ actionInitiatingUser: { userID: actionInitiatingUser.toString() }, URL: config.BACKEND_URL + '/logger_sources/sources' });
    const [highlightedRowIDs, setHighlightedRowIDs] = useState([]);
    
    let currentViewName = "";
    let currentView = {};
    let tempStartDateTime = useRef("");
    let tempEndDateTime = useRef("");
    let sourceIDs = [];
    let selectedRows = useRef([]);
    const areDatesValid = (firstDate = '', secondDate = '') => {
        return (firstDate.length === 0) || (secondDate.length === 0) || (firstDate <= secondDate);
    }
    const [userGroup, setUserGroup] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [stateOption, setStateOption] = useState([]);

    const handleStartTime = (e) => {
        tempStartDateTime.current = e.target.value;
        if (e.target.value === '') {
            swal("Start time cannot be unspecified!", "Please specify a start date-time.", "error");
        }
        else if (!areDatesValid(tempStartDateTime.current, tempEndDateTime.current)) {
            swal("Start time must be before end time!", "Please specify valid start time.", "error");
        }
    }

    const handleEndTime = (e) => {
        tempEndDateTime.current = e.target.value;
        if (e.target.value === '') {
            swal("End time cannot be unspecified!", "Please specify an end date-time.", "error");
        }
        else if (!areDatesValid(tempStartDateTime.current, tempEndDateTime.current)) {
            swal("End time must be after start time!", "Please specify a valid end time.", "error");
        }
    }

    const handleViewsEdit = (e) => {
        e.preventDefault();
        mixpanel.track("View Edit Button Clicked", { userID: cookies.get('userID') });
        let name = $("#name")[0].value,
        start = $("#startDate")[0].value,
        end = $("#endDate")[0].value,
        table = $("#tableInput")[0].checked,
        chart = $("#chartInput")[0].checked;
        currentViewName = $('#views')[0].value;
        findView();
        tempStartDateTime.current = start;
        tempEndDateTime.current = end;

        // Check the validity of dates.
        if (tempStartDateTime.current === '' || tempEndDateTime.current === '') {
            swal("Start time and end time cannot be unspecified!", "Please specify valid date-times.", "error");
            return;
        }

        if (areDatesValid(tempStartDateTime.current, tempEndDateTime.current)) {
            if (!currentView.id) {
                swal("View not selected!", "Please select a view before editing it!", "error");
            } else {
                const filteredUserGroup = userGroup.filter((user, index) => {
                    return stateOption[index];
                });
                sourceIDs = selectedRows.current.map(row => row.id);
                let body = {};
                if(sourceIDs.length !== 0){
                    body = { name, table, chart, "timeStamps": { start, end }, "sourceIDs": sourceIDs, actionInitiatingUser, "filteredUserGroup": filteredUserGroup };
                } else {
                    body = { name, table, chart, "timeStamps": { start, end }, actionInitiatingUser, "filteredUserGroup": filteredUserGroup };
                }
                axios.patch(config.BACKEND_URL + `/views/${currentView.id}`, body, { headers })
                    .then(res => {
                        swal("Success!", "View edited successfully!", "success")
                            .then(() => {
                                window.location.reload();
                            });
                    })
                    .catch(err => {
                        console.log("Error: ", err);
                    })
            }
        } else {
            swal("Please enter valid dates!", "Ending date has to be greater than or equal to the start date", "error");
        }
    }

    const fillDetails = () => {
        $("#name")[0].value = currentView ? currentView.name : "";
        $("#startDate")[0].value = currentView ? new Date(currentView.timeStamps.start) : "";
        $("#endDate")[0].value = currentView ? new Date(currentView.timeStamps.end) : "";
        $("#tableInput")[0].checked = currentView ? currentView.table : false;
        $("#chartInput")[0].checked = currentView ? currentView.chart : false;
        tempStartDateTime.current = currentView ? currentView.timeStamps.start : "";
        tempEndDateTime.current = currentView ? currentView.timeStamps.end : "";
        setHighlightedRows();
    }

    const findView = () => {
        currentView = currentViewName !== "" ? views.filter(v => v.name === currentViewName)[0] : null;
    }

    const setHighlightedRows = () => {
        let temp = currentView ? currentView.sourceIDs : [];
        setHighlightedRowIDs(temp)
    }

    const setSelectedRows = (rows) => {
        selectedRows.current = rows;
    };

    const setViewName = (e) => {
        e.persist();
        if (e.target.value === "selectViewText") {
            currentViewName = "";
            setHighlightedRows();
            return;
        }
        else {
            currentViewName = e.target.value;
        }
        findView();
        fetchUG();
        fillDetails();
    }

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleClear = () => {
        setStateOption(Array(userGroup.length).fill(false));
    }

    const handleAll = () => {
        setStateOption(Array(userGroup.length).fill(true));
    }

    const handleChangeDialog = (e, index) => {
        let newArr = [...stateOption]; 
        newArr[index] = e.target.checked;
        setStateOption(newArr);
    };

    const fetchUG = async () => {
        await axios.post(config.BACKEND_URL + '/views/user-group', {userId : actionInitiatingUser }, { headers }).then(res => {
            const tugs = res.data.map(ug => { return {id: ug.id, name: ug.name}; });
            setUserGroup(tugs);
        }).catch(err => {
            console.log(err);
        });
        setStateOption(Array(userGroup.length).fill(true));
    };

    return (
        <div className="Views">
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <Navbar />
            <main className="container-fluid">
                <section className="d-flex row justify-content-center" style={{ padding: "1vw" }}>
                    <header className="h1 text-center mb-2">Edit View</header>
                    <div className="h3 text-center mb-2">Select the view you wish to edit.</div>
                    <section className="d-flex py-2 px-5 justify-content-center mb-3">
                        {isPendingViews && <div className="text-center h4 mb-3">Loading...</div>}
                        {errorViews && <div className="text-center h4 mb-3">Cannot fetch all the views right now!</div>}
                        {views &&
                            ((views.length === 0 && <div className="text-center h4 mb-3">Currently, there are no views!</div>) ||
                                <select onChange={setViewName} className="form-select" style={{ width: "auto" }} name="views" id="views">
                                    <option value="selectViewText">Choose view</option>
                                    {views.map((view, index) => {
                                        return <option key={index} value={view.name}>{view.name}</option>
                                    })}
                                </select>)
                        }
                    </section>
                    {views && views.length > 0 && <section className="row d-flex justify-content-center mb-3" style={{ width: "100%" }}>
                        <form id="editForm" className="row col-sm-9 d-flex justify-content-center" onSubmit={handleViewsEdit}>
                            <section className="row d-flex justify-content-center">
                                <section className="d-flex col-sm-4 justify-content-center flex-row">
                                    <label className="text-center col-sm-3" htmlFor="startDate">From</label>
                                    <input className="col-sm-10" type="datetime-local" name="startDate" id="startDate" onChange={handleStartTime} />
                                </section>
                                <section className="d-flex col-sm-4 justify-content-center flex-row">
                                    <label className="text-center col-sm-2" htmlFor="endDate">To</label>
                                    <input className="col-sm-10" type="datetime-local" name="endDate" id="endDate" onChange={handleEndTime} />
                                </section>
                            </section>
                            <section className="row d-flex justify-content-center mt-2">
                                <section className="col-sm-6 floating-label-group row d-flex justify-content-center">
                                    <input type="text" id="name" className="form-control" autoComplete="off" required style={{ fontWeight: "bold" }} />
                                    <label className="floating-label">Enter name of the view</label>
                                </section>
                            </section>
                            <section className="row d-flex justify-content-center form-check flex-column">
                                <header className="col-sm-12 d-flex justify-content-center h5 mb-0">What do you wish to include in the view?</header>
                                <section className="col-sm-12 floating-label-group d-flex justify-content-center form-check">
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" name="storeDetails[]" type="checkbox" value="table" id="tableInput" />
                                        <label className="form-check-label" htmlFor="table">
                                            Table
                                        </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" name="storeDetails[]" type="checkbox" value="chart" id="chartInput" />
                                        <label className="form-check-label" htmlFor="chart">
                                            Chart
                                        </label>
                                    </div>
                                </section>
                            </section>
                            <div className="row">
                                <header className="col-sm-9 d-flex justify-content-center h5 mb-3 mt-3">Please Select User Group to which you want to assign this view*</header>
                                <Button className="col-sm-3" variant="contained" color="primary" onClick={handleOpenDialog} style={{width: 200}}> Open User Group </Button>
                            </div>
                            <section /*className="d-flex justify-content-center col-sm-12"*/>
                                <section className="d-flex justify-content-center p-3" style={{ width: "auto", float: "center", height: "auto" }}>
                                    {isPendingSources && <div className="text-center h4 mb-3">Loading...</div>}
                                    {errorSources && <div className="text-center h4 mb-3">Cannot fetch the sources right now!</div>}
                                    {sources && sources.columns.length > 0 &&
                                        <>
                                            <Table
                                                tableData={sources.data}
                                                tableColumns={sources.columns}
                                                setParentRows={setSelectedRows}
                                                shouldModifySources={false}
                                                shouldDownload={false}
                                                highlightedRows={highlightedRowIDs}
                                                tableID="ViewsTable"
                                            />
                                        </>
                                    }
                                </section>
                                <section className="d-flex justify-content-center mt-3">
                                    <button className="btn btn-success" type="submit">Submit</button>
                                    &nbsp;
                                    &nbsp;
                                    <button className="btn btn-danger" type="reset">Reset</button>
                                </section>

                            </section>
                        </form>
                        
                        <div>
                            <Dialog open={openDialog} onClose={handleCloseDialog} ariaLabel="form-dialog-title" fullWidth maxWidth="sm">
                                <DialogTitle id="form-dialog-title">Below are the list of User Group in which you are in</DialogTitle>
                                <DialogContent className={classes.dialogContent}>
                                    {userGroup && userGroup.map((ug, index) => <FormControlLabel key={index} control={<Checkbox key={index} checked={stateOption[index]} onChange={(e) => handleChangeDialog(e, index)} name={ug.name} color="primary" />} label={ug.name} /> )}
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleCloseDialog} color="primary"> Cancel </Button>
                                    <Button onClick={handleClear} color="primary"> Clear </Button>
                                    <Button onClick={handleAll} color="primary"> All </Button>
                                    <Button onClick={handleCloseDialog} color="primary"> Ok </Button>
                                </DialogActions>
                            </Dialog>
                        </div>
                    </section>}
                </section>
            </main >
        </div >
    );
}

export default Views_Edit;