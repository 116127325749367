import React from 'react';
import { TextField, Button } from '@material-ui/core';
import axios from "axios";
import config from "../config.json";
import swal from "sweetalert";

const MqttWidget = ({ source }) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("sending data");
    let message = document.getElementById('messageMqtt').value;
    let payload = isJSONString(message) ? JSON.parse(message) : message;
    axios.post(config.BACKEND_URL + '/widgets/send-mqtt-data', { source, payload }).then(() => {
      console.log("i am here");
      swal("MQTT Data Published", "Click on OK to continue...", "success");
    }).catch(err => {
      console.log(err);
    });
  };
  const isJSONString = (str) => {
    try {
      JSON.parse(str);
      return true;
    } catch (error) {
      return false;
    }
  };
  const stopPropagation = (e) => {
    e.stopPropagation(); // Prevent grid item dragging
  };

  return (
    <form onSubmit={handleSubmit}>
      <div style={{ display: 'flex', flexDirection: 'column', margin: '5%' }}>
        <TextField id="messageMqtt" label="Message (JSON Format)" placeholder="Value" multiline variant="filled" />
        <br />
        <Button variant="contained" onMouseDown={stopPropagation}
          onTouchStart={stopPropagation} color="primary" type="submit"> Send </Button>
      </div>
    </form>
  );
}

export default MqttWidget