import { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';

const useFetch = ({ URL: url, actionInitiatingUser }) => {
    const [data, setData] = useState(null);
    const [isPending, setIsPending] = useState(true);
    const [error, setError] = useState(null);
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    const headers = {
        'Authorization': `Bearer ${userToken}`,
        'Content-Type': 'application/json'
    };
    useEffect(() => {
        const abortCont = new AbortController();

        setTimeout(() => {
            fetch(url, {
                signal: abortCont.signal,
                headers
            }).then(res => {
                if (!res.ok) { // error coming back from server
                    throw Error('could not fetch the data for that resource');
                }
                return res.json();
            }).then(data => {
                setIsPending(false);
                setData(data);
                setError(null);
            }).catch(err => {
                if (err.name === 'AbortError') {
                    console.log('fetch aborted')
                } else {
                    // auto catches network / connection error
                    setIsPending(false);
                    setError(err.message);
                }
            })
        }, 500);

        // abort the fetch
        return () => abortCont.abort();
    }, [url])
    return { data, isPending, error, setData };
}

export default useFetch;