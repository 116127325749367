import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect } from 'react';
import { TabulatorFull as Tabulator } from "tabulator-tables";

import "tabulator-tables/dist/css/tabulator.min.css";
import axios from 'axios'
import config from '../../config.json';
import Cookies from 'universal-cookie';

var table
global.groupid = []
global.groupName = []

async function fetchData() {
  const cookies = new Cookies();
  const userToken = cookies.get('userToken');
  const headers = {
    'Authorization': `Bearer ${userToken}`,
    'Content-Type': 'application/json'
  };
  let UserGroups = [];
  axios.get(config.BACKEND_URL + "/usergroups", { headers })
    .then(res => {
      UserGroups = res.data.data.map(group => {
        return { id: group.id, name: group.name, userIDs: group.userIDs }
      });
      table.setData(UserGroups)
    })
    .catch(err => {
      console.log(err);
    })
}

function GroupTable() {
  const columns = [
    { formatter: "rownum", align: "center", width: 70 },
    { title: "id", field: "id", visible: false },
    { title: "Group Name", field: "name", headerFilter:"input" },
  ]


  useEffect(() => {
    table = new Tabulator("#App-table", {
      //---------------------- Table Structure ----------------------------------
      layout: "fitColumns",
      columns: columns,
      selectableRows: 1,
      // fitColumns: true,
      pagination: "local",
      paginationSize: 10,
      paginationSizeSelector: [25, 50, 75, 100],
      tooltips: true,
      responsiveLayout: true,
    });

    table.on('tableBuilt', fetchData);

    table.on('rowClick', function (e, row) {
      if (!global.groupid.includes(row.getData().id)) {
        table.selectRow(row.getData().id);
        global.groupid[0] = row.getData().id;
        global.groupName[0] = row.getData().name;
      }
      else {
        table.deselectRow(row.getData().id);
        global.groupid[0] = null
        global.groupName[0] = null
      }
    })

  }, [])

  return (
    <>
      <div className="GroupTable">
        <div style={{ 'padding': '25px' }}>
          <div id="App-table"></div>
        </div>
      </div>
    </>
  );
}

export default GroupTable;