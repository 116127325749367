import React, { useState } from "react"
import '../styles/user_management_styles.css'
import Navbar from "./Navbar.js"
import 'bootstrap/dist/css/bootstrap.min.css';
import Table from "./Table.js";
import TestChart from "./TestChart.js";
import useFetch from './useFetch.js';
import swal from "sweetalert";
import axios from "axios";
import 'chartjs-plugin-zoom';
import config from '../config.json';
import Cookie from 'universal-cookie';
import mixpanel from "mixpanel-browser";

let shouldDisplayObject = {};

const Reports = () => {
    const cookies = new Cookie();
    let userToken = "";
    let actionInitiatingUser = -1;
    try {
        userToken = cookies.get('userToken');
        actionInitiatingUser = cookies.get('userID');
    } catch (error) {}
    const headers = {
        'Authorization': `Bearer ${userToken}`,
        'Content-Type': 'application/json'
    };
    const { data: views, isPending: isPendingViews, error: errorViews } = useFetch({ actionInitiatingUser: { userID: actionInitiatingUser }, URL: config.BACKEND_URL + '/views' });
    const { data: sources, isPending: isPendingSources, error: errorSources } = useFetch({ actionInitiatingUser: { userID: actionInitiatingUser }, URL: config.BACKEND_URL + '/logger_sources/sources' });
    const [dataLogObject, setDataLogObject] = useState({});
    let currentView = {};
    let currentViewName = "";

    const handleLoad = (e) => {
        e.preventDefault();
        mixpanel.track("Report Page Load Button Clicked", { userID: cookies.get('userID') });
        if (currentViewName === "") {
            swal("View not selected!", "Select a view before it can be loaded.", "error");
        }
        else {
            setDataLogObject({});
            axios.get(config.BACKEND_URL + '/views', { headers })
                .then(res => {
                    const view = res.data.filter(view => view.name === currentViewName)[0];
                    const actionInitiatingUser = localStorage.getItem("token") ? JSON.parse(localStorage.getItem("token")).username : "unknown";
                    axios.get(config.BACKEND_URL + `/views/${view.id}/find_data?actionInitiatingUser=${actionInitiatingUser}`, { headers })
                        .then(res => {
                            let chartDataSets = {};
                            let dataLogData = [];
                            let dataLogColumns = [];
                            if (view.chart) {
                                chartDataSets["datasets"] = [];
                                for (let source of Object.keys(res.data)) {
                                    let data = res.data[source];
                                    let borderColor = `rgb(${Math.floor(Math.random() * 127)}, ${Math.floor(Math.random() * 127)}, ${Math.floor(Math.random() * 127)})`;
                                    let label = source;
                                    let newDataSet = {
                                        label, borderColor, data
                                    };
                                    chartDataSets["datasets"].push(newDataSet);
                                }
                            }
                            if (view.table) {
                                Object.keys(res.data).forEach(key => {
                                    res.data[key].forEach(dataPoint => {
                                        dataPoint[key] = dataPoint["y"];;
                                        dataPoint["timeStamp"] = dataPoint["x"];
                                    })
                                    dataLogData.push(...res.data[key]);
                                });
                                dataLogColumns = [
                                    { title: "Timestamp", field: "timeStamp" },
                                    ...Object.keys(res.data).map(column => ({
                                        title: column,
                                        field: column
                                    }))
                                ]
                            }
                            setDataLogObject({
                                tableData: dataLogData,
                                tableColumns: dataLogColumns,
                                chartData: chartDataSets,
                                shouldDisplay: shouldDisplayObject
                            })
                        })
                        .catch(err => {
                            console.log(err);
                        })
                })
        }
    }

    const findView = () => {
        currentView = currentViewName !== "" ? views.filter(v => v.name === currentViewName)[0] : null;
    }

    const handleReset = (e) => {
        mixpanel.track("Report Page Reset Button Clicked", { userID: cookies.get('userID') });
        currentViewName = "";
        findView();
        setDataLogObject({});
    }

    const setViewName = (e) => {
        e.persist();
        currentViewName = e.target.value === "selectViewText" ? "" : e.target.value;
        findView();
        if (!currentView) {
            setDataLogObject({});
            return;
        }
        else {
            shouldDisplayObject["table"] = currentView.table;
            shouldDisplayObject["chart"] = currentView.chart;
        }
    }
    return (
        <div className="Reports">
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons"
                rel="stylesheet" />
            <script src="https://cdn.jsdelivr.net/npm/chart.js"></script>
            <Navbar />
            <main className="container-fluid">
                <section className="d-flex row justify-content-center" style={{ padding: "1vw" }}>
                    <header className="h1 text-center mb-2">Reports</header>
                    <div className="h3 text-center mb-2">Select the view for its reports.</div>
                    <section className="selectDateRange row d-flex justify-content-center mb-3" style={{ width: "100%" }}>
                        <form id="deleteForm" className="row col-sm-9 d-flex justify-content-center">
                            <section /*className="d-flex justify-content-center col-sm-12"*/>
                                <div className="d-flex py-2 px-5 justify-content-center mb-3">
                                    {isPendingViews && <div className="text-center h4 mb-3">Loading...</div>}
                                    {errorViews && <div className="text-center h4 mb-3">Cannot fetch all the views right now!</div>}
                                    {views && !isPendingViews &&
                                        ((views.length === 0 && <div className="text-center h4 mb-3">Currently, there are no views!</div>) ||
                                            <select onClick={setViewName} className="form-select" style={{ width: "auto" }} name="views" id="views">
                                                <option value="selectViewText">Choose view</option>
                                                {views.map((view, index) => {
                                                    return <option key={index} value={view.name}>{view.name}</option>
                                                })}
                                            </select>
                                        )
                                    }
                                </div>
                                <section className="d-flex justify-content-center mb-3">
                                    <button className="btn btn-primary" type="submit" onClick={handleLoad}>Load</button>
                                    &nbsp;
                                    &nbsp;
                                    <button className="btn btn-secondary" type="reset" onClick={handleReset}>Reset</button>
                                </section>
                                {dataLogObject
                                    && dataLogObject.shouldDisplay
                                    && dataLogObject.shouldDisplay.table
                                    && dataLogObject.tableColumns
                                    && dataLogObject.tableColumns.length > 0
                                    && dataLogObject.tableData
                                    && dataLogObject.tableData.length > 0
                                    && console.log("Data log object: ", dataLogObject) === undefined
                                    && <><div className="mb-3">
                                        <Table
                                            tableData={dataLogObject.tableData}
                                            tableColumns={dataLogObject.tableColumns}
                                            shouldModifySources={false}
                                            shouldDownload={false}
                                            isSelectable={false}
                                            shouldDisplay={dataLogObject.shouldDisplay.table}
                                            tableID="ReportsTable"
                                        />
                                    </div>
                                    
                                    </>
                                }
                                {console.log('Should display chart: ', shouldDisplayObject.chart) === undefined
                                    // && console.log('Chart labels: ', dataLogObject.chartLabels) === undefined
                                    && console.log('Chart datasets: ', dataLogObject.chartData) === undefined
                                    && dataLogObject.chartData
                                    && dataLogObject.chartData.datasets
                                    && dataLogObject.chartData.datasets.length > 0
                                    && <div className="mb-3">
                                        <TestChart
                                            // labels={dataLogObject.chartLabels}
                                            datasets={dataLogObject.chartData.datasets}
                                            chartID="ReportsChart"
                                            shouldDisplay={dataLogObject.shouldDisplay.chart}
                                        />
                                    </div>
                                }
                                {dataLogObject
                                    && dataLogObject.shouldDisplay
                                    && dataLogObject.shouldDisplay.table
                                    && dataLogObject.tableColumns
                                    && dataLogObject.tableColumns.length > 0
                                    && dataLogObject.tableData
                                    && dataLogObject.tableData.length > 0 && console.log('Should display chart: ', shouldDisplayObject.chart) === undefined
                                    // && console.log('Chart labels: ', dataLogObject.chartLabels) === undefined
                                    && console.log('Chart datasets: ', dataLogObject.chartData) === undefined
                                    && dataLogObject.chartData
                                    && dataLogObject.chartData.datasets
                                    && dataLogObject.chartData.datasets.length > 0
                                    && <button className="btn btn-primary">Get Reports</button>}
                            </section>
                        </form>
                    </section>
                </section>
            </main >
        </div >
    );
}

export default Reports;