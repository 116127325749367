import { color } from 'd3-color';
import { interpolateRgb } from 'd3-interpolate';
import React, { useState, useEffect, useRef } from 'react';
import LiquidFillGauge from 'react-liquid-gauge';
import axios from 'axios';
import config from "../config.json";

const FluidTank = ({ value: propValue, lower_limit_value, upper_limit_value, source }) => {
    const [value, setValue] = useState(0);
    const [radius, setRadius] = useState(110); // Default radius
    const containerRef = useRef(null);

    useEffect(() => {
        const getFluidTankData = async () => {
            if (!propValue) {  // Check if propValue is not null, undefined, or empty
                console.error("propValue is missing, cannot fetch data.");
                return;  // Stop the function from proceeding
            }
            try {
                const res = await axios.post(`${config.BACKEND_URL}/widgets/get-fluid-tank-data`, { value: propValue, lower_limit_value, upper_limit_value, source });
                setValue(res.data.value);
            } catch (err) {
                console.error(err);
            }
        };

        getFluidTankData();
        const interval = setInterval(getFluidTankData, 3000);

        return () => clearInterval(interval);
    }, [propValue]);

    useEffect(() => {
        const updateDimensions = () => {
            if (containerRef.current) {
                const { width, height } = containerRef.current.getBoundingClientRect();
                const newRadius = Math.max(width, height) / 3; // Set radius based on smallest dimension
                setRadius(newRadius);
                console.log(`Updated dimensions: width=${width}, height=${height}, radius=${newRadius}`);
            } else {
                console.error("containerRef is not defined.");
            }
        };

        updateDimensions();
        window.addEventListener('resize', updateDimensions);

        return () => window.removeEventListener('resize', updateDimensions);
    }, []);

    const startColor = '#6495ed'; // cornflowerblue
    const endColor = '#dc143c'; // crimson
    const interpolate = interpolateRgb(startColor, endColor);
    const fillColor = interpolate(value / 100);
    const gradientStops = [
        {
            key: "0%",
            stopColor: color(fillColor).darker(0.5).toString(),
            stopOpacity: 1,
            offset: "0%"
        },
        {
            key: "50%",
            stopColor: fillColor,
            stopOpacity: 0.75,
            offset: "50%"
        },
        {
            key: "100%",
            stopColor: color(fillColor).brighter(0.5).toString(),
            stopOpacity: 0.5,
            offset: "100%"
        }
    ];

    return (
        <div ref={containerRef} >
            <LiquidFillGauge
                style={{ margin: "0 auto" }}
                width={radius * 2}
                height={radius * 2}
                value={value}
                percent="%"
                textSize={1}
                textOffsetX={0}
                textOffsetY={0}
                textRenderer={props => {
                    const value = Math.round(props.value);
                    const radius = Math.min(props.height / 2, props.width / 2);
                    const textPixels = (props.textSize * radius) / 2;
                    const valueStyle = {
                        fontSize: textPixels
                    };
                    const percentStyle = {
                        fontSize: textPixels * 0.6
                    };

                    return (
                        <tspan>
                            <tspan className="value" style={valueStyle}>
                                {value}
                            </tspan>
                            <tspan style={percentStyle}>{props.percent}</tspan>
                        </tspan>
                    );
                }}
                riseAnimation
                waveAnimation
                waveFrequency={2}
                waveAmplitude={1}
                gradient
                gradientStops={gradientStops}
                circleStyle={{
                    fill: fillColor
                }}
                waveStyle={{
                    fill: fillColor
                }}
                textStyle={{
                    fill: color("#444").toString(),
                    fontFamily: "Arial"
                }}
                waveTextStyle={{
                    fill: color("#fff").toString(),
                    fontFamily: "Arial"
                }}
            />
        </div>
    );
}

export default FluidTank;
