import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from 'react';
import { TabulatorFull } from "tabulator-tables";
import "tabulator-tables/dist/css/tabulator.min.css";

const AlarmsTable = ({ alarms = [], alarmSettings = [], sources = [] }) => {
    useEffect(() => {
        let formatter = function (cell, formatterParams) {
            cell.getRow().getElement().style.backgroundColor = cell.getRow().getData().color;
        }
        let setColumns = () => {
            let columns = [
                {
                    title: "color",
                    field: "color",
                    headerSort: false,
                    visible: false,
                    formatter
                },
                {
                    "title": "Timestamp",
                    "field": "createdAt",
                },
                {
                    "title": "Tag",
                    "field": "sourceName",
                    "headerFilter": true
                },
                {
                    "title": "Value",
                    "field": "value",
                },
                {
                    "title": "Title",
                    "field": "title",
                    "headerFilter": true
                },
                {
                    "title": "Trigger Condition",
                    "field": "condition",
                },
            ];
            return columns;
        }
        let setData = () => {
            alarms = alarms ? alarms : [];
            alarmSettings = alarmSettings ? alarmSettings : [];
            sources = sources ? sources : [];
            let data = [];
            for (let alarm of alarms) {
                let requiredDataSource = sources.filter(source => source.id === alarm.sourceId)[0];
                let requiredAlarmSetting = alarmSettings.filter(alarmSetting => alarmSetting.id === alarm.alarmSettingId)[0];
                let newObj = {
                    createdAt: alarm.createdAt,
                    sourceName: requiredDataSource ? requiredDataSource.name : '',
                    value: alarm ? alarm.value : '',
                    title: requiredAlarmSetting ? requiredAlarmSetting.title : '',
                    condition: requiredAlarmSetting ? `${requiredAlarmSetting.direction} ${requiredAlarmSetting.value}` : '',
                    color: requiredAlarmSetting ? requiredAlarmSetting.color : '#FFFFFF',
                }
                data.push(newObj);
            }
            return data;
        }
        let table = new TabulatorFull(`#alarmsTable`, {
            layout: "fitColumns",
            columns: setColumns(),
            data: setData(),
            pagination: "local",
            paginationSize: 10,
            paginationSizeSelector: [10, 25, 50, 100],
            tooltips: true,
            responsiveLayout: true
        });
    }, [alarms, alarmSettings, sources])

    return (
        <div className="d-flex justify-content-center">
            <div style={{ width: "60rem" }} id="alarmsTable"></div>
        </div>
    );
}
export default AlarmsTable;