import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from "../config.json";

const TextWidget = ({ source, datasets }) => {
  return (
    <div style={{fontSize:`${datasets.fontSize}px`, fontWeight:datasets.fontWeight, padding:'10px', margin:'10px'}}>{datasets.text}</div>
  )
}

export default TextWidget