import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import axios from "axios";
import config from "../config.json";

const BarChart = ({ source, datasets }) => {
  const [barData, setBarData] = useState([]);
  const [labels, setLabels] = useState([]);
  const getBarData = async () => {
    if (source) {
      await axios
        .post(config.BACKEND_URL + "/widgets/get-bar-chart-data", { source })
        .then((res) => {
          console.log(res);
          setBarData(res.data.barData);
          setLabels(res.data.labels);
        })
        .catch((err) => console.log(err));
    } else {
      setLabels(["Red", "Blue", "Yellow", "Green", "Purple"]);
      setBarData([12, 19, 3, 5, 2]);
    }
  };
  useEffect(() => {
    getBarData();
    const interval = setInterval(() => {
      getBarData();
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const data = {
    labels: labels,
    datasets: [
      {
        label: source ? source : "Please Add Source to view data",
        data: barData,
        backgroundColor: datasets[0].bar_bg_color ? datasets[0].bar_bg_color : "rgba(75,192,192,0.6)",
        borderColor: datasets[0].bar_br_color ? datasets[0].bar_br_color : "rgba(75,192,192,1)",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          color: "rgba(0, 0, 0, 0.1)",
        },
        ticks: {
          stepSize: 5,
        },
      },
    },
  };

  return (
    <div style={{ width:'100%'}}>
      <Bar data={data} options={options} />
    </div>
  );
};

export default BarChart;
