import React, { useState, useFetch, useEffect } from "react";
import axios from "axios";
import swal from "sweetalert";
import "../styles/dashboard_styles.css";
import "../styles/log.css";
import "../styles/switch.css";
import Navbar from "./Navbar.js";
import "../fSelect.js";
import Table from "./Table";
import $ from "jquery";
import config from '../config.json';
import Cookie from "universal-cookie";
import mixpanel from "mixpanel-browser";

var dummyDatasources = [];

var options = [];

const EventLog = () => {
    const cookies = new Cookie();
    const [data, setData] = useState(null);

    const [logType, setLogType] = useState("table"); 

    var months = [ "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ];

    const changeTimeFormat = (date) => {
        let time = new Date(date).toLocaleTimeString("en", { timeStyle: "short", hour12: false, timeZone: "UTC" });
        let newDate = months[date.getMonth()] + "-" + date.getDate() + "-" + date.getFullYear() + " " + time;
        return newDate;
    };

    const getLog = (event) => {
        event.preventDefault();
        let obj = Object.fromEntries(new FormData(event.target).entries());
        let startDate = new Date(obj.startDate + "Z");
        let endDate = new Date(obj.endDate + "Z");
        axios.get(config.BACKEND_URL + "/logger/query", {
                params: {
                    from: changeTimeFormat(startDate),
                    until: changeTimeFormat(endDate),
                },
            }).then(function (response) {
                let obj = {
                    columns: [
                        { title: "Timestamp", field: "timestamp" },
                        { title: "User", field: "User" },
                        { title: "Message", field: "message" },
                        {
                            title: "Details",
                            field: "details",
                            formatter: function (cell, formatterParams, onRendered) {
                                return "<button>Details</button>";
                            },
                            width: 200,
                            align: "center",
                            cellClick: function (e, cell) {
                                let detailObj = cell.getData();
                                swal(
                                    "Raw Log Details",
                                    JSON.stringify(detailObj, null, 2).replace(/[{}]/g, "")
                                );
                            },
                        },
                    ],
                    data: response.data.file,
                };
                if (logType === "CSV") {
                    mixpanel.track("Event Log Export CSV Button Clicked", { userID: cookies.get('userID') });
                    var csv = "Timestamp,User,Message,Details\n";
                    let dummy = obj.data;
                    const csvString = [
                        ...dummy.map((item) => [
                            item.timestamp,
                            item.user,
                            item.message,
                            JSON.stringify(item).replaceAll("\n", ""),
                        ]),
                    ];
                    // merge the data with CSV
                    csvString.forEach(function (row) {
                        csv += row.join(",");
                        csv += "\n";
                    });

                    var hiddenElement = document.createElement("a");
                    hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
                    hiddenElement.target = "_blank";

                    //provide the name htmlFor the CSV file to be downloaded
                    hiddenElement.download = "data.csv";
                    hiddenElement.click();
                } else {
                    mixpanel.track("Event Log Get Log Button Clicked", { userID: cookies.get('userID') });
                    setData(obj);
                }
            });
    };

    function setSwitch(event) {
        let data = {
            key: event.target.id.replaceAll("_", " "),
            value: event.target.checked,
        }
        $.ajax({
            // url: config.BACKEND_URL + '/setswitch'
            url: config.BACKEND_URL + "/logger/setswitch",
            type: "POST",
            data: data,
            // change status depending on return value
            success: function (data) {
                event.target.checked = eval(data.value);
            },
            error: function (err) {
                console.log(err);
            },
        });
    }
    
    $.get(config.BACKEND_URL + "/logger/getswitch", (logConfig) => {
        
        let logevents = Object.keys(logConfig).map((el) => el.replaceAll(" ", "_"));
        logevents.forEach((logevent) => {
            // set status
            const element = document.querySelector("#" + logevent);
            if (element) {
                element.checked = logConfig[logevent.replaceAll("_", " ")];
            }
            if(element){
                element.onchange = setSwitch;
            }
        });
    });

    return (
        <div className="Dashboard">
            <Navbar />
            <div className="container title">
                <h2>Log Setting</h2>
            </div>
            <div className="container log_setting">
                <table className="table table-fixed">
                    <thead>
                        <tr>
                            <th>Event type</th>
                            <th>Logging status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>User Logged In</td>
                            <td className="checkboxCol">
                                <div className="switch">
                                    <input
                                        id="User_Logged_In"
                                        type="checkbox"
                                        className="switch-input"
                                    />
                                    <label htmlFor="User_Logged_In" className="switch-label">
                                        checkbox 1
                                    </label>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>User Logged Out</td>
                            <td className="checkboxCol">
                                <div className="switch">
                                    <input
                                        id="User_Logged_Out"
                                        type="checkbox"
                                        className="switch-input"
                                    />
                                    <label htmlFor="User_Logged_Out" className="switch-label">
                                        checkbox 1
                                    </label>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>Dashboard Added</td>
                            <td className="checkboxCol">
                                <div className="switch">
                                    <input
                                        id="Dashboard_Added"
                                        type="checkbox"
                                        className="switch-input"
                                    />
                                    <label htmlFor="Dashboard_Added" className="switch-label">
                                        checkbox 1
                                    </label>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>Dashboard Edited</td>
                            <td className="checkboxCol">
                                <div className="switch">
                                    <input
                                        id="Dashboard_Edited"
                                        type="checkbox"
                                        className="switch-input"
                                    />
                                    <label htmlFor="Dashboard_Edited" className="switch-label">
                                        checkbox 1
                                    </label>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>Dashboard Deleted</td>
                            <td className="checkboxCol">
                                <div className="switch">
                                    <input
                                        id="Dashboard_Deleted"
                                        type="checkbox"
                                        className="switch-input"
                                    />
                                    <label htmlFor="Dashboard_Deleted" className="switch-label">
                                        checkbox 1
                                    </label>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>User Added</td>
                            <td className="checkboxCol">
                                <div className="switch">
                                    <input
                                        id="User_Added"
                                        type="checkbox"
                                        className="switch-input"
                                    />
                                    <label htmlFor="User_Added" className="switch-label">
                                        checkbox 1
                                    </label>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>User Edited</td>
                            <td className="checkboxCol">
                                <div className="switch">
                                    <input
                                        id="User_Edited"
                                        type="checkbox"
                                        className="switch-input"
                                    />
                                    <label htmlFor="User_Edited" className="switch-label">
                                        checkbox 1
                                    </label>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>User Deleted</td>
                            <td className="checkboxCol">
                                <div className="switch">
                                    <input
                                        id="User_Deleted"
                                        type="checkbox"
                                        className="switch-input"
                                    />
                                    <label htmlFor="User_Deleted" className="switch-label">
                                        checkbox 1
                                    </label>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>User Group Added</td>
                            <td className="checkboxCol">
                                <div className="switch">
                                    <input
                                        id="User_Group_Added"
                                        type="checkbox"
                                        className="switch-input"
                                    />
                                    <label htmlFor="User_Group_Added" className="switch-label">
                                        checkbox 1
                                    </label>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>User Group Edited</td>
                            <td className="checkboxCol">
                                <div className="switch">
                                    <input
                                        id="User_Group_Edited"
                                        type="checkbox"
                                        className="switch-input"
                                    />
                                    <label htmlFor="User_Group_Edited" className="switch-label">
                                        checkbox 1
                                    </label>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>User Group Deleted</td>
                            <td className="checkboxCol">
                                <div className="switch">
                                    <input
                                        id="User_Group_Deleted"
                                        type="checkbox"
                                        className="switch-input"
                                    />
                                    <label htmlFor="User_Group_Deleted" className="switch-label">
                                        checkbox 1
                                    </label>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>Dashboard Group Added</td>
                            <td className="checkboxCol">
                                <div className="switch">
                                    <input
                                        id="Dashboard_Group_Added"
                                        type="checkbox"
                                        className="switch-input"
                                    />
                                    <label htmlFor="Dashboard_Group_Added" className="switch-label">
                                        checkbox 1
                                    </label>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>Dashboard Group Edited</td>
                            <td className="checkboxCol">
                                <div className="switch">
                                    <input
                                        id="Dashboard_Group_Edited"
                                        type="checkbox"
                                        className="switch-input"
                                    />
                                    <label htmlFor="Dashboard_Group_Edited" className="switch-label">
                                        checkbox 1
                                    </label>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>Dashboard Group Deleted</td>
                            <td className="checkboxCol">
                                <div className="switch">
                                    <input
                                        id="Dashboard_Group_Deleted"
                                        type="checkbox"
                                        className="switch-input"
                                    />
                                    <label htmlFor="Dashboard_Group_Deleted" className="switch-label">
                                        checkbox 1
                                    </label>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>Alarm Setting Added</td>
                            <td className="checkboxCol">
                                <div className="switch">
                                    <input
                                        id="Alarm_Setting_Added"
                                        type="checkbox"
                                        className="switch-input"
                                    />
                                    <label htmlFor="Alarm_Setting_Added" className="switch-label">
                                        checkbox 1
                                    </label>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>Alarm Setting Edited</td>
                            <td className="checkboxCol">
                                <div className="switch">
                                    <input
                                        id="Alarm_Setting_Edited"
                                        type="checkbox"
                                        className="switch-input"
                                    />
                                    <label htmlFor="Alarm_Setting_Edited" className="switch-label">
                                        checkbox 1
                                    </label>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>Alarm Setting Deleted</td>
                            <td className="checkboxCol">
                                <div className="switch">
                                    <input
                                        id="Alarm_Setting_Deleted"
                                        type="checkbox"
                                        className="switch-input"
                                    />
                                    <label htmlFor="Alarm_Setting_Deleted" className="switch-label">
                                        checkbox 1
                                    </label>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>View Added</td>
                            <td className="checkboxCol">
                                <div className="switch">
                                    <input
                                        id="View_Added"
                                        type="checkbox"
                                        className="switch-input"
                                    />
                                    <label htmlFor="View_Added" className="switch-label">
                                        checkbox 1
                                    </label>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>View Edited</td>
                            <td className="checkboxCol">
                                <div className="switch">
                                    <input
                                        id="View_Edited"
                                        type="checkbox"
                                        className="switch-input"
                                    />
                                    <label htmlFor="View_Edited" className="switch-label">
                                        checkbox 1
                                    </label>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>View Deleted</td>
                            <td className="checkboxCol">
                                <div className="switch">
                                    <input
                                        id="View_Deleted"
                                        type="checkbox"
                                        className="switch-input"
                                    />
                                    <label htmlFor="View_Deleted" className="switch-label">
                                        checkbox 1
                                    </label>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>Source Added</td>
                            <td className="checkboxCol">
                                <div className="switch">
                                    <input
                                        id="Source_Added"
                                        type="checkbox"
                                        className="switch-input"
                                    />
                                    <label htmlFor="Source_Added" className="switch-label">
                                        checkbox 1
                                    </label>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>Source Edited</td>
                            <td className="checkboxCol">
                                <div className="switch">
                                    <input
                                        id="Source_Edited"
                                        type="checkbox"
                                        className="switch-input"
                                    />
                                    <label htmlFor="Source_Edited" className="switch-label">
                                        checkbox 1
                                    </label>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>Source Deleted</td>
                            <td className="checkboxCol">
                                <div className="switch">
                                    <input
                                        id="Source_Deleted"
                                        type="checkbox"
                                        className="switch-input"
                                    />
                                    <label htmlFor="Source_Deleted" className="switch-label">
                                        checkbox 1
                                    </label>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>Report Request</td>
                            <td className="checkboxCol">
                                <div className="switch">
                                    <input
                                        id="Report_Request"
                                        type="checkbox"
                                        className="switch-input"
                                    />
                                    <label htmlFor="Report_Request" className="switch-label">
                                        checkbox 1
                                    </label>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>Alarm Raised</td>
                            <td className="checkboxCol">
                                <div className="switch">
                                    <input
                                        id="Alarm_Raised"
                                        type="checkbox"
                                        className="switch-input"
                                    />
                                    <label htmlFor="Alarm_Raised" className="switch-label">
                                        checkbox 1
                                    </label>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>Data Write</td>
                            <td className="checkboxCol">
                                <div className="switch">
                                    <input
                                        id="Data_Write"
                                        type="checkbox"
                                        className="switch-input"
                                    />
                                    <label htmlFor="Data_Write" className="switch-label">
                                        checkbox 1
                                    </label>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="container title"> <h2>Log Display</h2> </div>
            <div className="container log_display">
                <section className="selectDateRange row d-flex justify-content-between mb-3" style={{ width: "100%" }}>
                    <form
                        className="row col-sm-12 d-flex justify-content-center"
                        id="activity-log-form"
                        action=""
                        onSubmit={getLog}
                    >
                        <section className="mb-4 row">
                            <section className="d-flex col-sm-6 justify-content-center flex-column">
                                <label className="text-center" htmlFor="startDate">
                                    Starting Date and Time
                                </label>
                                <input type="datetime-local" name="startDate" id="startDate" />
                            </section>
                            <section className="d-flex col-sm-6 justify-content-center flex-column">
                                <label className="text-center" htmlFor="endDate">
                                    Ending Date and Time
                                </label>
                                <input type="datetime-local" name="endDate" id="endDate" />
                            </section>
                        </section>
                        <section className="d-flex justify-content-center col col-sm-12">
                            <button type="submit" className="btn btn-primary d-block col-sm-2 mr-2" onClick={() => { setLogType("Table"); }}> Get Log </button>
                            <button className="btn btn-primary d-block col-sm-2 mr-2" onClick={() => { setLogType("CSV"); }}> Export CSV </button>
                        </section>
                    </form>
                </section>
                {
                    data && (
                        <>
                            <Table tableColumns={data.columns} tableData={data.data} isSelectable = {false} />
                        </>
                    )
                }
            </div>
        </div>
    );
};

export default EventLog;