import axios from "axios";
import React, { useEffect } from "react"
import { Redirect } from "react-router-dom"
import Cookie from "universal-cookie";
import config from '../config.json';

const Logout = () => {
    const cookies = new Cookie();
    let userToken = "";
    let userID = "";
    try {
        userToken = cookies.get('userToken');
        userID = cookies.get("userID");
    } catch (error) {}
    const headers = {
        'Authorization': `Bearer ${userToken}`,
        'Content-Type': 'application/json'
    };
    useEffect(()=>{
        try {
            cookies.remove('userID', { path: '/', secure: true });
            cookies.remove('userToken', { path: '/', secure: true });
            cookies.remove('userStatus', { path: '/', secure: true });
            cookies.remove('urname', { path: '/', secure: true });
            cookies.remove('uruser', { path: '/', secure: true });
            cookies.remove('uruserGroup', { path: '/', secure: true });
            cookies.remove('uruserDashboard', { path: '/', secure: true });
            cookies.remove('uralarm', { path: '/', secure: true });
            cookies.remove('urview', { path: '/', secure: true });
            cookies.remove('ursource', { path: '/', secure: true });
            cookies.remove('urrole', { path: '/', secure: true });
            cookies.remove('urdashboard', { path: '/', secure: true });
            cookies.remove('urreport', { path: '/', secure: true });
            if(userID === '99999'){
                axios.post(config.BACKEND_URL + "/superuser/logout", { userID }, { headers }).then(res => {
                    window.location.replace(config.FRONTEND_URL);
                    // window.location.replace(config.BACKEND_URL);
                }).catch(err => {
                    console.log("Logout failed!", err);
                });
            } else {
                axios.post(config.BACKEND_URL + "/users/logout", { userID }, { headers }).then(res => {
                    window.location.replace(config.FRONTEND_URL);
                    // window.location.replace(config.BACKEND_URL);
                }).catch(err => {
                    console.log("Logout failed!", err);
                });
            }
        }
        catch (err) {
            console.log("Error logging out: ", err);
        }
    }, []);
    
    return (<Redirect to="/" />);
}

export default Logout;