// import React, { useEffect } from 'react';
// import Cookie from 'universal-cookie';
// import { decrypt } from './useCryptoJS';
// import '../styles/navbar_styles.css'
// const logo = require('../images/logo.png');

// const Navbar = React.memo(() => {
//     const cookies = new Cookie();
//     let uruser = "", uruserGroup = "", uruserDashboard = "", uralarm = "", urview = "", ursource = "", urreport = "", urrole = "", userstatus = "";
//     try {
//         uruser = cookies.get('uruser') !== undefined ?decrypt(cookies.get('uruser')) : "0000";
//         uruserGroup = cookies.get('uruserGroup') !== undefined ?decrypt(cookies.get('uruserGroup')) : "0000";
//         uruserDashboard = cookies.get('uruserDashboard') !== undefined ?decrypt(cookies.get('uruserDashboard')) : "0000";
//         uralarm = cookies.get('uralarm') !== undefined ?decrypt(cookies.get('uralarm')) : "0000";
//         urview = cookies.get('urview') !== undefined ?decrypt(cookies.get('urview')) : "0000";
//         ursource = cookies.get('ursource') !== undefined ?decrypt(cookies.get('ursource')) : "0000";
//         urreport = cookies.get('urreport') !== undefined ?decrypt(cookies.get('urreport')) : "0";
//         urrole = cookies.get('urrole') !== undefined ?decrypt(cookies.get('urrole')) : "00000";
//         userstatus = decrypt(cookies.get('userStatus'));
//     } catch (error) {}
//     useEffect(() => {
//         setTimeout(() => {
//             const sideNavEl = document.querySelector('.js-side-nav');
//             const sideNavContainerEl = document.querySelector('.js-side-nav-container');
//             const showButtonEl = document.querySelector('.js-menu-open');
//             const closeButtonEl = document.querySelector('.js-menu-close');
//             const dropdowns = document.querySelectorAll('.navbar-dropdown-container');
//             const dropdownIcons = document.querySelectorAll('.navbar-dropdown-icon');

//             const blockClicks = function (evt) {
//                 evt.stopPropagation();
//             }

//             const onTransitionEnd = function (evt) {
//                 sideNavContainerEl.classList.remove('side-nav-animatable');
//                 sideNavContainerEl.removeEventListener('transitionend', onTransitionEnd);
//             }

//             const initialiseDropdowns = function () {
//                 dropdowns.forEach(dropdown => {
//                     // console.dir(dropdown.children);
//                     for (let child of dropdown.children) {
//                         if (child.classList.contains('navbar-dropdown-item')) {
//                             child.style.display = "none";
//                         }
//                     }
//                 })
//             }

//             const openSideNav = function () {
//                 sideNavContainerEl.classList.add('side-nav-animatable');
//                 sideNavContainerEl.classList.add('side-nav-visible');
//                 sideNavContainerEl.addEventListener('transitionend', onTransitionEnd);
//             }

//             const closeSideNav = function () {
//                 sideNavContainerEl.classList.add('side-nav-animatable');
//                 sideNavContainerEl.classList.remove('side-nav-visible');
//                 sideNavContainerEl.addEventListener('transitionend', onTransitionEnd);
//             }

//             const addEventListeners = function () {
//                 if (showButtonEl) { showButtonEl.addEventListener('click', openSideNav); }
//                 if (closeButtonEl) { closeButtonEl.addEventListener('click', closeSideNav); }
//                 if (sideNavEl) { sideNavEl.addEventListener('click', blockClicks); }
//                 if (sideNavContainerEl) { sideNavContainerEl.addEventListener('click', closeSideNav); }
//                 initialiseDropdowns();
//                 if (dropdownIcons) { dropdownIcons.forEach(icon => icon.addEventListener('click', toggleDropdown)); }
//             }

//             addEventListeners();
//         }, 50);
//     }, [])

//     const toggleDropdownIcon = (target) => {
//         if (target.innerHTML === 'expand_more') {
//             target.innerHTML = 'expand_less';
//         }
//         else {
//             target.innerHTML = 'expand_more'
//         }
//     }

//     const toggleDropdownLinks = (target) => {
//         target.forEach(child => {
//             if (child.style.display === 'none') {
//                 child.style.display = '';
//             }
//             else {
//                 child.style.display = 'none';
//             }
//         });
//     }

//     const toggleDropdown = (e) => {
//         e.preventDefault();
//         e.stopPropagation();
//         toggleDropdownIcon(e.target);
//         const dropdownChildren = [...e.target.parentElement.parentElement.children]
//             .filter(child => { return child.classList.contains('navbar-dropdown-item') });
//         toggleDropdownLinks(dropdownChildren);
//     }
//     return (
//         <nav id="navbar">
//             <a className="js-menu-open menu-open">
//                 <i className="material-icons">menu</i>
//             </a>
//             <div id="main-logo-container" style={{ marginLeft: "40%" }}>
//                 <img src={logo} alt="Embedos Logo" id="mainLogo" style={{ width: "1.5rem", height: "1.5rem", marginRight: "0.7rem" }} />
//                 <strong>Embedos</strong>
//             </div>
//             <div className="js-side-nav-container side-nav-container">
//                 <div className="js-side-nav side-nav">
//                     <a id="menu-close-icon-container" className="js-menu-close menu-close">
//                         <i className="material-icons">close</i>
//                     </a>
//                     <div className="navbar-link-container">
//                         <i className="material-icons navbar-icon">home</i>
//                         <a href="/dashboard" className="js-menu-close menu-close navbar-link">
//                             Home
//                         </a>
//                     </div>
//                     <div className="navbar-link-container">
//                         <i className="material-icons navbar-icon">person</i>
//                         <a href="/user-profile" className="js-menu-close menu-close navbar-link">
//                             Profile
//                         </a>
//                     </div>
//                     <div className="navbar-link-container">
//                         <i className="material-icons navbar-icon">notes</i>
//                         <a href="/EventLog" className="js-menu-close menu-close navbar-link">
//                             Log
//                         </a>
//                     </div>

//                     <div className="navbar-dropdown-container">
//                         { 
//                             ( uruser[0]==='1' || uruserGroup[0]==='1' || uruserDashboard[0]==='1' ) && <div className="navbar-dropdown-header">
//                                 <i className="material-icons navbar-icon">manage_accounts</i>
//                                 <a href="/user_management" className="js-menu-close menu-close navbar-link">
//                                     User Management
//                                 </a>
//                                 <i className="material-icons navbar-dropdown-icon">expand_more</i>
//                             </div>
//                         }
//                         {
//                             (uruser[0]==='1' || uruser[1]==='1') && <div className="navbar-link-container navbar-dropdown-item">
//                                 <i className="material-icons navbar-icon">person</i>
//                                 <a href="/user_management/users" className="js-menu-close menu-close navbar-link">
//                                     Users
//                                 </a>
//                             </div>
//                         }
//                         {
//                             (uruserGroup[0]==='1' || uruserGroup[1]==='1') && <div className="navbar-link-container navbar-dropdown-item">
//                                 <i className="material-icons navbar-icon">people_alt</i>
//                                 <a href="/user_management/user_groups" className="js-menu-close menu-close navbar-link">
//                                     User Groups
//                                 </a>
//                             </div>
//                         }
//                         {
//                             (uruserDashboard.charAt(0)==='1' || uruserDashboard.charAt(1)==='1') && <div className="navbar-link-container navbar-dropdown-item">
//                                 <i className="material-icons navbar-icon">dashboard</i>
//                                 <a href="/user_management/dashboard_groups" className="js-menu-close menu-close navbar-link">
//                                     Dashboard Groups
//                                 </a>
//                             </div>
//                         }
//                     </div>
//                     {/* { uralarm.charAt(0)==='1' && <div className="navbar-link-container">
//                             <i className="material-icons navbar-icon">alarm</i>
//                             <a href="/alarms" className="js-menu-close menu-close navbar-link">
//                                 Alarms
//                             </a>
//                         </div>
//                     } */}
//                     {uralarm.charAt(0)==='1' && 
//                         <div className="navbar-dropdown-container">
//                             <div className="navbar-dropdown-header">
//                                 <i className="material-icons navbar-icon">alarm</i>
//                                 <a href="/alarms" className="js-menu-close menu-close navbar-link">
//                                     Alarms
//                                 </a>
//                                 {(uralarm.charAt(1)==='1' || uralarm.charAt(2)==='1' || uralarm.charAt(3)==='1')  && <i className="material-icons navbar-dropdown-icon">expand_more</i>}
//                             </div>
//                             { uralarm.charAt(1)==='1' &&
//                                 <div className="navbar-link-container navbar-dropdown-item">
//                                     <i className="material-icons navbar-icon">add_circle_outline</i>
//                                     <a href="/alarms/create" className="js-menu-close menu-close navbar-link">
//                                         Create
//                                     </a>
//                                 </div>
//                             }
//                             { uralarm.charAt(2)==='1' &&
//                                 <div className="navbar-link-container navbar-dropdown-item">
//                                     <i className="material-icons navbar-icon">edit</i>
//                                     <a href="/alarms/edit" className="js-menu-close menu-close navbar-link">
//                                         Edit
//                                     </a>
//                                 </div>
//                             }
//                             { uralarm.charAt(3)==='1' &&
//                                 <div className="navbar-link-container navbar-dropdown-item">
//                                     <i className="material-icons navbar-icon">delete</i>
//                                     <a href="/alarms/delete" className="js-menu-close menu-close navbar-link">
//                                         Delete
//                                     </a>
//                                 </div>
//                             }
//                         </div>
//                     }
//                     {urreport.charAt(0)==='1' && <div className="navbar-link-container">
//                             <i className="material-icons navbar-icon">description</i>
//                             <a href="/reports" className="js-menu-close menu-close navbar-link">
//                                 Reports
//                             </a>
//                         </div>
//                     }
//                     {urview.charAt(0)==='1' && <div className="navbar-dropdown-container">
//                         <div className="navbar-dropdown-header">
//                             <i className="material-icons navbar-icon">library_books</i>
//                             <a href="/views" className="js-menu-close menu-close navbar-link">
//                                 Views
//                             </a>
//                             {(urview.charAt(1)==='1' || urview.charAt(2)==='1' || urview.charAt(3)==='1')  && <i className="material-icons navbar-dropdown-icon">expand_more</i>}
//                         </div>
//                         { urview.charAt(1)==='1' &&
//                             <div className="navbar-link-container navbar-dropdown-item">
//                                 <i className="material-icons navbar-icon">add_circle_outline</i>
//                                 <a href="/views/create" className="js-menu-close menu-close navbar-link">
//                                     Create
//                                 </a>
//                             </div>
//                         }
//                         { urview.charAt(2)==='1' &&
//                             <div className="navbar-link-container navbar-dropdown-item">
//                                 <i className="material-icons navbar-icon">edit</i>
//                                 <a href="/views/edit" className="js-menu-close menu-close navbar-link">
//                                     Edit
//                                 </a>
//                             </div>
//                         }
//                         { urview.charAt(3)==='1' &&
//                             <div className="navbar-link-container navbar-dropdown-item">
//                                 <i className="material-icons navbar-icon">delete</i>
//                                 <a href="/views/delete" className="js-menu-close menu-close navbar-link">
//                                     Delete
//                                 </a>
//                             </div>
//                         }   
//                     </div>}
//                     {(ursource.charAt(0)==='1' || ursource.charAt(1)==='1' || ursource.charAt(2)==='1' || ursource.charAt(3)==='1') && <div className="navbar-link-container">
//                         <i className="material-icons navbar-icon">settings</i>
//                         <a href="/sensor_settings" className="js-menu-close menu-close navbar-link">
//                             Settings
//                         </a>
//                     </div>}
//                     {(urrole.charAt(1)==='1' ||  urrole.charAt(2)==='1' || urrole.charAt(3)==='1' || urrole.charAt(4)==='1') && 
//                     <div className="navbar-dropdown-container">
//                         <div className="navbar-dropdown-header">
//                             <i className="material-icons navbar-icon">supervisor_account</i>
//                             <a className="js-menu-close menu-close navbar-link">
//                                 Roles
//                             </a>
//                             {(urrole.charAt(1)==='1' || urrole.charAt(2)==='1' || urrole.charAt(3)==='1' || urrole.charAt(4)==='1') &&
//                             <i className="material-icons navbar-dropdown-icon">expand_more</i>}
//                         </div>
//                         { urrole.charAt(1)==='1' &&
//                             <div className="navbar-link-container navbar-dropdown-item">
//                             <i className="material-icons navbar-icon">add_circle_outline</i>
//                             <a href="/permissions/create" className="js-menu-close menu-close navbar-link">
//                                 Create Role
//                             </a>
//                         </div>}
//                         { urrole.charAt(2)==='1' &&
//                             <div className="navbar-link-container navbar-dropdown-item">
//                             <i className="material-icons navbar-icon">edit</i>
//                             <a href="/permissions/edit" className="js-menu-close menu-close navbar-link">
//                                 Edit Role
//                             </a>
//                         </div>}
//                         { urrole.charAt(3)==='1' &&
//                             <div className="navbar-link-container navbar-dropdown-item">
//                             <i className="material-icons navbar-icon">delete</i>
//                             <a href="/permissions/delete" className="js-menu-close menu-close navbar-link">
//                                 Delete Role
//                             </a>
//                         </div>}
//                         { urrole.charAt(4)==='1' &&
//                             <div className="navbar-link-container navbar-dropdown-item">
//                             <i className="material-icons navbar-icon">assignment</i>
//                             <a href="/permissions/assign" className="js-menu-close menu-close navbar-link">
//                                 Assign Role
//                             </a>
//                         </div>}
//                     </div>}
//                     { userstatus==='superuser' && 
//                     <div className="navbar-link-container">
//                         <i className="material-icons navbar-icon">verified_user</i>
//                         <a href="/superuser" className="js-menu-close menu-close navbar-link">
//                             SuperUser
//                         </a>
//                     </div>}
//                     {uruser[1]==='1' && <div className="navbar-link-container">
//                         <i className="material-icons navbar-icon">share</i>
//                         <a href="/invite-user" className="js-menu-close menu-close navbar-link">
//                             Invite user
//                         </a>
//                     </div>}
//                     {/* {context && */}
//                     <div className="navbar-link-container navbar-last-item">
//                         <i className="material-icons navbar-icon">exit_to_app</i>
//                         <a href="/logout" className="js-menu-close menu-close navbar-link">
//                             Logout
//                         </a>
//                     </div>
//                     {/* } */}
//                 </div>
//             </div>
//         </nav>
//     );
// });
// export default Navbar;

//---------------------------------------------------------------------------------NEW CHANGES--------------------------------------------------------------------------------------------------------------------------------

import React, { useEffect } from 'react';
import Cookie from 'universal-cookie';
import { decrypt } from './useCryptoJS';
import '../styles/navbar_styles.css'
const logo = require('../images/logo.png');

const Navbar = React.memo((props) => {
    const cookies = new Cookie();
    let uruser = "", uruserGroup = "", uruserDashboard = "", uralarm = "", urview = "", ursource = "", urreport = "", urrole = "", userstatus = "";
    try {
        uruser = cookies.get('uruser') !== undefined ? decrypt(cookies.get('uruser')) : "0000";
        uruserGroup = cookies.get('uruserGroup') !== undefined ? decrypt(cookies.get('uruserGroup')) : "0000";
        uruserDashboard = cookies.get('uruserDashboard') !== undefined ? decrypt(cookies.get('uruserDashboard')) : "0000";
        uralarm = cookies.get('uralarm') !== undefined ? decrypt(cookies.get('uralarm')) : "0000";
        urview = cookies.get('urview') !== undefined ? decrypt(cookies.get('urview')) : "0000";
        ursource = cookies.get('ursource') !== undefined ? decrypt(cookies.get('ursource')) : "0000";
        urreport = cookies.get('urreport') !== undefined ? decrypt(cookies.get('urreport')) : "0";
        urrole = cookies.get('urrole') !== undefined ? decrypt(cookies.get('urrole')) : "00000";
        userstatus = decrypt(cookies.get('userStatus'));
    } catch (error) { }
    useEffect(() => {
        setTimeout(() => {
            const sideNavEl = document.querySelector('.js-side-nav');
            const sideNavContainerEl = document.querySelector('.js-side-nav-container');
            const showButtonEl = document.querySelector('.js-menu-open');
            const closeButtonEl = document.querySelector('.js-menu-close');
            const dropdowns = document.querySelectorAll('.navbar-dropdown-container');
            const dropdownIcons = document.querySelectorAll('.navbar-dropdown-icon');

            const blockClicks = function (evt) {
                evt.stopPropagation();
            }

            const onTransitionEnd = function (evt) {
                sideNavContainerEl.classList.remove('side-nav-animatable');
                sideNavContainerEl.removeEventListener('transitionend', onTransitionEnd);
            }

            const initialiseDropdowns = function () {
                dropdowns.forEach(dropdown => {
                    // console.dir(dropdown.children);
                    for (let child of dropdown.children) {
                        if (child.classList.contains('navbar-dropdown-item')) {
                            child.style.display = "none";
                        }
                    }
                })
            }

            const openSideNav = function () {
                sideNavContainerEl.classList.add('side-nav-animatable');
                sideNavContainerEl.classList.add('side-nav-visible');
                sideNavContainerEl.addEventListener('transitionend', onTransitionEnd);
            }

            const closeSideNav = function () {
                sideNavContainerEl.classList.add('side-nav-animatable');
                sideNavContainerEl.classList.remove('side-nav-visible');
                sideNavContainerEl.addEventListener('transitionend', onTransitionEnd);
            }

            const addEventListeners = function () {
                if (showButtonEl) { showButtonEl.addEventListener('click', openSideNav); }
                if (closeButtonEl) { closeButtonEl.addEventListener('click', closeSideNav); }
                if (sideNavEl) { sideNavEl.addEventListener('click', blockClicks); }
                if (sideNavContainerEl) { sideNavContainerEl.addEventListener('click', closeSideNav); }
                initialiseDropdowns();
                if (dropdownIcons) { dropdownIcons.forEach(icon => icon.addEventListener('click', toggleDropdown)); }
            }

            addEventListeners();
        }, 50);
    }, [])

    const toggleDropdownIcon = (target) => {
        if (target.innerHTML === 'expand_more') {
            target.innerHTML = 'expand_less';
        }
        else {
            target.innerHTML = 'expand_more'
        }
    }

    const toggleDropdownLinks = (target) => {
        target.forEach(child => {
            if (child.style.display === 'none') {
                child.style.display = '';
            }
            else {
                child.style.display = 'none';
            }
        });
    }

    const toggleDropdown = (e) => {
        e.preventDefault();
        e.stopPropagation();
        toggleDropdownIcon(e.target);
        const dropdownChildren = [...e.target.parentElement.parentElement.children]
            .filter(child => { return child.classList.contains('navbar-dropdown-item') });
        toggleDropdownLinks(dropdownChildren);
    }
    return (
        <nav id="navbar">
            <div id='display-flex' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', position: 'relative' }}>
                <div style={{ flexShrink: 0 }}>
                    <a className="js-menu-open menu-open">
                        <i className="material-icons">menu</i>
                    </a>
                </div>

                <div id="main-logo-container" style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img src={logo} alt="Embedos Logo" id="mainLogo" style={{ width: "1.5rem", height: "1.5rem", marginRight: "0.7rem" }} />
                    <h3 style={{ margin: 0 }}><strong>Embedos</strong></h3>
                </div>

                <div style={{ flexShrink: 0, marginRight:'0.4rem' }}>
                    <h5 className="dashboard-name"><span>Dashboard: {props.newDashboard}</span></h5>
                </div>
            </div>
            <div className="js-side-nav-container side-nav-container">
                <div className="js-side-nav side-nav">
                    <a id="menu-close-icon-container" className="js-menu-close menu-close">
                        <i className="material-icons">close</i>
                    </a>
                    <div className="navbar-link-container">
                        <i className="material-icons navbar-icon">home</i>
                        <a href="/dashboard" className="js-menu-close menu-close navbar-link">
                            Home
                        </a>
                    </div>
                    <div className="navbar-link-container">
                        <i className="material-icons navbar-icon">person</i>
                        <a href="/user-profile" className="js-menu-close menu-close navbar-link">
                            Profile
                        </a>
                    </div>
                    <div className="navbar-link-container">
                        <i className="material-icons navbar-icon">notes</i>
                        <a href="/EventLog" className="js-menu-close menu-close navbar-link">
                            Log
                        </a>
                    </div>

                    <div className="navbar-dropdown-container">
                        {
                            (uruser[0] === '1' || uruserGroup[0] === '1' || uruserDashboard[0] === '1') && <div className="navbar-dropdown-header">
                                <i className="material-icons navbar-icon">manage_accounts</i>
                                <a href="/user_management" className="js-menu-close menu-close navbar-link">
                                    User Management
                                </a>
                                <i className="material-icons navbar-dropdown-icon">expand_more</i>
                            </div>
                        }
                        {
                            (uruser[0] === '1' || uruser[1] === '1') && <div className="navbar-link-container navbar-dropdown-item">
                                <i className="material-icons navbar-icon">person</i>
                                <a href="/user_management/users" className="js-menu-close menu-close navbar-link">
                                    Users
                                </a>
                            </div>
                        }
                        {
                            (uruserGroup[0] === '1' || uruserGroup[1] === '1') && <div className="navbar-link-container navbar-dropdown-item">
                                <i className="material-icons navbar-icon">people_alt</i>
                                <a href="/user_management/user_groups" className="js-menu-close menu-close navbar-link">
                                    User Groups
                                </a>
                            </div>
                        }
                        {
                            (uruserDashboard.charAt(0) === '1' || uruserDashboard.charAt(1) === '1') && <div className="navbar-link-container navbar-dropdown-item">
                                <i className="material-icons navbar-icon">dashboard</i>
                                <a href="/user_management/dashboard_groups" className="js-menu-close menu-close navbar-link">
                                    Dashboard Groups
                                </a>
                            </div>
                        }
                    </div>
                    {/* { uralarm.charAt(0)==='1' && <div className="navbar-link-container">
                            <i className="material-icons navbar-icon">alarm</i>
                            <a href="/alarms" className="js-menu-close menu-close navbar-link">
                                Alarms
                            </a>
                        </div>
                    } */}
                    {uralarm.charAt(0) === '1' &&
                        <div className="navbar-dropdown-container">
                            <div className="navbar-dropdown-header">
                                <i className="material-icons navbar-icon">alarm</i>
                                <a href="/alarms" className="js-menu-close menu-close navbar-link">
                                    Alarms
                                </a>
                                {(uralarm.charAt(1) === '1' || uralarm.charAt(2) === '1' || uralarm.charAt(3) === '1') && <i className="material-icons navbar-dropdown-icon">expand_more</i>}
                            </div>
                            {uralarm.charAt(1) === '1' &&
                                <div className="navbar-link-container navbar-dropdown-item">
                                    <i className="material-icons navbar-icon">add_circle_outline</i>
                                    <a href="/alarms/create" className="js-menu-close menu-close navbar-link">
                                        Create
                                    </a>
                                </div>
                            }
                            {uralarm.charAt(2) === '1' &&
                                <div className="navbar-link-container navbar-dropdown-item">
                                    <i className="material-icons navbar-icon">edit</i>
                                    <a href="/alarms/edit" className="js-menu-close menu-close navbar-link">
                                        Edit
                                    </a>
                                </div>
                            }
                            {uralarm.charAt(3) === '1' &&
                                <div className="navbar-link-container navbar-dropdown-item">
                                    <i className="material-icons navbar-icon">delete</i>
                                    <a href="/alarms/delete" className="js-menu-close menu-close navbar-link">
                                        Delete
                                    </a>
                                </div>
                            }
                        </div>
                    }
                    {urreport.charAt(0) === '1' && <div className="navbar-link-container">
                        <i className="material-icons navbar-icon">description</i>
                        <a href="/reports" className="js-menu-close menu-close navbar-link">
                            Reports
                        </a>
                    </div>
                    }
                    {urview.charAt(0) === '1' && <div className="navbar-dropdown-container">
                        <div className="navbar-dropdown-header">
                            <i className="material-icons navbar-icon">library_books</i>
                            <a href="/views" className="js-menu-close menu-close navbar-link">
                                Views
                            </a>
                            {(urview.charAt(1) === '1' || urview.charAt(2) === '1' || urview.charAt(3) === '1') && <i className="material-icons navbar-dropdown-icon">expand_more</i>}
                        </div>
                        {urview.charAt(1) === '1' &&
                            <div className="navbar-link-container navbar-dropdown-item">
                                <i className="material-icons navbar-icon">add_circle_outline</i>
                                <a href="/views/create" className="js-menu-close menu-close navbar-link">
                                    Create
                                </a>
                            </div>
                        }
                        {urview.charAt(2) === '1' &&
                            <div className="navbar-link-container navbar-dropdown-item">
                                <i className="material-icons navbar-icon">edit</i>
                                <a href="/views/edit" className="js-menu-close menu-close navbar-link">
                                    Edit
                                </a>
                            </div>
                        }
                        {urview.charAt(3) === '1' &&
                            <div className="navbar-link-container navbar-dropdown-item">
                                <i className="material-icons navbar-icon">delete</i>
                                <a href="/views/delete" className="js-menu-close menu-close navbar-link">
                                    Delete
                                </a>
                            </div>
                        }
                    </div>}
                    {(ursource.charAt(0) === '1' || ursource.charAt(1) === '1' || ursource.charAt(2) === '1' || ursource.charAt(3) === '1') && <div className="navbar-link-container">
                        <i className="material-icons navbar-icon">settings</i>
                        <a href="/sensor_settings" className="js-menu-close menu-close navbar-link">
                            Settings
                        </a>
                    </div>}
                    {(urrole.charAt(1) === '1' || urrole.charAt(2) === '1' || urrole.charAt(3) === '1' || urrole.charAt(4) === '1') &&
                        <div className="navbar-dropdown-container">
                            <div className="navbar-dropdown-header">
                                <i className="material-icons navbar-icon">supervisor_account</i>
                                <a className="js-menu-close menu-close navbar-link">
                                    Roles
                                </a>
                                {(urrole.charAt(1) === '1' || urrole.charAt(2) === '1' || urrole.charAt(3) === '1' || urrole.charAt(4) === '1') &&
                                    <i className="material-icons navbar-dropdown-icon">expand_more</i>}
                            </div>
                            {urrole.charAt(1) === '1' &&
                                <div className="navbar-link-container navbar-dropdown-item">
                                    <i className="material-icons navbar-icon">add_circle_outline</i>
                                    <a href="/permissions/create" className="js-menu-close menu-close navbar-link">
                                        Create Role
                                    </a>
                                </div>}
                            {urrole.charAt(2) === '1' &&
                                <div className="navbar-link-container navbar-dropdown-item">
                                    <i className="material-icons navbar-icon">edit</i>
                                    <a href="/permissions/edit" className="js-menu-close menu-close navbar-link">
                                        Edit Role
                                    </a>
                                </div>}
                            {urrole.charAt(3) === '1' &&
                                <div className="navbar-link-container navbar-dropdown-item">
                                    <i className="material-icons navbar-icon">delete</i>
                                    <a href="/permissions/delete" className="js-menu-close menu-close navbar-link">
                                        Delete Role
                                    </a>
                                </div>}
                            {urrole.charAt(4) === '1' &&
                                <div className="navbar-link-container navbar-dropdown-item">
                                    <i className="material-icons navbar-icon">assignment</i>
                                    <a href="/permissions/assign" className="js-menu-close menu-close navbar-link">
                                        Assign Role
                                    </a>
                                </div>}
                        </div>}
                    {userstatus === 'superuser' &&
                        <div className="navbar-link-container">
                            <i className="material-icons navbar-icon">verified_user</i>
                            <a href="/superuser" className="js-menu-close menu-close navbar-link">
                                SuperUser
                            </a>
                        </div>}
                    {uruser[1] === '1' && <div className="navbar-link-container">
                        <i className="material-icons navbar-icon">share</i>
                        <a href="/invite-user" className="js-menu-close menu-close navbar-link">
                            Invite user
                        </a>
                    </div>}
                    {/* {context && */}
                    <div className="navbar-link-container navbar-last-item">
                        <i className="material-icons navbar-icon">exit_to_app</i>
                        <a href="/logout" className="js-menu-close menu-close navbar-link">
                            Logout
                        </a>
                    </div>
                    {/* } */}
                </div>
            </div>
        </nav>
    );
});
export default Navbar;





