import React, { useEffect, useState } from 'react';
import { Grid, Paper, TextField, Button, FormControl, InputLabel, Input, InputAdornment, IconButton } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import config from '../config.json';
import swal from 'sweetalert';
import Cookies from 'universal-cookie';
import mixpanel from 'mixpanel-browser';


const RegisterUser = () => {
    const [isFormShow, setIsFormShow] = useState(false);
    const [username, setUsername] = useState('');
    const [fullname, setFullname] = useState('');
    const [contact, setContact] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const { token } = useParams();
    const cookies = new Cookies();

    useEffect(()=>{
        axios.post(config.BACKEND_URL + '/invite-user/is-token-valid', { token }).then(res => {
            if(res.status !== 200){
                window.location.replace("http://localhost:3003/");
            } else {
                setEmail(res.data.email);
                setIsFormShow(true);
            }
        }).catch(err => {
            window.location.replace("http://localhost:3003/");
        });
    }, []);
    
    const handleSubmit = (event) => {
        event.preventDefault();
        mixpanel.track("User Register Invite Submit Button Clicked", {});
        if (password === confirmPassword) {
            swal({
                title: "Are you sure?",
                text: "Do you want to register?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then(async (willDelete) => {
                if (willDelete) {
                    axios.post(config.BACKEND_URL + "/invite-user/create", { username, fullname, email, contact, password, token } ).then(res => {
                        if(res.status === 200){
                            swal("User created successfully!", "Check mail to authenticate", "success").then(() => window.location.replace('http://localhost:3003') )
                        } else {
                            swal("Error", `Process Failed ${res.data.message}`, "error");
                        }
                    }).catch(err => {
                        console.log(err);
                        swal("Error", err.response.data.message, "error");
                        console.log(err)
                    });
                } else {
                    swal("User not added!", "", "info");
                }
            });
        }
        else {
            swal("Password mismatch!", "Process failed. Try again.", "error")
        }
    };

    const handleUsernameChange = (event) => {
        setUsername(event.target.value);
    };

    const handleFullnameChange = (event) => {
        setFullname(event.target.value);
    };

    const handleContactChange = (event) => {
        setContact(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
    };

    const handleShowPasswordClick = () => {
        setShowPassword(!showPassword);
    };

    const handleShowConfirmPasswordClick = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    return (
        isFormShow ? <Grid container justifyContent="center">
            <Grid item xs={12} sm={8} md={6} lg={4}>
                <Paper style={{ padding: '16px' }}>
                    <form onSubmit={handleSubmit}>
                        <TextField label="Username" variant="outlined" margin="normal" fullWidth required value={username} onChange={handleUsernameChange} />
                        <TextField label="Full Name" variant="outlined" margin="normal" fullWidth required value={fullname} onChange={handleFullnameChange} />
                        <TextField label="Contact" variant="outlined" margin="normal" fullWidth required value={contact} onChange={handleContactChange} />
                        <TextField label="Email" variant="outlined" margin="normal" fullWidth required type="email" value={email} disabled />
                        <FormControl variant="outlined" margin="normal" fullWidth required>
                            <InputLabel>Password</InputLabel>
                            <Input type={showPassword ? 'text' : 'password'} value={password} onChange={handlePasswordChange}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleShowPasswordClick}> {showPassword ? <VisibilityOff /> : <Visibility />} </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                        <FormControl variant="outlined" margin="normal" fullWidth required>
                            <InputLabel>Confirm Password</InputLabel>
                            <Input type={showConfirmPassword ? 'text' : 'password'} value={confirmPassword} onChange={handleConfirmPasswordChange}
                                endAdornment={
                                <InputAdornment position="end">
                                    <IconButton onClick={handleShowConfirmPasswordClick}> {showConfirmPassword ? <VisibilityOff /> : <Visibility />} </IconButton>
                                </InputAdornment>
                                }
                            />
                        </FormControl>
                        <Button type="submit" variant="contained" color="primary" fullWidth style={{ marginTop: '16px' }} > Register </Button>
                    </form>
                </Paper>
            </Grid>
        </Grid> :
        <h1 style={{textAlign: 'center', color: '#000'}}>Loading...</h1>
    );
}

export default RegisterUser;