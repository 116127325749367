import React, { useState } from "react"
import '../styles/user_management_styles.css'
import Navbar from "./Navbar.js"
import 'bootstrap/dist/css/bootstrap.min.css';
import useFetch from './useFetch.js';
import swal from "sweetalert";
import axios from "axios";
import MultiSelect from './MultiSelect.js';
import config from '../config.json';
import Cookie from 'universal-cookie';
import mixpanel from "mixpanel-browser";

const Views_Delete = () => {
    const cookies = new Cookie();
    let userToken = "";
    let actionInitiatingUser = -1;
    try {
        userToken = cookies.get('userToken');
        actionInitiatingUser = parseInt(cookies.get('userID'));
    } catch (error) {}
    const headers = {
        'Authorization': `Bearer ${userToken}`,
        'Content-Type': 'application/json'
    };
    const { data: views, isPending: isPendingViews, error: errorViews } = useFetch({ actionInitiatingUser: { userID: actionInitiatingUser.toString() }, URL: config.BACKEND_URL + '/views' });

    let selectedViews = [];
    const handleDelete = (e) => {
        e.preventDefault();
        mixpanel.track("View Delete Button Clicked", { userID: cookies.get('userID') });
        if (selectedViews.length === 0) {
            swal("View not selected!", "Select a view before it can be deleted.", "error");
        }
        else {
            swal({
                title: `Are you sure?`,
                text: "Are you sure you want to delete the selected views?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then(async (willDelete) => {
                    if (willDelete) {
                        for (let id of selectedViews) {
                            try {
                                await axios.delete(config.BACKEND_URL + `/views/${id}/${actionInitiatingUser}`, { headers });
                            }
                            catch (err) {
                                console.log(err);
                            }
                        }
                        swal("Views deleted successfully!", "Click on OK to continue...", "success").then(() => {
                            window.location.reload();
                        });
                    }
                    else {
                        swal("Cancelled!", "The selected view has not been deleted!", "error");
                    }
                });
        }
    } 
    const handleViewsSelections = (selected) => {
        selectedViews = selected.map(view => view.value);
    }

    return (
        <div className="Views">
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <Navbar />
            <main className="container-fluid">
                <section className="d-flex row justify-content-center" style={{ padding: "1vw" }}>
                    <header className="h1 text-center mb-2">Delete View</header>
                    <div className="h3 text-center mb-2">Select the view you wish to delete.</div>
                    <section className="selectDateRange row d-flex justify-content-center mb-3" style={{ width: "100%" }}>
                        <form id="deleteForm" className="row col-sm-9 d-flex justify-content-center" onSubmit={handleDelete}>
                            <section /*className="d-flex justify-content-center col-sm-12"*/>
                                <div className="d-flex py-2 px-5 justify-content-center mb-3">
                                    {isPendingViews && <div className="text-center h4 mb-3">Loading...</div>}
                                    {errorViews && <div className="text-center h4 mb-3">Cannot fetch all the views right now!</div>}
                                    {
                                        (views && views.length > 0 &&
                                            <MultiSelect
                                                handleChange={handleViewsSelections}
                                                data={views.map(view => { return { value: view.id, label: view.name } })}
                                                defaultSelected={[]} />) ||
                                        (views && views.length === 0 &&
                                            <div className="text-center h4 mb-3">Currently, there are no views!</div>)
                                    }
                                </div>
                                {views && views.length > 0 && <section className="d-flex justify-content-center">
                                    <button className="btn btn-danger" type="submit">Delete</button>
                                </section>}

                            </section>
                        </form>
                    </section>
                </section>
            </main >
        </div >
    );
}

export default Views_Delete;