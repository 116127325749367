import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState } from 'react';
import { TabulatorFull as Tabulator } from "tabulator-tables";
import "tabulator-tables/dist/css/tabulator.min.css";
import axios from 'axios'
import swal from 'sweetalert';
import config from '../../config.json';
import $ from 'jquery';
import Cookies from 'universal-cookie';
import { Button, Dialog, DialogContent } from '@material-ui/core';
import mixpanel from 'mixpanel-browser';

var selectedData = [];
var table;
const cookies = new Cookies();
const userToken = cookies.get('userToken');
const headers = {
  'Authorization': `Bearer ${userToken}`,
  'Content-Type': 'application/json'
};

const Delete = async (e) => {
  mixpanel.track("User Page Delete Button Clicked", { userID: cookies.get('userID') });
  const actionInitiatingUser = parseInt(cookies.get('userID'));
  if (selectedData == null) {
    swal("No User Selected", "Process Failed", "error")
  }
  else {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover these users!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          axios.post(config.BACKEND_URL + "/users/delete-users", {
            "IDs": selectedData,
            actionInitiatingUser
          }, { headers }).then(res => {
            swal("Users Deleted", "Process Successful", "success").then(() => {
              window.location.reload();
            });
          }).catch(err => {
            swal("Users Not Deleted!", "Process Failed", "error");
            console.log(err);
          })
        }
        else {
          swal("Users Not Deleted!", "Action cancelled.", "info");
        }
      });
  }
}

async function fetchData() {
  let Users = [];
  
  axios.get(config.BACKEND_URL + "/users", { headers })
    .then(res => {
      Users = res.data.data;
      table.setData(Users);
    })
    .catch(err => {
      console.log(err);
    })
}

function UserTable({ setSelectedUser, edit, deletee }) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState("");

  const handleClose = () => {
    setDialogOpen(false);
  };
  
  const handleClick = async (e, cell, data) => {
    setDialogOpen(true);
    await axios.post(config.BACKEND_URL + '/users/get-role-by-id', {id: data.id}, { headers }).then(res => {
      data = {...data, role: res.data.role, fullname: res.data.fullname};
    }).catch(err => {
      console.log(err);
    })
    console.log(data);
    const message = `Name: ${data.fullname}\nEmail: ${data.email}\nContact: ${data.contact}\nRole: ${!data.role ? "NA": data.role}`;
    setDialogContent(message);
  };
  const buttonFormatter = () => {
    return "<Button variant='outlined' color='primary'>Details</Button>";
  };
  const columns = [
    { formatter: "rownum", align: "center" },
    { title: "Username", field: "name", headerFilter:"input", width: "40%" },
    {
      title: "Information",
      width: "40%",
      formatter: buttonFormatter,
      cellClick: function(e, cell) {
        e.stopPropagation();
        if(cell.getColumn()._column.definition.title === 'Information'){
          cell.getRow().getElement().classList.remove("tabulator-selected");
        }
        handleClick(e, cell, cell.getRow().getData());
        table.deselectRow();
      },
    }
  ];
  useEffect(() => {
    table = new Tabulator("#user-table", {
      rowClick: function (e, row) {
        alert("Row " + row.getData().name + " Clicked!!!!");
      },
      layout: "fitColumns",
      columns: columns,
      pagination: "local",
      paginationSize: 10,
      paginationSizeSelector: [25, 50, 75, 100],
      tooltips: true,
      responsiveLayout: true,
      selectableRows: true
    });

    table.on('tableBuilt', fetchData);
    table.on('rowSelectionChanged', () => {
      selectedData = table.getSelectedData().map(user => user.id);
      try {
        setSelectedUser(selectedData);
      } catch (error) {}
    });
  }, []);

  const Edit = async (e) =>{
    mixpanel.track("User Page Edit Button Clicked", { userID: cookies.get('userID') });
    if(selectedData.length != 1){
      swal("Cannot edit row", "Please select only one row to edit", "error");
      return;
    } else {
      axios.get(config.BACKEND_URL + `/users/${selectedData[0]}`, { headers }).then(res => {
        $('#username')[0].value = res.data.name;
        $('#useremail')[0].value = res.data.email;
        $('#usercontact')[0].value = res.data.contact;
        $('#userfullname')[0].value = res.data.fullname;
        document.querySelector('#usersubmit').innerText = 'Update';
      }).catch(err => {
        console.log(err);
      })
    }
  }

  return (
    <>
      <div className="UserTable">
        <div style={{ 'padding': '25px' }}>
          <div id="user-table"></div>
        </div>
      </div>
      <div>
        {deletee && <button className="btn btn-danger mx-4" type="delete" onClick={Delete}>  Delete </button>}
        {edit && <button className="btn btn-danger" type="delete" onClick={Edit}>  Edit </button>}
      </div>
      <Dialog open={dialogOpen} onClose={handleClose} aria-labelledby="information-dialog-title">
        <DialogContent> 
          <div style={{ whiteSpace: "pre-line" }}>
            {dialogContent.split("\n").map((line, i) => {
              const [key, value] = line.split(":");
              return (
                <div key={i} style={{fontSize: '1.2rem'}}>
                  <strong>{key}:</strong> {value}
                </div>
              );
            })}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default UserTable;