import React from 'react';
import { Line } from 'react-chartjs-2';
import 'chartjs-plugin-zoom';
import { Chart as ChartJS, registerables } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
// import Cookie  from 'universal-cookie';

ChartJS.register(...registerables, zoomPlugin);

const LineChart = (props) => {
    // const cookies = new Cookie();
    const chartRef = React.useRef(null);

    const handleResetZoom = () => {
        if (chartRef.current) {
            chartRef.current.resetZoom();
        }
    };

    return (
        <div>
            <button className='btn btn-primary' onClick={handleResetZoom} onTouchStart = {props.onTouchStart} onMouseDown={props.onMouseDown}>Reset Zoom</button>
            <Line
                ref={chartRef}
                data={props.chartData}
                options={{
                    plugins: {
                        title: {
                            display: true,
                            text: "Data"
                        },
                        legend: {
                            display: true,
                            position: "bottom"
                        },
                        tooltip: {
                            
                            callbacks: {
                                label: function(tooltipItem) {
                                    return `Value: ${tooltipItem.raw}`;
                                }
                            }
                        },
                        zoom: {
                            zoom: {
                                wheel: {
                                    enabled: true,
                                },
                                pinch: {
                                    enabled: true
                                },
                                mode: 'x',
                            },
                            pan: {
                                enabled: true,
                                mode: 'x',
                            }
                        }
                    }
                }}
            />
        </div>
    );
};

export default LineChart;
