import React, { useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Navbar from "./Navbar";
import { BsListCheck, BsFillEyeFill, } from 'react-icons/bs';
import { IoCreate } from 'react-icons/io5';
import { AiFillEdit } from 'react-icons/ai';
import { RiDeleteBin2Fill } from 'react-icons/ri';
import { MdAssignmentInd } from 'react-icons/md';
import { decrypt } from './useCryptoJS';
import { handleChangea, handleChanger, handleChangerl, handleChanges, handleChangeu, handleChangeud, handleChangeug, handleChangev, submitCreate, handleChangeda } from "../functions/permissions";
import MuiAlert from '@material-ui/lab/Alert';
import { Snackbar } from '@material-ui/core';
import Cookies from 'universal-cookie';


// let selectedUserIDs = [];
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexWrap:'wrap',
      color: '#fff'
    },
    formControl: {
      margin: theme.spacing(2),
    },
    formLabel: {
      color:'rgb(99 249 200)',
      '&.focused': {
        color: 'white'
      }
    },
    checkbox:{
      color: "#fff",
      '&.Mui-checked': {
        color: "#fff",
      },
    },
    tableRoot: {
      fontSize: "20px",
      fontWeight: "700",
      borderRadius: '16px',
      border: '3px solid #464646',
    },
    tHeader: {
      border: '3px solid #464646',
      background: '#000',
      color: '#fff',
    },
    tSubPermissions: {
      display: 'flex', justifyContent: 'space-between', padding: '0px 16px'
    },
    tDarkBackground: {
      background: 'rgb(30 30 30)',
      color: 'rgb(223 223 223)',
    },
    tLightBackground: {
      background: "rgb(82 82 82)",
      color: "rgb(231, 231, 231)",
    },
    tNameColCenter: {
      borderRight: '3px solid #464646', padding: '0px 16px'
    },
    tPerColAlign: {
      display: 'flex', justifyContent: 'space-between', padding: '0px 16px'
    },
}));

const PermissionsCreate = () => {
  const classes = useStyles();
  const cookies = new Cookies();
  let uruser = "0000", uruserGroup = "0000", uruserDashboard = "0000", uralarm = "0000", urview = "0000", ursource = "0000", urreport = "0", urrole = "00000", urdashboard = "0000";
  let actionInitiatingUser = -1;
    try {
        uruser = decrypt(cookies.get('uruser'));
        uruserGroup = decrypt(cookies.get('uruserGroup'));
        uruserDashboard = decrypt(cookies.get('uruserDashboard'));
        uralarm = decrypt(cookies.get('uralarm'));
        urview = decrypt(cookies.get('urview'));
        ursource = decrypt(cookies.get('ursource'));
        urreport = decrypt(cookies.get('urreport'));
        urrole = decrypt(cookies.get('urrole'));
        urdashboard = decrypt(cookies.get('urdashboard'));
        actionInitiatingUser = parseInt(cookies.get('userID'));
    } catch (error) {}
  const [user, setUser] = React.useState({
    allu: false,
    viewu: false,
    createu: false,
    editu: false,
    deleteu: false,
  });
  const [userGroup, setUserGroup] = React.useState({
    allug: false,
    viewug: false,
    createug: false,
    editug: false,
      deleteug: false,
  });
  const [userDash, setUserDash] = React.useState({
      allud: false,
      viewud: false,
      createud: false,
      editud: false,
      deleteud: false,
  });
  const [alarm, setAlarm] = React.useState({
      alla: false,
      viewa: false,
      createa: false,
      edita: false,
      deletea: false,
  });
  const [view, setView] = React.useState({
      allv: false,
      viewv: false,
      createv: false,
      editv: false,
      deletev: false,
  });
  const [source, setSource] = React.useState({
      alls: false,
      views: false,
      creates: false,
      edits: false,
      deletes: false,
  });
  const [role, setRole] = React.useState({
    allrl: false,
    viewrl: true,
    createrl: false,
    editrl: false,
    deleterl: false,
    assignrl: false,
  });
  const [dashboard, setDashboard] = React.useState({
    alld: false,
    viewd: false,
    created: false,
    editd: false,
    deleted: false,
  });
  const [report, setReport] = React.useState({
    allr: false,
    viewr: false,
  });
  const [roleName, setRoleName] = React.useState("");
  
  const { allu, viewu, createu, editu, deleteu } = user;
  const { allug, viewug, createug, editug, deleteug } = userGroup;
  const { allud, viewud, createud, editud, deleteud } = userDash;
  const { alla, viewa, createa, edita, deletea } = alarm;
  const { allv, viewv, createv, editv, deletev } = view;
  const { alls, views, creates, edits, deletes } = source;
  const { allrl, createrl, editrl, deleterl, assignrl } = role;
  const { alld, viewd, created, editd, deleted } = dashboard;
  const { allr, viewr } = report;

  const [openSnack, setOpenSnack] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') return;
    setOpenSnack(false);
  };

  return (
    <>
      <div className="Reports">
        <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
        <script src='bootstrap/dist/js/bootstrap.min.js'></script>
        <Navbar />
        <main className="container-fluid" style={{ display: 'flex', justifyContent: 'space-around', paddingTop: '2rem' }}>
          <div className="container">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-8 col-md-12 d-flex justify-content-center">
                <table className={classes.tableRoot}>
                  <tr className={classes.tHeader}>
                      <th className={classes.tNameColCenter}>Features</th>
                      <th style={{padding: 0}}> Permissions
                          <table>
                              <tr className={classes.tSubPermissions} style={{marginBottom: '0.5rem'}}>
                                  <th>All &nbsp; <BsListCheck /></th>
                                  <th>View &nbsp; <BsFillEyeFill /></th>
                                  <th>Create &nbsp; <IoCreate /></th>
                                  <th>Edit &nbsp; <AiFillEdit /></th>
                                  <th>Delete &nbsp; <RiDeleteBin2Fill /></th>
                                  <th>Assign &nbsp; <MdAssignmentInd /></th>
                              </tr>
                          </table>
                      </th>
                  </tr>
                  { (uruserDashboard.charAt(0)==='1' || uruserDashboard.charAt(1)==='1' || uruserDashboard.charAt(2)==='1' || uruserDashboard.charAt(3)==='1') && 
                    <tr className={classes.tDarkBackground}>
                      <td className={classes.tNameColCenter}> User Dashboard </td>
                      <td className={classes.tPerColAlign}> 
                        <td><FormControlLabel control={<Checkbox checked={allud} onChange={(e)=>handleChangeud(e, viewud, createud, editud, deleteud, setUserDash, userDash, setUserGroup, userGroup, setUser, user, role, alarm, setOpenSnack, setAlertMsg)} name="allud" className={classes.checkbox} />} /></td>
                        <td><FormControlLabel control={<Checkbox disabled={uruserDashboard.charAt(0)==='0'} checked={viewud} onChange={(e)=>handleChangeud(e, viewud, createud, editud, deleteud, setUserDash, userDash, setUserGroup, userGroup, setUser, user, role, alarm, setOpenSnack, setAlertMsg)} name="viewud" className={classes.checkbox} />} /></td>
                        <td><FormControlLabel control={<Checkbox disabled={uruserDashboard.charAt(1)==='0'} checked={createud} onChange={(e)=>handleChangeud(e, viewud, createud, editud, deleteud, setUserDash, userDash, setUserGroup, userGroup, setUser, user, role, alarm, setOpenSnack, setAlertMsg)} name="createud" className={classes.checkbox} />} /></td>
                        <td><FormControlLabel control={<Checkbox disabled={uruserDashboard.charAt(2)==='0'} checked={editud} onChange={(e)=>handleChangeud(e, viewud, createud, editud, deleteud, setUserDash, userDash, setUserGroup, userGroup, setUser, user, role, alarm, setOpenSnack, setAlertMsg)} name="editud" className={classes.checkbox} />} /></td>
                        <td><FormControlLabel control={<Checkbox disabled={uruserDashboard.charAt(3)==='0'} checked={deleteud} onChange={(e)=>handleChangeud(e, viewud, createud, editud, deleteud, setUserDash, userDash, setUserGroup, userGroup, setUser, user, role, alarm, setOpenSnack, setAlertMsg)} name="deleteud" className={classes.checkbox} />} /></td>
                        <td><FormControlLabel control={<Checkbox checked={false} disabled className={classes.checkbox} />} /></td>
                      </td>
                  </tr>}
                  { (uruserGroup.charAt(0)==='1' || uruserGroup.charAt(1)==='1' || uruserGroup.charAt(2)==='1' || uruserGroup.charAt(3)==='1') && 
                    <tr className={classes.tLightBackground}>
                      <td className={classes.tNameColCenter}> User Group </td>
                      <td className={classes.tPerColAlign}> 
                        <td><FormControlLabel control={<Checkbox checked={allug} onChange={(e)=>handleChangeug(e, viewug, createug, editug, deleteug, setUserGroup, userGroup, setUser, user, role, alarm, userDash, setOpenSnack, setAlertMsg)} name="allug" className={classes.checkbox} />} /></td>
                        <td><FormControlLabel control={<Checkbox disabled={uruserGroup.charAt(0)==='0'} checked={viewug} onChange={(e)=>handleChangeug(e, viewug, createug, editug, deleteug, setUserGroup, userGroup, setUser, user, role, alarm, userDash, setOpenSnack, setAlertMsg)} name="viewug" className={classes.checkbox} />} /></td>
                        <td><FormControlLabel control={<Checkbox disabled={uruserGroup.charAt(1)==='0'} checked={createug} onChange={(e)=>handleChangeug(e, viewug, createug, editug, deleteug, setUserGroup, userGroup, setUser, user, role, alarm, userDash, setOpenSnack, setAlertMsg)} name="createug" className={classes.checkbox} />} /></td>
                        <td><FormControlLabel control={<Checkbox disabled={uruserGroup.charAt(2)==='0'} checked={editug} onChange={(e)=>handleChangeug(e, viewug, createug, editug, deleteug, setUserGroup, userGroup, setUser, user, role, alarm, userDash, setOpenSnack, setAlertMsg)} name="editug" className={classes.checkbox} />} /></td>
                        <td><FormControlLabel control={<Checkbox disabled={uruserGroup.charAt(3)==='0'} checked={deleteug} onChange={(e)=>handleChangeug(e, viewug, createug, editug, deleteug, setUserGroup, userGroup, setUser, user, role, alarm, userDash, setOpenSnack, setAlertMsg)} name="deleteug" className={classes.checkbox} />} /></td>
                        <td><FormControlLabel control={<Checkbox checked={false} disabled className={classes.checkbox} />} /></td>
                      </td>
                  </tr>}
                  { (uruser.charAt(0)==='1' || uruser.charAt(1)==='1' || uruser.charAt(2)==='1' || uruser.charAt(3)==='1') && 
                    <tr className={classes.tDarkBackground}>
                      <td className={classes.tNameColCenter}> User </td>
                      <td className={classes.tPerColAlign}> 
                        <td><FormControlLabel control={<Checkbox checked={allu} onChange={(e)=>handleChangeu(e, viewu, createu, editu, deleteu, setUser, user, role, userGroup, userDash, alarm, setOpenSnack, setAlertMsg)} name="allu" className={classes.checkbox} />} /></td>
                        <td><FormControlLabel control={<Checkbox disabled={uruser.charAt(0)==='0'} checked={viewu} onChange={(e)=>handleChangeu(e, viewu, createu, editu, deleteu, setUser, user, role, userGroup, userDash, alarm, setOpenSnack, setAlertMsg)} name="viewu" className={classes.checkbox} />} /></td>
                        <td><FormControlLabel control={<Checkbox disabled={uruser.charAt(1)==='0'} checked={createu} onChange={(e)=>handleChangeu(e, viewu, createu, editu, deleteu, setUser, user, role, userGroup, userDash, alarm, setOpenSnack, setAlertMsg)} name="createu" className={classes.checkbox} />} /></td>
                        <td><FormControlLabel control={<Checkbox disabled={uruser.charAt(2)==='0'} checked={editu} onChange={(e)=>handleChangeu(e, viewu, createu, editu, deleteu, setUser, user, role, userGroup, userDash, alarm, setOpenSnack, setAlertMsg)} name="editu" className={classes.checkbox} />} /></td>
                        <td><FormControlLabel control={<Checkbox disabled={uruser.charAt(3)==='0'} checked={deleteu} onChange={(e)=>handleChangeu(e, viewu, createu, editu, deleteu, setUser, user, role, userGroup, userDash, alarm, setOpenSnack, setAlertMsg)} name="deleteu" className={classes.checkbox} />} /></td>
                        <td><FormControlLabel control={<Checkbox checked={false} disabled className={classes.checkbox} />} /></td>
                      </td>
                  </tr> }
                  { (uralarm.charAt(0)==='1' || uralarm.charAt(1)==='1' || uralarm.charAt(2)==='1' || uralarm.charAt(3)==='1') && 
                    <tr className={classes.tLightBackground}>
                      <td className={classes.tNameColCenter}> Alarm </td>
                      <td className={classes.tPerColAlign}> 
                        <td><FormControlLabel control={<Checkbox checked={alla} onChange={(e)=> handleChangea(e, viewa, createa, edita, deletea, setAlarm, alarm, setSource, source, setUserGroup, userGroup, setUser, user, role, userDash, view, setOpenSnack, setAlertMsg)} name="alla" className={classes.checkbox} />} /></td>
                        <td><FormControlLabel control={<Checkbox disabled={uralarm.charAt(0)==='0'} checked={viewa} onChange={(e)=> handleChangea(e, viewa, createa, edita, deletea, setAlarm, alarm, setSource, source, setUserGroup, userGroup, setUser, user, role, userDash, view, setOpenSnack, setAlertMsg)} name="viewa" className={classes.checkbox} />} /></td>
                        <td><FormControlLabel control={<Checkbox disabled={uralarm.charAt(1)==='0'} checked={createa} onChange={(e)=> handleChangea(e, viewa, createa, edita, deletea, setAlarm, alarm, setSource, source, setUserGroup, userGroup, setUser, user, role, userDash, view, setOpenSnack, setAlertMsg)} name="createa" className={classes.checkbox} />} /></td>
                        <td><FormControlLabel control={<Checkbox disabled={uralarm.charAt(2)==='0'} checked={edita} onChange={(e)=> handleChangea(e, viewa, createa, edita, deletea, setAlarm, alarm, setSource, source, setUserGroup, userGroup, setUser, user, role, userDash, view, setOpenSnack, setAlertMsg)} name="edita" className={classes.checkbox} />} /></td>
                        <td><FormControlLabel control={<Checkbox disabled={uralarm.charAt(3)==='0'} checked={deletea} onChange={(e)=> handleChangea(e, viewa, createa, edita, deletea, setAlarm, alarm, setSource, source, setUserGroup, userGroup, setUser, user, role, userDash, view, setOpenSnack, setAlertMsg)} name="deletea" className={classes.checkbox} />} /></td>
                        <td><FormControlLabel control={<Checkbox checked={false} disabled className={classes.checkbox} />} /></td>
                      </td>
                  </tr>}
                  { (urview.charAt(0)==='1' || urview.charAt(1)==='1' || urview.charAt(2)==='1' || urview.charAt(3)==='1') && 
                    <tr className={classes.tDarkBackground}>
                      <td className={classes.tNameColCenter}> View </td>
                      <td className={classes.tPerColAlign}> 
                        <td><FormControlLabel control={<Checkbox checked={allv} onChange={(e)=>handleChangev(e, viewv, createv, editv, deletev, setView, view, setSource, source, alarm, setOpenSnack, setAlertMsg)} name="allv" className={classes.checkbox} />} /></td>
                        <td><FormControlLabel control={<Checkbox disabled={urview.charAt(0)==='0'} checked={viewv} onChange={(e)=>handleChangev(e, viewv, createv, editv, deletev, setView, view, setSource, source, alarm,  setOpenSnack, setAlertMsg)} name="viewv" className={classes.checkbox} />} /></td>
                        <td><FormControlLabel control={<Checkbox disabled={urview.charAt(1)==='0'} checked={createv} onChange={(e)=>handleChangev(e, viewv, createv, editv, deletev, setView, view, setSource, source, alarm, setOpenSnack, setAlertMsg)} name="createv" className={classes.checkbox} />} /></td>
                        <td><FormControlLabel control={<Checkbox disabled={urview.charAt(2)==='0'} checked={editv} onChange={(e)=>handleChangev(e, viewv, createv, editv, deletev, setView, view, setSource, source, alarm, setOpenSnack, setAlertMsg)} name="editv" className={classes.checkbox} />} /></td>
                        <td><FormControlLabel control={<Checkbox disabled={urview.charAt(3)==='0'} checked={deletev} onChange={(e)=>handleChangev(e, viewv, createv, editv, deletev, setView, view, setSource, source, alarm,  setOpenSnack, setAlertMsg)} name="deletev" className={classes.checkbox} />} /></td>
                        <td><FormControlLabel control={<Checkbox checked={false} disabled className={classes.checkbox} />} /></td>
                      </td>
                  </tr>}
                  { (ursource.charAt(0)==='1' || ursource.charAt(1)==='1' || ursource.charAt(2)==='1' || ursource.charAt(3)==='1') && 
                    <tr className={classes.tLightBackground}>
                      <td className={classes.tNameColCenter}> Source </td>
                      <td className={classes.tPerColAlign}> 
                        <td><FormControlLabel control={<Checkbox checked={alls} onChange={(e)=>handleChanges(e, views, creates, edits, deletes, setSource, source, view, alarm, setOpenSnack, setAlertMsg)} name="alls" className={classes.checkbox} />} /></td>
                        <td><FormControlLabel control={<Checkbox disabled={ursource.charAt(0)==='0'} checked={views} onChange={(e)=>handleChanges(e, views, creates, edits, deletes, setSource, source, view, alarm, setOpenSnack, setAlertMsg)} name="views" className={classes.checkbox} />} /></td>
                        <td><FormControlLabel control={<Checkbox disabled={ursource.charAt(1)==='0'} checked={creates} onChange={(e)=>handleChanges(e, views, creates, edits, deletes, setSource, source, view, alarm, setOpenSnack, setAlertMsg)} name="creates" className={classes.checkbox} />} /></td>
                        <td><FormControlLabel control={<Checkbox disabled={ursource.charAt(2)==='0'} checked={edits} onChange={(e)=>handleChanges(e, views, creates, edits, deletes, setSource, source, view, alarm, setOpenSnack, setAlertMsg)} name="edits" className={classes.checkbox} />} /></td>
                        <td><FormControlLabel control={<Checkbox disabled={ursource.charAt(3)==='0'} checked={deletes} onChange={(e)=>handleChanges(e, views, creates, edits, deletes, setSource, source, view, alarm, setOpenSnack, setAlertMsg)} name="deletes" className={classes.checkbox} />} /></td>
                        <td><FormControlLabel control={<Checkbox checked={false} disabled className={classes.checkbox}/>} /></td>
                      </td>
                  </tr>}
                  { (urdashboard.charAt(0)==='1' || urdashboard.charAt(1)==='1' || urdashboard.charAt(3)==='1') && 
                    <tr className={classes.tDarkBackground}>
                      <td className={classes.tNameColCenter}> Dashboard </td>
                      <td className={classes.tPerColAlign}> 
                        <td><FormControlLabel control={<Checkbox checked={alld} onChange={(e)=> handleChangeda(e, viewd, created, deleted, dashboard, setDashboard, setOpenSnack, setAlertMsg)} name="alld" className={classes.checkbox} />} /></td>
                        <td><FormControlLabel control={<Checkbox disabled={urdashboard.charAt(0)==='0'} checked={viewd} onChange={(e)=> handleChangeda(e, viewd, created, deleted, dashboard, setDashboard, setOpenSnack, setAlertMsg)} name="viewd" className={classes.checkbox} />} /></td>
                        <td><FormControlLabel control={<Checkbox disabled={urdashboard.charAt(1)==='0'} checked={created} onChange={(e)=> handleChangeda(e, viewd, created, deleted, dashboard, setDashboard, setOpenSnack, setAlertMsg)} name="created" className={classes.checkbox} />} /></td>
                        <td><FormControlLabel control={<Checkbox disabled={true} checked={editd} onChange={(e)=> handleChangeda(e, viewd, created, deleted, dashboard, setDashboard, setOpenSnack, setAlertMsg)} name="editd" className={classes.checkbox} />} /></td>
                        <td><FormControlLabel control={<Checkbox disabled={urdashboard.charAt(3)==='0'} checked={deleted} onChange={(e)=> handleChangeda(e, viewd, created, deleted, dashboard, setDashboard, setOpenSnack, setAlertMsg)} name="deleted" className={classes.checkbox} />} /></td>
                        <td><FormControlLabel control={<Checkbox checked={false} disabled className={classes.checkbox}/>} /></td>
                      </td>
                  </tr>}
                  { (urrole.charAt(0)==='1' || urrole.charAt(1)==='1' || urrole.charAt(2)==='1' || urrole.charAt(3)==='1') && 
                    <tr className={classes.tLightBackground}>
                      <td className={classes.tNameColCenter}> Roles </td>
                      <td className={classes.tPerColAlign}> 
                        <td><FormControlLabel control={<Checkbox checked={allrl} onChange={(e)=> handleChangerl(e, setRole, role, setOpenSnack, setAlertMsg)} name="allrl" className={classes.checkbox} />} /></td>
                        <td><FormControlLabel control={<Checkbox disabled checked={false} onChange={(e)=> handleChangerl(e, setRole, role, setOpenSnack, setAlertMsg)} name="viewrl" className={classes.checkbox} />} /></td>
                        <td><FormControlLabel control={<Checkbox disabled={urrole.charAt(1)==='0'} checked={createrl} onChange={(e)=> handleChangerl(e, setRole, role, setOpenSnack, setAlertMsg)} name="createrl" className={classes.checkbox} />} /></td>
                        <td><FormControlLabel control={<Checkbox disabled={urrole.charAt(2)==='0'} checked={editrl} onChange={(e)=> handleChangerl(e, setRole, role, setOpenSnack, setAlertMsg)} name="editrl" className={classes.checkbox} />} /></td>
                        <td><FormControlLabel control={<Checkbox disabled={urrole.charAt(3)==='0'} checked={deleterl} onChange={(e)=> handleChangerl(e, setRole, role, setOpenSnack, setAlertMsg)} name="deleterl" className={classes.checkbox} />} /></td>
                        <td><FormControlLabel control={<Checkbox disabled={urrole.charAt(4)==='0'} checked={assignrl} onChange={(e)=> handleChangerl(e, setRole, role, setUser, user, setOpenSnack, setAlertMsg)} name="assignrl" className={classes.checkbox} />} /></td>
                      </td>
                  </tr>}
                  { (urreport.charAt(0)==='1') &&
                    <tr className={classes.tDarkBackground}>
                      <td className={classes.tNameColCenter}> Report </td>
                      <td className={classes.tPerColAlign}> 
                        <td><FormControlLabel control={<Checkbox checked={allr} onChange={(e)=>handleChanger(e, setReport, report, setOpenSnack, setAlertMsg)} name="allr" className={classes.checkbox} />} /></td>
                        <td><FormControlLabel control={<Checkbox checked={viewr} onChange={(e)=>handleChanger(e, setReport, report, setOpenSnack, setAlertMsg)} name="viewr" className={classes.checkbox} />} /></td>
                        <td><FormControlLabel control={<Checkbox disabled checked={false} onChange={(e)=>handleChanger(e, setReport, report, setOpenSnack, setAlertMsg)} name="creater" className={classes.checkbox} />} /></td>
                        <td><FormControlLabel control={<Checkbox disabled checked={false} onChange={(e)=>handleChanger(e, setReport, report, setOpenSnack, setAlertMsg)} name="editr" className={classes.checkbox} />} /></td>
                        <td><FormControlLabel control={<Checkbox disabled checked={false} onChange={(e)=>handleChanger(e, setReport, report, setOpenSnack, setAlertMsg)} name="deleter" className={classes.checkbox} />} /></td>
                        <td><FormControlLabel control={<Checkbox disabled checked={false} onChange={(e)=>handleChanger(e, setReport, report, setOpenSnack, setAlertMsg)} name="deleter" className={classes.checkbox} />} /></td>
                      </td>
                  </tr>}
                </table>
              </div>
              <Snackbar open={openSnack} autoHideDuration={3000} onClose={handleCloseSnack}>
                  <Alert severity="error">{alertMsg}</Alert>
              </Snackbar>
              <div class="col-lg-4 d-flex justify-content-center">
                <FormControl component="fieldset" className={classes.formControl}>
                    <form className="d-flex flex-column justify-content-center align-items-center">
                        <p className="h3 mb-0">Create Role</p>
                        <div className="col-xs-4 col-xs-offset-4">
                            <div className="floating-label-group">
                                <input type="text" id="roleName" className="form-control" autoComplete="off" required style={{ fontWeight: "bold" }} onChange={ (e) => setRoleName(e.target.value)}/>
                                <label className="floating-label">Role Name</label>
                            </div>
                        </div>
                        <div>
                            <button type="submit" className="btn btn-success" id="submit" onClick={(e) => submitCreate(e, user, userGroup, userDash, alarm, view, source, report, role, dashboard, roleName, actionInitiatingUser)}>Submit</button> &nbsp;
                            <button type="cancel" className="btn btn-danger" onClick={(e)=> window.location.reload(false)}>Cancel</button>
                        </div>
                    </form>
                </FormControl>
              </div>
            </div>
          </div>
        </main>
      </div >
    </>
  );
}

export default PermissionsCreate;