import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import axios from "axios";
import config from "../config.json";

const EquilizerBar = ({ sources, datasets }) => {
  const [barData, setBarData] = useState([]);
  const [backgroundColors, setBackgroundColors] = useState([]);
  const [borderColors, setBorderColors] = useState([]);
  const [sourceLabel, setSourceLabel] = useState([]);

  const getEquilizerBar = async () => {
    if (sources.length > 0) {
      await axios
        .post(config.BACKEND_URL + "/widgets/get-equilizer-bar-data", {
          sources: sources.map(source => source.id),
        })
        .then((res) => {
          setBarData(res.data.barData);
        })
        .catch((err) => console.log(err));
    } else {
      setBarData([12, 19, 3, 5, 2]);
    }
  };
  const extractColor = () => {
    datasets.forEach(item => {
      if (item.k1) {
        setBackgroundColors(prevColors => [...prevColors, item.k1]);
      }
      if (item.k2) {
        setBorderColors(prevColors => [...prevColors, item.k1]);
      }
    });

    sources.forEach(source => {
      if(source.name){
        setSourceLabel(prevSource => [...prevSource, source.name]);
      }
    });
  }
  useEffect(() => {
    getEquilizerBar();
    extractColor();
    const interval = setInterval(() => {
      getEquilizerBar();
    }, 3000);
    
    return () => {
      clearInterval(interval);
    };
  }, []);
  
  const data = {
    labels: sourceLabel.length > 0 ? sourceLabel : [],
    datasets: [
      {
        label: "Multi DataSource Bar Chart",
        data: barData,
        backgroundColor: backgroundColors.length > 0
          ? backgroundColors
          : "rgba(75,192,192,0.6)",
        borderColor: borderColors.length > 0
          ? borderColors
          : "rgba(75,192,192,1)",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          color: "rgba(0, 0, 0, 0.1)",
        },
        ticks: {
          stepSize: 5,
        },
      },
    },
  };
  
  return (
    <div style={{ maxHeight: "300px" }}>
      <Bar data={data} options={options} />
    </div>
  );
};

export default EquilizerBar;
