import axios from 'axios';
import React, { useEffect, useState } from 'react';
import '../styles/table_styles.css';
import config from "../config.json";

const MqttIndicator = ({ indicatorData, source }) => {
  // const { offText, onText, refreshInterval, title, value: keyToBeAccess } = indicatorData;
  const { offText, onText, refreshInterval, title } = indicatorData;
  const [flag, setFlag] = useState(false);
  const [indicatorClass , setIndicatorClass ] = useState('red');
  const getIndicatorData = async () => {
    if (source) {
      await axios
        .post(config.BACKEND_URL + "/widgets/get-indicator-data", { source })
        .then((res) => {
          if (res.data.flag === '1') {
            setFlag(true);
            setIndicatorClass('green');
          } else {
            setFlag(false);
            setIndicatorClass('red');
          }
        })
        .catch((err) => console.log(err));
    } else {
      setFlag(false);
      setIndicatorClass('red');
    }
  };
  useEffect(() => {
    getIndicatorData();
    const interval = setInterval(() => {
      getIndicatorData();
    }, parseInt(refreshInterval));

    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <>
      <div>{title}</div>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', marginBottom: '0.5rem' }}>
        <div className={`indicator ${indicatorClass}`}></div>
        <div>{ flag ? onText : offText }</div>
      </div>
    </>
  )
}

export default MqttIndicator