import React, { useEffect, useRef } from 'react';
import useFetch from './useFetch.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/muuri_styles.css';
import '../styles/add_pane.css';
import '../styles/modal.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import $, { data } from "jquery";
import config from "../config.json";
import Cookie from 'universal-cookie';

const WidgetSettingModal = (props) => {
    const cookies = new Cookie();
    const { data: http_datasources, error, isPending } = useFetch({ actionInitiatingUser: { userID: cookies.get('userID') }, URL: config.BACKEND_URL + '/logger_sources/sources/http' });
    const { data: mqtt_datasources, error: err, isPending: isMqttPending } = useFetch({ actionInitiatingUser: { userID: cookies.get('userID') }, URL: config.BACKEND_URL + '/logger_sources/sources/mqtt' });


    (function ($) {
        $(function () {
            $('.widget-setting').fSelect({
                placeholder: 'Select some options',
                numDisplayed: 3,
                overflowText: '{n} selected',
                noResultsText: 'No results found',
                searchText: 'Search',
                showSearch: true,
                multiple: true,
                showButtons: false,

            });
        });
    })($);

   
   
    const widgetSettingHandler = async (event) => {
        if (event) {
            event.preventDefault();
        }
        console.log("MQTT DATASOURCE", mqtt_datasources)
        let data = [];
        // if(props.widget.widget_type === 'realtime_table'){
        //     let value = document.querySelector('#widget-datasource-form > div > div > div.fs-wrap.multiple > div.fs-label-wrap > div');
        //     data.push({ sources: value.innerHTML });
        // } else {
            let form = document.querySelector('.widget-datasource');
            let formData = Object.fromEntries(new FormData(form).entries());
            for (const prop in formData) {
                let split = formData[prop].split(",");
                if (split[2] === "HTTP") {
                    data.push({ key: split[0], name: split[1], type: split[2], URL: split[3], method: split[4] })
                }
                else if (split[2] === "MQTT") {
                    data.push({ id: split[0], name: split[1], type: split[2], host: split[3], port: split[4], topic: split[5], key: split[6] })
                }
            }
        // }
        let dummyPaneArray = props.paneArray;
        dummyPaneArray[props.currentPaneIndex].widgetsArray[props.currentWidgetIndex].datasource = data;
        props.setPaneArray(dummyPaneArray);
        props.setChangeSourceModal(false);
    }
    return (
        <div className="modalOverlay">
            <div className="overlay" ></div>
            <div className="modal-content" style={{maxHeight:'400px', overflowY:'scroll'}}>
                <div className='close-form'>
                    <button className="close-modal btn btn-danger" onClick={() => props.setChangeSourceModal(false)}>
                        <FontAwesomeIcon icon={faTimes} />
                    </button>
                    {/* <FontAwesomeIcon icon={faStopCircle} /> */}
                </div>
                {isPending && isMqttPending && <div className="text-center mt-5 h4">Loading...</div>}
                {/* {error && err && <div className="text-center mt-5 h4">Cannot fetch the data right now!</div>} */}
                {!isPending && !isMqttPending &&
                    <div className='form-settings' >
                        <form onSubmit={widgetSettingHandler} className='widget-datasource' id='widget-datasource-form'>
                            <div >
                                {props.widget.datasets.map((src, ind) => (
                                    <div className='form-row' key={ind}>
                                        <div className='form-label'>
                                            {
                                                console.log("SOURCE ,---------",src)
                                            }
                                            {props.widget.widget_type === 'realtime_table'?<label>{src[`label${ind}`]}</label>:<label >{src.label}</label>}
                                        </div>
                                        <select className="widget-setting" multiple='multiple'  name={"datasource_data_" + ind} >
                                            <optgroup label="HTTP">
                                                {http_datasources && http_datasources.data.map((dataSrc, idx) => {
                                                    return (
                                                        <option key={'httpData' + idx} value={dataSrc.key + "," + dataSrc.name + "," + dataSrc.type + "," + dataSrc.URL + "," + dataSrc.method} >{dataSrc.name}</option>
                                                    )
                                                })}
                                            </optgroup>
                                            <optgroup label="MQTT">
                                                {mqtt_datasources && mqtt_datasources.data.map((dataSrc, idx) => {
                                                    console.log("DATA SOURCE ,------------------", dataSrc)
                                                    return (
                                                        <option key={'mqttData' + idx} value={dataSrc.id + "," + dataSrc.name + "," + dataSrc.type + "," + dataSrc.host + "," + dataSrc.port + "," + dataSrc.topic + "," + dataSrc.key} >{dataSrc.name}</option>
                                                    )
                                                })}
                                            </optgroup>
                                        </select>
                                    </div>
                                ))}

                                <button className="btn btn-primary btn-sm btn-submit" type='submit'>
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                }

            </div>
        </div>
    )
}

export default WidgetSettingModal;