import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/muuri_styles.css';
import '../styles/add_pane.css';
import '../styles/modal.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import axios from "axios";
import config from "../config.json";


function DropDownModal(props) {
    const [settingsVisibility, setSetttingsVisibility] = useState({ gauge: false, bar_chart: false, realtime_chart: false, fluid_tank: false, custom_gauge: false, equilizer_bar: false, mqtt_writer: false, mqtt_indicator: false, realtime_table: false, text_widget: false, dynamic_text_widget: false, custom_form_widget: false });
    const [chartArr, setChartArr] = useState([1]);
    const [sourceCount, setSourceCount] = useState(1);
    const [numberOfColumn, setNumberOfColumn] = useState(1);
    const [numberOfStatuses, setNumberOfStatuses] = useState(0); // State to manage number of statuses
    const [number_of_input_fields_for_custom_forms, setNumberOfInputFields] = useState(0);
    const [statusPairs, setStatusPairs] = useState([{ key: '', value: '' }]); // State to manage key-value pairs
    const [custom_form_input_fields, setCustomFormInputFields] = useState({ form_input_field: '' });
    const [] = useState([]);
    var obj;

    useEffect(() => {
        if (props.editWidgetModal && props.widget.widget_type) {
            setSourceCount(props.widget.datasets[0].sourceCnt)
            setNumberOfColumn(props.widget.datasets[0].columns)
            // Create a synthetic event object
            const event = { target: { value: props.widget.widget_type } };
            onChangeWidget(event);
        }
    }, [props.editWidgetModal]);

    const handleSourceCountChange = (value) => setSourceCount(parseInt(value));

    const handleNumberOfColumns = (value) => setNumberOfColumn(parseInt(value));

    const handleNumberOfStatusesChange = (e) => {
        const count = parseInt(e.target.value, 10);
        const maxStatuses = 100; // Set a reasonable limit, adjust as needed

        if (!isNaN(count) && count >= 0 && count <= maxStatuses) {
            setNumberOfStatuses(count);
            const newPairs = Array(count).fill().map((_, i) => statusPairs[i] || { key: '', value: '' });
            setStatusPairs(newPairs);
        } else if (count > maxStatuses) {
            alert(`Maximum allowed statuses is ${maxStatuses}.`);
            setNumberOfStatuses(maxStatuses);
            const newPairs = Array(maxStatuses).fill().map((_, i) => statusPairs[i] || { key: '', value: '' });
            setStatusPairs(newPairs);
        } else {
            // Optionally handle other invalid input, e.g., reset to previous valid state or set to 0
            setNumberOfStatuses();
            setStatusPairs([]);
        }
    };

    const handleStatusPairChange = (index, type, value) => {
        const newPairs = [...statusPairs];
        newPairs[index][type] = value;
        setStatusPairs(newPairs);
    };

    const handleNumberOfInputFields = (e) => {
        const count = parseInt(e.target.value, 10);
        const maxFormInputs = 100; // Set a reasonable limit, adjust as needed

        if (!isNaN(count) && count >= 0 && count <= maxFormInputs) {
            setNumberOfInputFields(count);
            const newFields = Array(count).fill().map((_, i) => custom_form_input_fields[`form_input_field_${i}`] || '');
            setCustomFormInputFields(newFields);
        } else if (count > maxFormInputs) {
            alert(`Maximum allowed input fields is ${maxFormInputs}.`);
            setNumberOfInputFields(maxFormInputs);
            const newFields = Array(maxFormInputs).fill().map((_, i) => custom_form_input_fields[`form_input_field_${i}`] || '');
            setCustomFormInputFields(newFields);
        } else {
            // Optionally handle other invalid input, e.g., reset to previous valid state or set to 0
            setNumberOfInputFields(0);
            setCustomFormInputFields([]);
        }
    };

    const handleFormInputFields = (index, type, value) => {
        const newFields = { ...custom_form_input_fields };
        newFields[`form_input_field_${index}`] = value;
        setCustomFormInputFields(newFields);
    };




    const func2 = (event) => {
        obj = { gauge: false, bar_chart: false, realtime_chart: false, fluid_tank: false, custom_gauge: false, equilizer_bar: false, mqtt_writer: false, mqtt_indicator: false, realtime_table: false, text_widget: false, dynamic_text_widget: false, custom_form_widget: false };
        obj[event.target.value] = true;
        setSetttingsVisibility(prevState => ({
            ...obj
        }));
    }

    const onChangeWidget = (event) => {
        obj = { gauge: false, bar_chart: false, realtime_chart: false, fluid_tank: false, custom_gauge: false, equilizer_bar: false, mqtt_writer: false, mqtt_indicator: false, realtime_table: false, text_widget: false, dynamic_text_widget: false, custom_form_widget: false };
        obj[event.target.value] = true;
        setSetttingsVisibility(prevState => ({
            ...obj
        }));
    }
    const addChart = () => {
        if (!props.editWidgetModal) {
            setChartArr(arr => [...arr, 1]);
        }
        else {
            setChartArr(arr => [...arr, 1]);
            props.widget.datasets.push(
                chartArr
            )
        }
    }

    const renderSourceColorInputs = (sourceCount) => {
        const inputs = [];

        for (let i = 0; i < sourceCount; i++) {
            if (props.editWidgetModal) {
                console.log("HERERERERER")
                inputs.push(
                    <div className='form-row' key={i}>
                        <div className='form-label'>
                            <label>Source {i + 1}</label>
                        </div>
                        <div>
                            <label>Background Color:</label>
                            <input name={`eb_bg_color_${i}`} className='color_input' type='color' defaultValue={props.widget.datasets[i]?.k1 || ''}  ></input>
                        </div>
                        <div>
                            <label>Border Color:</label>
                            <input name={`eb_br_color_${i}`} className='color_input' type='color' defaultValue={props.widget.datasets[i]?.k2 || ''} ></input>
                        </div>
                    </div>
                );
            }

            else {

                inputs.push(
                    <div className='form-row' key={i}>
                        <div className='form-label'>
                            <label>Source {i + 1}</label>
                        </div>
                        <div>
                            <label>Background Color:</label>
                            <input name={`eb_bg_color_${i}`} className='color_input' type='color' defaultValue={'#000000'} ></input>
                        </div>
                        <div>
                            <label>Border Color:</label>
                            <input name={`eb_br_color_${i}`} className='color_input' type='color' defaultValue={'#000000'}></input>
                        </div>
                    </div>
                );

            }
        }
        return inputs



    };

    const renderLabelValue = (numberOfColumn) => {
        const inputs = [];
        for (let i = 0; i < numberOfColumn; i++) {
            if (!props.editWidgetModal) {
                inputs.push(
                    <div className='form-row' key={i}>
                        <div>
                            <label>Label:</label>
                            <input name={`rt_label${i}`} required></input>
                        </div>
                        {/* <div>
                            <label>Key:</label>
                            <input name={`rt_value${i}`} ></input>
                        </div> */}
                    </div>
                );
            }
            else {
                inputs.push(
                    <div className='form-row' key={i}>
                        <div>
                            <label>Label:</label>
                            <input name={`rt_label${i}`} defaultValue={props.widget.datasets[i]?.[`label${i}`] || '' } required></input>
                        </div>
                       
                    </div>
                );
            }
        }
        return inputs;
    };

    const renderStatusInputs = () => {
        const inputs = [];
        for (let index = 0; index < numberOfStatuses; index++) {
            inputs.push(
                <div key={index}>
                    <div className='form-row'>
                        <div className='form-label'>
                            <label>Key {index + 1}</label>
                        </div>
                        <input
                            type="text"
                            name={`key_${index}`}
                            value={statusPairs[index].key}
                            onChange={(e) => handleStatusPairChange(index, 'key', e.target.value)}
                            placeholder="Enter Key"
                        />
                        <div className='form-label'>
                            <label>String {index + 1}</label>
                        </div>
                        <input
                            type="text"
                            name={`value_${index}`}
                            value={statusPairs[index].value}
                            onChange={(e) => handleStatusPairChange(index, 'value', e.target.value)}
                            placeholder="Enter String"
                        />
                    </div>
                    {/* <div className='form-row'>
                        <div className='form-label'>
                            <label>String {index + 1}</label>
                        </div>
                        <input
                            type="text"
                            name={`value_${index}`}
                            value={statusPairs[index].value}
                            onChange={(e) => handleStatusPairChange(index, 'value', e.target.value)}
                            placeholder="Enter String"
                        />
                    </div> */}
                </div>
            );
        }
        return inputs;
    };

    const WidgetSelect = () => {
        const widgetOptions = [
            { value: "none", label: "None" },
            { value: "gauge", label: "Gauge Widget" },
            { value: "bar_chart", label: "Single Source Bar Chart" },
            { value: "realtime_chart", label: "Realtime Chart" },
            { value: "fluid_tank", label: "Fluid Tank" },
            { value: "custom_gauge", label: "Custom Gauge" },
            { value: "equilizer_bar", label: "Multi Source Bar Chart" },
            { value: "mqtt_writer", label: "MQTT Writer" },
            { value: "mqtt_indicator", label: "Indicator Light" },
            { value: "realtime_table", label: "Realtime Table" },
            { value: "text_widget", label: "Text Widget" },
            { value: "dynamic_text_widget", label: "Dynamic Text Widget" },
            { value: "custom_form_widget", label: "Custom Form Widget" }
        ];

        return (
            <div className='form-row'>
                <div className='form-label'>
                    <label>Widget Type</label>
                </div>
                <select
                    id="widget_type"
                    name="widget_type"
                    onChange={props.onChangeWidget}
                    defaultValue={props.widget.widget_type}
                >
                    {widgetOptions.map(option => (
                        <option
                            key={option.value}
                            value={option.value}
                            disabled={option.value !== props.widget.widget_type}
                        >
                            {option.label}
                        </option>
                    ))}
                </select>
            </div>
        );
    }
    const renderCustomFormInputFields = () => {
        const inputs = [];
        for (let index = 0; index < number_of_input_fields_for_custom_forms; index++) {
            if (!props.editWidgetModal) {
                inputs.push(
                    <div key={index}>
                        <div className='form-row'>
                            <div className='form-label'>
                                <label>Form Input Field {index + 1}</label>
                            </div>
                            <input
                                type="text"
                                name={`form_input_field_${index}`}
                                value={custom_form_input_fields[`form_input_field_${index}`] || ''}
                                onChange={(e) => handleFormInputFields(index, 'form_input_field', e.target.value)}
                                placeholder="Enter Form Input Name"
                            />
                        </div>
                    </div>
                );
            }
            else {
                inputs.push(
                    <div key={index}>
                        <div className='form-row'>
                            <div className='form-label'>
                                <label>Form Input Field {index + 1}</label>
                            </div>
                            <input
                                type="text"
                                name={`form_input_field_${index}`}
                                defaultValue={props.widget.datasets[0].customFormInputs[`form_input_field_${index}`] || ''}
                                onChange={(e) => handleFormInputFields(index, 'form_input_field', e.target.value)}
                                placeholder="Enter Form Input Name"
                            />
                        </div>
                    </div>
                );

            }

        }
        return inputs;
    };
    return (
        <div className="modalOverlay">

            {props.editWidgetModal ?
                (<>
                    <div onClick={props.editWidgeToggleModal} className="overlay"></div>
                    <div className="modal-content">
                        <div className='close-form'>
                            <button className="close-modal btn btn-danger" onClick={props.editWidgeToggleModal}>
                                <FontAwesomeIcon icon={faTimes} />
                            </button>
                        </div>
                        <div className='form-settings' >
                            <form id='create-widget'>

                                {WidgetSelect()}

                                {settingsVisibility.gauge && (<div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label >Name</label>
                                        </div>
                                        <input name="widget_name" defaultValue={props.widget.widget_name}></input>
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label >Unit</label>
                                        </div>
                                        <input name="widget_unit" defaultValue={props.widget.widget_unit}></input>
                                    </div>
                                </div>)}
                                {settingsVisibility.bar_chart && (<div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label >Name</label>
                                        </div>
                                        <input name="widget_name" defaultValue={props.widget.datasets[0].name}></input>
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label >Bar Background Color</label>
                                        </div>
                                        <input name='bar_bg_color' className="color_input" type="color" defaultValue={props.widget.datasets[0].bar_bg_color}></input>
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label >Bar Border Color</label>
                                        </div>
                                        <input name='bar_br_color' className="color_input" type="color" defaultValue={props.widget.datasets[0].bar_br_color}></input>
                                    </div>
                                </div>)}
                                {settingsVisibility.realtime_chart && (<div style={{ overflowY: 'scroll', maxHeight: window.innerHeight - 400 }}>
                                    {props.widget.datasets.map((chart, ind) => {
                                        return (
                                            <div >
                                                <div className='form-row'>
                                                    <div className='form-label'>
                                                        <label >Label {ind + 1}</label>
                                                    </div>
                                                    <input name={"chart_label" + ind} defaultValue={props.widget.datasets[ind].label}></input>
                                                </div>
                                                <div className='form-row'>
                                                    <div className='form-label'>
                                                        <label >Color {ind + 1}</label>
                                                    </div>
                                                    <input name={"chart_color" + ind} className="color_input" type="color" defaultValue={props.widget.datasets[ind].backgroundColor} ></input>
                                                </div>
                                            </div>
                                        )
                                    })}

                                    <btn className="btn btn-sm btn-primary" style={{ marginTop: '5px' }} onClick={addChart}>Add Chart</btn>
                                </div>)}
                                {settingsVisibility.fluid_tank && (<div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label >Name</label>
                                        </div>
                                        <input name="widget_name" defaultValue={props.widget.widget_name}></input>
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label >Lower Limit</label>
                                        </div>
                                        <input name="lower_limit" type={"number"} defaultValue={props.widget.lower_limit}></input>
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label >Upper Limit</label>
                                        </div>
                                        <input name="upper_limit" type={"number"} defaultValue={props.widget.upper_limit}></input>
                                    </div>
                                </div>)}
                                {settingsVisibility.custom_gauge && (<div style={{ overflowY: 'scroll', maxHeight: '50vh', width: '100%' }}>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label >Unit</label>
                                        </div>
                                        <input name="cg_unit" type='string' defaultValue={props.widget.datasets[0].unit}></input>
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label >Min Value</label>
                                        </div>
                                        <input name="cg_min_value" type='number' defaultValue={props.widget.datasets[0].minValue}></input>
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label >Max Value</label>
                                        </div>
                                        <input name="cg_max_value" type='number' defaultValue={props.widget.datasets[0].maxValue}></input>
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Animation Speed</label>
                                        </div>
                                        <input name="cg_ani_speed" type='number' defaultValue={props.widget.datasets[0].animationSpeed} min={20} max={200}></input>
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Pointer Angle</label>
                                        </div>
                                        <input name="cg_angle" type='number' defaultValue={props.widget.datasets[0].angle} min={-1} max={1}></input>
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Gauge Width</label>
                                        </div>
                                        <input name="cg_gauge_width" type='number' defaultValue={props.widget.datasets[0].lineWidth} min={0.1} max={0.4}></input>
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Radius Spread</label>
                                        </div>
                                        <input name="cg_radius" type='number' defaultValue={props.widget.datasets[0].radiusScale} min={1} max={5}></input>
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Pointer Length</label>
                                        </div>
                                        <input name="cg_pointer_length" type='number' defaultValue={props.widget.datasets[0].pointerLength} min={0.1} max={2}></input>
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Pointer Stroke Width</label>
                                        </div>
                                        <input name="cg_pointer_sw" type='number' defaultValue={props.widget.datasets[0].pointerStrokeWidth} min={0.01} max={0.2}></input>
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Pointer Color</label>
                                        </div>
                                        <input name="cg_pointer_color" type='color' defaultValue={props.widget.datasets[0].pointerColor}></input>
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Divisions</label>
                                        </div>
                                        <input name="cg_divisions" type='number' defaultValue={props.widget.datasets[0].divisions} min={5} max={20}></input>
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Division Width</label>
                                        </div>
                                        <input name="cg_dw" type='number' defaultValue={props.widget.datasets[0].divWidth} min={1} max={5}></input>
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Division Length</label>
                                        </div>
                                        <input name="cg_dl" type='number' defaultValue={props.widget.datasets[0].divLength} min={0.2} max={2}></input>
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Division Color</label>
                                        </div>
                                        <input name="cg_div_color" type='color' defaultValue={props.widget.datasets[0].divColor}></input>
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Sub Divisions</label>
                                        </div>
                                        <input name="cg_subdivisions" type='number' defaultValue={props.widget.datasets[0].subDivisions} min={2} max={10}></input>
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Sub Division Width</label>
                                        </div>
                                        <input name="cg_sdw" type='number' defaultValue={props.widget.datasets[0].subWidth} min={0.1} max={2}></input>
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Sub Division Length</label>
                                        </div>
                                        <input name="cg_sdl" type='number' defaultValue={props.widget.datasets[0].subLength} min={0.1} max={2}></input>
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Sub Division Color</label>
                                        </div>
                                        <input name="cg_subdiv_color" type='color' defaultValue={props.widget.datasets[0].subColor}></input>
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Limit Max Value</label>
                                        </div>
                                        <input name="cg_limit_max" type='checkbox' checked={props.widget.datasets[0].limitMax}></input>
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Limit Min Value</label>
                                        </div>
                                        <input name="cg_limit_min" type='checkbox' checked={props.widget.datasets[0].limitMin}></input>
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Start Color</label>
                                        </div>
                                        <input name="cg_start_color" type='color' defaultValue={props.widget.datasets[0].colorStart}></input>
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Stop Color</label>
                                        </div>
                                        <input name="cg_stop_color" type='color' defaultValue={props.widget.datasets[0].colorStop}></input>
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Stroke Color</label>
                                        </div>
                                        <input name="cg_stroke_color" type='color' defaultValue={props.widget.datasets[0].strokeColor}></input>
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Gradient Effect</label>
                                        </div>
                                        <input name="cg_gradient" type='checkbox' checked={props.widget.datasets[0].generateGradient}></input>
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Donut Shape</label>
                                        </div>
                                        <input name="cg_donut" type='checkbox' checked={props.widget.datasets[0].donut}></input>
                                    </div>
                                </div>)}
                                {settingsVisibility.equilizer_bar && (<div style={{ overflowY: 'scroll', maxHeight: '50vh', width: '100%' }}>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Name</label>
                                        </div>
                                        <input name='eb_name' defaultValue={props.widget.datasets[0].name}></input>
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Number Of Sources</label>
                                        </div>
                                        <input
                                            name='eb_src_cnt'
                                            className='color_input'
                                            type='number'
                                            min={1}
                                            max={5}
                                            onChange={(e) => handleSourceCountChange(e.target.value)}
                                            defaultValue={props.widget.datasets[0].sourceCnt}
                                        ></input>
                                    </div>
                                    {renderSourceColorInputs(sourceCount)}
                                </div>)}
                                {settingsVisibility.mqtt_writer && (<div style={{ overflowY: 'scroll', maxHeight: '50vh', width: '100%' }}>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>MQTT Server</label>
                                        </div>
                                        <input type="text" required="required" id="mqttServer" name="mw_server" placeholder="MQTT Server" defaultValue={props.widget.datasets[0].host} />
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Port</label>
                                        </div>
                                        <input type="number" required="required" id="port" name="mw_port" placeholder="Port" defaultValue={props.widget.datasets[0].port} />
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Client ID</label>
                                        </div>
                                        <input type="text" id="clientId" name="mw_clientId" placeholder="Client ID" />
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Username</label>
                                        </div>
                                        <input type="text" id="username" name="mw_username" placeholder="Username" />
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Password</label>
                                        </div>
                                        <input type="password" id="password" name="mw_password" placeholder="Password" />
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Topic</label>
                                        </div>
                                        <input type="text" required="required" id="mw_topic" name="mw_topic" placeholder="Topic" defaultValue={props.widget.datasets[0].topic} />
                                    </div>
                                </div>)}
                                {settingsVisibility.mqtt_indicator && (<div style={{ overflowY: 'scroll', maxHeight: '50vh', width: '100%' }}>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Title</label>
                                        </div>
                                        <input type="text" name="mi_title" placeholder="Title" defaultValue={props.widget.datasets[0].title} />
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>ON Text</label>
                                        </div>
                                        <input type="text" name="mi_on_text" placeholder="ON Text" defaultValue={props.widget.datasets[0].onText} />
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>OFF Text</label>
                                        </div>
                                        <input type="text" name="mi_off_text" placeholder="OFF Text" defaultValue={props.widget.datasets[0].offText} />
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Refresh Interval (Seconds)</label>
                                        </div>
                                        <input type="number" name="mi_refresh" placeholder="Refresh Interval (Seconds)" defaultValue={props.widget.datasets[0].refreshInterval} />
                                    </div>
                                </div>)}
                                {settingsVisibility.realtime_table && (<div style={{ overflowY: 'scroll', maxHeight: '50vh', width: '100%' }}>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Enter name</label>
                                        </div>
                                        <input
                                            type="text"
                                            name="table_name"
                                            placeholder="Enter name"
                                            defaultValue={props.widget.tableName}
                                            required
                                        />
                                    </div>
                                    <div className='form-row'>

                                        <div className='form-label'>
                                            <label>Number of Columns</label>
                                        </div>
                                        <input
                                            type="number"
                                            name="rt_noc"
                                            placeholder="Enter Number of Columns"
                                            defaultValue={props.widget.columns}
                                            required
                                            onChange={(e) => handleNumberOfColumns(e.target.value)}
                                        />
                                    </div>
                                    {renderLabelValue(numberOfColumn)}
                                </div>)}
                                {settingsVisibility.text_widget && (<div style={{ overflowY: 'scroll', maxHeight: '50vh', width: '100%' }}>
                                    <div className='form-row'>

                                        <div className='form-label'>
                                            <label>Name</label>
                                        </div>
                                        <input
                                            type="text"
                                            name="text_widget"
                                            placeholder="Enter Name"
                                            defaultValue={props.widget.datasets[0].text}
                                        />
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Font Size</label>
                                        </div>
                                        <input
                                            type="number"
                                            name="text_font_size"
                                            placeholder="Enter Font Size"
                                            defaultValue={props.widget.datasets[0].fontSize}
                                        />
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Font Weight</label>
                                        </div>
                                        <input
                                            type="number"
                                            name="text_font_weight"
                                            placeholder="Enter Font Weight"
                                            defaultValue={props.widget.datasets[0].fontWeight}
                                        />
                                    </div>
                                </div>)}
                                {settingsVisibility.dynamic_text_widget && (<div style={{ overflowY: 'scroll', maxHeight: '50vh', width: '100%' }}>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Name/Title</label>
                                        </div>
                                        <input
                                            type="text"
                                            name="dynamic_text_widget"
                                            placeholder="Enter Name/Title"
                                            defaultValue={props.widget.datasets[0].name}
                                        />
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Font Size</label>
                                        </div>
                                        <input
                                            type="number"
                                            name="dynamic_text_widget_font_size"
                                            placeholder="Enter Font Size"
                                            defaultValue={props.widget.datasets[0].fontSize}
                                        />
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Font Weight</label>
                                        </div>
                                        <input
                                            type="number"
                                            name="dynamic_text_widget_font_weight"
                                            placeholder="Enter Font Weight"
                                            defaultValue={props.widget.datasets[0].fontWeight}
                                        />
                                    </div>
                                    {/* <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Number of Statuses</label>
                                        </div>
                                        <input
                                            type="number"
                                            name="NUMBER_of_STATUS"
                                            value={numberOfStatuses}
                                            onChange={handleNumberOfStatusesChange}
                                            placeholder="Enter Number of Statuses"
                                        />
                                    </div>
                                    {renderStatusInputs()} */}
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Write your logic here</label>
                                        </div>
                                        <textarea

                                            rows="4"
                                            cols="30"
                                            name="logic_input"
                                            defaultValue={props.widget.datasets[0].logicInput}
                                            color='grey'
                                        />
                                    </div>
                                </div>)}
                                {settingsVisibility.custom_form_widget && (<div style={{ overflowY: 'scroll', maxHeight: '50vh', width: '100%' }}>
                                    <div className='form-row'>

                                        <div className='form-label'>
                                            <label>Form Header</label>
                                        </div>
                                        <input
                                            type="text"
                                            name="form_header"
                                            placeholder="Enter Name of the Form"
                                            defaultValue={props.widget.datasets[0].name}
                                        />
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>MQTT Server</label>
                                        </div>
                                        <input type="text" required="required" id="mqttServer" name="mw_server" placeholder="MQTT Server" defaultValue={props.widget.datasets[0].host} />
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Port</label>
                                        </div>
                                        <input type="number" required="required" id="port" name="mw_port" placeholder="Port" defaultValue={props.widget.datasets[0].port} />
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Client ID</label>
                                        </div>
                                        <input type="text" id="clientId" name="mw_clientId" placeholder="Client ID" />
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Username</label>
                                        </div>
                                        <input type="text" id="username" name="mw_username" placeholder="Username" />
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Password</label>
                                        </div>
                                        <input type="password" id="password" name="mw_password" placeholder="Password" />
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Topic</label>
                                        </div>
                                        <input type="text" required="required" id="mw_topic" name="mw_topic" placeholder="Topic" defaultValue={props.widget.datasets[0].topic} />
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Number of Input Fields</label>
                                        </div>
                                        <input
                                            type="number"
                                            name="number_of_input_fields"
                                            // value={number_of_input_fields_for_custom_forms}
                                            onChange={handleNumberOfInputFields}
                                            placeholder="Enter Number of Input Fields"
                                            defaultValue={props.widget.datasets[0].numberOfInputFields}
                                        />
                                    </div>
                                    {renderCustomFormInputFields()}

                                </div>)}
                                <button className="btn btn-primary btn-sm btn-submit" onClick={(event) => {
                                    event.preventDefault();
                                    props.editWidgeToggleModal()
                                    props.widgetSettupHandler();
                                }}>
                                    Update
                                </button>
                            </form>
                        </div>
                    </div>
                </>

                )
                :
                (<>
                    <div onClick={props.toggleModal} className="overlay"></div>
                    <div className="modal-content">
                        <div className='close-form'>
                            <button className="close-modal btn btn-danger" onClick={props.toggleModal}>
                                <FontAwesomeIcon icon={faTimes} />
                            </button>
                        </div>
                        <div className='form-settings' >
                            <form id='create-widget'>
                                <div className='form-row'>
                                    <div className='form-label'>
                                        <label >Widget Type</label>
                                    </div>
                                    <select id="widget_type" name="widget_type" onChange={func2}>
                                        <option value="none">None</option>
                                        <option value="gauge" >Gauge Widget</option>
                                        <option value="bar_chart" >Single Source Bar Chart</option>
                                        <option value="realtime_chart" >Realtime Chart</option>
                                        <option value="fluid_tank" >Fluid Tank</option>
                                        <option value="custom_gauge" >Custom Gauge</option>
                                        <option value="equilizer_bar" >Multi Source Bar Chart</option>
                                        <option value="mqtt_writer" > MQTT Writer </option>
                                        <option value="mqtt_indicator" > Indicator Light </option>
                                        <option value="realtime_table" > Realtime Table </option>
                                        <option value="text_widget" > Text Widget </option>
                                        <option value="dynamic_text_widget" > Dynamic Text Widget </option>
                                        <option value="custom_form_widget" > Custom Form Widget </option>
                                    </select>
                                </div>

                                {settingsVisibility.gauge && (<div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label >Name</label>
                                        </div>
                                        <input name="widget_name" ></input>
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label >Unit</label>
                                        </div>
                                        <input name="widget_unit" ></input>
                                    </div>
                                </div>)}
                                {settingsVisibility.bar_chart && (<div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label >Name</label>
                                        </div>
                                        <input name="widget_name" ></input>
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label >Bar Background Color</label>
                                        </div>
                                        <input name='bar_bg_color' className="color_input" type="color" ></input>
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label >Bar Border Color</label>
                                        </div>
                                        <input name='bar_br_color' className="color_input" type="color" ></input>
                                    </div>
                                </div>)}
                                {settingsVisibility.realtime_chart && (<div style={{ overflowY: 'scroll', maxHeight: window.innerHeight - 400 }}>
                                    {chartArr.map((chart, ind) => {
                                        return (
                                            <div >
                                                <div className='form-row'>
                                                    <div className='form-label'>
                                                        <label >Label {ind + 1}</label>
                                                    </div>
                                                    <input name={"chart_label" + ind} ></input>
                                                </div>
                                                <div className='form-row'>
                                                    <div className='form-label'>
                                                        <label >Color {ind + 1}</label>
                                                    </div>
                                                    <input name={"chart_color" + ind} className="color_input" type="color" ></input>
                                                </div>
                                            </div>
                                        )
                                    })}

                                    <btn className="btn btn-sm btn-primary" style={{ marginTop: '5px' }} onClick={addChart}>Add Chart</btn>
                                </div>)}
                                {settingsVisibility.fluid_tank && (<div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label >Name</label>
                                        </div>
                                        <input name="widget_name" ></input>
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label >Lower Limit</label>
                                        </div>
                                        <input name="lower_limit" type={"number"}></input>
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label >Upper Limit</label>
                                        </div>
                                        <input name="upper_limit" type={"number"}></input>
                                    </div>
                                </div>)}
                                {settingsVisibility.custom_gauge && (<div style={{ overflowY: 'scroll', maxHeight: '50vh', width: '100%' }}>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label >Unit</label>
                                        </div>
                                        <input name="cg_unit" type='string'></input>
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label >Min Value</label>
                                        </div>
                                        <input name="cg_min_value" type='number' defaultValue={0}></input>
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label >Max Value</label>
                                        </div>
                                        <input name="cg_max_value" type='number' defaultValue={1000}></input>
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Animation Speed</label>
                                        </div>
                                        <input name="cg_ani_speed" type='number' defaultValue={32} min={20} max={200}></input>
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Pointer Angle</label>
                                        </div>
                                        <input name="cg_angle" type='number' defaultValue={-0.3} min={-1} max={1}></input>
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Gauge Width</label>
                                        </div>
                                        <input name="cg_gauge_width" type='number' defaultValue={0.15} min={0.1} max={0.4}></input>
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Radius Spread</label>
                                        </div>
                                        <input name="cg_radius" type='number' defaultValue={1} min={1} max={5}></input>
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Pointer Length</label>
                                        </div>
                                        <input name="cg_pointer_length" type='number' defaultValue={0.5} min={0.1} max={2}></input>
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Pointer Stroke Width</label>
                                        </div>
                                        <input name="cg_pointer_sw" type='number' defaultValue={0.035} min={0.01} max={0.2}></input>
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Pointer Color</label>
                                        </div>
                                        <input name="cg_pointer_color" type='color' defaultValue='#000000'></input>
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Divisions</label>
                                        </div>
                                        <input name="cg_divisions" type='number' defaultValue={5} min={5} max={20}></input>
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Division Width</label>
                                        </div>
                                        <input name="cg_dw" type='number' defaultValue={1.1} min={1} max={5}></input>
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Division Length</label>
                                        </div>
                                        <input name="cg_dl" type='number' defaultValue={0.7} min={0.2} max={2}></input>
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Division Color</label>
                                        </div>
                                        <input name="cg_div_color" type='color' defaultValue='#333333'></input>
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Sub Divisions</label>
                                        </div>
                                        <input name="cg_subdivisions" type='number' defaultValue={3} min={2} max={10}></input>
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Sub Division Width</label>
                                        </div>
                                        <input name="cg_sdw" type='number' defaultValue={0.5} min={0.1} max={2}></input>
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Sub Division Length</label>
                                        </div>
                                        <input name="cg_sdl" type='number' defaultValue={0.6} min={0.1} max={2}></input>
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Sub Division Color</label>
                                        </div>
                                        <input name="cg_subdiv_color" type='color' defaultValue='#666666'></input>
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Limit Max Value</label>
                                        </div>
                                        <input name="cg_limit_max" type='checkbox' checked={false}></input>
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Limit Min Value</label>
                                        </div>
                                        <input name="cg_limit_min" type='checkbox' checked={false}></input>
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Start Color</label>
                                        </div>
                                        <input name="cg_start_color" type='color' defaultValue='#6F6EA0'></input>
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Stop Color</label>
                                        </div>
                                        <input name="cg_stop_color" type='color' defaultValue='#C0C0DB'></input>
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Stroke Color</label>
                                        </div>
                                        <input name="cg_stroke_color" type='color' defaultValue='#EEEEEE'></input>
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Gradient Effect</label>
                                        </div>
                                        <input name="cg_gradient" type='checkbox' checked={true}></input>
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Donut Shape</label>
                                        </div>
                                        <input name="cg_donut" type='checkbox' checked={false}></input>
                                    </div>
                                </div>)}
                                {settingsVisibility.equilizer_bar && (<div style={{ overflowY: 'scroll', maxHeight: '50vh', width: '100%' }}>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Name</label>
                                        </div>
                                        <input name='eb_name'></input>
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Number Of Sources</label>
                                        </div>
                                        <input
                                            name='eb_src_cnt'
                                            className='color_input'
                                            type='number'
                                            min={1}
                                            max={5}
                                            defaultValue={1}
                                            onChange={(e) => handleSourceCountChange(e.target.value)}
                                        ></input>
                                    </div>
                                    {renderSourceColorInputs(sourceCount)}
                                </div>)}
                                {settingsVisibility.mqtt_writer && (<div style={{ overflowY: 'scroll', maxHeight: '50vh', width: '100%' }}>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>MQTT Server</label>
                                        </div>
                                        <input type="text" required="required" id="mqttServer" name="mw_server" placeholder="MQTT Server" />
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Port</label>
                                        </div>
                                        <input type="number" required="required" id="port" name="mw_port" placeholder="Port" defaultValue='1883' />
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Client ID</label>
                                        </div>
                                        <input type="text" id="clientId" name="mw_clientId" placeholder="Client ID" />
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Username</label>
                                        </div>
                                        <input type="text" id="username" name="mw_username" placeholder="Username" />
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Password</label>
                                        </div>
                                        <input type="password" id="password" name="mw_password" placeholder="Password" />
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Topic</label>
                                        </div>
                                        <input type="text" required="required" id="mw_topic" name="mw_topic" placeholder="Topic" />
                                    </div>
                                </div>)}
                                {settingsVisibility.mqtt_indicator && (<div style={{ overflowY: 'scroll', maxHeight: '50vh', width: '100%' }}>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Title</label>
                                        </div>
                                        <input type="text" name="mi_title" placeholder="Title" />
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>ON Text</label>
                                        </div>
                                        <input type="text" name="mi_on_text" placeholder="ON Text" />
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>OFF Text</label>
                                        </div>
                                        <input type="text" name="mi_off_text" placeholder="OFF Text" />
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Refresh Interval (Seconds)</label>
                                        </div>
                                        <input type="number" name="mi_refresh" placeholder="Refresh Interval (Seconds)" />
                                    </div>
                                </div>)}
                                {settingsVisibility.realtime_table && (<div style={{ overflowY: 'scroll', maxHeight: '50vh', width: '100%' }}>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Enter name</label>
                                        </div>
                                        <input
                                            type="text"
                                            name="table_name"
                                            placeholder="Enter name"
                                            required
                                            defaultValue={`Real Time Table`}
                                        />
                                    </div>
                                    <div className='form-row'>

                                        <div className='form-label'>
                                            <label>Number of Columns</label>
                                        </div>
                                        <input
                                            type="number"
                                            name="rt_noc"
                                            placeholder="Enter Number of Columns"
                                            defaultValue={1}
                                            required
                                            onChange={(e) => handleNumberOfColumns(e.target.value)}
                                        />
                                    </div>
                                    {renderLabelValue(numberOfColumn)}
                                </div>)}
                                {settingsVisibility.text_widget && (<div style={{ overflowY: 'scroll', maxHeight: '50vh', width: '100%' }}>
                                    <div className='form-row'>

                                        <div className='form-label'>
                                            <label>Name</label>
                                        </div>
                                        <input
                                            type="text"
                                            name="text_widget"
                                            placeholder="Enter Name"
                                            defaultValue={`Text Widget`}
                                        />
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Font Size</label>
                                        </div>
                                        <input
                                            type="number"
                                            name="text_font_size"
                                            placeholder="Enter Font Size"
                                            defaultValue={`16`}
                                        />
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Font Weight</label>
                                        </div>
                                        <input
                                            type="number"
                                            name="text_font_weight"
                                            placeholder="Enter Font Weight"
                                            defaultValue={`300`}
                                        />
                                    </div>
                                </div>)}
                                {settingsVisibility.dynamic_text_widget && (<div style={{ overflowY: 'scroll', maxHeight: '50vh', width: '100%' }}>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Name/Title</label>
                                        </div>
                                        <input
                                            type="text"
                                            name="dynamic_text_widget"
                                            placeholder="Enter Name/Title"
                                            defaultValue={`Dynamic Text Widget`}
                                        />
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Font Size</label>
                                        </div>
                                        <input
                                            type="number"
                                            name="dynamic_text_widget_font_size"
                                            placeholder="Enter Font Size"
                                            defaultValue={`16`}
                                        />
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Font Weight</label>
                                        </div>
                                        <input
                                            type="number"
                                            name="dynamic_text_widget_font_weight"
                                            placeholder="Enter Font Weight"
                                            defaultValue={`300`}
                                        />
                                    </div>
                                    {/* <div className='form-row'>
                            <div className='form-label'>
                                <label>Number of Statuses</label>
                            </div>
                            <input
                                type="number"
                                name="NUMBER_of_STATUS"
                                value={numberOfStatuses}
                                onChange={handleNumberOfStatusesChange}
                                placeholder="Enter Number of Statuses"
                            />
                        </div>
                        {renderStatusInputs()} */}
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Write your logic here</label>
                                        </div>
                                        <textarea

                                            rows="4"
                                            cols="30"
                                            name="logic_input"
                                            defaultValue={`//NOTE: use variable name as 'status' to map strings`}
                                            color='grey'
                                        />
                                    </div>
                                </div>)}
                                {settingsVisibility.custom_form_widget && (<div style={{ overflowY: 'scroll', maxHeight: '50vh', width: '100%' }}>
                                    <div className='form-row'>

                                        <div className='form-label'>
                                            <label>Form Header</label>
                                        </div>
                                        <input
                                            type="text"
                                            name="form_header"
                                            placeholder="Enter Name of the Form"
                                            defaultValue={`Custom Form`}
                                        />
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>MQTT Server</label>
                                        </div>
                                        <input type="text" required="required" id="mqttServer" name="mw_server" placeholder="MQTT Server" />
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Port</label>
                                        </div>
                                        <input type="number" required="required" id="port" name="mw_port" placeholder="Port" defaultValue='1883' />
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Client ID</label>
                                        </div>
                                        <input type="text" id="clientId" name="mw_clientId" placeholder="Client ID" />
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Username</label>
                                        </div>
                                        <input type="text" id="username" name="mw_username" placeholder="Username" />
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Password</label>
                                        </div>
                                        <input type="password" id="password" name="mw_password" placeholder="Password" />
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Topic</label>
                                        </div>
                                        <input type="text" required="required" id="mw_topic" name="mw_topic" placeholder="Topic" />
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label>Number of Input Fields</label>
                                        </div>
                                        <input
                                            type="number"
                                            name="number_of_input_fields"
                                            value={number_of_input_fields_for_custom_forms}
                                            onChange={handleNumberOfInputFields}
                                            placeholder="Enter Number of Input Fields"
                                        />
                                    </div>
                                    {renderCustomFormInputFields()}
                                    {console.log("I'm Sick")}
                                </div>)}
                                <button className="btn btn-primary btn-sm btn-submit" onClick={(event) => {
                                    event.preventDefault();
                                    // if (settingsVisibility.dynamic_text_widget) {
                                    //     handleDynamicTextWidgetSubmit();
                                    // } else {

                                    props.widgetSettupHandler();


                                    // }
                                }}>
                                    Submit
                                </button>
                            </form>
                        </div>
                    </div>

                </>
                )

            }

        </div>
    )
}

export default DropDownModal;