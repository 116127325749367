import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import makeAnimated from 'react-select/animated';
const animatedComponents = makeAnimated();

const customStyles = {
    option: provided => ({
        ...provided,
        color: 'black'
    }),
    control: provided => ({
        ...provided,
        color: 'black'
    }),
    singleValue: provided => ({
        ...provided,
        color: 'black'
    })
}
const MultiSelect = ({
    handleChange,
    styles = customStyles,
    placeholder = "Search for, or select elements...",
    data: options = [],
    minWidth = "auto",
    maxWidth = "auto",
    className = "",
    id = "",
    defaultSelected: defaultValue
}) => {
    return (
        <div style={{ minWidth, maxWidth }} id={id} className={className}>
            <Select
                options={options}
                styles={styles}
                closeMenuOnSelect={false}
                components={animatedComponents}
                isMulti
                defaultValue= {defaultValue.map(grp => {
                    return grp;
                })}
                onChange={handleChange}
                placeholder={placeholder}
            />
        </div>
    );
}

export default MultiSelect;