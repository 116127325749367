import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import useInterval from "./useInterval";
import Gauge from "react-gaugejs";
import LineChart from "./Chart.js";
import FluidTank from "./FluidTank";
import config from "../config.json";
import Cookie from 'universal-cookie';
import BarChart from "./BarChart";
import CustomGauge from "./CustomGauge";
import EquilizerBar from "./EquilizerBar";
import MqttWidget from "./MqttWidget";
import MqttIndicator from "./MqttIndicator";
import RealtimeTable from "./RealtimeTable";
import TextWidget from "./TextWidget";
import DynamicTextWidget from "./DynamicTextWidget";
import CustomFormWidget from "./CustomFormWidget.js";

function Widget({ options, ...otherProps }) {
    const cookies = new Cookie();
    const userToken = cookies.get('userToken');
    const headers = {
        'Authorization': `Bearer ${userToken}`,
        'Content-Type': 'application/json'
    };
    if (!options) {
        options = {
            colorStart: "#6fadcf",
            colorStop: void 0,
            gradientType: 0,
            strokeColor: "#e0e0e0",
            generateGradient: true,
            percentColors: [
                [0.0, "#a9d70b"],
                [0.5, "#f9c802"],
                [1.0, "#ff0000"],
            ],
            pointer: {
                length: 0.8,
                strokeWidth: 0.035,
                iconScale: 1.0,
            },
            staticLabels: {
                font: "10px sans-serif",
                labels: [200, 500, 2100, 2800],
                fractionDigits: 0,
            },
            staticZones: [
                { strokeStyle: "#F03E3E", min: 0, max: 200 },
                { strokeStyle: "#FFDD00", min: 200, max: 500 },
                { strokeStyle: "#30B32D", min: 500, max: 2100 },
                { strokeStyle: "#FFDD00", min: 2100, max: 2800 },
                { strokeStyle: "#F03E3E", min: 2800, max: 3000 },
            ],
            renderTicks: {
                divisions: 5,
                divWidth: 1.1,
                divLength: 0.7,
                divColor: "#333333",
                subDivisions: 3,
                subLength: 0.5,
                subWidth: 0.6,
                subColor: "#666666",
            },
            angle: 0.15,
            lineWidth: 0.44,
            radiusScale: 1.0,
            fontSize: 40,
            limitMax: false,
            limitMin: false,
            highDpiSupport: true,
        };
    }

    const [widgetVal, setWidgetVal] = useState(0);
    const [chartData, setChartData] = useState({
        labels: [],
        // datasets is an array of objects where each object represents a set of data to display corresponding to the labels above. for brevity, we'll keep it at one object
        datasets: otherProps.widget.datasets,
        options: {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: true
                    }
                }]
            }
        },
    });
    var chartObj;
    useEffect(() => {
        if (otherProps.widget.datasource != null) {
        }
    }, []);

    const loadDataSrc = async () => {
        if (otherProps.widget.widget_type !== 'bar_chart' && otherProps.widget.widget_type !== 'custom_gauge' && otherProps.widget.widget_type !== 'fluid_tank' && otherProps.widget.widget_type !== 'equilizer_bar' && otherProps.widget.widget_type !== 'mqtt_writer' && otherProps.widget.widget_type !== 'mqtt_indicator' && otherProps.widget.widget_type !== 'realtime_table' && otherProps.widget.widget_type !== 'text_widget' && 
        otherProps.widget.widget_type !== 'dynamic_text_widget' &&  otherProps.widget.widget_type !== 'custom_form_widget' && otherProps.widget.datasource && otherProps.widget.datasource.length != 0) {
            let httpResponse;
            let mqttResponse = await axios.get(config.BACKEND_URL + "/logger_sources/mqttData", { headers });
            let response;
            let res;                                                          
            chartObj = otherProps.paneArray[otherProps.currPaneIndex].widgetsArray[otherProps.currWidgetIndex].chartData || chartData;
            chartObj.labels.push(
                new Date().getHours() +
                ":" +
                new Date().getMinutes() +
                ":" +
                new Date().getSeconds()
            );
            for (let i = 0; i < otherProps.widget.datasource.length; i++) {
                // console.log("RESPONSE GOT FOR REAL TIME TABLE 11",res);
                if (otherProps.widget.datasource[i].type === "MQTT") {
                    response = mqttResponse.data.filter((obj) => {
                        return obj.name === (otherProps.widget.datasource)[i].name;
                    });

                    // console.log("RESPONSE ", response)
                    if (response.length > 0) {
                        res = response[0].value;
                    }
                }
                else {
                    let key = otherProps.widget.datasource[i].key;
                    httpResponse = await axios.get((otherProps.widget.datasource[i].URL));
                    if (httpResponse.data && key in httpResponse.data) {
                        res = httpResponse.data[key];
                    } else {
                        console.warn(`Key ${key} not found in HTTP response`);
                    }
                }
                if (otherProps.widget.widget_type === "realtime_chart") {
                    // console.log("RESPONSE GOT FOR REAL TIME TABLE",res);
                    let dummyPaneArray = otherProps.paneArray;
                    if (res !== null && res !== undefined) {
                        chartObj.datasets[i].data.push(res);
                    } else {
                        chartObj.datasets[i].data.push('-');
                    }
                    dummyPaneArray[otherProps.currPaneIndex].widgetsArray[
                        otherProps.currWidgetIndex
                    ].chartData = chartObj;
                    otherProps.setPaneArray(dummyPaneArray);
                    setChartData((prevState) => ({
                        ...chartObj,
                    }));
                }
                else {
                    // console.log("RESPONSE GOT FOR REAL TIME TABLE -------------------------------->>>>>>>>.",res);
                    setWidgetVal(parseFloat(res));
                    let dummyPaneArray = otherProps.paneArray;
                    dummyPaneArray[otherProps.currPaneIndex].widgetsArray[
                        otherProps.currWidgetIndex
                    ].value = res;
                    otherProps.setPaneArray(dummyPaneArray);
                }
            }
        }
    };

    useInterval(async () => {
        if (otherProps.widget.dashboard_name === otherProps.newDashboard) {
            loadDataSrc();
            console.log("LAODING DATA SOURCE")
        }
    }, 2000);
    console.log("otherProps", widgetVal);
    return (
        <>
            {otherProps.widget.widget_type === "gauge" && (
                <>
                    <Gauge
                        value={parseFloat(widgetVal || otherProps.value || 0).toFixed(2)}
                        minValue={otherProps.minValue || 0}
                        maxValue={otherProps.maxValue || 100}
                        animationSpeed={otherProps.animationSpeed || 32}
                        options={options}
                        className={otherProps.className || "gauge-canvas"}
                        style={{width:'100%', height:'100%' }}
                    />
                    <div>{`${parseFloat(widgetVal || otherProps.value || 0).toFixed(2)} ${otherProps.widget.widget_unit
                        }`}</div>
                </>
            )}
            {otherProps.widget.widget_type === "bar_chart" && (
                <>
                    <BarChart source={otherProps.widget.datasource ? otherProps.widget.datasource[0].name:''} datasets={otherProps.widget.datasets} />
                </>
            )}
            {otherProps.widget.widget_type === "realtime_chart" && (
                <div className="line-chart">
                    <LineChart chartData={otherProps.paneArray[otherProps.currPaneIndex].widgetsArray[otherProps.currWidgetIndex].chartData || chartData} onMouseDown = {otherProps.onMouseDown} onTouchStart= {otherProps.onTouchStart} />
                </div>
            )}
            {otherProps.widget.widget_type === "fluid_tank" && (
                <div className="fluid_tank">
                    {/* Console log widgetVal, otherProps.widget.lower_limit, otherProps.widget.upper_limit */}
                    {/* {console.log("widgetVal:", widgetVal, "Lower Limit:", parseInt(otherProps.widget.lower_limit), "Upper Limit:", parseInt(otherProps.widget.upper_limit),  "MQTT Value?:", parseInt(otherProps.value))}
                    <FluidTank value={50} /> */}
                    {/* {console.log("Calculated Value:", ((widgetVal - parseInt(otherProps.widget.lower_limit)) / (parseInt(otherProps.widget.upper_limit) - parseInt(otherProps.widget.lower_limit))) * 100)}
                    <FluidTank value={((widgetVal - parseInt(otherProps.widget.lower_limit)) / (parseInt(otherProps.widget.upper_limit) - parseInt(otherProps.widget.lower_limit))) * 100} source={otherProps.widget.datasource ? otherProps.widget.datasource[0].name:''} /> */}
                    <FluidTank 
                        value={2312}
                        lower_limit_value= {parseInt(otherProps.widget.lower_limit)}
                        upper_limit_value= {parseInt(otherProps.widget.upper_limit)}
                        source={otherProps.widget.datasource && otherProps.widget.datasource.length > 0 ? otherProps.widget.datasource[0].name : 'default-source'} 
                       
                    /> {/* Enter random numerical value for value. It is simply to be used as a placeholder, main widget will value from backend API and MQTT directly. */}
                </div>
            )}
            {otherProps.widget.widget_type === "custom_gauge" && (
                <CustomGauge source={otherProps.widget.datasource ? otherProps.widget.datasource[0].name:''} gaugeSetting = { otherProps.widget.datasets[0] }  style={{width:'100%', height:'100%' }}/>
            )}
            {otherProps.widget.widget_type === "equilizer_bar" && (
                <EquilizerBar datasets={otherProps.widget.datasets} sources={otherProps.widget.datasource ? otherProps.widget.datasource:[]} />
            )}
            {otherProps.widget.widget_type === "mqtt_writer" && (
                <MqttWidget source={otherProps.widget.datasets[0]} dashboard_name={otherProps.widget.dashboard_name} />
            )}
            {otherProps.widget.widget_type === "mqtt_indicator" && (
                <MqttIndicator source={otherProps.widget.datasource ? otherProps.widget.datasource[0].id:''} indicatorData = { otherProps.widget.datasets[0] } />
            )}
            {otherProps.widget.widget_type === "realtime_table" && (
                <RealtimeTable sources={otherProps.widget.datasource} datasets={otherProps.widget.datasets} paneID={otherProps.widget.paneID} widgetProperty = {otherProps.widget} widgetValue={otherProps.paneArray[otherProps.currPaneIndex].widgetsArray[otherProps.currWidgetIndex].value }/>
            )}
            {otherProps.widget.widget_type === "text_widget" && (
                <TextWidget source={otherProps.widget.datasource ? otherProps.widget.datasource:''} datasets = { otherProps.widget.datasets[0]}/>
            )}
            {otherProps.widget.widget_type === "dynamic_text_widget" && (
                <DynamicTextWidget value={2312} source={otherProps.widget.datasource && otherProps.widget.datasource.length > 0 ? otherProps.widget.datasource[0].name : 'default-source'} datasets = { otherProps.widget.datasets[0]}/>
            )}
            {otherProps.widget.widget_type === "custom_form_widget" && (
                <CustomFormWidget datasets = { otherProps.widget.datasets[0] } />
            )}
        </>   
    );
}

export default Widget;