import React, { useEffect, useState } from "react"
import '../styles/user_management_styles.css'
import Navbar from "./Navbar.js"
import 'bootstrap/dist/css/bootstrap.min.css';
import Axios from "axios";
import config from '../config.json';
import RoleTable from "./tables/RoleTable";
import UserTable from "./tables/UserTable";
import swal from 'sweetalert';
import { FormControl, MenuItem, Select } from "@material-ui/core";
import Cookies from "universal-cookie";
import mixpanel from "mixpanel-browser";

const PermissionsAssign = () => {
    const [selectedRole, setSelectedRole] = useState([]);
    const [selectedUser, setSelectedUser] = useState([]);
    const [defaultRole, setDefaultRole] = useState("");
    const cookies = new Cookies();
    let actionInitiatingUser = -1;
    let userToken = "";
    try {
        actionInitiatingUser = cookies.get('userID');
        userToken = cookies.get('userToken');
    } catch (error) {}
    const headers = {
        'Authorization': `Bearer ${userToken}`,
        'Content-Type': 'application/json'
    };

    useEffect((()=>{
    }),[selectedRole, selectedUser]);

    const handleChange = (event) => {
        setDefaultRole(event.target.value);
    };
    const Assign = () =>{
        mixpanel.track("Assign Role Page Assign Button Clicked", { userID: cookies.get('userID') });
        if (selectedRole.length === 1 && defaultRole!="") {
            swal("You can't assign two role to user", "Please try again", "error");
        } else {
            if(selectedRole.length === 0 && defaultRole !== "" && selectedUser.length>0){
                swal("Are you sure you want to update the user with this role?", "", "warning", {
                    buttons: {
                        cancel: "Cancel",
                        catch: {
                            text: "Assign",
                            value: "assign",
                        },
                    },
                }).then(value => {
                    if(value==="assign"){
                        Axios.post(config.BACKEND_URL + '/permissions/default/assign', {defaultRole, selectedUser, actionInitiatingUser}, { headers }).then(res=>{
                            if(res.status==200){
                                swal("Role Added", "Role Added to the users", "success").then(() => {
                                    window.location.reload();
                                });
                            }
                        }).catch(err => {
                            console.log(err);
                        });
                    }
                });
                return;
            }
        }
        if(selectedRole.length === 1 && selectedUser.length>0 && defaultRole === ""){
            swal("Are you sure you want to update the user with this role?", "", "warning", {
                buttons: {
                    cancel: "Cancel",
                    catch: {
                        text: "Assign",
                        value: "assign",
                    },
                },
            }).then(value => {
                if(value==="assign"){
                    Axios.post(config.BACKEND_URL + '/permissions/assign', {selectedRole, selectedUser, actionInitiatingUser}, { headers }).then(res=>{
                        if(res.status==200){
                            swal("Role Added", "Role Added to the user", "success").then(() => {
                                window.location.reload();
                            });
                        }
                    }).catch(err => {
                        console.log(err);
                    });
                }
            });
        } else {
            swal("One role can be assign to one or more user", "Please try again", "error");
        }
    }
    return (
        <div className="Reports">
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons"
                rel="stylesheet" />
            <script src='bootstrap/dist/js/bootstrap.min.js'></script>
            <Navbar />
            <main className="container-fluid">
                <header className="h1 text-center" style={{paddingTop: '1rem'}}>Assign Role and Permissions</header>
                <div className="d-flex row justify-content-center">
                    <div className="col-lg-9 col-md-12">
                        <RoleTable defaultOnly={true} selectedRole={selectedRole} setSelectedRole={setSelectedRole} editButton={false} deleteButton={false} />
                    </div>
                    <div className="col-lg-3 col-md-12 d-flex flex-column align-items-center">
                        <h4 className="text-center">Assign role</h4>
                        <FormControl style={{minWidth: '200px', margin: '1rem 0'}} variant="standard">
                            <Select displayEmpty renderValue={defaultRole !== "" ? undefined : () => "Default Roles"} id="grouped-select" value={defaultRole} onChange={handleChange} style={{background:'#fff', textAlign: 'center', borderRadius: '10px'}}>
                                <MenuItem value=""><em>None</em></MenuItem>
                                <MenuItem value="Admin">Admin</MenuItem>
                                <MenuItem value="Manager">Manager</MenuItem>
                                <MenuItem value="Supervisor">Supervisor</MenuItem>
                                <MenuItem value="User">User</MenuItem>
                                <MenuItem value="Guest">Guest</MenuItem>
                            </Select>
                        </FormControl>
                        <button className="btn btn-primary" type="submit" onClick={Assign}>  Assign </button>
                    </div>
                    
                </div>
                <div className="d-flex row " style={{padding: "0"}}>
                    <div className="col-lg-9 col-md-12" style={{padding: "0"}}>
                        <UserTable selectedUser={selectedUser} setSelectedUser={setSelectedUser} />
                    </div>
                </div>
            </main>
        </div >
    );
}

export default PermissionsAssign;