import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from "../config.json";

const DynamicTextWidget = ({ value: propValue, source, datasets }) => {
  const [textData, setTextData] = useState(0);
  const [result, setResult] = useState('NA');
  useEffect(() => {
    const getTextdata = async () => {
      if (!propValue) {  // Check if propValue is not null, undefined, or empty
        console.error("propValue is missing, cannot fetch data.");
        return;  // Stop the function from proceeding
      }
      try {
        const res = await axios.post(`${config.BACKEND_URL}/widgets/get-dynamic-text-data`, { value: propValue, source });
        //console.log("RES: ", res.data);
        //const res = { value: propValue , source};
        //const responseSD = { value: propValue , lower_limit_value, upper_limit_value, source}; 
        //const responseSD = {"value": 10}; //Checking with static data
        //setTextData(res.value);
        setTextData(res.data.value);
        //console.log("ResponseSD: ", responseSD);
      } catch (err) {
        console.error(err);
      }
    };
    getTextdata();
    const interval = setInterval(() => {
      getTextdata();
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, [propValue]);

  useEffect(() => {
    if (datasets.logicInput){
      const evaluateLogic = (logicInput, machineValue) => {
        const wrappedLogic = logicInput.replace(/status/g, machineValue);

        try {
          return eval(`(function() { ${wrappedLogic} })()`);
        } catch (e) {
          console.error("Error evaluating logic input: ", e);
          return 'NA';
        }
      };
  
      setResult(evaluateLogic(datasets.logicInput, textData));
    }
   

  }, [textData, datasets.logicInput]);
  return (
    // <div style={{fontSize:`${datasets.fontSize}px`, fontWeight:datasets.fontWeight, padding:'10px', margin:'10px'}}>
    //   {datasets.text[textData] ? String(datasets.text[textData]) : 'NA'}
    // </div>
    <div style={{ fontSize: `${datasets.fontSize}px`, fontWeight: datasets.fontWeight, padding: '10px', margin: '10px' }}>
      {textData  ? String(result): textData == 0 ?  String(result) : 'please select data source'}
    </div>
  );
}

export default DynamicTextWidget
