import React from "react";
import { Redirect } from "react-router-dom";
import Cookie from "universal-cookie";
import { encrypt } from './useCryptoJS';

const SetLoginCookies = ({ location }) => {
    let newCookies = {};
    let allParams = new URLSearchParams(location.search)
    for (let [key, value] of allParams.entries()) {
        newCookies[key] = value;
    }
    const cookies = new Cookie();
    cookies.set('userID', newCookies['userID'], { path: '/', secure: true, maxAge: 86400 });
    cookies.set('userToken', newCookies['token'], { path: '/', secure: true, maxAge: 86400 });
    cookies.set('urname',encrypt(newCookies['username']), { path: '/', secure: true, maxAge: 86400 });
    cookies.set('uruser',encrypt(newCookies['user']), { path: '/', secure: true, maxAge: 86400 });
    cookies.set('uruserGroup',encrypt(newCookies['userGroup']), { path: '/', secure: true, maxAge: 86400 });
    cookies.set('uruserDashboard',encrypt(newCookies['userDashboard']), { path: '/', secure: true, maxAge: 86400 });
    cookies.set('uralarm',encrypt(newCookies['alarm']), { path: '/', secure: true, maxAge: 86400 });
    cookies.set('urview',encrypt(newCookies['view']), { path: '/', secure: true, maxAge: 86400 });
    cookies.set('ursource',encrypt(newCookies['source']), { path: '/', secure: true, maxAge: 86400 });
    cookies.set('urrole',encrypt(newCookies['role']), { path: '/', secure: true, maxAge: 86400 });
    cookies.set('urdashboard',encrypt(newCookies['dashboard']), { path: '/', secure: true, maxAge: 86400 });
    cookies.set('urreport',encrypt(newCookies['report']), { path: '/', secure: true, maxAge: 86400 });
    return (<Redirect to="/dashboard" />);
}

export default SetLoginCookies;