import React from "react";
import { AiFillEdit } from "react-icons/ai";
import { BsFillEyeFill, BsListCheck } from "react-icons/bs";
import { IoCreate } from "react-icons/io5";
import { MdAssignmentInd } from "react-icons/md";
import { RiDeleteBin2Fill } from "react-icons/ri";

export const roleTablePermissions = [
    {
        id: 0,
        name: "All",
        Icon: <BsListCheck />
    },
    {
        id: 1,
        name: "View",
        Icon: <BsFillEyeFill />
    },
    {
        id: 2,
        name: "Create",
        Icon: <IoCreate />
    },
    {
        id: 3,
        name: "Edit",
        Icon: <AiFillEdit />
    },
    {
        id: 4,
        name: "Delete",
        Icon: <RiDeleteBin2Fill />
    },
    {
        id: 5,
        name: "Assign",
        Icon: <MdAssignmentInd />
    },
];