import React from "react"
import '../styles/user_management_styles.css'
import Navbar from "./Navbar.js"
import 'bootstrap/dist/css/bootstrap.min.css';
import { useHistory   } from "react-router-dom";
import 'jquery-ui-bundle';
import 'jquery-ui-bundle/jquery-ui.min.css';
import mixpanel from 'mixpanel-browser';
import Cookies from "universal-cookie";


const Settings = (props) => {
    const history = useHistory();
    const cookies = new Cookies();
    const handleFormDisplay = (e) => {
        e.preventDefault();
        if (e.target[0].value === 'HTTP') {
            mixpanel.track("HTTP Source Page", { userID: cookies.get('userID') });
            history.push('/http_sources');
        }
        else if (e.target[0].value === 'MQTT') {
            mixpanel.track("MQTT Source Page", { userID: cookies.get('userID') });
            history.push('/mqtt_sources');
        }
    }
    return (
        <div className="Settings" >
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons"
                rel="stylesheet" />
            <script src='bootstrap/dist/js/bootstrap.min.js'></script>
            <Navbar />
            <header className="text-center mt-3 display-6 mb-3">Dashboard Settings</header>
            <main className="d-flex row justify-content-center container-fluid">
                <header className="h2 text-center">Add a Data Source</header>
                <form id="protocolForm" onSubmit={handleFormDisplay} className="row d-flex justify-content-center mb-3">
                    <div className="col-xs-4 col-xs-offset-4 col-sm-4">
                        <div className="floating-label-group">
                            <label htmlFor="protcol">Select the protocol for data retrieval</label>
                            <select style={{ fontWeight: "bold" }} className="form-select" id="protocol" name="protocol" aria-label="Default select example">
                                <option style={{ fontWeight: "bold" }} value="HTTP">HTTP</option>
                                <option style={{ fontWeight: "bold" }} value="MQTT">MQTT</option>
                            </select>
                        </div>
                    </div>
                    <section className="d-flex justify-content-center">
                        <button className="btn btn-success" type="submit">
                            Submit
                        </button>
                    </section>
                </form>
            </main>
        </div >
    )
}
export default Settings;