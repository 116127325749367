import React, { useState } from "react"
import '../styles/user_management_styles.css'
import Navbar from "./Navbar.js"
import 'bootstrap/dist/css/bootstrap.min.css';
import RoleTable from "./tables/RoleTable";
import Cookies from "universal-cookie";

const PermissionDelete = () => {
    const [selectedRole, setSelectedRole] = useState([]);
    const cookies = new Cookies();
    let actionInitiatingUser = -1;
    try {
        actionInitiatingUser = parseInt(cookies.get('userID'));
    } catch (error) {}
    
    return (
        <div className="Reports">
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons"
                rel="stylesheet" />
            <script src='bootstrap/dist/js/bootstrap.min.js'></script>
            <Navbar />
            <main className="container-fluid">
                <section className="d-flex row justify-content-center" style={{ padding: "1vw" }}>
                    <header className="h1 text-center">Delete Role and Permissions</header>
                    <div>
                        <RoleTable selectedRole={selectedRole} setSelectedRole={setSelectedRole} deleteButton={true} editButton={false} actionInitiatingUser = {actionInitiatingUser} />
                    </div>
                </section>
            </main>
        </div >
    );
}

export default PermissionDelete;