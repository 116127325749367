
//---------------------------------------------------------NEW CHANGES------------------------------------------------------------------------------------------------------------------------------------------------

import React, { useState, useEffect, useRef } from "react"
import axios from "axios";
import swal from 'sweetalert';
import '../styles/dashboard_styles.css'
import '../styles/fSelect.css'
import MuuriWidget from "./MuuriWidget"
import Navbar from "./Navbar.js"
import '../fSelect.js';
import AddDashboardModal from "./AddDashboardModal";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { faUpload } from '@fortawesome/free-solid-svg-icons'
import { faArrowAltCircleDown } from '@fortawesome/free-solid-svg-icons'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import $ from "jquery";
import config from "../config.json";
import Cookie from 'universal-cookie';
import { decrypt } from "./useCryptoJS";
import mixpanel from 'mixpanel-browser';

var options = [];

const Dashboard = () => {
    const cookies = new Cookie();
    const [editModal, setEditModal] = useState(false);
    const [paneSize, setPaneSize] = useState(300);
    const [paneArray, setPaneArray] = useState([]);
    const [toggleAddDashboard, setToggleAddDashboard] = useState(false);
    const [newDashboard, setNewDashboard] = useState("");
    const [dashboardData, setDashboardData] = useState([]);
    const [isPendingDashboardData, setIsPendingDashboardData] = useState(true);
    const [isNewDashboard, setIsNewDashboard] = useState(false);
    const [cols, setCols] = useState(Math.ceil(window.innerWidth / 100) - 1);
    const muuriWidgetRef = useRef();
    let actionInitiatingUser = -1;
    let urdashboard = "0000";
    try {
        actionInitiatingUser = parseInt(cookies.get('userID'));
        urdashboard = String(decrypt(cookies.get('urdashboard'))) || "0000";
    } catch (error) { }


    const updateCols = () => {
        setCols(Math.ceil(window.innerWidth / 100) - 1);
    };

    useEffect(() => {
        let x = 1;
        axios.get(config.BACKEND_URL + '/loadDashboard', { x }).then((response) => {
            mixpanel.track("Load Dashboard Request Sent To Backend", { userID: actionInitiatingUser });
            options = response.data.dashboards;
            if (options) {
                setDashboardData(options.map((dashboard, idx) => {
                    return (
                        <option key={'dashData' + idx} value={dashboard.dashboard_name} > {dashboard.dashboard_name} </option>
                    )
                }));
            }

            setIsPendingDashboardData(false);
            (function ($) {
                $(function () {
                    $('.your-select-box').fSelect({
                        placeholder: 'Select some options',
                        numDisplayed: 3,
                        overflowText: '{n} selected',
                        noResultsText: 'No results found',
                        searchText: 'Search',
                        showSearch: true,
                        multiple: true,
                        showButtons: true,
                    });
                });
            })($);
        }, (error) => {
            console.log("dashboard options ", error);
        });

        // window.addEventListener('resize', updateCols);


        // document.addEventListener('keydown', handleCtrlS);
        // startAutoSave();

        return () => {
            // window.removeEventListener('resize', updateCols);
            // document.removeEventListener('keydown', handleCtrlS);
        };

    }, []);

    var dashboards = [];
    var dummyPaneArray = [];

    const toggleEditModal = () => {
        setEditModal(!editModal)
    }

    const handleCtrlS = (e) => {
        if (e.ctrlKey && e.key === 's') {
            e.preventDefault();  // Prevent the browser's save dialog
            // saveDashboard();
        }
    };

    const startAutoSave = () => {
        setInterval(() => {
            saveDashboard();
        }, 5000);
    };


    const saveDashboard = async () => {
        mixpanel.track("Save Dashboard Button Clicked", { userID: actionInitiatingUser });
        if (newDashboard != "") {
            dashboards = dashboardData;
            dashboards = dashboards.filter((dashboard) => {
                return (
                    dashboard.dashboard_name != newDashboard
                )
            })
            dashboards.push({ dashboard_name: newDashboard, dashboard_panes: paneArray })

            try {
                swal({
                    title: "Are you sure?",
                    text: "Do you want to save the dashboard?",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                }).then(async (willDelete) => {
                    if (willDelete) {
                        try {
                            let dashboard = { dashboard_name: newDashboard, dashboard_panes: paneArray };
                            let requestBody = { dashboard, "actionInitiatingUser": actionInitiatingUser, isNewDashboard };
                            await axios.post(config.BACKEND_URL + '/savedashboard', requestBody).then(res => {
                                swal("Dashboard saved successfully!", "", "success").then(() => { window.location.reload(); });
                            }).catch(err => {
                                console.log(err);
                            });
                        }
                        catch (err) {
                            console.log(err);
                            swal("Error saving dashboard!", "", "error");
                        }
                    } else {
                        swal("Dashboard not saved!", "", "info");
                    }
                });
            } catch (err) {
                console.log('ERROR!', err);
                swal("Error while saving dashboard!", `${err}`, "error")
            }
        }
        else {
            swal("Dashboard not saved!", "", "error")
        }
    }

    // const saveDashboard = async () => {
    //     mixpanel.track("Save Dashboard Button Clicked", { userID: actionInitiatingUser });
    //     if (newDashboard !== "") {
    //         dashboards = dashboardData;
    //         dashboards = dashboards.filter((dashboard) => dashboard.dashboard_name !== newDashboard);
    //         dashboards.push({ dashboard_name: newDashboard, dashboard_panes: paneArray });

    //         try {
    //             let dashboard = { dashboard_name: newDashboard, dashboard_panes: paneArray };
    //             let requestBody = { dashboard, "actionInitiatingUser": actionInitiatingUser, isNewDashboard };
    //             await axios.post(config.BACKEND_URL + '/savedashboard', requestBody)
    //                 .then(res => {
    //                     console.log("Dashboard saved successfully!");
    //                 })
    //                 .catch(err => {
    //                     console.error(err);
    //                 });
    //         } catch (err) {
    //             console.error('ERROR!', err);
    //         }
    //     } else {
    //         console.error("Dashboard name is empty, not saving!");
    //     }
    // };

    const addDashboard = () => {
        mixpanel.track("Add Dashboard Button Clicked", { userID: actionInitiatingUser });
        setToggleAddDashboard(true);
    }

    const loadDashboard = async () => {
        mixpanel.track("Load Dashboard Button Clicked", { userID: actionInitiatingUser });
        let form = document.querySelector('form');
        var selected = [];
        for (var option of document.getElementById('dropdown-dashboards').options) {
            if (option.selected) {
                selected.push(option.value);
            }
        }
        if (selected.length === 1) {
            let dash_name = selected[0];
            try {
                swal({
                    title: "Are you sure?",
                    text: "Do you want to load the dashboard?",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                }).then(async (willDelete) => {
                    if (willDelete) {
                       
                        await axios.post(config.BACKEND_URL + '/load', { dash_name, actionInitiatingUser })
                            .then((response) => {
                                setNewDashboard(response.data.dashboard_name)
                                setPaneArray(response.data.dashboard_panes)
                                swal(response.data.dashboard_name + " loaded successfully!", "", "success")
                            }, (error) => {
                                console.log(error);
                            });
                    } else {
                        swal("Dashboard not loaded!", "", "info");
                    }
                });
            }
            catch {
                swal("Error while loading dashboard", "", "error")
            }
        }
        else {
            swal("One dashboard can be loaded at a time", "", "error")
        }
    }

    const deleteDashboard = async () => {
        mixpanel.track("Delete Boards Button Clicked", { userID: actionInitiatingUser });
        let form = document.querySelector('form');
        let formData = Object.fromEntries(new FormData(form).entries());
        var selected = [];
        for (var option of document.getElementById('dropdown-dashboards').options) {
            if (option.selected) {
                selected.push(option.value);
            }
        }
        dashboards = dashboardData;
        dashboards = dashboards.filter((dashboard, idx) => {
            return (!selected.includes(dashboard.dashboard_name))
        });
        //are you sure you want to delete the dashboard?
        try {
            swal({
                title: "Are you sure?",
                text: "Do you want to delete the selected dashboards?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then(async (willDelete) => {
                    if (willDelete) {
                        try {
                            let formData = Object.fromEntries(new FormData(form).entries());
                            await axios.post(config.BACKEND_URL + '/deletedashboard', { selected, actionInitiatingUser })
                            swal("Dashboards successfully deleted!", "", "success")
                                .then(function () {
                                    window.location.href = config.FRONTEND_URL + '/dashboard';
                                    // window.location.href = config.BACKEND_URL + '/dashboard';
                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                            if (selected.includes(newDashboard)) {
                                setPaneArray((paneArray) => {
                                    return []
                                });
                                setNewDashboard("")
                            }
                        }
                        catch (err) {
                            console.log(err);
                            swal("Error deleting the dashboards!", "", "error");
                        }
                    }
                    else {
                        swal("Dashboards not deleted!", "", "info");
                    }
                });
        }
        catch (err) {
            console.log('ERROR!', err);
            swal("Error while deleting the dashboards!", `${err}`, "error")
        }
    }

    const addDashboardHandler = () => {
        let form = document.querySelector('form');
        let formData = Object.fromEntries(new FormData(form).entries());
        for (let i = 0; i < dashboardData.length; i++) {
            if (dashboardData[i].dashboard_name === formData.dashboard_name) {
                alert('Dashboard Name already exists');
                return;
            }
        }
        setNewDashboard(formData.dashboard_name)
        setPaneArray((paneArray) => {
            return []
        });
        setIsNewDashboard(true);
    }


    // const addPane = () => {
    //     dummyPaneArray = [...paneArray, { x: 0, y: 0, w: 3, h: 6, minWidth: 3, maxWidth: 12, minHeight: 5, widgetsArray: [] }];
    //     setPaneArray((paneArray) => {
    //         return [...paneArray, { x: 0, y: 0, w: 3, h: 6, minWidth: 3, maxWidth: 12, minHeight: 4, dashboard_name: newDashboard, widgetsArray: [] }]
    //     });

    //     // Update layouts by calling the method on MuuriWidget component
    //     if (muuriWidgetRef.current) {
    //         muuriWidgetRef.current.updateLayouts(paneArray);
    //     }
    // }

    const addPane = () => {
        // Create a new pane object
        const newPane = {
            x: 0,
            y: 0,
            w: 3,
            h: 6,
            minWidth: 3,
            maxWidth: 12,
            minHeight: 5,
            widgetsArray: []
        };
    
        // Update the state with the new pane
        setPaneArray((prevPaneArray) => {
            const updatedPaneArray = [...prevPaneArray, newPane];
            return updatedPaneArray;
        });
    
        // Use the updated paneArray to update layouts
        if (muuriWidgetRef.current) {
            muuriWidgetRef.current.updateLayouts([...paneArray, newPane]);
        }
    };

    const downloadBoard = () => {
        mixpanel.track("Download boards Button Clicked", { userID: actionInitiatingUser });
        var selected = [];
        console.log(document.getElementById('fs-option'))
        for (var option of document.getElementById('dropdown-dashboards').options) {
            if (option.selected) {
                selected.push(option.value);
            }

        }
        if (selected.length === (document.getElementById('dropdown-dashboards').options).length) {
            setTimeout(() => {
                window.location.href = config.BACKEND_URL + '/downAllBoard'
            }, 1000);
        }

        else if (selected.length === 1) {
            let dashName = selected[0];

            axios.get(config.BACKEND_URL + '/downBoard', {
                params: {
                    dashName
                }
            })
                .then(() => {
                }, (error) => {
                    console.log(error);
                });
            setTimeout(() => {
                window.location.href = config.BACKEND_URL + '/file'
            }, 1000);
        }
        else {
            swal("One or all dashboards can be downloaded at a time", "Try again")
        }
    }

    return (
        <div className="Dashboard"  >
            {toggleAddDashboard && (
                <AddDashboardModal setToggleAddDashboard={setToggleAddDashboard} addDashboardHandler={addDashboardHandler} />
            )}
            {editModal && (
                <div className="modalOverlay">
                    <div className="overlay"></div>
                    <div className="modal-content">
                        <div className='close-form'>
                            <button className="close-modal btn btn-danger" onClick={toggleEditModal}>
                                <FontAwesomeIcon icon={faTimes} />
                            </button>
                            {/* <FontAwesomeIcon icon={faStopCircle} /> */}
                        </div>
                        <div className='form-settings'>
                            <form>
                                <div>
                                    <div className='form-row'>
                                        <div className='form-label'>
                                            <label >Pane Size</label>
                                        </div>
                                        <input name="pane_size" ></input>
                                    </div>
                                    <button className="btn btn-primary btn-sm btn-submit" onClick={(event) => {
                                        event.preventDefault();
                                        let form = document.querySelector('form');
                                        let formData = Object.fromEntries(new FormData(form).entries());
                                        // paneSize=formData.pane_size;
                                        setPaneSize(formData.pane_size)
                                        setEditModal(false);

                                    }}>
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}

            <Navbar newDashboard={newDashboard} />
            <div style={{ 'textAlign': 'center' }}>
                <div className='top-panel' >
                    <div className="row">
                        <div className="col-sm">
                            <div className="dashboard-settings row">

                                <div className="col-lg-7" style={{ display: 'flex' }}>
                                    <div id="dash-drop">
                                        <form >
                                            {isPendingDashboardData && <div className="h5 text-white">Loading...</div>}
                                            {!isPendingDashboardData && dashboardData.length === 0 && <div className="h5">Currently, there are no dashboards!</div>}
                                            {urdashboard.charAt(1) === '0' && urdashboard.charAt(3) === '0' && urdashboard.charAt(0) === '0' && <div className="h5">You don't have any permissions related to Dashboard</div>}
                                            {!isPendingDashboardData && dashboardData.length > 0 && (urdashboard.charAt(3) === '1' || urdashboard.charAt(0) === '1') &&
                                                <>
                                                    <select id="dropdown-dashboards" className="your-select-box" multiple='multiple' name="dashboard_names" >
                                                        {dashboardData}
                                                    </select>
                                                </>
                                            }
                                        </form>
                                    </div>

                                    {/* <div className="buttons">{urdashboard.charAt(0) === '1' && <button onClick={loadDashboard} className="luxury-button"><FontAwesomeIcon className={'dash'} icon={faUpload} style={{color:'#94ffae'}}/><span>Load Dashboard</span></button>}</div>
                                    <div className="buttons">{urdashboard.charAt(1) === '1' && <button onClick={saveDashboard} className="luxury-button"><FontAwesomeIcon className={'dash'} icon={faDownload} style={{color:'#94ffae'}}/><span>Save Dashboard</span></button>}</div>
                                    <div className="buttons">{(newDashboard != "") && (urdashboard.charAt(1) === '1' || urdashboard.charAt(0) === '1') && <button onClick={() => { addPane(); }} className="luxury-button" ><FontAwesomeIcon className={'dash'} icon={faPlus} style={{color:'black'}}/><span>Add Pane</span></button>}</div> */}

                                    {urdashboard.charAt(0) === '1' && (
                                        <div className="buttons">
                                            <button onClick={loadDashboard} className="luxury-button">
                                                <FontAwesomeIcon className="dash" icon={faUpload} style={{ color: '#94ffae' }} />
                                                <span>Load Dashboard</span>
                                            </button>
                                        </div>
                                    )}
                                    {urdashboard.charAt(1) === '1' && (
                                        <div className="buttons">
                                            <button onClick={saveDashboard} className="luxury-button">
                                                <FontAwesomeIcon className="dash" icon={faDownload} style={{ color: '#94ffae' }} />
                                                <span>Save Dashboard</span>
                                            </button>
                                        </div>
                                    )}
                                    {(newDashboard !== "") && (urdashboard.charAt(1) === '1' || urdashboard.charAt(0) === '1') && (
                                        <div className="buttons">
                                            <button onClick={addPane} className="luxury-button">
                                                <FontAwesomeIcon className="dash" icon={faPlus} style={{ color: 'black' }} />
                                                <span>Add Pane</span>
                                            </button>
                                        </div>
                                    )}

                                </div>

                                <div className="col-lg-5" style={{ display: 'flex' }}>
                                    <div className="buttons">{urdashboard.charAt(1) === '1' && <button onClick={addDashboard} className="luxury-button" ><FontAwesomeIcon className={'dash '} icon={faPlus} style={{ color: 'black' }} /><span>Add Dashboard</span></button>}</div>
                                    <div className="buttons">{urdashboard.charAt(0) === '1' && <button onClick={() => { downloadBoard() }} className="luxury-button"><FontAwesomeIcon className={'dash'} icon={faArrowAltCircleDown} style={{ color: '#94e5ff' }} /><span>Download boards</span></button>}</div>
                                    <div className="buttons">{urdashboard.charAt(3) === '1' && <button onClick={deleteDashboard} className="luxury-button"><FontAwesomeIcon className={'dash'} icon={faTrashAlt} style={{ color: 'red' }} /><span>Delete Boards</span></button>}</div>





                                </div>
                                <div className="col">
                                    {/* blank */}
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-sm">
                            
                        </div> */}
                    </div>

                </div>

                <div>
                    <MuuriWidget newDashboard={newDashboard} paneWidth={paneSize} paneArray={paneArray} setPaneArray={setPaneArray} addPane={addPane} ref={muuriWidgetRef}/>
                </div>
            </div>
        </div>

    )
}

export default Dashboard;