import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Navbar from "./Navbar";
import { Avatar, Button, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemText, Paper, TextField, Typography } from "@material-ui/core";
import { deepPurple } from '@material-ui/core/colors';
import axios from "axios";
import config from '../config.json';
import Cookies from "universal-cookie";
import { ReactTabulator } from 'react-tabulator';
import swal from "sweetalert";
import '../App.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTimes, faCheck, faUserCheck } from "@fortawesome/free-solid-svg-icons";
import { BsKey } from 'react-icons/bs';
import default_avatar from '../images/default-user-avatar.jpg';
import mixpanel from "mixpanel-browser";



const MAX_SIZE = 2 * 1024 * 1024; // 2 MB
const MAX_WIDTH = 400; // 400 pixels
const MAX_HEIGHT = 400; // 400 pixels

const useStyles = makeStyles((theme) => ({
    avatar: {
        width: theme.spacing(16),
        height: theme.spacing(16),
    },
    button: {
        backgroundColor: deepPurple[500],
        color: theme.palette.getContrastText(deepPurple[500]),
        '&:hover': {
            backgroundColor: deepPurple[700],
        },
    },
    avatarBox: {
        display: 'flex',
        alignItems: 'center',
        borderRadius: '8px',
        boxShadow: ' 1px 1px 8px #6a5f5f',
        padding: '1rem',
        flexDirection: 'column',
        minHeight: '270px',
        justifyContent: 'space-evenly',
        marginBottom: '2rem'
    },
    listItem: {
        fontWeight: 'bold',
    },
    paperList: {
        width: '100%',
        maxHeight: 250,
        maxWidth: 360,
        backgroundColor: "#00000059",
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        color: '#fff',
        justifyContent: 'center',
        marginBottom: '1rem'
        // alignItems: 'center',
    },
    bulletArrowColors: {
        color: '#8c65c9',
    },
    userLabelStyle: {
        fontFamily: "'IBM Plex Sans', sans-serif",
        fontWeight: 600,
    },
    userContentStyle: {
        fontFamily: "'IBM Plex Sans', sans-serif",
        fontWeight: 400,
    },
    customDivider: {
        borderBottom: '1px solid #fff', width: "90%", margin: '0.5rem 0'
    },
    userDataRoot: {
        // marginTop: '1rem', 
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    userDataMain: {
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        minHeight: '270px',
        marginBottom: '2rem',
        color: '#dee2e6',
        justifyContent: 'space-evenly',
        width: '90%',
        [`@media (max-width: 950px)`]: {
            width: '100%',
            fontSize: 10
        },
        borderRadius: '8px',
        boxShadow: ' 1px 1px 8px #6a5f5f',
        padding: '1rem',
    },
    userInputStyle: {
        // border: "1px solid #1e90ff",
        border: 'none',
        width: '78%',
        background: "transparent",
        color: '#fff',
        "&:focus": {
            outline: "none",
        }
    },
    icon: {
        position: "absolute",
        fontSize: '2rem',
        bottom: '1rem',
        right: '1rem',
    },
    darkTheme: {
        backgroundColor: '#222',
        color: '#fff',
    },
    imageUploadInput: {
        display: 'block',
        maxWidth: '250px',
        margin: '0.5rem 0',
        padding: '0.5rem',
        backgroundColor: '#333',
        color: '#fff',
        border: 'none',
        borderRadius: '0.25rem',
    },
    submitButton: {
        padding: '0.5rem 1rem',
        backgroundColor: '#007bff',
        color: '#fff',
        border: 'none',
        borderRadius: '0.25rem',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#0069d9',
        },
    },
    error:{
        color:'red'
    }
}));
const columns = [
    { title: "Time", field: "timestamp" },
    { title: "User", field: "User" },
    { title: "Message", field: "message" },
    {
        title: "Details",
        field: "details",
        formatter: function (cell, formatterParams, onRendered) { return "<button>Details</button>"; },
        width: 200,
        align: "center",
        cellClick: function (e, cell) {
            let detailObj = cell.getData();
            swal("Raw Log Details", JSON.stringify(detailObj, null, 2).replace(/[{}]/g, ""));
        },
    },
];
const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
const changeTimeFormat = (date) => {
    let time = new Date(date).toLocaleTimeString("en", {
        timeStyle: "short",
        hour12: false,
        timeZone: "UTC",
    });
    let newDate =
        months[date.getMonth()] +
        "-" +
        date.getDate() +
        "-" +
        date.getFullYear() +
        " " +
        time;
    return newDate;
};
const UserProfile = () => {
    const classes = useStyles();
    const cookies = new Cookies();
    const [image, setImage] = useState(null);
    const [error, setError] = useState('');
    const [assignedReports, setAssignedReports] = useState([]);
    const [assignedDashboards, setAssignedDashboards] = useState([]);
    const [assignedGroups, setAssignedGroups] = useState([]);
    const [userData, setUserData] = useState({});
    const userId = parseInt(cookies.get('userID'));
    const [selectedRow, setSelectedRow] = useState(null);
    const [data, setData] = useState([]);
    const token = cookies.get('userToken');

    // password change
    const [open, setOpen] = useState(false);
    const [step, setStep] = useState(1);
    const [password, setPassword] = useState(false);
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setStep(1);
        setPassword(false);
        setNewPassword('');
        setConfirmNewPassword('');
        setCurrentPassword('');
    };

    const handleNext = async () => {
        await axios.post(config.BACKEND_URL + '/profile/check-password', { password: currentPassword, userId }, { headers }).then(async res => {
            if (res.status === 200) {
                setPassword(true);
                if (step === 1) {
                    mixpanel.track("Step 1 Reset Password", { userID: cookies.get('userID') });
                    setStep(2);
                } else if (password && step === 2 && newPassword === confirmNewPassword) {
                    mixpanel.track("Step 2 Reset Password", { userID: cookies.get('userID') });
                    handleClose();
                    await axios.post(config.BACKEND_URL + '/profile/submit-password-profile', { token, email: userData.email, name: userData.name, password: newPassword }, { headers }).then(res => {
                        if (res.status === 200) {
                            mixpanel.track("New Password Updated", { userID: cookies.get('userID') });
                            window.location.replace('http://localhost:3003/user-profile');
                        } else {
                            window.location.replace('http://localhost:3003');
                        }
                    }).catch(err => {
                        window.location.replace('http://localhost:3003');
                        console.log(err);
                    });
                }
            } else {
                handleClose();
                swal("Invalid Password", "Please try again with correct password", "error");
                return;
            }
        }).catch(err => {
            handleClose();
            swal("Invalid Password", "Please try again with correct password", "error");
            return;
        });
    };
    const handleCurrentPasswordChange = (event) => setCurrentPassword(event.target.value);
    const handleNewPasswordChange = (event) => setNewPassword(event.target.value);
    const handleConfirmNewPasswordChange = (event) => setConfirmNewPassword(event.target.value);
    let userToken = "";
    try {
        userToken = cookies.get('userToken');
    } catch (error) { }
    const headers = {
        'Authorization': `Bearer ${userToken}`,
        'Content-Type': 'application/json'
    };
    useEffect(() => {
        const fetchData = async () => {
            await axios.post(config.BACKEND_URL + '/profile/get-reports', { userId }, { headers }).then(res => {
                setAssignedReports(res.data);
            }).catch(err => {
                console.log(err);
            });
            await axios.post(config.BACKEND_URL + '/profile/get-dashboards', { userId }, { headers }).then(res => {
                setAssignedDashboards(res.data);
            }).catch(err => {
                console.log(err);
            });
            await axios.post(config.BACKEND_URL + '/profile/get-groups', { userId }, { headers }).then(res => {
                setAssignedGroups(res.data);
            }).catch(err => {
                console.log(err);
            });
            await axios.post(config.BACKEND_URL + '/profile/get-user-data', { userId }).then(res => {
                setUserData(res.data);
            }).catch(err => {
                console.log(err);
            });
            mixpanel.track("Get Reports Dashboards Groups UserData Fetched", { userID: cookies.get('userID') });
        };
        fetchData();
    }, []);
    const getLog = (e) => {
        e.preventDefault();
        mixpanel.track("User Profile Get Log Button Clicked", { userID: cookies.get('userID') });
        let obj = Object.fromEntries(new FormData(e.target).entries());
        let startDate = new Date(obj.startDate + "Z");
        let endDate = new Date(obj.endDate + "Z");
        axios.get(config.BACKEND_URL + "/logger/query", {
            params: {
                from: changeTimeFormat(startDate),
                until: changeTimeFormat(endDate),
            },
        }).then((res) => {
            const filteredEntries = res.data.file.filter(function (entry) {
                return entry.User === parseInt(cookies.get('userID'));
            });
            setData(filteredEntries);
        }).catch(err => {
            console.log(err);
        });
    }

    const [editMode, setEditMode] = useState(false);

    const handleEditMode = () => {
        setEditMode(!editMode);
    };

    const handleEditSubmit = async () => {
        setEditMode(false);
        await axios.post(config.BACKEND_URL + '/profile/fill-details', { fullname: userData.fullname, email: userData.email, contact: userData.contact, userId }, { headers }).then(() => { }).catch(err => console.log(err));
        mixpanel.track("User Info Updated", { userID: cookies.get('userID') });
    }

    const handleInputChange = (e) => {
        const name = e.target.name;
        let value = e.target.value;

        // Check if the input value is longer than 10 characters
        if (name === 'contact' && value.length > 10) {
            value = value.substring(0, 10); // Truncate the value to 10 characters
        }

        // Allow only numbers to be entered
        if (name === 'contact' && !/^\d*$/.test(value)) {
            return; // If input value contains non-digit characters, return without updating the state
        }

        // Check if the input value is longer than 50 characters
        if (name === 'email' && value.length > 50) {
            value = value.substring(0, 50); // Truncate the value to 50 characters
        }

        // Check if the input value is a valid email address
        if (name === 'email' && !/^[\w-.]+@([\w-]+\.)+[\w-]{2,}$/.test(value)) {
            return; // If input value is not a valid email address, return without updating the state
        }
        setUserData(prevState => ({ ...prevState, [name]: value }));
    };

    const authenticateMe = async () => {
        swal("Mail Sent!", "Please check your email with registered email id", "warning");
        await axios.post(config.BACKEND_URL + '/profile/authenticate-user', { userId, email: userData.email, username: userData.name }, { headers }).then(() => { }).catch(err => console.log(err));
    }


    // const handleImageChange = (event) => {
    //       const file = event.target.files[0];
    //     if (file.size > MAX_SIZE) {
    //         setError('File size exceeds the 2 MB limit.');
    //         return;
    //     }

    //     const img = new Image();
    //     img.src = URL.createObjectURL(file);

    //     img.onload = () => {
    //         if (img.width > MAX_WIDTH || img.height > MAX_HEIGHT) {
    //             setError(`Image dimensions should not exceed ${MAX_WIDTH}x${MAX_HEIGHT} pixels.`);
    //             return;
    //         }

    //         setError('');
    //         setImage(event.target.files[0]);
    //     };

    //     console.log("IMAGE", image)
    // };


    const handleImageChange = (event) => {
        const file = event.target.files[0];
        
        if (!file) {
          setError('No file selected.');
          return;
        }
        
        if (file.size > MAX_SIZE) {
          setError('File size exceeds the 2 MB limit.');
          return;
        }
    
        const img = new Image();
        const reader = new FileReader();
    
        reader.onload = (e) => {
          img.src = e.target.result;
        };
    
        img.onload = () => {
          if (img.width > MAX_WIDTH || img.height > MAX_HEIGHT) {
            setError(`Image dimensions should not exceed ${MAX_WIDTH}x${MAX_HEIGHT} pixels.`);
          } else {
            setError('');
            setImage(file);
          }
        };
    
        reader.readAsDataURL(file);
      };
    const handleImageSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData();
        if (image === null) {
            mixpanel.track("Upload Button Clicked Without Image ", { userID: cookies.get('userID') });
            swal("Image not provided!!", "", "error");
        }
        mixpanel.track("New Profile Image Uploaded", { userID: cookies.get('userID') });
        formData.append('image', image);
        formData.append('userId', userId);
        console.log("FORM DATA OF IMAGE", formData)
        try {
            await axios.post(config.BACKEND_URL + '/profile/update-user-photo', formData, {
                headers: {
                    'Authorization': `Bearer ${userToken}`,
                    'Content-Type': 'multipart/form-data'
                }
            }).then(() => {
                window.location.reload(false);
            }).catch(err => console.log(err));

        } catch (error) {
            console.log(error);
        }
    };

    let base64String = "";
    let dataUrl = "";

    if (userData.photo && userData.photo.data) {
        // Convert ArrayBuffer to Uint8Array
        const uint8Array = new Uint8Array(userData.photo.data);
        // Convert Uint8Array to base64 string
        base64String = btoa(String.fromCharCode.apply(null, uint8Array));
        dataUrl = `data:image/jpeg;base64,${base64String}`;
    }


    const handleResetPassword = () => {
        // TODO: Send request to backend for password reset email
        swal("Password Reset Link Sent", "Please check your mail to reset password", "warning").then(async () => {
            handleClose();
            await axios.post(config.BACKEND_URL + '/profile/send-password-reset-link', { username: userData.name, email: userData.email, userId }, { headers }).then(() => {
            }).catch(err => {
                console.log(err);
            });
        });
    };
    return (
        <div className="Reports">
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
            <link href="https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,300;0,400;0,500;0,600;1,200;1,300;1,400;1,500&display=swap" rel="stylesheet" />
            <script src='bootstrap/dist/js/bootstrap.min.js'></script>
            <Navbar />
            <main className="container-fluid">
                <br /><br /><br />
                <div className="row" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0' }}>
                    <div className="col-12 col-xl-3 col-md-4">
                        <div className={classes.avatarBox}>
                            {userData.photo && userData.photo.data ? <Avatar src={dataUrl} alt="User Avatar" className={classes.avatar} /> :
                                <Avatar src={default_avatar} alt="User Avatar" className={classes.avatar} />}
                            <form onSubmit={handleImageSubmit} style={{ width: '100%' }}>
                                <input type="file" name="image" className={classes.imageUploadInput} onChange={handleImageChange} accept="image/*" />
                                {error && (
                                    <div className={classes.error}>
                                        <p>{error}</p>
                                    </div>
                                )}
                                <button type="submit" className={`${classes.submitButton} ${classes.darkTheme}`}> Upload </button>
                            </form>
                            <Typography variant="h5">{userData.name}</Typography>
                            {(userId === 99999 || userData.isEmailAuthenticated) ? <div style={{ display: 'flex', alignItems: 'center' }}>
                                <FontAwesomeIcon icon={faUserCheck} style={{ color: "#ADD8E6", fontSize: '1.5rem' }} />
                                <span style={{ fontSize: '1.5rem', marginLeft: '0.5rem' }}>Verified</span>
                            </div>
                                : <Button variant="contained" className={classes.button} style={{ borderRadius: '1rem' }} onClick={authenticateMe}> Authenticate </Button>}
                            <div>
                                <Button variant="contained" startIcon={<BsKey />} onClick={handleOpen}> CHANGE PASSWORD </Button>
                                <Dialog open={open} onClose={handleClose}>
                                    {step === 1 && (
                                        <>
                                            <DialogTitle>Enter Current Password</DialogTitle>
                                            <DialogContent>
                                                <TextField autoFocus margin="dense" label="Current Password" type="password" fullWidth value={currentPassword} onChange={handleCurrentPasswordChange} />
                                            </DialogContent>
                                            <Typography style={{ color: '#007bff', margin: '0 1.5rem' }} onClick={handleResetPassword} type="button" className="forgot-password-link">Forgot Password? Click here to reset</Typography>
                                            <DialogActions>
                                                <Button onClick={handleClose}>Cancel</Button>
                                                <Button onClick={handleNext} disabled={!currentPassword}>Next</Button>
                                            </DialogActions>
                                        </>
                                    )}
                                    {step === 2 && (
                                        <>
                                            <DialogTitle>Enter New Password</DialogTitle>
                                            <DialogContent>
                                                <TextField autoFocus margin="dense" label="New Password" type="password" fullWidth value={newPassword} onChange={handleNewPasswordChange} />
                                                <TextField autoFocus margin="dense" label="Confirm New Password" type="password" fullWidth value={confirmNewPassword} onChange={handleConfirmNewPasswordChange} />
                                            </DialogContent>
                                            <DialogActions>
                                                <Button onClick={() => setStep(1)}>Back</Button>
                                                <Button onClick={handleNext} disabled={!newPassword || newPassword !== confirmNewPassword}>Save</Button>
                                            </DialogActions>
                                        </>
                                    )}
                                </Dialog>
                            </div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                            <Typography variant="h6" style={{ textAlign: 'center', margin: '0.5rem 0' }}><span style={{ color: '#acebf9', fontStyle: 'italic' }}>Assigned</span> Reports</Typography>
                            <Paper className={classes.paperList}>
                                <List>
                                    {assignedReports.map(item => (
                                        <ListItem key={item.id}>
                                            <ListItemText primary={<Typography className={classes.listItem} variant="subtitle1"><i className="material-icons navbar-icon" style={{ color: '#8065ed' }}>arrow_forward</i>{item.name}</Typography>} />
                                        </ListItem>
                                    ))}
                                </List>
                            </Paper>
                        </div>
                    </div>
                    <div className="col-12 col-xl-9 col-md-8">
                        <div className={classes.userDataRoot}>
                            <div className={classes.userDataMain} style={{ boxShadow: editMode ? '1px 1px 8px #1e90ff' : '1px 1px 8px #6a5f5f' }}>
                                <Typography variant="h5" className={classes.userContentStyle}>
                                    <span className={classes.userLabelStyle}>Full Name:</span>{" "}
                                    {editMode ? (<input type="text" name="fullname" value={userData.fullname} onChange={handleInputChange} className={classes.userInputStyle} />) : (userData.fullname)}
                                </Typography>
                                <div className={classes.customDivider}></div>
                                <Typography variant="h5" className={classes.userContentStyle}>
                                    <span className={classes.userLabelStyle}>Email:</span>{" "}
                                    {editMode ? (<input type="text" name="email" value={userData.email} onChange={handleInputChange} className={classes.userInputStyle} />) : (userData.email)}
                                </Typography>
                                <div className={classes.customDivider}></div>
                                <Typography variant="h5" className={classes.userContentStyle}>
                                    <span className={classes.userLabelStyle}>Phone Number:</span>{" "}
                                    {editMode ? (<input type="text" name="contact" value={userData.contact} onChange={handleInputChange} className={classes.userInputStyle} />) : (userData.contact)}
                                </Typography>
                                <div className={classes.customDivider}></div>
                                <Typography variant="h5" className={classes.userContentStyle}>
                                    <span className={classes.userLabelStyle}>Created At:</span>{" "} {new Date(userData.createdAt).toLocaleString()}
                                </Typography>
                                <div className={classes.customDivider}></div>
                                <Typography variant="h5" className={classes.userContentStyle}>
                                    <span className={classes.userLabelStyle}>Role:</span> {userData.role}
                                    {editMode ? <FontAwesomeIcon icon={faTimes} className={classes.icon} onClick={handleEditMode} style={{ color: 'red' }} /> :
                                        <FontAwesomeIcon icon={faEdit} className={classes.icon} onClick={handleEditMode} style={{ color: "dodgerblue" }} />}
                                    {editMode && <FontAwesomeIcon icon={faCheck} onClick={handleEditSubmit} style={{ color: "green", position: "absolute", fontSize: '2rem', right: '4rem', bottom: '1rem' }} />}
                                </Typography>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-lg-6" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                <Typography variant="h6" style={{ textAlign: 'center', margin: '0.5rem 0' }}><span style={{ color: 'rgb(172 249 174)', fontStyle: 'italic' }}>Assigned</span> Dashboards</Typography>
                                <Paper className={classes.paperList}>
                                    <List>
                                        {assignedDashboards.map((item, i) => (
                                            <ListItem key={i}>
                                                <ListItemText primary={<Typography className={classes.listItem} variant="subtitle1"><i className="material-icons navbar-icon" style={{ color: '#8065ed' }}>arrow_forward</i>{item.name}</Typography>} />
                                            </ListItem>
                                        ))}
                                    </List>
                                </Paper>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-lg-6" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                <Typography variant="h6" style={{ textAlign: 'center', margin: '0.5rem 0' }}><span style={{ color: 'rgb(247 249 150)', fontStyle: 'italic' }}>Assigned</span> Groups</Typography>
                                <Paper className={classes.paperList}>
                                    <List>
                                        {assignedGroups.map((item, i) => (
                                            <ListItem key={i}>
                                                <ListItemText primary={<Typography className={classes.listItem} variant="subtitle1"><i className="material-icons navbar-icon" style={{ color: '#8065ed' }}>arrow_forward</i>{item.name}</Typography>} />
                                            </ListItem>
                                        ))}
                                    </List>
                                </Paper>
                            </div>
                        </div>
                    </div>
                </div>
                <br /> <br />
                <section className="selectDateRange row d-flex justify-content-between mb-3" style={{ width: "70%", margin: 'auto' }}>
                    <form className="row col-sm-12 d-flex justify-content-center" id="activity-log-form" action="" onSubmit={getLog}>
                        <section className="mb-4 row">
                            <section className="d-flex col-sm-6 justify-content-center flex-column">
                                <label className="text-center" htmlFor="startDate">
                                    Starting Date and Time
                                </label>
                                <input type="datetime-local" name="startDate" id="startDate" />
                            </section>
                            <section className="d-flex col-sm-6 justify-content-center flex-column">
                                <label className="text-center" htmlFor="endDate">
                                    Ending Date and Time
                                </label>
                                <input type="datetime-local" name="endDate" id="endDate" />
                            </section>
                        </section>
                        <section className="d-flex justify-content-center col col-sm-12">
                            <button type="submit" className="btn btn-primary d-block col-sm-2 mr-2"> Get Log </button>
                        </section>
                    </form>
                </section>
                <div className="d-flex justify-content-center align-content-center">
                    <div className="col-12 col-lg-9 col-md-10">
                        <ReactTabulator
                            columns={columns}
                            data={data}
                            options={{
                                pagination: "local",
                                paginationSize: 10,
                                paginationSizeSelector: [10, 20, 50, 100],
                            }}
                            rowClick={(e, row) => setSelectedRow(row.getData().id)}
                            rowSelected={row => row.getData().id === selectedRow}
                        />
                    </div>
                </div>
                <br /> <br />
            </main>
        </div >
    );
};

export default UserProfile;
