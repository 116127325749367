import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, TextField, Typography } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import config from '../config.json';
import Axios from 'axios';

const ResetPassword = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [valid, setValid] = useState(false);
    const [user, setUser] = useState({});
    const { token } = useParams();
    
    useEffect(() => {
        const checkToken = async () => {
            await Axios.post(config.BACKEND_URL + '/profile/is-reset-token-valid', { token }).then(res => {
                if(res.status === 200){
                    setValid(true);
                    setUser(res.data.details);
                } else {
                    setValid(false);
                }
            }).catch(err => {
                setValid(false);
                console.log(err);
            });
        }
        checkToken();
    }, []);
    const handleSubmit = async (event) => {
        event.preventDefault();
        await Axios.post(config.BACKEND_URL + '/profile/submit-password', { token, email: user.email, name: user.username, password }).then(res => {
            if(res.status === 200){
                window.location.replace('http://localhost:3003/user-profile');
            } else {
                window.location.replace('http://localhost:3003');
            }
        }).catch(err => {
            window.location.replace('http://localhost:3003');
            console.log(err);
        });
    };
    return (
        <>
            {valid ? 
            <Box>
                <div style={{display:"flex", alignItems:"center", justifyContent:"center", width: "500px", margin: "auto" }}>
                    <Grid container spacing={2} alignItems="center" justifyContent="center">
                        <Grid item xs={12}> <Typography variant="h4" align="center" gutterBottom> Reset Password </Typography> </Grid>
                        <Grid item xs={12}>
                            <form onSubmit={handleSubmit}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}> <TextField variant="outlined" fullWidth label="New Password" type="password" value={password} onChange={event => setPassword(event.target.value)} /> </Grid>
                                    <Grid item xs={12}> <TextField variant="outlined" fullWidth label="Confirm New Password" type="password" value={confirmPassword} onChange={event => setConfirmPassword(event.target.value)} /> </Grid>
                                    <Grid item xs={12}> <Button type="submit" variant="contained" color="primary" fullWidth disabled={password!==confirmPassword || password.length < 3 || confirmPassword.length < 3 }> Reset Password </Button> </Grid>
                                </Grid>
                            </form>
                        </Grid>
                    </Grid>
                </div>
            </Box>: <h3 style={{textAlign: 'center', padding: '1rem', color: '#000'}}>User is not Authorized</h3>}
        </>
    );
}

export default ResetPassword