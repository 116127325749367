import React, { useState } from "react"
import '../styles/user_management_styles.css'
import Navbar from "./Navbar.js"
import 'bootstrap/dist/css/bootstrap.min.css';
import useFetch from './useFetch.js';
import swal from "sweetalert";
import $ from 'jquery';
import '../styles/alarms.css';
import AlarmsTable from "./tables/AlarmsTable.js";
import config from '../config.json';
import Cookie from "universal-cookie";
let dataLogData = [];
let dataLogColumns = [];
let shouldDisplayObject = {};
let currentViewName = "";
let currentView = {};

const Alarms = () => {
    const cookies = new Cookie();
    let actionInitiatingUser = -1;
    try {
        actionInitiatingUser = cookies.get('userID');
    } catch (error) {
    }
    const { isPending: isPendingAlarms, data: alarms, setData: setAlarms, error: errorAlarms } = useFetch({ actionInitiatingUser: { userID: actionInitiatingUser }, URL: config.BACKEND_URL + '/logger_alarm' });
    const { isPending: isPendingTempAlarms, data: tempAlarms, setData: setTempAlarms, error: errorTempAlarms } = useFetch({ actionInitiatingUser: { userID:  actionInitiatingUser }, URL: config.BACKEND_URL + '/logger_alarm' });
    const { isPending: isPendingAlarmSettings, data: alarmSettings, setData: setAlarmSettings, error: errorAlarmSettings } = useFetch({ actionInitiatingUser: { userID:  actionInitiatingUser }, URL: config.BACKEND_URL + '/alarms' });
    const { isPending: isPendingDataLogs, data: dataLogs, setData: setDataLogs, error: errorDataLogs } = useFetch({ actionInitiatingUser: { userID:  actionInitiatingUser }, URL: config.BACKEND_URL + '/logger_data' });
    const { isPending: isPendingSources, data: sources, setData: setsources, error: errorSources } = useFetch({ actionInitiatingUser: { userID:  actionInitiatingUser }, URL: config.BACKEND_URL + '/logger_sources/sources' });
    
    let tempStartDateTime = '', tempEndDateTime = '';

    const handleAlarmsSubmit = (e) => {
        e.preventDefault();
        if (tempStartDateTime === '' || tempEndDateTime === '') {
            swal("Start time and end time cannot be unspecified!", "Please specify valid date-times.", "error");
            return;
        }
        if (!areDatesValid(tempStartDateTime, tempEndDateTime)) {
            swal("Start time and end time cannot be invalid!", "Please specify valid date-times.", "error");
            return;
        }
        else {
            let tempAlarms = alarms.filter(alarm => { return alarm.createdAt < tempEndDateTime && alarm.createdAt > tempStartDateTime });
            $("#startDate")[0].value = '';
            $("#endDate")[0].value = '';
            tempStartDateTime = '';
            tempEndDateTime = '';
            setTempAlarms(tempAlarms);
        }
    }

    const handleStartTime = (e) => {
        tempStartDateTime = e.target.value;
        if (e.target.value === '') {
            swal("Start time cannot be unspecified!", "Please specify a start date-time.", "error");
        }
        else if (!areDatesValid(tempStartDateTime, tempEndDateTime)) {
            swal("Start time must be before end time!", "Please specify valid start time.", "error");
        }
    }

    const handleEndTime = (e) => {
        tempEndDateTime = e.target.value;
        if (e.target.value === '') {
            swal("End time cannot be unspecified!", "Please specify an end date-time.", "error");
        }
        else if (!areDatesValid(tempStartDateTime, tempEndDateTime)) {
            swal("End time must be after start time!", "Please specify a valid end time.", "error");
        }
    }

    const areDatesValid = (firstDate = '', secondDate = '') => {
        return (firstDate.length === 0) || (secondDate.length === 0) || (firstDate <= secondDate);
    }

    return (
        <div className="Alarms">
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons"
                rel="stylesheet" />
            <script src="https://cdn.jsdelivr.net/npm/chart.js"></script>
            <Navbar />
            <main className="container-fluid">
                <header className="h1 text-center">Alarms</header>
                <form id="allAlarms" className="row col-sm-12 d-flex justify-content-center mb-3" onSubmit={handleAlarmsSubmit}>
                    <section className="row d-flex justify-content-center mb-4">
                        <section className="d-flex col-sm-4 justify-content-center flex-row">
                            <label className="text-center col-sm-3" htmlFor="startDate">From</label>
                            <input className="col-sm-10" type="datetime-local" name="startDate" id="startDate" onChange={handleStartTime} />
                        </section>
                        <section className="d-flex col-sm-4 justify-content-center flex-row">
                            <label className="text-center col-sm-2" htmlFor="endDate">To</label>
                            <input className="col-sm-10" type="datetime-local" name="endDate" id="endDate" onChange={handleEndTime} />
                        </section>
                    </section>
                    <section className="d-flex justify-content-center">
                        <button className="btn btn-primary" type="submit">Get Alarms</button>
                    </section>
                </form>
                {(isPendingAlarms || isPendingDataLogs || isPendingAlarmSettings || isPendingSources) && <div className="d-flex justify-content-center mt-3 h3">Loading...</div>}
                {(errorAlarms || errorAlarmSettings || errorSources) &&
                    console.log(errorAlarms, errorDataLogs, errorAlarmSettings, errorSources) === undefined &&
                    <div className="d-flex justify-content-center mt-3 h3">Cannot fetch the alarms right now!</div>}
                {
                    alarms && alarmSettings && sources &&
                    (
                        (alarms.length === 0 && <div className="d-flex justify-content-center mt-3 h3">Currently, there are no alarm records!</div>) ||
                        <AlarmsTable alarmSettings={alarmSettings} alarms={tempAlarms} sources={sources.data} />
                    )
                }
            </main>
        </div >
    );
}

export default Alarms;