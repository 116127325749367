import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import PopupComponent from './CustomFormPopupWidget';
import axios from 'axios';
import config from "../config.json";

const CustomFormWidget = ({ datasets }) => {
    const [showPopup, setShowPopup] = useState(false);
    const [formData, setFormData] = useState({});
    //const mappedData = {};
    const formdata = {};
    let formData1 = datasets.customFormInputs

    const handleChangeFormData = (e) =>{
        const {name, value} = e.target
        setFormData({
            ...formData,
            [name]:value
        }
        )
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Add your form submission logic here
        const mappedData = Object.fromEntries(
            Object.entries(formData1).map(([key, value]) => [value, formData[key]])
        );
        // const finalFormData = {};
        // finalFormData[datasets.name] = mappedData;
        // finalFormData[datasets.name]["port"] = datasets.port;
        // finalFormData[datasets.name]["host"] = datasets.host;
        // finalFormData[datasets.name]["topic"] = datasets.topic;
        
        mappedData["port"] = datasets.port;
        mappedData["host"] = datasets.host;
        mappedData["topic"] = datasets.topic;
        const res = await axios.post(`${config.BACKEND_URL}/widgets/post-user-form-data`, { mappedData});
        console.log(res);
        // Reset the form data after submission if needed
        // setFormData({});
    };

    console.log(datasets.customFormInputs)
    console.log(datasets)
    console.log("formData: ", formData)
    console.log("formData1: ", formData1)

    const stopPropagation = (e) => {
        e.stopPropagation(); // Prevent grid item dragging
      };

    return (
        // Display Form Here from USER POV.
        <>
        <div>

            {/* <Button variant='contained' size='large'
                onClick={() => setShowPopup(true)}
            >{datasets.name}</Button> */}
            <h1>{datasets.name}</h1>
            
            <form onSubmit={handleSubmit}>
                {Array.from({ length: datasets.numberOfInputFields }, (_, index) => (
                    <div key={index} className='form-row'>
                        <div className='form-label'>
                            <label>{datasets.customFormInputs[`form_input_field_${index}`]}</label>
                        </div>
                        <input
                            type="text"
                            name={`form_input_field_${index}`}
                            value={formdata[datasets.customFormInputs[`form_input_field_${index}`]]}
                            placeholder={`Enter the ${datasets.customFormInputs[`form_input_field_${index}`]}`}
                            onChange={handleChangeFormData}
                            required
                            onMouseDown={stopPropagation} onTouchStart={stopPropagation}
                        />
                    </div>
                ))}
                <button type="submit" onMouseDown={stopPropagation} onTouchStart={stopPropagation}>Submit</button>
                <div>
                    <h3>
                    </h3>
                </div>
            </form>
           
        </div>
        {/* {showPopup && <PopupComponent datasets = { datasets }/>} */}
        </>
    );
}

export default CustomFormWidget;
