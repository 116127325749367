import React, { useState } from "react"
import '../styles/user_management_styles.css'
import Navbar from "./Navbar.js"
import TempNavbar from "./TempNavbar.js"
import 'bootstrap/dist/css/bootstrap.min.css';
import Table from "./Table.js";
import { Redirect } from "react-router-dom";
import useFetch from './useFetch.js';
import swal from "sweetalert";
import axios from "axios";
import { useEffect } from "react";
import config from '../config.json';
import Cookie from 'universal-cookie';
const Views = () => {
    const cookies = new Cookie();
    let actionInitiatingUser = -1;
    try {
        actionInitiatingUser = parseInt(cookies.get('userID'));
    } catch (error) {}
    const { data: views, isPending, error } = useFetch({ actionInitiatingUser: { userID: actionInitiatingUser }, URL: config.BACKEND_URL + '/views' });
    return (
        <div className="Reports">
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons"
                rel="stylesheet" />
            <script src='bootstrap/dist/js/bootstrap.min.js'></script>
            <Navbar />
            <main className="container-fluid">
                <section className="d-flex row justify-content-center" style={{ padding: "1vw" }}>
                    <header className="h1 text-center">Views</header>
                    <section className="col-sm-8">
                        {isPending && <div className="text-center mt-5 h2">Loading...</div>}
                        {error && <div className="text-center mt-5 h2">Failed to fetch data!</div>}
                        {views &&
                            (views.length === 0 ?
                                <div className="text-center mt-5 h2">Currently, there are no views!</div> :
                                console.log('Data: ', views) === undefined
                                &&
                                <Table
                                    tableData={views.map(view => {
                                        return {
                                            name: view.name,
                                            start: new Date(view.timeStamps.start).toLocaleString('en-GB', { hour12: false }).substring(0, 20),
                                            end: new Date(view.timeStamps.end).toLocaleString('en-GB', { hour12: false }).substring(0, 20),
                                            sourceIDs: view.sourceIDs,
                                            createdAt: view.createdAt
                                        }
                                    })}
                                    tableColumns={[
                                        {
                                            title: "Name", field: "name"
                                        },
                                        {
                                            title: "Start timestamp", field: "start"
                                        },
                                        {
                                            title: "End timestamp", field: "end"
                                        },
                                        {
                                            title: "Source IDs", field: "sourceIDs"
                                        },
                                        {
                                            title: "Created At", field: "createdAt"
                                        }
                                    ]}
                                    shouldModifySources={false}
                                    isSelectable={false}
                                    shouldDownload={true}
                                    tableID={"views"}
                                />
                            )}
                        {/* <Table tableData={data.data} tableColumns={data.columns} shouldModifySources={false} /> */}
                    </section>
                </section>
            </main>
        </div >
    );
}

export default Views;