import React from 'react';
import '../styles/navbar_styles.css'
import Cookie from 'universal-cookie';
const logo = require('../images/logo.png');

const TempNavbar = ({ context }) => {
    const cookies = new Cookie();
    setTimeout(() => {
        const sideNavEl = document.querySelector('.js-side-nav');
        const sideNavContainerEl = document.querySelector('.js-side-nav-container');
        const showButtonEl = document.querySelector('.js-menu-open');
        const closeButtonEl = document.querySelector('.js-menu-close');

        const blockClicks = function (evt) {
            evt.stopPropagation();
        }

        const onTransitionEnd = function (evt) {
            sideNavContainerEl.classList.remove('side-nav-animatable');
            sideNavContainerEl.removeEventListener('transitionend', onTransitionEnd);
        }

        const openSideNav = function () {
            sideNavContainerEl.classList.add('side-nav-animatable');
            sideNavContainerEl.classList.add('side-nav-visible');
            sideNavContainerEl.addEventListener('transitionend', onTransitionEnd);
        }

        const closeSideNav = function () {
            sideNavContainerEl.classList.add('side-nav-animatable');
            sideNavContainerEl.classList.remove('side-nav-visible');
            sideNavContainerEl.addEventListener('transitionend', onTransitionEnd);
        }

        const addEventListeners = function () {
            if (showButtonEl) { showButtonEl.addEventListener('click', openSideNav); }
            if (closeButtonEl) { closeButtonEl.addEventListener('click', closeSideNav); }
            if (sideNavEl) { sideNavEl.addEventListener('click', blockClicks); }
            if (sideNavContainerEl) { sideNavContainerEl.addEventListener('click', closeSideNav); }
        }

        addEventListeners();
    }, 50);
    return <>
        <nav>
            <a href="#menu" className="js-menu-open menu-open">
                <i className="material-icons">menu</i>
            </a>
            <div id="main-logo-container" style={{ marginLeft: "40%" }}>
                <img src={logo} alt="Embedos Logo" id="mainLogo" style={{ width: "1.5rem", height: "1.5rem", marginRight: "0.7rem" }} />
                <strong>Embedos</strong>
            </div>
        </nav>
        <div className="js-side-nav-container side-nav-container">
            <div className="js-side-nav side-nav">
                <a href="#0" className="js-menu-close menu-close">
                    <i className="material-icons">close</i>
                </a>
                <div className="navbar-link-container">
                    <i className="material-icons navbar-icon">notifications_active</i>
                    <a href="/alarms" className="js-menu-close menu-close navbar-link">
                        Alarms
                    </a>
                </div>
                <div className="navbar-link-container">
                    <i className="material-icons navbar-icon">article</i>
                    <a href="/reports" className="js-menu-close menu-close navbar-link">
                        Reports
                    </a>
                </div>
                <div className="navbar-link-container">
                    <i className="material-icons navbar-icon">manage_accounts</i>
                    <a href="/user_management" className="js-menu-close menu-close navbar-link">
                        User Management
                    </a>
                </div>
                <div className="navbar-link-container">
                    <i className="material-icons navbar-icon">settings</i>
                    <a href="/sensor_settings" className="js-menu-close menu-close navbar-link">
                        Settings
                    </a>
                </div>
                {context && <div className="navbar-link-container">
                    <i className="material-icons navbar-icon">exit_to_app</i>
                    <a href="/logout" className="js-menu-close menu-close navbar-link">
                        Logout
                    </a>
                </div>}
            </div>
        </div>
    </>
}

export default TempNavbar;