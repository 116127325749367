import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/muuri_styles.css';
import '../styles/add_pane.css';
import '../styles/modal.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

function EditSettingModal(props) {
    return (
        <div className="modalOverlay">
            <div className="overlay"></div>
            <div className="modal-content">
                <div className='close-form'>
                    <button className="close-modal btn btn-danger" onClick={props.toggleEditModal}>
                        <FontAwesomeIcon icon={faTimes} />
                    </button>
                </div>
                <div className='form-settings'>
                    <form id='pane-settings'>
                        <div>
                            <div className='form-row'>
                                <div className='form-label'>
                                    <label >Pane Width</label>
                                </div>
                                <input name="pane_width" ></input>
                            </div>
                            <div className='form-row'>
                                <div className='form-label'>
                                    <label >Pane Height</label>
                                </div>
                                <input name="pane_height" ></input>
                            </div>
                            <button className="btn btn-primary btn-sm btn-submit" onClick={(event) => {
                                event.preventDefault();
                                props.paneSettingsHandler();
                            }}>
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default EditSettingModal;