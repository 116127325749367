import React, {useState, useEffect} from "react"
import '../styles/user_management_styles.css'
import Navbar from "./Navbar.js"
import 'bootstrap/dist/css/bootstrap.min.css';
import Table from "./Table.js";
import useFetch from './useFetch.js';
import swal from 'sweetalert';
import axios from 'axios';
import $ from 'jquery';
import config from '../config.json';
import Cookie from 'universal-cookie';
import mixpanel from "mixpanel-browser";
import { decrypt } from './useCryptoJS';

const HTTPSources = (props) => {
    const cookies = new Cookie();
    const [ursource, setUrsource] = useState("0000");
    useEffect(()=>{
        setUrsource(decrypt(cookies.get('ursource')));
    }, []);
    let selectedRows = [];
    let isInEditableMode = false;
    let editableData = {};
    let actionInitiatingUser = -1;
    let userToken = "";
    try {
        userToken = cookies.get('userToken');
        actionInitiatingUser = parseInt(cookies.get('userID'));
    } catch (error) {}
    const { isPending, data: sources, error } = useFetch({ actionInitiatingUser: { userID: actionInitiatingUser.toString()}, URL: config.BACKEND_URL + '/logger_sources/sources/http' });
    const headers = {
        'Authorization': `Bearer ${userToken}`,
        'Content-Type': 'application/json'
    };

    const setEditableData = function (value) {
        editableData = value;
        $('#httpName')[0].value = value.name;
        $('#httpURL')[0].value = value.URL;
        $('#httpKey')[0].value = value.key;
        $('#httpMethod')[0].value = value.method;
        $('#httpEnable')[0].checked = value.enable;
        document.querySelector('#httpFormSubmit').innerText = 'Update';
        isInEditableMode = true;
    }

    const setSelectedRows = (rows) => {
        selectedRows = rows;
    }
    const handleDeleteButton = (e) => {
        e.preventDefault();
        mixpanel.track("HTTP Delete Button Clicked", { userID: cookies.get('userID') });
        if(ursource.charAt(3)!='1'){
            swal("No Permission", "You cannot perform delete operation", "error");
            return;
        }
        if (selectedRows.length === 0) {
            swal("Invalid selection!", "You have no selected any rows for deleting!", "error");
        }
        else {
            swal({
                title: "Are you sure?",
                text: "Do you want to delete the selected data sources?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then(async (willDelete) => {
                    if (willDelete) {
                        await axios.post(config.BACKEND_URL + '/logger_sources/sources/delete-by-ids', {
                            sourceIDs: selectedRows.map(row => row.id),
                            actionInitiatingUser
                        }, { headers })
                            .then((res) => {
                                swal("Sources deleted successfully!", "Click on OK to continue...", "success")
                                    .then(() => {
                                        window.location.reload();
                                    })
                            })
                            .catch(err => {
                                swal("Error!", "Your selected sources have not been deleted because of a server error!", "error");
                            })
                    }
                    else {
                        swal("Cancelled!", "Your selected sources have not been deleted!", "error");
                    }
                });
        }
    };
    const handleEditButton = (e) => {
        e.preventDefault();
        mixpanel.track("HTTP Edit Button Clicked", { userID: cookies.get('userID') });
        if(ursource.charAt(2)!='1'){
            swal("No Permission", "You cannot perform edit operation", "error");
            return;
        }
        if (selectedRows.length === 0) {
            swal("Please select a row to edit!", "", "error");
        }
        else if (selectedRows.length > 1) {
            swal("Please select only one row to edit!", "", "error");
        }
        else {
            setEditableData(selectedRows[0]);
        }
    }

    const handleHTTPForm = async (e) => {
        e.preventDefault();
        mixpanel.track("HTTP Submit Button Clicked", { userID: cookies.get('userID') });
        if(ursource.charAt(2)=='0' && ursource.charAt(1)=='0'){
            swal("No Permission", "You cannot perform create/edit operation", "error");
            return;
        }
        if (!isInEditableMode) {
            console.dir(e.target);
            const name = $('#httpName')[0].value;
            const URL = $('#httpURL')[0].value;
            const key = $('#httpKey')[0].value;
            const method = $('#httpMethod')[0].value;
            const enable = $('#httpEnable')[0].checked;
            // var URLValidator = URL.match('^(https?://)?(www\\.)?([-a-z0-9]{1,63}\\.)*?[a-z0-9][-a-z0-9]{0,61}[a-z0-9]\\.[a-z]{2,6}(/[-\\w@\\+\\.~#\\?&/=%]*)?$');
            var URLValidator = true;
            if (URLValidator) {
                swal({
                    title: "Are you sure?",
                    text: "Do you want to add the data source?",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                })
                    .then(async (willDelete) => {
                        if (willDelete) {
                            try {
                                // let createBody = JSON.stringify({ name, type: "HTTP", data: { URL, method, key }, enable });
                                let createBody = { name, type: "HTTP", data: { URL, method, key }, enable, actionInitiatingUser };
                                await axios.post(config.BACKEND_URL + '/logger_sources/sources/http', createBody, { headers }).then(res => {
                                    swal("Source added successfully!", "Click on OK to continue...", "success").then(() => {
                                        window.location.reload();
                                    });
                                }).catch(err => {
                                    console.log(err);
                                })
                            }
                            catch (err) {
                                console.log("Error: ", err);
                                swal("Error!", "Your data source has not been added because of an error!", "error");
                            }
                        } else {
                            swal("Data source not added!", "", "info");
                        }
                    });
            }
            else {
                swal("Invalid URL!", "Data source could not be added!", "error");
            }
        }
        else {
            const source = {
                name: $('#httpName')[0].value,
                URL: $('#httpURL')[0].value,
                key: $('#httpKey')[0].value,
                method: $('#httpMethod')[0].value,
                enable: $('#httpEnable')[0].checked,
                actionInitiatingUser
            };
            console.log(source);
            var URLValidator = true;
            if (URLValidator) {
                swal({
                    title: "Are you sure?",
                    text: "Do you want to edit the selected data source?",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                })
                    .then(async (willDelete) => {
                        if (willDelete) {
                            try {
                                await axios.patch(config.BACKEND_URL + `/logger_sources/sources/http/${editableData.id}`, source, { headers });
                                swal("Source updated successfully!", "Click on OK to refresh the page...", "success").then(() => {
                                    window.location.reload();
                                });
                            }
                            catch (err) {
                                console.log(err);
                                swal("Could not update source!", err.response.data.message, "error");
                            }
                        }
                        else {
                            swal("The selected source has not been edited!", "", "info");
                        }
                    });
            }
            else {
                swal("Invalid URL!", "Data source could not be updated!", "error");
            }
        }
    }
    return (
        <div className="HTTPSources d-flex flex-column">
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons"
                rel="stylesheet" />
            <script src='bootstrap/dist/js/bootstrap.min.js'></script>
            <Navbar />
            <header className="h2 text-center mt-4">HTTP Data Sources</header>
            <main>
                { ursource.charAt(0)==='1' &&
                    <section className="d-flex row justify-content-center p-3" style={{ width: "50%", float: "left" }}>
                        <header className="h3 text-center mb-3">Current HTTP Data Sources</header>
                        {isPending && <div className="text-center mt-5 h4">Loading...</div>}
                        {error && <div className="text-center mt-5 h4">Cannot fetch the data right now!</div>}
                        {
                            sources && sources.data && sources.data.length === 0 && <div className="text-center mt-5 h4">Currently, there are no HTTP data sources!</div> ||
                            sources && sources.columns.length > 0 &&
                            <>
                                <Table
                                    tableColumns={sources.columns}
                                    tableData={sources.data}
                                    handleDeleteButton={handleDeleteButton}
                                    handleEditButton={handleEditButton}
                                    setParentRows={setSelectedRows}
                                    shouldModifySources={true}
                                    tableID="HTTPTable" 
                                    edit={ursource.charAt(2)==='1'}
                                    deletee={ursource.charAt(3)==='1'}
                                />
                            </>
                        }
                    </section>
                }
                <section className="d-flex justify-content-center p-3" style={{ padding: "1vw", width: "50%", float: "right" }}>
                    <form id="httpForm" onSubmit={handleHTTPForm} className="row d-flex justify-content-center mb-3">
                        <header className="h2 text-center">Add an HTTP Data Source</header>
                        <div className="form-fields col-xs-4 col-xs-offset-4">
                            <p className="h4 text-center mb-2">Logging</p>
                            <div className="form-check form-switch form-switch-lg d-flex justify-content-center">
                                <label className="form-check-label mr-3" htmlFor="httpEnable">Disable</label>
                                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                <input className="form-check-input" type="checkbox" id="httpEnable" />
                                &nbsp; &nbsp;
                                <label className="form-check-label" htmlFor="httpEnable">Enable</label>
                            </div>
                            <div className="floating-label-group">
                                <input type="text" id="httpName" className="form-control" autoComplete="off" required style={{ fontWeight: "bold" }} />
                                <label className="floating-label">Enter name of the data source</label>
                            </div>
                            <div className="floating-label-group">
                                <input type="text" id="httpURL" className="form-control" autoComplete="off" required style={{ fontWeight: "bold" }} />
                                <label className="floating-label">Enter URL for data retrieval</label>
                            </div>
                            <div className="floating-label-group">
                                <input type="text" id="httpKey" className="form-control" autoComplete="off" required style={{ fontWeight: "bold" }} />
                                <label className="floating-label">Enter key for data retrieval</label>
                            </div>
                            <div className="floating-label-group">
                                <label htmlFor="httpMethod">Select HTTP method for data retrieval</label>
                                <select style={{ fontWeight: "bold" }} className="form-select" id="httpMethod" name="method" aria-label="Default select example">
                                    <option style={{ fontWeight: "bold" }} value="GET">GET</option>
                                    <option style={{ fontWeight: "bold" }} value="POST">POST</option>
                                </select>
                            </div>
                        </div>
                        <section className="d-flex justify-content-center">
                            <button id="httpFormSubmit" className="btn btn-success" type="submit">
                                Submit
                            </button>
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            <button className="btn btn-warning" type="reset">
                                Reset
                            </button>
                        </section>
                    </form>
                </section>
            </main>
        </div >
    )
};
export default HTTPSources;