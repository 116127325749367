import React, { useEffect, useState } from "react";
import '../styles/user_management_styles.css'
import Navbar from "./Navbar.js"
import 'bootstrap/dist/css/bootstrap.min.css';
import { Avatar, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormLabel, ListItem, ListItemAvatar, Radio, RadioGroup, Snackbar, TextField, Typography } from "@material-ui/core";
import axios from "axios";
import config from '../config.json';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import swal from "sweetalert";
import { IoWarningOutline } from 'react-icons/io5';
import MuiAlert from '@material-ui/lab/Alert';
import Cookies from "universal-cookie";
import mixpanel from "mixpanel-browser";

const useStyles = makeStyles(theme => ({
    root: {
      padding: theme.spacing(2),
      backgroundColor: 'lightyellow',
      maxWidth: '80%'
    },
    changeSAbutton: {
        backgroundColor: '#1e2b38',
        marginTop: '1rem',
        color: 'white',
        '&:hover': {
          backgroundColor: '#1e2b38',
        },
    },
    avatar: {
        backgroundColor: '#333',
        color: "#fff",
    },
    warningButton: {
        border: '1px solid',
        borderColor: theme.palette.warning.main,
        color: theme.palette.warning.main,
    },    
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const SuperUserPage =  () => {
    const classes = useStyles();
    const [superUserDetails, setSuperUserDetails] = useState({});
    const [users, setUsers] = useState([]);
    const cookies = new Cookies();
    let userToken = "";
    try {
        userToken = cookies.get('userToken');
    } catch (error) {}
    const headers = {
        'Authorization': `Bearer ${userToken}`,
        'Content-Type': 'application/json'
    };
    useEffect(() => {
        async function fetchData() {
            await axios.get(config.BACKEND_URL + '/superuser/sudetail', { headers }).then(res => {
                setSuperUserDetails(res.data);
            }).catch(err => {
                console.log(err);
            });
            await axios.get(config.BACKEND_URL + '/users/get-admin-users', { headers }).then(res => {
                setUsers(res.data);
            }).catch(err => {
                console.log(err);
            });
        }
        fetchData();
    }, []);

    const [open, setOpen] = useState(false);
    const [openNext, setOpenNext] = useState(false);
    const [selectedUser, setSelectedUser] = useState("-1");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        mixpanel.track("Super Page Cancel Button Clicked", { userID: cookies.get('userID') });
        setOpen(false);
    };

    const handleClear = () => {
        mixpanel.track("Super Page Clear Button Clicked", { userID: cookies.get('userID') });
        setSelectedUser("-1");
    };

    const handleCloseNext = () => {
        mixpanel.track("Change Super Administrator Cancel Button Clicked", { userID: cookies.get('userID') });
        setOpenNext(false);
    };

    const handleClick = () => {
        mixpanel.track("Super Page Assign Button Clicked", { userID: cookies.get('userID') });
        if(selectedUser === "-1"){
            handleOpenSnack();
            return;
        }
        setOpenNext(true);
        setOpen(false);
    }

    const [openSnack, setOpenSnack] = useState(false);
    const handleCloseSnack = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpenSnack(false);
    };
    const handleOpenSnack = () => {
        setOpenSnack(true);
    }

    const submit = async () => {
        let flag = false;
        mixpanel.track("Change Super Administrator Submit Button Clicked", { userID: cookies.get('userID') });
        await axios.post(config.BACKEND_URL + '/superuser/password-valid', { password }, { headers }).then(res => {
            if(res.status === 200){
                flag = true;
            } else if(res.status=== 400 ||  res.status === 500) {
                flag = false;
            }
        }).catch(err => {
            if(err.response.status === 400 || err.response.status === 500) {
                flag = false;
            }
        });
        if(!flag){
            swal("Password is Incorrect", "Please Try Again!", "error");
        } else {
            swal("Are you sure you want to continue the changing of Superuser to this admin?", "", "warning", {
                buttons: {
                    cancel: "Cancel",
                    catch: {
                        text: "Assign",
                        value: "assign",
                    },
                },
            }).then(async value => {
                if(value==='assign'){
                    handleCloseNext();
                    await axios.post(config.BACKEND_URL + "/superuser/change-superuser", { selectedUser, usernameSu: username, passwordSu: password }, { headers }).then(res => {
                        if(res.status === 200){
                            swal("Superuser Changed Successfully!", "Please Login with username: superuser and your password", "success").then(() => {
                                cookies.remove('userID', { path: '/', secure: true });
                                cookies.remove('userStatus', { path: '/', secure: true });
                                cookies.remove('urname', { path: '/', secure: true });
                                cookies.remove('uruser', { path: '/', secure: true });
                                cookies.remove('uruserGroup', { path: '/', secure: true });
                                cookies.remove('uruserDashboard', { path: '/', secure: true });
                                cookies.remove('uralarm', { path: '/', secure: true });
                                cookies.remove('urview', { path: '/', secure: true });
                                cookies.remove('ursource', { path: '/', secure: true });
                                cookies.remove('urrole', { path: '/', secure: true });
                                cookies.remove('urreport', { path: '/', secure: true });
                                axios.post(config.BACKEND_URL + "/superuser/logout", { sudata: superUserDetails }).then(() => {
                                    window.location.replace("http://localhost:3003");
                                }).catch(err => {
                                    console.log("Logout failed!", err);
                                });
                            });
                        }
                    }).catch(err => {
                        if (err.response && err.response.status === 409) {
                            swal("Database Conflict Error!", err.response.data.message, "error").then(() => {
                                window.location.reload(false);
                            });
                        } else {
                            console.log("Error: ", err);
                        }
                        console.log(err);
                    });
                }
            }).catch(err => {
                console.log(err);
            });
        }
        
    }
    return (
        <div className="Reports">
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons"
                rel="stylesheet" />
            <script src='bootstrap/dist/js/bootstrap.min.js'></script>
            <Navbar />
            <main className="container-fluid">
                <section className="d-flex row justify-content-center">
                    <div style={{marginTop: '4rem', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                        <Avatar alt="Profile Pic" src="https://images.unsplash.com/flagged/photo-1570612861542-284f4c12e75f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8cGVyc29ufGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60" 
                        style={{ width: 150, height: 150 }}  variant='square' />
                        <h2 style={{fontWeight: '600', marginBottom: '1.5rem', marginTop: '1rem', fontSize: '26px'}}>{superUserDetails.fullname}</h2>
                        <h4 style={{fontWeight: '400', fontSize: '18px', marginBottom: '1rem'}}>{superUserDetails.email}</h4>
                        <Paper className={classes.root}>
                            <Typography variant="body2">
                                <span style={{color: 'rgb(59 67 175)', fontWeight: 'bold' }}>Note: </span> The super admin has the highest level of privileges across the organization. Only you can transfer this role to any other administrator in your organization
                            </Typography>
                        </Paper>
                        <Button variant="contained"  className={classes.changeSAbutton} onClick = {handleClickOpen} >
                            Change Super Admin
                        </Button>
                        <Dialog open={open} onClose={handleClose}>
                            <DialogTitle>Assign Super Admin</DialogTitle>
                            <DialogContent>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">Users</FormLabel>
                                    <RadioGroup
                                        aria-label="user"
                                        name="user"
                                        value={selectedUser}
                                        onChange={(event) => setSelectedUser(event.target.value)}
                                        >
                                        {users.map((user) => (
                                            <ListItem key={user.id.toString()}>
                                                <ListItemAvatar>
                                                    <Avatar className={classes.avatar}>{user.fullname.charAt(0).toUpperCase()}</Avatar>
                                                </ListItemAvatar>
                                                <FormControlLabel
                                                    value={user.id.toString()}
                                                    control={<Radio />}
                                                    label={user.fullname.toUpperCase()}
                                                />
                                            </ListItem>
                                        ))}
                                    </RadioGroup>
                                </FormControl>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} variant='outlined' color="secondary">
                                    Cancel
                                </Button>
                                <Button onClick={handleClear} className={classes.warningButton}>
                                    Clear
                                </Button>
                                <Button onClick={handleClick} variant='outlined' color="primary">
                                    Assign
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Dialog open={openNext} onClose={handleCloseNext}>
                            <DialogTitle style={{ color: '#d92d2d'}}> <IoWarningOutline style={{marginBottom: '3px'}} /> Change Super Administrator</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Please Enter Your Superuser password and you are also required to provide new username
                                </DialogContentText>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="username"
                                    label="Username"
                                    type="text"
                                    fullWidth
                                    variant="standard"
                                    value={username}
                                    onChange = { e => setUsername(e.target.value) }
                                />
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="password"
                                    label="Password"
                                    type="password"
                                    fullWidth
                                    variant="standard"
                                    value = {password}
                                    onChange = { e => setPassword(e.target.value) }
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseNext} variant='outlined' color="secondary" >Cancel</Button>
                                <Button onClick={submit} variant='contained' color="primary" >Submit</Button>
                            </DialogActions>
                        </Dialog>
                        <Snackbar open={openSnack} autoHideDuration={3000} onClose={handleCloseSnack}>
                            <Alert severity="error">Please Select One User!</Alert>
                        </Snackbar>
                    </div>
                </section>
            </main>
        </div >
    );
}

export default SuperUserPage;