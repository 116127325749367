import React, { useEffect, useState } from "react";
import Gauge from "react-gaugejs";
import axios from "axios";
import config from "../config.json";

const CustomGauge = ({ gaugeSetting, source }) => {
  const [value, setValue] = useState(0);
  const getGaugeData = async () => {
    if (source) {
      await axios
        .post(config.BACKEND_URL + "/widgets/get-custom-gauge-data", { source })
        .then((res) => {
          setValue(res.data.value);
        })
        .catch((err) => console.log(err));
    } else {
      setValue(0);
    }
  };
  useEffect(() => {
    getGaugeData();
    const interval = setInterval(() => {
      getGaugeData();
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <>
        <p>Value: {value} {gaugeSetting.unit}</p>
      <Gauge
        value={value}
        minValue={gaugeSetting.minValue ? parseFloat(gaugeSetting.minValue) : 0}
        maxValue={
          gaugeSetting.maxValue ? parseFloat(gaugeSetting.maxValue) : 1000
        }
        animationSpeed={
          gaugeSetting.animationSpeed
            ? parseFloat(gaugeSetting.animationSpeed)
            : 32
        }
        options={{
          angle: gaugeSetting.angle ? parseFloat(gaugeSetting.angle) : -0.3,
          lineWidth: gaugeSetting.lineWidth
            ? parseFloat(gaugeSetting.lineWidth)
            : 0.15,
          radiusScale: gaugeSetting.radiusScale
            ? parseFloat(gaugeSetting.radiusScale)
            : 1,
          pointer: {
            length: gaugeSetting.pointerLength
              ? parseFloat(gaugeSetting.pointerLength)
              : 0.5,
            strokeWidth: gaugeSetting.pointerStrokeWidth
              ? parseFloat(gaugeSetting.pointerStrokeWidth)
              : 0.035,
            color: gaugeSetting.pointerColor
              ? gaugeSetting.pointerColor
              : "#000000",
          },
          renderTicks: {
            divisions: gaugeSetting.divisions
              ? parseFloat(gaugeSetting.divisions)
              : 5,
            divWidth: gaugeSetting.divWidth
              ? parseFloat(gaugeSetting.divWidth)
              : 1.1,
            divLength: gaugeSetting.divLength
              ? parseFloat(gaugeSetting.divLength)
              : 0.7,
            divColor: gaugeSetting.divColor ? gaugeSetting.divColor : "#333333",
            subDivisions: gaugeSetting.subDivisions
              ? parseFloat(gaugeSetting.subDivisions)
              : 3,
            subLength: gaugeSetting.subLength
              ? parseFloat(gaugeSetting.subLength)
              : 0.5,
            subWidth: gaugeSetting.subWidth
              ? parseFloat(gaugeSetting.subWidth)
              : 0.6,
            subColor: gaugeSetting.subColor ? gaugeSetting.subColor : "#666666",
          },
          limitMax: gaugeSetting.limitMax ? gaugeSetting.limitMax : false,
          limitMin: gaugeSetting.limitMin ? gaugeSetting.limitMin : false,
          colorStart: gaugeSetting.colorStart
            ? gaugeSetting.colorStart
            : "#6F6EA0",
          colorStop: gaugeSetting.colorStop
            ? gaugeSetting.colorStop
            : "#C0C0DB",
          strokeColor: gaugeSetting.strokeColor
            ? gaugeSetting.strokeColor
            : "#EEEEEE",
          generateGradient: gaugeSetting.generateGradient
            ? gaugeSetting.generateGradient
            : true,
        }}
        className="gauge-canvas"
        style={{width:'100%', height:'100%' }}
        donut={gaugeSetting.donut ? gaugeSetting.donut : false}
      />
    </>
  );
};

export default CustomGauge;
