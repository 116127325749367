// PopupComponent.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from "../config.json";

const PopupComponent = ({ datasets }) => {
    const [value, setValue] = useState(0);
    const [showPopup, setShowPopup] = useState();
    const postCustomFormData = async () => {
        try {
            const res = await axios.post(`${config.BACKEND_URL}/widgets/post-custom-form-data`, { });
            
            setValue(res.data.value);
            
        } catch (err) {
            console.error(err);
        }
    };

    const handleSubmit = async () => {
        
    };

    useEffect(() => {
        //postCustomFormData();
        //const interval = setInterval(postCustomFormData, 3000);

        //return () => clearInterval(interval);
    }, []);

    return (
        <div className="popup">
            <div className="popup-inner">
                <h2>Popup Title</h2>
                <form>
                    {/* Add your form fields here */}
                </form>
                <button onClick={() => setShowPopup(false)}>Close</button>
                <button onClick={() => setShowPopup(false)}>Submit</button>
            </div>
        </div>
    );
}

export default PopupComponent;