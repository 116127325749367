import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect } from 'react';
import { TabulatorFull as Tabulator } from "tabulator-tables";
import "tabulator-tables/dist/css/tabulator.min.css";
import config from '../../config.json';
import axios from 'axios'
import Cookies from 'universal-cookie';
var table;

const cookies = new Cookies();
const userToken = cookies.get('userToken');
const headers = {
  'Authorization': `Bearer ${userToken}`,
  'Content-Type': 'application/json'
};

const UserGroupTable = (props) => {
  const columns = [
    { formatter: "rownum", align: "center", width: 70 },
    { title: "id", field: "id", visible: false },
    { title: "Username", field: "name", headerFilter:"input" }
  ];
  useEffect(() => {
    async function fetchData() {
      let Users = [];
      axios.get(config.BACKEND_URL + "/users", { headers })
        .then(res => {
          Users = res.data.data;
          table.setData(Users);
          if (props.highlightedUserIDs.length > 0) {
            table.selectRow(table.getRows().filter(row => props.highlightedUserIDs.includes(row.getData().id)));
          }
        })
        .catch(err => {
          console.log(err);
        })
    }
    table = new Tabulator("#user-group-table", {
      layout: "fitColumns",
      columns: columns,
      pagination: "local",
      paginationSize: 10,
      paginationSizeSelector: [25, 50, 75, 100],
      tooltips: true,
      responsiveLayout: true,
      selectableRows: true
    });

    table.on('tableBuilt', fetchData);
    table.on('rowSelectionChanged', () => {
      props.setSelectedUserRows(table.getSelectedData().map(row => row.id));
    })

  }, [props.highlightedUserIDs])

  return (
    <>
      <div className="UserGroupTable">
        <div style={{ 'padding': '25px' }}>
          <div id="user-group-table"></div>
        </div>
      </div>

    </>
  );
}

export default UserGroupTable;