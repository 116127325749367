import React, { useState, useEffect } from "react"
import '../styles/user_management_styles.css';
import Navbar from "./Navbar.js";
import 'bootstrap/dist/css/bootstrap.min.css';
import Table from "./Table";
import useFetch from "./useFetch";
import swal from 'sweetalert';
import axios from "axios";
import $ from 'jquery';
import config from '../config.json';
import Cookie from 'universal-cookie';
import { decrypt } from './useCryptoJS';
import mixpanel from "mixpanel-browser";

let currentUserGroupName = '';
let currentUserGroup = 0;
let selectedDashboardGroups = [];
let selectedDashboards = [];
let isInEditableMode = false;

const UserManagement_CreateDashboardGroup = () => {
    const cookies = new Cookie();
    let userToken = "", uruserDashboard = "0000";
    let actionInitiatingUser = -1;
    try {
        userToken = cookies.get('userToken');
        uruserDashboard = decrypt(cookies.get('uruserDashboard'));
        actionInitiatingUser = parseInt(cookies.get('userID'));
    } catch (error) {}
    
    const headers = {
        'Authorization': `Bearer ${userToken}`,
        'Content-Type': 'application/json'
    };
    const { data: userGroups, isPending: isPendingUserGroups, error: userGroupsError } = useFetch({ actionInitiatingUser: { userID: actionInitiatingUser }, URL: config.BACKEND_URL + '/usergroups' });
    const { data: dashboardGroups, isPending: isPendingDashboardGroups, error: dashboardGroupsError } = useFetch({ actionInitiatingUser: { userID: actionInitiatingUser }, URL: config.BACKEND_URL + '/dashboardgroups' });
    const { data: dashboards, isPending: isPendingDashboards, error: dashboardsError } = useFetch({ actionInitiatingUser: { userID: actionInitiatingUser }, URL: config.BACKEND_URL + '/dashboards' });
    const [highlightedRowIDs, setHighlightedRowIDs] = useState([]);
    const createPer = uruserDashboard.charAt(1)==='1';

    const findUserGroup = () => {
        currentUserGroup = currentUserGroupName !== "" ? userGroups.data.filter(g => g.name === currentUserGroupName)[0] : null;
    }

    const setUserGroupName = (e) => {
        e.persist();
        currentUserGroupName = e.target.value === "selectUserGroupText" ? "" : e.target.value;
        findUserGroup();
    }

    const setDashboards = (dashboards) => {
        selectedDashboards = dashboards.map(board => board.id);
    }

    const setSelectedDashboardGroups = (dashboardGroups) => {
        selectedDashboardGroups = dashboardGroups.map(group => group.id);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        mixpanel.track("Dashboard Group Submit Button Clicked", { userID: cookies.get('userID') });
        if (currentUserGroup === null) {
            swal("Please select a user group!");
        } else if (selectedDashboards.length === 0) {
            swal("Please select at least one dashboard!");
        } else if (isInEditableMode && uruserDashboard.charAt(2)!='1'){
            swal("No Permission", "You cannot Edit Usergroup", "error");
        } else if(!isInEditableMode && !createPer){
            swal("No Permission", "You cannot Create Usergroup", "error");
        } else {
            if (!isInEditableMode) {
                const data = {
                    userGroupId: currentUserGroup.id,
                    dashboardIDs: selectedDashboards,
                    name: $('#name')[0].value,
                    actionInitiatingUser
                }
                axios.post(config.BACKEND_URL + '/dashboardgroups', data, { headers })
                    .then(res => {
                        swal("Dashboard group created successfully!", "", "success")
                            .then(() => {
                                window.location.reload();
                            });
                    })
                    .catch(err => {
                        console.log(err);
                        swal("Error creating dashboard group!", "", "error");
                    })
            } else {
                // Create a patch request for the dashboard group
                currentUserGroupName = $('#usergroups')[0].value === "selectUserGroupText" ? "" : $('#usergroups')[0].value;
                findUserGroup();
                const editedDashboardGroup = {
                    dashboardIDs: selectedDashboards,
                    name: $('#name')[0].value,
                    id: selectedDashboardGroups[0],
                    userGroupId: currentUserGroup.id,
                    actionInitiatingUser
                }
                // PATCH request to /dashboardgroups/:id
                axios.patch(config.BACKEND_URL + `/dashboardgroups/${editedDashboardGroup.id}`, editedDashboardGroup, { headers })
                    .then(res => {
                        swal("Dashboard group edited successfully!", "", "success")
                            .then(() => {
                                window.location.reload();
                            });
                    })
                    .catch(err => {
                        console.log(err);
                        swal("Error editing dashboard group!", "", "error");
                    })
            }
        }
    }

    const handleEdit = (e) => {
        e.preventDefault();
        mixpanel.track("Dashboard Group Edit Button Clicked", { userID: cookies.get('userID') });
        isInEditableMode = true;
        if (selectedDashboardGroups.length === 0) {
            swal("Please select a dashboard group!", "", "error");
        }
        else if (selectedDashboardGroups.length > 1) {
            swal("Please select only one dashboard group!", "", "error");
        }
        else {
            let dashboardGroup = dashboardGroups.data.filter(g => g.id === selectedDashboardGroups[0])[0];
            $('#name')[0].value = dashboardGroup.name;
            $('#usergroups')[0].value = userGroups.data.filter(group => group.id === dashboardGroup.userGroupId)[0].name;
            $('#submit')[0].textContent = 'Update';
            // setHighlightedRowIDs(dashboardGroup.dashboardIDs);
        }
    }

    const handleDelete = (e) => {
        mixpanel.track("Dashboard Group Delete Button Clicked", { userID: cookies.get('userID') });
        swal(
            {
                title: "Are you sure?",
                text: "Once deleted, you will not be able to recover these dashboard groups!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }
        ).then((willDelete) => {
            if (willDelete) {
                axios.post(config.BACKEND_URL + '/dashboardgroups/delete-by-ids', {
                    dashboardGroupIDs: selectedDashboardGroups,
                    actionInitiatingUser
                }, { headers })
                    .then(res => {
                        swal("Dashboard groups deleted successfully!", "", "success")
                            .then(() => {
                                window.location.reload(true);
                            })
                    })
                    .catch(err => {
                        console.log(err);
                        swal("Error deleting dashboard group!", "", "error");
                    })
            } else {
                swal("Dashboard group was not deleted!");
            }
        });
    }

    return (
        <div className="UserManagement_CreateDashboardGroup">
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons"
                rel="stylesheet" />
            <Navbar />
            <main className="container-fluid" style={{ 'textAlign': 'center' }}>
                <header className="h2 mt-3">Dashboard Group Management</header>
                <section className="row">
                    {/* ---------User Table Start----------------------- */}
                    <div className="col-sm-4 p-2 justify-content-center" style={{ height: "auto" }}>
                        { uruserDashboard.charAt(0)==='1' &&
                        <div>
                            <header className="h3 text-center mb-1">All Dashboards</header>
                            <section className="d-flex row justify-content-center" style={{ width: "100%", float: "left" }}>
                                {isPendingDashboards && <div className="text-center h4 mt-5 mb-3">Loading...</div>}
                                {dashboardsError && <div className="text-center h4 mt-5 mb-3">Could not fetch the dashboard right now!</div>}
                                {
                                    dashboards && dashboards.data.length === 0 && <div className="text-center h4 mt-5 mb-3">Currently, there are no dashboards!</div> ||
                                    dashboards && dashboards.columns.length > 0 &&
                                    // !setAllDashboards(dashboards) &&
                                    console.log(dashboards) === undefined &&
                                    <Table
                                        shouldDisplay={true}
                                        isSelectable={true}
                                        shouldDownload={false}
                                        tableData={dashboards.data}
                                        tableColumns={dashboards.columns}
                                        shouldModifySources={false}
                                        displaySerial={true}
                                        setParentRows={setDashboards}
                                        highlightedRows={highlightedRowIDs}
                                        tableID="allDashboards"
                                    />
                                }
                            </section>
                        </div>
                        }
                    </div>
                    {/* --------- Table End  -------------------------- */}


                    <div className="col-sm-4 p-2 d-flex justify-content-center" style={{ height: "auto" }}>
                        <form action="" className="d-flex flex-column justify-content-center align-items-center">
                            <p className="h3 mb-0">Create Dashboard Group</p>
                            {isPendingUserGroups && <div className="text-center h4 mt-5">Loading...</div>}
                            {userGroupsError && <div className="text-center h4 mt-5">Could not fetch the user groups!</div>}
                            {userGroups && !isPendingUserGroups &&
                                <select onClick={setUserGroupName} className="mt-5 form-select" style={{ width: "auto" }} name="usergroups" id="usergroups">
                                    <option value="selectUserGroupText">Choose user group</option>
                                    {userGroups.data.map((group, index) => {
                                        return <option key={group.name} value={group.name}>{group.name}</option>
                                    })}
                                </select>
                            }
                            <div className="col-xs-4 col-xs-offset-4">
                                <img src="" alt="" />
                                <div className="floating-label-group">
                                    <input type="text" id="name" className="form-control" autoComplete="off" required style={{ fontWeight: "bold" }} defaultValue="" />
                                    <label className="floating-label">Group Name</label>
                                </div>
                            </div>
                            <div>
                                <button type="submit" className="btn btn-success" id="submit" onClick={handleSubmit}>Submit</button> &nbsp;
                                <button type="cancel" className="btn btn-danger">Cancel</button>
                            </div>
                        </form>
                    </div>

                    {/* ---------Group Table Start----------------------- */}
                    <div className="col-sm-4 p-2 justify-content-center" style={{ height: "auto" }}>
                        <header className="h3 text-center mb-1">All Dashboard Groups</header>
                        <section className="d-flex row justify-content-center" style={{ width: "100%", float: "left" }}>
                            {isPendingDashboardGroups && <div className="text-center h4 mt-5 mb-3">Loading...</div>}
                            {dashboardGroupsError && <div className="text-center h4 mt-5 mb-3">Could not fetch the dashboard groups right now!</div>}
                            {
                                dashboardGroups && dashboardGroups.data.length === 0 && <div className="text-center h4 mt-5 mb-3">Currently, there are no dashboard groups!</div> ||
                                dashboardGroups && dashboardGroups.columns.length > 0 &&
                                <Table
                                    shouldDisplay={true}
                                    isSelectable={true}
                                    setParentRows={setSelectedDashboardGroups}
                                    shouldDownload={false}
                                    handleDeleteButton={handleDelete}
                                    handleEditButton={handleEdit}
                                    tableData={dashboardGroups.data}
                                    tableColumns={dashboardGroups.columns}
                                    shouldModifySources={true}
                                    displaySerial={true}
                                    tableID="allDashboardGroups"
                                    edit={uruserDashboard.charAt(2)==='1'}
                                    deletee={uruserDashboard.charAt(3)==='1'}
                                />
                            }
                        </section>
                    </div>
                    {/* --------- Table End  -------------------------*/}

                </section>
            </main>
        </div >
    )
}

export default UserManagement_CreateDashboardGroup;