import React from "react"
import '../styles/user_management_styles.css'
import Navbar from "./Navbar.js"
import 'bootstrap/dist/css/bootstrap.min.css';
import UserGroupTable from "./tables/UserGroupTable.js";
import GroupTable from "./tables/GroupTable.js";
import swal from 'sweetalert';
import "../styles/form_styles.css";
import { useState, useEffect } from "react"
import Axios from "axios"
import axios from "axios";
import $ from 'jquery';
import config from '../config.json';
import Cookie from 'universal-cookie';
import { decrypt } from './useCryptoJS';
import mixpanel from 'mixpanel-browser';

let selectedUserIDs = [];
let textInput = React.createRef();
global.change = [null]
var flag = false

const UserManagement_CreateUserGroup = () => {
    const cookies = new Cookie();
    let userToken = "";
    let actionInitiatingUser = -1;
    try {
        userToken = cookies.get('userToken');
        actionInitiatingUser = parseInt(cookies.get('userID'));
    } catch (error) {}
    const headers = {
        'Authorization': `Bearer ${userToken}`,
        'Content-Type': 'application/json'
    };
    const [uruserGroup, setUruserGroup] = useState("0000");
    const [createPer, setCreatePer] = useState(false);
    const [highlightedUserIDs, setHighlightedRowIDs] = useState([]);

    useEffect(()=>{
        try {
            setUruserGroup(decrypt(cookies.get('uruserGroup')));
            setCreatePer(uruserGroup.charAt(1)==='1');
        } catch (error) {}
    }, []);

    const cancel = () => {
        window.location.reload(false);
    }

    const setRowEventListener = () => {
        const rows = $('#Group-table')[0].childNodes[1].children[0].children;
        for (let row of rows) {
            row.addEventListener('click', (e) => {
                if (row.classList.contains("tabulator-selected")) {
                    row.classList.remove("tabulator-selected");
                    global.selectedUserIDs.splice(global.selectedUserIDs.indexOf(parseInt(row.childNodes[1].textContent)), 1)
                }
                else {
                    row.classList.add("tabulator-selected");
                    global.selectedUserIDs.push(parseInt(row.childNodes[1].textContent))
                }
            })
        }
    }

    const setSelectedUserRows = (selectedRows) => {
        selectedUserIDs = selectedRows;
    }

    const Edit = async (e) => {
        mixpanel.track("User Group Edit Button Clicked", { userID: cookies.get('userID') });
        flag = true;
        if (global.groupid.length === 0) {
            setCreatePer(false);
            swal("No Group Selected", "Please select a group!", "error");
        }
        else {
            setCreatePer(true);
            const id = global.groupid[0];
            let userIDs = [];
            axios.get(config.BACKEND_URL + `/usergroups/${id}`, { headers })
                .then(res => {
                    userIDs = res.data.userIDs;
                    $("#submit")[0].textContent = "Update";
                    $('#name')[0].value = res.data.name;
                    global.selectedUserIDs = userIDs;
                    setHighlightedRowIDs(userIDs);
                    return;
                })
                .catch(err => {
                    console.log('Error: ', err);
                    return;
                })
        }
    }

    const Delete = async () => {
        mixpanel.track("User Group Delete Button Clicked", { userID: cookies.get('userID') });
        if (global.groupid.length === 0) {
            swal("No Group Selected", "Please select a group!", "error")
        }
        else {
            const id = global.groupid[0]
            swal("Are you sure?", "Once deleted, you will not be able to recover these groups!", "warning", {
                buttons: {
                    cancel: "Cancel",
                    catch: {
                        text: "Delete",
                        value: "delete",
                    },
                },
            })
                .then((value) => {
                    switch (value) {
                        case "delete":
                            Axios.delete(config.BACKEND_URL + `/usergroups/${id}?actionInitiatingUser=${actionInitiatingUser}`, { headers })
                                .then((res) => {
                                    swal("Group deleted successfully!", "", "success")
                                        .then(() => {
                                            window.location.reload(false);
                                        })
                                })
                                .catch(err => {
                                    console.log('Error: ', err);
                                })
                            break;
                        default:
                            swal("Delete operation cancelled!", "", "info");
                            return;
                    }
                })
        }
    }

    const submit = async (e) => {
        e.preventDefault();
        mixpanel.track("User Group Submit Button Clicked", { userID: cookies.get('userID') });
        var groupName = textInput.current.value;
        if (flag) {
            if (groupName === "") {
                swal("No group name entered!", "Process Failed", "error")
            }
            else {
                const id = global.groupid[0]
                swal("Are you sure you want to update this group?", "", "warning", {
                    buttons: {
                        cancel: "Cancel",
                        catch: {
                            text: "Update",
                            value: "update",
                        },
                    },
                })
                    .then((value) => {
                        switch (value) {
                            case "update":
                                Axios.patch(config.BACKEND_URL + `/usergroups/${id}`, {
                                    name: groupName,
                                    userIDs: selectedUserIDs,
                                    actionInitiatingUser
                                }, { headers })
                                    .then((res) => {
                                        swal("Group updated successfully", "", "success")
                                            .then(() => {
                                                window.location.reload(false);
                                            })
                                    })
                                    .catch(err => {
                                        console.log('Error: ', err);
                                    });
                                break;
                            default:
                                swal("Update operation cancelled!", "");
                                return;
                        }
                    })
            }
        }
        else {
            if (groupName === "") {
                swal("No group name entered!", "Process Failed", "error")
            }
            else if (selectedUserIDs.length === 0) {
                swal("No user selected!", "Process Failed", "error")
            }
            else {
                Axios.post(config.BACKEND_URL + "/usergroups", {
                    groupName,
                    userIDs: selectedUserIDs,
                    actionInitiatingUser
                }, { headers })
                    .then((res) => {
                        swal("Group created successfully", "", "success")
                            .then(() => {
                                window.location.reload(false);
                            });
                    })
                    .catch(err => {
                        console.log('Error: ', err);
                        swal("Group Already Exist", "Process Failed", "error")
                    })
            }
        }
    }
    return (
        <div className="UserManagement_CreateUserGroup pt-2">
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons"
                rel="stylesheet" />
            <Navbar />
            <main className="container-fluid" style={{ 'textAlign': 'center' }}>
                <header className="h2 mt-3">User Group Management</header>
                <section className="row">
                    <div className="col-sm-4 p-2 justify-content-center" style={{ height: "auto" }}>
                        <div>
                            <header className="h3 text-center mb-1">All Users</header>
                            <section className="d-flex row justify-content-center" style={{ width: "100%", float: "left" }}>
                                {setSelectedUserRows &&
                                    <UserGroupTable
                                        setSelectedUserRows={setSelectedUserRows}
                                        highlightedUserIDs={highlightedUserIDs} />
                                }
                            </section>
                        </div>
                    </div>

                    <div className="col-sm-4 p-2 d-flex justify-content-center" style={{ height: "auto" }}>
                        { uruserGroup.charAt(1)==='1' && 
                            <form action="" className="d-flex flex-column justify-content-center align-items-center">
                                <p className="h3 mb-0">Create User Group</p>
                                <div className="col-xs-4 col-xs-offset-4">
                                    <img src="" alt="" />
                                    <div className="floating-label-group">
                                        <input type="text" id="name" className="form-control" autoComplete="off" required style={{ fontWeight: "bold" }} ref={textInput} />
                                        <label className="floating-label">Group Name</label>
                                    </div>
                                </div>
                                <div>
                                    <button type="submit" className="btn btn-success" id="submit" onClick={submit}>Submit</button> &nbsp;
                                    <button type="cancel" className="btn btn-danger" onClick={cancel}>Cancel</button>
                                </div>
                            </form>
                        }
                    </div>

                    {/* ---------Group Table Start----------------------- */}
                    <div className="col-sm-4 p-2 justify-content-center" style={{ height: "auto" }}>
                        <div>
                            <header className="h3 text-center mb-1">All Groups</header>
                            <section className="d-flex row justify-content-center" style={{ width: "100%", float: "left" }}>
                                {(setRowEventListener && uruserGroup.charAt(0)==='1') &&
                                    <GroupTable setRowEventListener={setRowEventListener} />
                                }
                                <section className="d-flex justify-content-center mt-2">
                                    {uruserGroup.charAt(2)==='1' && <button className="btn btn-primary d-flex align-items-center justify-content-center" onClick={Edit} >Edit</button>}
                                    &nbsp;
                                    &nbsp;
                                    &nbsp;
                                    {uruserGroup.charAt(3)==='1' && <button className="btn btn-danger d-flex align-items-center justify-content-center" onClick={Delete}>Delete</button>}
                                </section>
                            </section>
                        </div>
                    </div>
                    {/* --------- Table End  -------------------------*/}

                </section>
            </main>
        </div >
    )
}
export default UserManagement_CreateUserGroup;