const cryptoJS = require('crypto-js');
const secretKey = 'Embedos Engineering LLP';
exports.encrypt = (text) => {
    return cryptoJS.AES.encrypt(text.toString(), secretKey).toString();
}

exports.decrypt = (text) => {
    if ([undefined, null].includes(text)) {
        return new Error("Text is not defined.");
    }
    return cryptoJS.AES.decrypt(text.toString(), secretKey).toString(cryptoJS.enc.Utf8);
}